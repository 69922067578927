import React from "react";
import { useTable } from "react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import styled from "styled-components";
import GPAP_InfoIcon from "../../../gpap-lib/components/GPAP_InfoIcon";
import BootstrapTooltip from "../../../gpap-lib/components/BootstrapTooltip";

const SimpleTable = ({ columns, states, data = {} }) => {
  const [expandedRows, setExpandedRows] = React.useState({});

  //const indexOriginal =
  //  states?.dataFilter[states?.idExpanded.id]?.originalIndex;


  if (data.every((item) => Array.isArray(item))) {
    if (data[0].every((item) => Array.isArray(item))) {
      console.log(data[0]);
    }
  }

  const transformData = (data) => {
    const test = data.reduce((acc, item) => {
      for (let key in item) {
        acc[key] = item[key];
      }

      return acc;
    }, {});

    return [test];
  };

  const getInfoIcon =(title) => {

    return <GPAP_InfoIcon title={title}/>

  }

  const dataNew = transformData(data);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: dataNew });

 
  return (
    <TableContainer
      onWheel={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
      onTouchStart={(e) => e.stopPropagation()}
      onTouchMove={(e) => e.stopPropagation()}
    >
      <Table {...getTableProps()}>
      <TableHeadCustom >
          {headerGroups.map((headerGroup) => (
            <TableRow
              {...headerGroup.getHeaderGroupProps()}
              style={{ maxWidth: "20px", overflow: "hidden" }}
            >
              {headerGroup.headers.map((column) => (
                    <TableCell
                    {...column.getHeaderProps()}
                    style={{ maxWidth: "20px", overflow: "hidden" }}
                  >
                      {column.tooltip
                          ? (<div style={{display: "inline-block"}}>
                            <BootstrapTooltip title={column.tooltip} forceUnderline={true}>{column.render("Header")}
                            </BootstrapTooltip>
                          </div>)
                          : <div style={{display: "inline-block"}}> {column.render("Header")}</div>
                      }
                  </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHeadCustom>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);

            return (
              <TableRow
                {...row.getRowProps()}
                style={{ maxWidth: "20px", overflow: "hidden" }}
              >
                {row.cells.map((cell) => {
                  let truncate = false;
                  let content;
                  if (Array.isArray(cell?.value)) {
                    content = cell?.value.join(", ");

                    if (content.length > 50) {
                      truncate = true;
                    }
                  }

                  if (cell.value?.length > 50) {
                    truncate = true;
                    content = cell.render("Cell");
                  } else {
                    content = cell.render("Cell");
                  }

                 
  return (
                <TableCellCustom
                  {...cell.getCellProps()}
                  isTruncated={truncate} 
                  style={{
                    maxWidth: "20px",
                  }}
                >
                  <Tooltip
                    title={truncate ? content : ""}
                    style={{ textOverflow: "ellipsis" }}
                  >
                    <div className="text-cell">{content}</div>
                  </Tooltip>
                </TableCellCustom>
              );
            })}

              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SimpleTable;

const TableHeadCustom = styled(TableHead)`
  cursor: pointer;
  
  & > tr {
    background-color: #EAEAEA;
    & > th {
      color: black;
    }
  }
`
const TableCellCustom = styled(TableCell)`
  cursor: pointer;
  
  & > div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    ${(props) =>
      props.isTruncated &&
      `
      text-decoration: underline;
      text-decoration-style: dotted;
      text-decoration-color: #BDBDBD;
      text-underline-offset: 3px;
    `}
  }

  & > td {
    div {
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;