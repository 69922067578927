import {
    cnv_variant_type_opts,
    inheritance_opts,
    origin_opts,
    significance_opts_germline,significance_opts_somatic,
    type_opts, variant_type_opts,
    zygosity_opts
} from "../tag_form_options";

export const createDataOptions = (samples, genes, transcripts, dataType, analysisType) => ({
    samples,
    genes:genes.map(g => {return { label: g, value: g }}),
    transcripts,
    zygosity_opts,
    inheritance_opts,
    significance_opts: ((dataType.includes("Somatic") || analysisType === "instand")
        ? significance_opts_somatic
        : significance_opts_germline ),
    origin: origin_opts,
    variant_type: type_opts,
    sv_type: dataType === "CNV" ? cnv_variant_type_opts : variant_type_opts,
});