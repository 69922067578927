

import React, {useEffect, useState} from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";

// Project-specific components and utilities
import GPAP_InfoIcon from "../../../../../../../../../gpap-lib/components/GPAP_InfoIcon";
import { get_pathways_reactome } from "../../../../../../../../../services/api/variant_external_calls";
import MappedText from "../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";

// Styles
import "./cards.css";


function PathwaysReactome(props){


    const [data, setData] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(function(){

        if(props.input){
            setData({searchHit: []});
            get_pathways_reactome(props.input)
                .then(r => {
                    setLoading(true);
                    return r.json()
                })
                .then(function(json){
                    setLoading(false);
                    setData(json);
                })
        }




    }, [props.input]);

    const renderLinks = (d) => {

        if(d!== undefined){
            // if there is a gene name;
            if(props.input){
                if(d.searchHit.length!== 0 ){
                    return d.searchHit.map(function(s){
                        let uri = s.uri;
                        let val = uri.split("/");
                        let link = (val!==undefined && val.length!== 0) ? val[val.length-1] : "";
                        return <div style={{paddingTop:"10px"}}><Link target={"_blank"} href={"https://reactome.org/PathwayBrowser/#/" + link}>{s.name}</Link></div>

                    } )
                }
                else{
                    if(props.input){
                        return <Box p={1}>
                            <Typography variant={"subtitle2"}>Query for {props.input} didn't return any associations</Typography>
                        </Box>
                    }
                    else{
                        return <Box p={1}>
                            <Typography variant={"subtitle2"}>No variant selected</Typography>
                        </Box>
                    }

                }

            }
            else{
                return <Typography variant={"subtitle2"}>No variant selected</Typography>
            }
        }
        else{
            return null;
        }


    };

    const renderLinksDivs = () => {


        return <div className={"boxResults"}> {(data) ? renderLinks(data) : <MappedText text="No Associations"/>} </div>

    }

    return  <Grid item lg={12}>
        <div className={"my_paper_box"}>
            <Box p={1}>
                <Typography variant={"body1"}>
                    <MappedText text="Pathways Reactome" /> {(data) ? "(" + data.numHits + ")": ""}
                    <GPAP_InfoIcon style={{float: "right"}} title={<MappedText text="Reactome is a free, open-source, curated and peer-reviewed pathway database" />}/> 
                </Typography>
                <Divider/>
                {
                    (loading)
                        ? <CircularProgress color="secondary" />
                        : renderLinksDivs()}
            </Box>
        </div>
    </Grid>

}


export default PathwaysReactome;