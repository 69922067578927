import MappedText
    from "../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import React from "react";


export const sectionHeader = (text, required) => {
    return <div className={"section_header"}>
        {text}
        {(required) ? <div className={"required"}><MappedText text={"Required"}/></div> : null }
    </div>
};