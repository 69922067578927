



import React from "react";
import { connect } from "react-redux";
import Inheritance_Mode from "./Inheritance_Mode";

// MUI5
import Fade from "@mui/material/Fade";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import cardStyles from "../card-style";


const Inheritance_Mode_renderer = (props) => {

    const classes = cardStyles();
    const  w  = props.full_size;

    let width = "100%";

    if(props.render){
        return (
            <Fade in={true} timeout={300}>
                <Card className={classes.root} variant="outlined">
                    <CardContent style={{width: width}}>
                        <Inheritance_Mode instandGeneticFindingsTab={props.instandGeneticFindingsTab}/>
                    </CardContent>
                </Card>
            </Fade>
           )

    }
    else{
        return null
    }


};



function samples_added (state) {
    if(state.sample_list.samples.length!==0)
    {
        return {
            render:true
        }
    }
    else{ return {render:false}}

}

export default connect(samples_added,null)(Inheritance_Mode_renderer);







