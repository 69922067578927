import {cnv_variant_type_opts, origin_opts, type_opts, variant_type_opts, zygosity_opts} from "../tag_form_options";

export const getInitialData = (dataType) => ({
    sample: "None",
    rs: "",
    gene: "None",
    chromosome: "None",
    transcript: "None",
    zygosity: zygosity_opts[0].label,
    inheritance: "",
    cDNA: "",
    protein: "",
    origin: origin_opts[0].label,
    significance: "",
    variant_source: "GPAP",
    comments: "",
    variant_type: type_opts[0].label,
    sv_type: dataType === "CNV" ? cnv_variant_type_opts[0].label : variant_type_opts[0].label,
    project: ""
});
