export const handleSNVVariant = (variant, samples, genes, transcripts, cDNA, protein) => {
    return {
        sample: samples.length > 0 ? samples[0].value : "None",
        chromosome: variant["chrom"],
        gene: genes.length > 0 ? genes[0] : "None",
        ref: variant["ref"],
        rs: variant["rs"],
        alt: variant["alt"],
        cDNA: cDNA,
        protein: protein,
        start_pos: variant["pos"],
        transcript: transcripts.length > 0 ? transcripts[0].value : "None",
        comments: variant["comments"] || ""
    };
};
