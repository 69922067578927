import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import MappedText from "../../scenes/instand/reports/create-report/shared-components/mapText";



// GPAP_Button styled using the styled utility
// Custom GPAP_Button styled to behave like a "contained" button
const GPAP_Button = styled(Button)(({ theme }) => ({
    color: theme.palette.common.white, // White text
    backgroundColor: "#4575B4", // Custom background color
    margin: "5px",
    textTransform: "none",
    boxShadow: "none", // Remove shadow if not needed
    '&:hover': {
        backgroundColor: "#91BFDB", // Custom hover color
    },
    // Ensuring it's styled like a "contained" button
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    '&.MuiButton-root': { // Ensure all button styles are correctly applied
        boxShadow: 'none', // You can keep or remove shadow
    },
}));

/*const GPAP_RunButton = styled(Button)(({ theme }) => ({
    color: "white",
    fontSize: "15px",
    backgroundColor: "#4575B4",
    '&:hover': {
        backgroundColor: "#feb24c",
    },
    margin: "1%",
}));*/



const GPAP_RunButton = ({
                      onClick,
                      text,
                      disabled,
                      startIcon,
                      variant = 'contained',  // Default to 'contained' to match the GPAP_RunButton style
                      size = 'small',
                      customStyles = {},
                      onChange,
                      href,
                      target,
                      id
                  }) => {
    const defaultStyles = {
        outlined: {
            backgroundColor: "#ffffff",
            color: "#3E69A9",
            borderColor: "#4768a4",
            margin: "5px",
        },
        contained: {
            backgroundColor: (  !disabled ) ? "#4575B4" : "gray",    // Match the GPAP_RunButton background color
            color: "#ffffff",
            margin: "5px",
            '&:hover': {
                backgroundColor: "#feb24c",  // Match GPAP_RunButton hover color
            },
        }
    };

    const appliedStyles = {
        ...defaultStyles[variant],
        ...customStyles
    };

    return (
        <Button
            style={{ textTransform: 'none', boxShadow: 'none', height: "30px", width: 'auto', ...appliedStyles }}
            variant={variant}
            size={size}
            onClick={onClick}
            startIcon={startIcon}
            onChange={onChange}
            component={href ? "a" : "button"}
            href={href}
            disabled ={ disabled }
            target={target}
            id={id}
        >
            <MappedText text={text} />
        </Button>
    );
};






const GPAP_Link_Button = styled(Button)(({ theme }) => ({
    color: "#4575B4",
    backgroundColor: "white",
    '&:hover': {
        backgroundColor: "#91BFDB",
    },
    fontSize: "18px",
    margin: "1%",
}));

const BigRoundedButton = styled(Button)(({ theme }) => ({
    marginLeft: "1%",
    borderRadius: "100px",
    fontSize: "20px",
    padding: "15px 40px",
    boxShadow: "1px 1px 5px black",
    '&:hover': {
        backgroundColor: "#91BFDB",
    },
    margin: "1%",
}));

const GPAP_Button_Light = styled(Button)(({ theme }) => ({
    color: "#4575B4",
    backgroundColor: "#FAFAFA",
    '&:hover': {
        backgroundColor: "#f0f0f0",
    },
}));

const GPAP_Button_Orange = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "orange",
    '&:hover': {
        backgroundColor: "#e6550d",
        color: "black",
    },
}));

const GPAP_Button_Gray = styled(Button)(({ theme }) => ({
    color: "black",
    backgroundColor: "#EAEAEA",
    '&:hover': {
        backgroundColor: "#D5D5D5",
        color: "black",
    },
}));

const GPAP_Button_LightOrange = styled(Button)(({ theme }) => ({
    margin: "2px",
    color: "black",
    backgroundColor: "#F3B82A !important",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
        backgroundColor: "#F3B82A",
        boxShadow: "none",
    },
}));

const OrangeTextButton = styled(Button)(({ theme }) => ({
    color: "orange",
}));

export {
    OrangeTextButton,
    GPAP_RunButton,
    GPAP_Button,
    GPAP_Button_Light,
    GPAP_Button_Orange,
    GPAP_Link_Button,
    BigRoundedButton,
    GPAP_Button_LightOrange,
    GPAP_Button_Gray,
};
