// React Imports
import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles'; // MUI v5 makeStyles with @mui/styles

// MUI Imports
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Accordion, AccordionDetails } from '@mui/material';
import { Alert } from '@mui/material'; // MUI v5 Alert from @mui/material
import { IconButton } from '@mui/material';

// MUI Icons Imports
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // MUI v5 Icon imports from @mui/icons-material

// Custom Components Imports
import LoadPredefinedList from './components/load-predefined-list/LoadPredefinedList';
import SingleGeneSearch from './components/single-gene-search/SingleGeneSearch';
import OMIM_Search from './components/omim-search/OMIM_Search';
import PanelApp_Selection from './components/panelapp/PanelApp_Selection';
import HPO_Section from './components/hpo-search/HPO_Section';
import PathwaySearch from './components/pathway-search/PathwaySearch';
import UploadGenes from './UploadGenes';
import IconLeftExpansionPanelSummary from '../../../../../../../../../../components/tables/ParticipantTable/LeftIconExpansion';
import GPAP_InfoIcon from '../../../../../../../../../../gpap-lib/components/GPAP_InfoIcon';
import MappedText from '../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText';

// Redux Imports
import { bindActionCreators } from 'redux';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '90%',
        flexShrink: 0,
        fontWeight: "bold",
        color: "#4575B4",
        textTransform: "uppercase"
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

const geneExpansionPanelStyle = {
    background: "#deebf7",
    width: "100%"
};

function GeneFilteringAccordion(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className={classes.root}>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} elevation={0}>
                <IconLeftExpansionPanelSummary
                    style={geneExpansionPanelStyle}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header">
                        <div style={{float:"left", width: "95%"}}>
                            <Typography className={classes.heading}><MappedText text={"Predefined lists"}/> (<Badge>{props.by_predefined_list}</Badge>)</Typography>
                        </div>
                        <Box p={0} style={{float: "right"}}>
                            <GPAP_InfoIcon title=<MappedText text={"Select a Predefined Gene List prepared from ERNs or the GPAP team"}/> color={"primary"}/>
                        </Box>
                </IconLeftExpansionPanelSummary>
                <AccordionDetails>
                    <Grid container
                          direction={"row"}
                          justify={"center"}>
                        <Grid item lg={12}>
                            <LoadPredefinedList resetList={ props.resetList }/>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} elevation={0}>
                <IconLeftExpansionPanelSummary
                    style={geneExpansionPanelStyle}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header">
                        <div style={{float:"left", width: "95%"}}>
                            <Typography className={classes.heading}><MappedText text={"Gene search and upload"}/> (<Badge>{props.by_gene_list}</Badge>)</Typography>
                        </div>
                        <Box p={0} style={{float: "right"}}>
                            <GPAP_InfoIcon title=<MappedText text={"Enter multiple gene symbols using HGNC nomenclature or Upload your own"}/> color={"primary"}/>
                        </Box>
                </IconLeftExpansionPanelSummary>
                <AccordionDetails>
                    <Grid container
                          direction={"row"}
                          justify={"center"}>
                        <Grid item lg={12}>
                        <Alert severity="warning">
                            <span>
                                <MappedText text="WARNING: This platform uses annotations from Ensembl " />
                                <a href="https://www.ensembl.info/2023/02/08/ensembl-109-has-been-released/" target="_blank">GRCh38 v109</a>
                                <MappedText text=". Any Gene Symbol used for filtering which does not match the symbol used in Ensembl v109 may be missed or wrongly assigned. This also applies to external web-services. We are currently working to mitigate this issue." />
                            </span>
                        </Alert>
                        </Grid>
                        <Grid item lg={12}>
                            <SingleGeneSearch/>
                        </Grid>
                        <Grid item lg={12}>
                            <UploadGenes/>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <IconLeftExpansionPanelSummary
                    style={geneExpansionPanelStyle}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header">
                        <div style={{float:"left", width: "95%"}}>
                    <Typography className={classes.heading}><MappedText text={"Diseases"}/> (<Badge>{props.by_disease}</Badge>)</Typography>
                        </div>
                        <Box p={0} style={{float: "right"}}>
                            <GPAP_InfoIcon title=<MappedText text={"Select one or more OMIM terms or a PanelApp to query the associated genes"}/> color={"primary"}/>
                        </Box>
                </IconLeftExpansionPanelSummary>
                <AccordionDetails>
                    <Grid container
                          direction={"row"}
                          justify={"center"}>
                        <Grid item lg={12}>
                                <OMIM_Search/>
                        </Grid>
                        <Grid item lg={12}>
                            <PanelApp_Selection/>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            {(props.studySettings.studyType!== "search_across" && props.studySettings.studyType!== "cohort")
            ? <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <IconLeftExpansionPanelSummary
                    style={geneExpansionPanelStyle}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header">
                        <div style={{float:"left", width: "95%"}}>
                    <Typography className={classes.heading}><MappedText text={"Symptoms"}/> (<Badge>{props.by_symptoms}</Badge>)</Typography>
                        </div>
                    <Box p={0} style={{float: "right"}}>
                        <GPAP_InfoIcon title=<MappedText text={"Collect the HPOs from the index case or search more HPOs to query the associated genes"}/> color={"primary"}/>
                    </Box>
                </IconLeftExpansionPanelSummary>
                <AccordionDetails>
                    <Grid container
                          direction={"row"}
                          justify={"center"}>
                        <Grid item lg={12}>
                            <HPO_Section/>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
             : null
            }
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <IconLeftExpansionPanelSummary
                    style={geneExpansionPanelStyle}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5bh-content"
                    id="panel5bh-header">
                        <div style={{float:"left", width: "95%"}}>
                    <Typography className={classes.heading}><MappedText text={"Pathways"}/> (<Badge>{props.by_pathways}</Badge>)</Typography>
                        </div>
                        <Box p={0} style={{float: "right"}}>
                            <GPAP_InfoIcon title=<MappedText text={"Pathways are retrieved from REACTOME and associations with genes from The Comparative Toxicogenomics database"}/> color={"primary"}/>
                        </Box>
                </IconLeftExpansionPanelSummary>
                <AccordionDetails>
                    <Grid container
                          direction={"row"}
                          justify={"center"}>
                        <Grid item lg={12}>
                            <PathwaySearch/>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        if(state.studySettings!== undefined)
        {
            return {
                studySettings: state.studySettings,
            }
        }
        else{
            return {studySettings: null}
        }
    }
}






export default connect(mapStateToProps,null)(GeneFilteringAccordion);