
import React  from "react";
import {Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";

import Genomics from "../../dashboard/components/genomics-dashboard/genomics/Genomics";
import MappedText from "../../study-page/create-study/analysis-page/components/clinical-info/mapText";




function getFindingsType(analysis) {


    if(analysis){
        if (analysis.toLowerCase().includes("somatic")) {
            return "Somatic Genetic Findings";
        } else if (analysis.toLowerCase().includes("germline")) {
            return "Germline Genetic Findings";
        }
        else if (analysis.toLowerCase().includes("fusion")) {
            return "Gene Fusions";
        }

        else {
            return "Unknown"; // Default case if neither is found
        }
    }
    else{
        return analysis;
    }

}


function GeneticFindings(props) {


    let text = getFindingsType(props.analysisType);


    const renderView = () => {
        return <Genomics instandGeneticFindingsTab={props.instandGeneticFindingsTab}/>


    }


    return <Grid container direction={"row"}>
        <Grid item xs={12}>
            <Typography variant={"h5"} style={{  fontWeight: "700", marginTop: "20px"}}><MappedText text={text}/></Typography>
            <Grid container spacing={1}  mb={4}>
                <Grid item xs={12} lg={12}>
                    <Box>
                        {renderView()}
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    </Grid>


}

export default GeneticFindings;
