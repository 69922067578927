

import React from "react";
import CNV_DATA_HEADER_FULL from "../cnv_config/headers/data_header_full";
import {additional_info_1, additional_info_2} from "../cnv_config/additional_info";
import CNV_DATA_HEADER_SPLIT from "../cnv_config/headers/data_header_split";
import CustomTableForCNVs from "../../../../../../../../../../components/tables/customVariantTable/CustomTableForCNVs";

import NoVariantsFig from "./DNA_no_data.png";
import {Typography} from "@mui/material";
import {splitTooltips} from "../cnv_config/tooltips/splitTooltips";
import {completeTooltips} from "../cnv_config/tooltips/completeTooltips";
import {splitColConfig} from "../cnv_config/splitColConfig";
import {completeColConfig} from "../cnv_config/completeColConfig";
import {chipACMG} from "../cnv_config/vis/chipACMG";


function CNV_Table(props){


    if(props.cnv_number  === -1 ){
        //show image no data
        return <div style={{background: "#fafafa", color: "gray", textAlign: "center"}}>
            <img src={NoVariantsFig}
                 alt={"No CNV available"}
                 style={{width: "30%", padding: "24px"}}
            />
            <Typography style={{fontWeight: "bold"}}>Genomic variants not available</Typography>
            <Typography variant={"body2"} style={{paddingBottom: "24px"}}>Experiment {props.experiment} does not have any data available yet</Typography>
        </div>
    }
    else{

    return <CustomTableForCNVs
        url={props.url}
        method = {props.method}
        token={props.token}
        locationData="items"
        total={props.cnv_number}
        experiment={props.experiment}
        genes={props.genes}
        getTotal = {props.getCNVNumber}
        setSelected = {props.setSelectedCNV}
        queryID = {props.queryID}
        setLoading={props.setLoading}
        configFilter={[
            {
                full: {
                    dataSearch: "Annotation_mode",
                    keyFilter: "full",
                },
            },
            {
                split: {
                    dataSearch: "Annotation_mode",
                    keyFilter: "split",
                },
            },
        ]}
        dataHeader={CNV_DATA_HEADER_FULL}
        compactColumns = {completeColConfig}
        compactColumnsSplit= {splitColConfig}
        tooltipCompact={ completeTooltips}
        tooltipCompactSplit = {splitTooltips}
        chipFull ={chipACMG}
        nestedInfo={additional_info_1}
        split={CNV_DATA_HEADER_SPLIT}
        nestedInfoB={additional_info_2}
        customFeatures = {[
            { switchFS: true },
        ]}
        buttons={[
            {
                nameButton: "Population",
                type: "popover",
                template: "Population",
                data: ["B_gain_AFmax", "B_loss_AFmax", "B_ins_AFmax", "B_inv_AFmax"],
            },
        ]}
        getAllTaggedVariants = {props.getAllTaggedVariants}
        all_tagged_variants = {props.all_tagged_variants}
        isSNVSelected={props.isSNVSelected}
    />

    }

}

export default CNV_Table