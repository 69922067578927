
import React, { Fragment } from 'react';
import { Box, Typography } from '@mui/material';
import {GPAP_Button} from "../../../../../../../../../../gpap-lib/components/Button_Collection";
import AfterSubmitContent from "./AfterSubmitForm";
import MappedText from "../../../../../../../../../instand/reports/create-report/shared-components/mapText";
import CaButton from "../../../../../../../../../../gpap-lib/components/shared-components/CaButton";
import Divider from "@mui/material/Divider";


const CompleteDiagnosisForm = (props) => {

    let { url_phenostore } = window.config.apis_configuration;
    let {index_case, setUpdated={setUpdated}, setSubmitted, analysisType} = props;

   return <Fragment>
       <Box>
           <Typography variant="body1">
               The variant is now tagged as <b>Causative</b>.
           </Typography>
           { (analysisType !== "instand") ? <>
               <Typography variant={"Please complete or review the diagnosis of the participant in GPAP PhenoStore."}/>
           <CaButton
               text={"Complete Case"}
               variant="contained"
               href={`${url_phenostore}/#/complete_diagnosis/${index_case}`}
               target="_blank"/>
           </>
               : null
           }
           <Divider/>
       </Box>
       <Box pt={2}>
           <CaButton
               variant="contained"
               text="Edit tag"
               onClick={() => { setUpdated(false); setSubmitted(false);}} />
       </Box>
    </Fragment>


}

const AfterSubmit = ({ operationType, state, dataType, index_case, setUpdated, setSubmitted, analysisType }) => {

    let text="";
    if(dataType.includes("SNV") || dataType === "CNV"){
        text = "Variant";
    }
    else{
        text="Tag";
    }


    return (
        <Fragment>
           {/* <Box p={1}>
                <Typography variant="subtitle1">
                    {text} {operationType} correctly
                </Typography>
            </Box>*/}
            {
                (state.causative && operationType !== "deleted" && index_case !== null)
                    ? <CompleteDiagnosisForm index_case = {index_case}
                                             setUpdated={setUpdated}
                                             setSubmitted={setSubmitted}
                                             analysisType={analysisType}
                    />
                    : <AfterSubmitContent status={state}
                        operationType ={operationType}
                        dataType={dataType}
                        index_case={index_case}
                        setUpdated={setUpdated}
                        setSubmitted={setSubmitted}
                        analysisType={analysisType}
                    />
            }
            {
                (operationType === "deleted" && dataType.includes("SNV")) &&
                <Fragment>
                    <Typography variant="subtitle1">
                        <MappedText text="You also deleted the variant from PhenoStore. If you did it by mistake, please tag again." />
                    </Typography>
                </Fragment>
            }
        </Fragment>
    );
};

export default AfterSubmit;
