import React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import GrainIcon from '@mui/icons-material/Grain';

import { Link as RouterLink } from "react-router-dom";

function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}

export default function GPAP_Breadcrumb() {
    return (
        <Breadcrumbs aria-label="breadcrumb">
            <Link
                color="text.primary"
                component={RouterLink}
                to={"/gpap_home"}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'gray',
                    fontSize: '0.8em',
                    textDecoration: 'none',
                    '&:hover': { textDecoration: 'underline' },
                }}
            >
                <HomeIcon sx={{ mr: 0.5, width: 20, height: 20 }} />
                GPAP Home
            </Link>
            <Link
                color="text.primary"
                component={RouterLink}
                to={"/home"}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'gray',
                    fontSize: '0.8em',
                    textDecoration: 'none',
                    '&:hover': { textDecoration: 'underline' },
                }}
            >
                <CloudUploadIcon sx={{ mr: 0.5, width: 20, height: 20 }} />
                Data Analysis
            </Link>
            <Typography
                color="text.primary"
                component={RouterLink}
                to={"/home"}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'gray',
                    fontSize: '0.8em',
                    textDecoration: 'none',
                }}
            >
                <GrainIcon sx={{ mr: 0.5, width: 20, height: 20 }} />
                Genomic Analysis
            </Typography>
        </Breadcrumbs>
    );
}
