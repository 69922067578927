

export const getDisplayText = (total) => {
    if (total === -1) {
        return "genomic variants not available";
    } else if (total === 1) {
        return "1 genomic variant";
    } else {
        return (total || 0) + " genomic variants";
    }
};