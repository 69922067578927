export const handleCNVVariant = (variant, transcripts) => {
    return {
        sample: variant["Experiment_ID"],
        chromosome: variant["SV_chrom"],
        gene: variant["Gene_name"],
        ref: variant["ref"],
        rs: variant["rs"],
        alt: variant["alt"],
        start_pos: variant["SV_start"],
        end_pos: variant["SV_end"],
        transcript: transcripts.length > 0 ? transcripts[0].value : "None",
        comments: variant["comments"] || "",
        sv_type: variant.SV_type === "DEL" ? "Deletion" : (variant.SV_type === "DUP" ? "Duplication" : undefined)
    };
};
