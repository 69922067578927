

import React from 'react';
import {
    Route,
    Redirect
} from "react-router-dom";
import auth from "../../../services/session/Auth";

import InstandGeneralLayout from '../../instand-components/InstandGeneralLayout';
import Headings from '../../instand-components/header/Headings';


import HomeInstandLayout from '../../instand-components/HomeInstandLayout';



function AuthenticatedRoute({ component: Component, enabled, project, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth.user.authenticated === true || !enabled) {
          if (project === "homeInstand") {
      
            return (
              <HomeInstandLayout
                isReportingRoute={rest.isReportingRoute}
                component={
                  <Component {...props} {...rest} />
                }
              />
            );
          } else if (project === "instand") {
       
            return (
              <>
                <Headings project="instand" />
                <InstandGeneralLayout
                  component={
                    <Component {...props} {...rest} />
                  }
                />
              </>
            );
          } else {
      
            return (
              <>
              <Headings project={ project }/>
              <Component {...props} {...rest} />
              </>
            );
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login/",
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
}

export default AuthenticatedRoute;
