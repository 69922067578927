
import React, { Fragment, useEffect, useState } from "react";

// Redux imports
import { connect } from "react-redux";

// Material-UI imports
import {
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    ListItemText,
    Typography,
    List,
    ListItem,
    Divider,
    ListItemIcon
} from "@mui/material";

// Material-UI icon imports
import TuneIcon from '@mui/icons-material/Tune';
import PersonIcon from '@mui/icons-material/Person';

// Custom components and services
import { GPAP_Button_Gray } from "../../../gpap-lib/components/Button_Collection";
import GPAP_Tooltip from "../../../gpap-lib/components/GPAP_Tooltip";
import QueryFilters_View from "./QueryFilters_View";
import MappedText from "../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import { get_all_filter_sets } from "../../../services/api/manage_study";




function FilterSetView(props){


    const [filter_sets, setFilterSets] = useState([]);
    const [openDialogFilters, setOpenDialogFilters]= useState(false);
    const [selectedFilterSet, setSelectedFilterSet]= useState(null);
    const [title, setTitle]= useState(null);
    const [initialized, setInitialized]=useState(false);


    useEffect(function(){
        if (!initialized){
            get_all_filter_sets(window.config.apis_configuration.api_nextgpap_server, props.token)
            .then(r => r.json())
            .then(function(json){

                let data = json.data.filter(s => s.filter_set_name !== "");

                data.forEach(function(s){
                    s["label"] = "user";
                })

                let full_list = data;
                setFilterSets(full_list);
                setInitialized(true);

            })
        }

       

    }, [])

    const viewDialogFilters = (filterSet, title) => {
        setSelectedFilterSet(filterSet);
        setTitle(title);
        setOpenDialogFilters(true);
    }

    const handleClose = () => {
        setOpenDialogFilters(false); 
        
    }

    const renderFilterDialog = () => {
        return (
            <Dialog open={openDialogFilters} onClose={handleClose} maxWidth={"lg"}>
                <DialogTitle>{title} {selectedFilterSet ? selectedFilterSet.filter_set_name : ""}</DialogTitle>
                <DialogContent>
                    {selectedFilterSet ? (
                        <div style={{width: "400px"}}>
                            <QueryFilters_View
                                filtersObj={selectedFilterSet}/>
                        </div>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        <MappedText text="Close"/>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }


    return <div>
        {renderFilterDialog()}
        <Grid container spacing={2} direction={"column"}>
            <Grid item xs={12} md={6}>
                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                    <MappedText  text="Available Filter Sets"/> {(filter_sets.length !== 0 ? "(" + filter_sets.length + ")" : "")}
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                    <List
                        sx={{
                            width: '100%',
                            maxWidth: "100%",
                            bgcolor: 'background.paper',
                            position: 'relative',
                            overflow: 'auto',
                            maxHeight: 500,
                            '& ul': { padding: 0 },
                        }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        dense={false}>
                        {filter_sets.map(function(item){
                            return <Fragment>
                                <ListItem>
                                    <ListItemIcon>
                                        { (item.label === "default")
                                            ?  <GPAP_Tooltip title={<MappedText text={"GPAP default"}/>}><TuneIcon /></GPAP_Tooltip>
                                            :  <GPAP_Tooltip title={<MappedText text={"User created"}/>}><PersonIcon/></GPAP_Tooltip> }
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={item.filter_set_name}
                                        secondary={item.description ? item.description : <MappedText text="No description"/>}
                                    />
                                    <GPAP_Button_Gray onClick={() => viewDialogFilters(item.filters, item.filter_set_name)}><MappedText text={"View filters"}/></GPAP_Button_Gray>
                                </ListItem>
                                <Divider />
                            </Fragment>

                        })}
                    </List>
            </Grid>
        </Grid>
    </div>


}

function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {
            token: state.authorization.token,
            query: state.query,
        }
    }
}


export default connect(mapStateToProps, null)(FilterSetView);