// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    // Define any custom spacing, typography, palette, etc.
    spacing: 8, // This is the default; it means 1 spacing unit equals 8px
    palette: {
        primary: {
            main: '#3e699e',
        },
        // ...additional palette options
    },
    // Any other theme customizations
});

export default theme;