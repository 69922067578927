
import React from 'react';
import FlagIcon from '@mui/icons-material/Flag';
import {GPAP_Button_LightOrange} from "../../../../../../../../../../gpap-lib/components/Button_Collection";
import MappedText from "../../../../../../../../../instand/reports/create-report/shared-components/mapText";
import GPAP_Tooltip from "../../../../../../../../../../gpap-lib/components/GPAP_Tooltip"; // Assuming you're using Material-UI for icons

const TagButton = ({ handleClickOpen }) => {
    return (
        <GPAP_Tooltip title={<MappedText text="Tag variants in the GPAP database" />}>
            <GPAP_Button_LightOrange
                style={{
                    height: '28px',
                    margin: '2px',
                    boxShadow: 'none',
                    textTransform: 'none',
                    color: 'white',
                }}
                size="small"
                onClick={handleClickOpen}
                variant="contained"
                startIcon={<FlagIcon />}
            >
                <MappedText text="Tag" />
            </GPAP_Button_LightOrange>
        </GPAP_Tooltip>
    );
};

export default TagButton;
