

import { Typography } from "@mui/material";
import MappedText from '../../../../../create-report/shared-components/mapText.js';
import React from "react";
import ExomeCoverage from "../components/ExomeCoverageTable.js";
import SomaticGenomeCoverage from "../../somatic/components/SomaticGenomeCoverageTable.js";
import { renderToStringAlt } from "../../../../../create-report/shared-components/renderToString.js"; 

import AlertInfoViews from "../../../../shared-components/AlertInfo.js";
export function sectionConfigSomatic(allDataApis, annexesData, clinicalReportName, setClinicalReportName, additionalData, annexesFields, updateAnnexesFields) {


    let coverageTitle = '';
    let isCoverageAvailable = true; 

    switch (allDataApis?.experiments?.[0].library_strategy) {
        case 'WGS':
            coverageTitle = renderToStringAlt('Genome Coverage Metrics');
            break;
        case 'WES':
            coverageTitle = renderToStringAlt('Exome Coverage Metrics');
            break;
        case 'Panels':
            coverageTitle = renderToStringAlt('Panel Coverage Metrics');
            break;
        default:
            coverageTitle = renderToStringAlt('Coverage Metrics');
            isCoverageAvailable = false; 
    }
   
    return [
        {
            columns: 1,
            render: () => (
                <Typography style={{ fontSize: '14px', marginBottom: '20px' }}>
                    <MappedText text="The following annexes complete the report, which should be reviewed as a whole." />
                </Typography>
            ),
        },
        {
            title: renderToStringAlt('Annex 1: Clinical record'),
            columns: 1,
            fields: [
                { title: 'Cancer Type', value: allDataApis?.participants?.[0].cancer_type  },
                { title:'Family History', component: 'TextField',
                    value: annexesFields["family_history"], setValue: (val) => updateAnnexesFields("family_history", val) },
                { title: 'Treatment', component: 'TextField',
                    value: annexesFields["treatment"], setValue: (val) => updateAnnexesFields("treatment", val) },
            ],
        },
        {
            title: renderToStringAlt('Annex 2: Tumor genomic characteristics'),
            columns: 2,
            fields: [
                { title: 'Purity', value: allDataApis?.tumor?.tumor_purity || 'Tumor purity cannot be determined for this sample' },
                { title: 'Average ploidy', value: allDataApis?.tumor?.average_ploidy || 'Average ploidy cannot be determined for this sample'} ,
            ],
        },
        {
            title: renderToStringAlt('Annex 3: Other biomarkers'),
            columns: 2,
            fields: [
                { title: 'Microsatellite status', value: allDataApis?.tumor?.microsatellite_status || 'Microsatellite status cannot be determined for this sample'},
                { title: 'Tumor mutation load', value: allDataApis?.tumor?.tumor_mutational_load || 'Tumor mutation load cannot be determined for this sample'},
                { title: 'Tumor mutational burden', value: allDataApis?.tumor?.tumor_mutational_burden || 'Tumor mutational burden cannot be determined for this sample'},
                { title: 'Homologous recombination deficiency', value: allDataApis?.tumor?.homologous_recombination_deficiency_status || 'Homologous recombination deficiency cannot be determined for this sample'},
                { title: 'Mutational profiles', value: allDataApis?.tumor?.mutational_profiles || 'Mutational profiles cannot be determined for this sample' },
            ],
        },
        {
            title: renderToStringAlt('Annex 4: Methodology'),
            columns: 2,
            fields: [
                { 
                    title: 'Test method', 
                    value: `${allDataApis?.experiments?.[0].library_strategy} using the ${allDataApis?.experiments?.[0].kit }` 
                },                
                { title: 'Type of variants assessed', value: allDataApis?.type_of_variants_assessed  },
                { title: 'Sample received', component: 'TextField',
                    value: annexesFields["sample_received"], setValue: (val) => updateAnnexesFields("sample_received",val)},
                { title: 'Sample type', value: allDataApis?.samples?.sample_type  },
                { title: 'Sample condition', value: allDataApis?.samples?.sample_status },
                { title: 'Site of tumor', value:  allDataApis?.samples?.sample_tissue_of_origin },
                { title: 'Treatment given before sample was taken',
                    component: 'TextField',
                    value: annexesFields["treatment_given_before"],
                    setValue: (val) => updateAnnexesFields("treatment_given_before",val) },
                { title: 'Date of sample collection', value: allDataApis?.samples?.date_of_sample_collection },
            ],
        },
        {
            subTitle: 'Sample information',
            columns: 2,
            fields: [
                { title: 'DNA purity', component: 'TextField', value: annexesFields["dna_purity"], setValue: (val) => updateAnnexesFields("dna_purity", val) },
                { title: 'DNA integrity', component: 'TextField', value: annexesFields["dna_integrity"], setValue: (val) => updateAnnexesFields("dna_integrity", val) },
            ]
        },
        {
            subTitle: 'Sample processing',
            columns: 2,
            fields: [
                { title: 'Centre', value: allDataApis?.centre },
                { title: 'Sequencing platform', value: allDataApis?.sequencing_platform },
                { title: 'Fragment (size and type)', value: allDataApis?.fragment },
            ],
            render: () => (
                <AlertInfoViews text={"The fields in this section have to be filled in manually after downloading the document. Automatic synchronisation coming soon."}/>
            ),
        },
        {
            subTitle: 'Bioinformatic analysis',
            columns: 2,
            fields: [
                { title: 'Human reference genome', value: allDataApis?.human_reference_genome },
                { title: 'Aligner', value: allDataApis?.aligner },
                { title: 'Mark duplicates', value: allDataApis?.mark_duplicates},
                { title: 'Base recalibration', value: allDataApis?.base_recalibration },
                { title: 'Smart variants caller', value: allDataApis?.smart_variants_caller },
                { title: 'Analysis pipeline', value: allDataApis?.analysis_pipeline},
            ],
            render: () => (
                <AlertInfoViews text={"The fields in this section have to be filled in manually after downloading the document. Automatic synchronisation coming soon."}/>
            ),
        },
        {
            title: renderToStringAlt('Annex 5: Quality control metrics'),
            render: () => (
                 <AlertInfoViews text={"The fields in this section have to be filled in manually after downloading the document. Automatic synchronisation coming soon."}/>
            ),
        },
        {
            subTitle: coverageTitle,
            render: () => {
                switch (allDataApis?.experiments?.[0].library_strategy) {
                    case 'WGS':
                        return <SomaticGenomeCoverage data={additionalData.somaticCoverageGenesData} />;
                    case 'WES':
                        return <ExomeCoverage data={additionalData.exomeCoverageData} />;
                    case 'Panel':
                        return <PanelCoverage data={additionalData.panelCoverageData} />;
                    default:
                        return (
                            <Typography style={{ marginBottom: '20px' }}>
                            {renderToStringAlt('Unavailable coverage data')}
                            </Typography>
                        );
                }
            }
        },
  
        isCoverageAvailable && {
            caption: 'C10/C15: percentage of bases covered by at least 10 and 15 reads, respectively.',
        },
        {
            fields: [
                {
                    title: 'List of diagnostically targeted genes' ,
                    component: 'TextField',
                    value: annexesFields["list_of_diag_genes"] || renderToStringAlt('No genes were targeted'),
                    setValue: (val) => updateAnnexesFields("list_of_diag_genes", val)
                }
            ],
        },
        {
            subTitle: renderToStringAlt('Coverage of the genes of interest (RefSeq coding sections)'),
        },
        {
            body: 'All genes have a C10 of 90 or more, except for:',
        },
        {
            render: () => <SomaticGenomeCoverage data={additionalData.somaticCoverageGenesData} />
        },
    ].filter(Boolean); 
}
