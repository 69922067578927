import React from "react";

const RenderOption = ({ props, option, field }) => {
    if (!option[field]) return null; // Fallback for undefined cases

    const isBold = option[field].includes("matches");

    return (
        <li {...props} style={isBold ? { fontWeight: "bold" } : undefined}>
            {option[field]}
        </li>
    );
};

export default RenderOption;
