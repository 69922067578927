



import React from "react";
import { connect } from "react-redux";

// MUI Components
import { Card, CardContent, Box, Typography } from '@mui/material';

// Custom Components and Styles
import GenotypeBuilder from "./GenotypeBuilder";
import MappedText from "../clinical-info/mapText";
import cardStyles from "../card-style";

const GB_renderer = (props) => {

    const classes = cardStyles();

    if(props.render){
        return (
            <React.Fragment>
                <Box p={1}>
                    <Typography variant={"h6"} color={"textSecondary"}>
                        <MappedText text="Configuration details" />
                    </Typography>
                </Box>
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <GenotypeBuilder instandGeneticFindingsTab={props.instandGeneticFindingsTab}/>
                    </CardContent>
                </Card>
            </React.Fragment>
        )
    }
    else{
        return null
    }


};



function inheritance_selected (state) {
    if(state.inheritance_key!== "")
    {
        return {render:true}
    }
    else{ return {render:false}}

}

export default connect(inheritance_selected,null)(GB_renderer);



