


import React, { useState } from "react"; // React and hooks

// Redux
import { connect, useSelector } from "react-redux";



import FormControlLabel from "@mui/material/FormControl";
import Switch from '@mui/material/Switch';


import TabPanel from "../../../../../../gpap-lib/components/GPAP_TabPanel"; // Local component
import Variant_Info from "./components/variant-info/Variant_Info"; // Local component
import GeneAggTable from "./GeneAggTable"; // Local component
import SampleTreeView from "./SampleTreeView"; // Local component
import DatabasesAccordion from "../results/components/database-accordion/DatabaseAccordion"; // Local component
import ExternalLinks from "../results/components/external-sources/ExternalLinks"; // Local component
import OncoEvidencesPanel from "./onco-evidences-panel/OncoEvidencesPanel";

import TableChartIcon from '@mui/icons-material/TableChart'; // Material-UI icons
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FindInPageIcon from '@mui/icons-material/FindInPage';

import "./variant-summary.css";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import Divider from "@mui/material/Divider";
import {makeStyles} from "@mui/styles";



const useStyles = makeStyles({
    tab: {
        minWidth: 40, // a number of your choice
    }
});


function ExternalResources(props) {

    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [bySample, setBySample] = useState(false);

    let gene_rows = props.summary;
    let sample_agg = props.sample_agg;


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeMode = () => {
        setBySample(!bySample)

    };

    const passSamples = (experiments, genes) => {
        props.passExperiments(experiments, genes)
    };
    const allDataApis = useSelector(state => state.generaltable.allDataApis);

    const renderTabs = () => {


        let tabObject_1  = <Tab key="tab_1" className={classes.tab} icon={<TableChartIcon/>} aria-label={"Results Summary"}/>;
        let tabObject_2 =  <Tab key="tab_2" className={classes.tab} icon={<ExitToAppIcon/>} aria-label="Resources"/>;

        //Do not display External Databases
        //let tabObject_3 =  <Tab  key="tab_3" className={classes.tab} icon={<PlaylistAddCheckIcon/>} aria-label={"External Databases"}/>;
        let tabObject_4 = (allDataApis.dirs?.analysis?.includes("somatic")) ? (
            <Tab key="tab_4" className={classes.tab} icon={<FindInPageIcon />} aria-label="Onco Panel" />
        ) : null;

        if(props.summary){
            return [tabObject_1 , tabObject_2,] //tabObject_3]
        }
        else{
            return [tabObject_2, tabObject_4] //tabObject_3]
        }
        

    };

    const renderTabPanels = () => {

        const options = (window.config.instand)
            ? ["resources", "onco_panel"]
            : ["resources"]

        let config = (props.summary)
            ? ["summary", "resources"]
            : options;

        let n_experiments  = (props.participants!== undefined && props.participants.experiments !== undefined) ? props.participants.experiments.length : 0;
        let n_par  = (props.participants!== undefined && props.participants.participants !== undefined) ? props.participants.participants.length : 0;

        return config.map(function(item, index){

            switch (item) {
                case "summary":
                    return <TabPanel value={value} index={index} key={index}>
                        <Box p={1} style={{
                            minHeight: "100%",
                            float: "left",
                            width: "100%"
                        }}>
                            <Box p={1} style={{float: "left"}}>
                                <Badge badgeContent={n_experiments} color="primary">Experiments</Badge>
                            </Box>
                            <Box p={1} style={{float: "left"}}>
                                <Badge badgeContent={n_par} color="primary">Participants</Badge>
                            </Box>
                            <div style={{
                                float: "left",
                                paddingLeft: "40px",
                                paddingTop: "8px"
                            }}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            size="small"
                                            checked={bySample}
                                            onChange={handleChangeMode}
                                            name="agg_type"
                                            color="primary"
                                        />
                                    }
                                    style={{float:"right"}}
                                    label="by Experiment"
                                />
                            </div>
                            <div style={{float: "left",
                                                    width: "100%",
                                                    margin: "10px"}}>

                            <Divider/></div>
                            {(bySample)
                                ? <SampleTreeView
                                    data={sample_agg}
                                    runSearchAcrossQuery={props.runSearchAcrossQuery}
                                    passSamples={passSamples}
                                    passGenes={props.passGenes}
                                />
                                : <GeneAggTable
                                    data={props.participants}
                                    runSearchAcrossQuery={props.runSearchAcrossQuery}
                                    rows={(Array.isArray(gene_rows)) ? gene_rows : []}
                                    totalVariants={props.totalVariants}
                                />
                            }
                        </Box>
                    </TabPanel>;
                case "resources":
                    return   <TabPanel value={value} index={index} key={index}>
                        <ExternalLinks/>
                    </TabPanel>;
                case "info":
                    return <TabPanel value={value} index={index} key={index}>
                        <Variant_Info/>
                    </TabPanel>;
                case "external_db":
                    return <TabPanel value={value} index={index} key={index}>
                        <DatabasesAccordion/>
                    </TabPanel>;
                 case "onco_panel":
                    return (allDataApis.dirs?.analysis?.includes("somatic")) ? (
                        <TabPanel value={value} index={index} key={index}>
                            <OncoEvidencesPanel />
                        </TabPanel>
                    ) : null;
              
            }


        });

    };




    return (
        <div className={props.classStyle} id={props.classStyle}>
            
            <Tabs
                indicatorColor="primary"
                value={value}
                textColor="primary"
                onChange={handleChange}>
                {renderTabs()}
            </Tabs>
            <Divider/>
                <React.Fragment>
                    {renderTabPanels()}
                </React.Fragment>


        </div>
    )

}


//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        if(state.queryContainer !== undefined)
        {
            return {queryList: state.queryContainer.queryList}
        }

    }
}


// to send data from MainPanel component to the Store;
/*const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});*/



export default connect(mapStateToProps)(ExternalResources);