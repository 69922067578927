import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

const GPAP_Orange_Tooltip = styled(Tooltip)(({ theme }) => ({
    tooltip: {
        color: 'white',
        backgroundColor: '#FF9800',
        fontSize: '12px',
    },
}));

export default GPAP_Orange_Tooltip;
