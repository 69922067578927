






const inheritances = [
    {
        "key": "ar",
        "label": "Autosomal recessive (both homozygous and compound heterozygous)",
        "condition": {},
        "explanation": "",
        "alert": "For C-Het analysis, please ensure you have selected a family trio for meaningful results.",
        "config": [
            {
                id: 2,
                label: "homozygous",
                condition: {},
                explanation: "",
                genotypes: [
                    {
                        "case": "index",
                        "setting": [
                            {"affected":true,  gt: {"M": ["1/1"], "F": ["1/1"], "U": ["1/1"] }},
                        ]},
                    {
                        "case": "mother",
                        "setting": [
                            {"affected":true,  gt: { "F": []}},
                            {"affected":false,  gt: {"F": ["0/1"] }}]
                    },
                    {
                        "case": "father",
                        "setting": [
                            {"affected":true,  gt: { "M": []}},
                            {"affected":false,  gt: {"M": ["0/1"] }}]
                    },
                    {
                        "case": "other",
                        "setting": [
                            {"affected":true,  gt: {"M": ["1/1"], "F": ["1/1"], "U": ["1/1"] }},
                            {"affected":false,  gt: {"M": ["0/0", "0/1"], "F": ["0/0", "0/1"], "U": ["0/0", "0/1"] }}]
                    },

                ]
            },
            {
                id: 3,
                label: "compound heterozygosity",
                condition: {},
                explanation: "",
                genotypes: [
                    {
                        "case": "index",
                        "setting": [
                            {"affected":true,  gt: {"M": ["0/1"], "F": ["0/1"], "U": ["0/1"] }},
                        ]},
                    {
                        "case": "mother",
                        "setting": [
                            {"affected":true,  gt: { "F": []}},
                            {"affected":false,  gt: {"F": ["0/0", "0/1"] }}]
                    },
                    {
                        "case": "father",
                        "setting": [
                            {"affected":true,  gt: { "M": []}},
                            {"affected":false,  gt: {"M": ["0/0", "0/1"] }}]
                    },
                    {
                        "case": "other",
                        "setting": [
                            {"affected":true,  gt: {"M": ["0/1"], "F": ["0/1"], "U": ["0/1"] }},
                            {"affected":false,  gt: {"M": ["0/0", "0/1"], "F": ["0/0", "0/1"], "U": ["0/0", "0/1"] }}]
                    },

                ]
            }
        ],
    },
    {
        "key": "ad",
        "label": "Autosomal dominant",
        "condition": {},
        "explanation": "",
        "config": [
            {
                id: 1,
                label: "Autosomal dominant",
                condition: {},
                genotypes: [
                    {
                        "case": "index",
                        "setting": [
                            {"affected":true,  gt: {"M": ["0/1"], "F": ["0/1"], "U": ["0/1"] }},
                        ]},
                    {
                        "case": "mother",
                        "setting": [
                            {"affected":true,  gt: { "F": ["0/1"]}},
                            {"affected":false,  gt: {"F": ["0/0"] }}]
                    },
                    {
                        "case": "father",
                        "setting": [
                            {"affected":true,  gt: { "M": ["0/1"]}},
                            {"affected":false,  gt: {"M": ["0/0"] }}]
                    },
                    {
                        "case": "other",
                        "setting": [
                            {"affected":true,  gt: {"M": ["0/1"], "F": ["0/1"], "U": ["0/1"] }},
                            {"affected":false,  gt: {"M": ["0/0"], "F": ["0/0"], "U": ["0/0"] }}]
                    },

                ]
            }
        ],
    },
    {
        "key": "de_novo",
        "label": "Autosomal dominant - de novo",
        "condition": {},
        "alert": "Please ensure you have selected a family trio for meaningful results.",
        "explanation": "",
        "config": [
            {
                id: 8,
                label: "De novo",
                condition: {},
                explanation: "",
                genotypes: [
                    {
                        "case": "index",
                        "setting": [
                            {"affected":true,  gt: {"M": ["0/1"],"F": ["0/1"], "U": ["0/1"] }},
                        ]},
                    {
                        "case": "mother",
                        "setting": [
                            {"affected":true,  gt: { "F": []}},
                            {"affected":false,  gt: {"F": ["0/0"] }}]
                    },
                    {
                        "case": "father",
                        "setting": [
                            {"affected":true,  gt: { "M": []}},
                            {"affected":false,  gt: {"M": ["0/0"] }}]
                    },
                    {
                        "case": "other",
                        "setting": [
                            {"affected":true,  gt: {"M": ["0/1"], "F": ["0/1"], "U": ["0/1"] }},
                            {"affected":false,  gt: {"M": ["0/0"], "F": ["0/0"], "U": ["0/0"] }}]
                    }
                ]
            }
        ]
    },
  /*  {
        "key": "ar_consanguineous",
        "label": "AR Consanguineous",
        "condition": {},
        "config": [
            {
                id: 4,
                label: "homozygous",
                condition: {},
                genotypes: [
                    {
                        "case": "index",
                        "setting": [
                            {"affected":true,  gt: {"M": "1/1", "F": "1/1", "U": "1/1" }},
                        ]},
                    {
                        "case": "mother",
                        "setting": [
                            {"affected":true,  gt: { "F": []}},
                            {"affected":false,  gt: {"F": ["0/1"] }}]
                    },
                    {
                        "case": "father",
                        "setting": [
                            {"affected":true,  gt: { "M": []}},
                            {"affected":false,  gt: {"M": ["0/1"] }}]
                    },
                    {
                        "case": "other",
                        "setting": [
                            {"affected":true,  gt: {"M": ["1/1"], "F": ["1/1"], "U": ["1/1"] }},
                            {"affected":false,  gt: {"M": ["0/0", "0/1"], "F": ["0/0", "0/1"], "U": ["0/0", "0/1"] }}]
                    },

                ]
            },
        ],
    },*/
    {
        "key": "x-linked-recessive",
        "label": "X-Linked Recessive ",
        "condition": {"sex": "M", index: true},
        "explanation": "",
        "config": [
            {
                id: 7,
                label: "X-Linked Recessive - Index Case Male",
                condition: {"sex": "M", index: true},
                genotypes: [
                    {
                        "case": "index",
                        "setting": [
                            {"affected":true,  gt: {"M": ["1/1"], "U": ["1/1"] }},
                        ]},
                    {
                        "case": "mother",
                        "setting": [
                            {"affected":true,  gt: { "F": ["1/1"]}},
                            {"affected":false,  gt: {"F": ["0/1"] }}]
                    },
                    {
                        "case": "father",
                        "setting": [
                            {"affected":true,  gt: { "M": []}},
                            {"affected":false,  gt: {"M": ["0/0"] }}]
                    },
                    {
                        "case": "other",
                        "setting": [
                            {"affected":true,  gt: {"M": ["1/1"], "F": ["1/1"], "U": ["1/1"] }},
                            {"affected":false,  gt: {"M": ["0/0"], "F": ["0/0", "0/1"], "U": ["0/0", "0/1"] }}]
                    },

                ]
            }
        ]
    },
    {
        "key": "x-linked-dominant",
        "label": "X-Linked Dominant ",
        "condition": {},
        "explanation": "",
        "config": [
            {
                id: 5,
                label: "X-Linked Dominant - Index Case Male",
                condition: {"sex": "M", index: true},
                explanation: "",
                genotypes: [
                    {
                        "case": "index",
                        "setting": [
                            {"affected":true,  gt: {"M": ["1/1"] }},
                        ]},
                    {
                        "case": "mother",
                        "setting": [
                            {"affected":true,  gt: { "F": ["0/1"]}},
                            {"affected":false,  gt: {"F": ["0/0"] }}]
                    },
                    {
                        "case": "father",
                        "setting": [
                            {"affected":true,  gt: { "M": []}},
                            {"affected":false,  gt: {"M": ["0/0"] }}]
                    },
                    {
                        "case": "other",
                        "setting": [
                            {"affected":true,  gt: {"M": ["1/1"], "F": ["0/1"], "U": ["1/1", "0/1"] }},
                            {"affected":false,  gt: {"M": ["0/0"], "F": ["0/0"], "U": ["0/0"] }}]
                    },

                ]
            },
            {
                id: 6,
                label: "X-Linked Dominant - Index Case Female",
                condition: {"sex": "F", index: true},
                explanation: "",
                genotypes: [
                    {
                        "case": "index",
                        "setting": [
                            {"affected":true,  gt: {"F": ["0/1"] }},
                        ]},
                    {
                        "case": "mother",
                        "setting": [
                            {"affected":true,  gt: { "F": ["0/1"]}},
                            {"affected":false,  gt: {"F": ["0/0"] }}]
                    },
                    {
                        "case": "father",
                        "setting": [
                            {"affected":true,  gt: { "M": ["1/1"]}},
                            {"affected":false,  gt: {"M": ["0/0"] }}]
                    },
                    {
                        "case": "other",
                        "setting": [
                            {"affected":true,  gt: {"M": ["1/1"], "F": ["0/1"], "U": ["1/1", "0/1"] }},
                            {"affected":false,  gt: {"M": ["0/0"], "F": ["0/0"], "U": ["0/0"] }}]
                    },

                ]
            }
        ]
    },
    {
        "key": "custom",
        "label": "Custom Inheritance ",
        "condition": {},
        "explanation": "",
        "config": [ {
            id: 0,
            label: "Custom Inheritance",
            condition: {},
            genotypes: [
                {
                    "case": "index",
                    "setting": [
                        {"affected":true,  gt: {"M": [], "F": [], "U": [] }},
                    ]},
                {
                    "case": "mother",
                    "setting": [
                        {"affected":true,  gt: { "F": []}},
                        {"affected":false,  gt: {"F": [] }}]
                },
                {
                    "case": "father",
                    "setting": [
                        {"affected":true,  gt: { "M": []}},
                        {"affected":false,  gt: {"M": [] }}]
                },
                {
                    "case": "other",
                    "setting": [
                        {"affected":true,  gt: {"M": [], "F": [], "U": [] }},
                        {"affected":false,  gt: {"M": [], "F": [], "U": [] }}]
                },

            ]
        }],
    },
    {
        "key": "somatic",
        "label": "Somatic",
        "condition": {},
        "explanation": "",
        "config": [
            {
                id: 9,
                label: "Somatic",
                condition: {},
                genotypes: [
                    {
                        "case": "index",
                        "setting": [
                            {"affected":true,  gt: {"M": ["0/1"], "F": ["0/1"], "U": ["0/1"] }},
                        ]},
                    {
                        "case": "mother",
                        "setting": [
                            {"affected":true,  gt: { "F": ["0/1"]}},
                            {"affected":false,  gt: {"F": ["0/0"] }}]
                    },
                    {
                        "case": "father",
                        "setting": [
                            {"affected":true,  gt: { "M": ["0/1"]}},
                            {"affected":false,  gt: {"M": ["0/0"] }}]
                    },
                    {
                        "case": "other",
                        "setting": [
                            {"affected":true,  gt: {"M": ["0/1"], "F": ["0/1"], "U": ["0/1"] }},
                            {"affected":false,  gt: {"M": ["0/0"], "F": ["0/0"], "U": ["0/0"] }}]
                    },

                ]
            }
        ],
    },

];

const  inheritances_x_linked_ids = [5,6,7];


export {inheritances, inheritances_x_linked_ids}