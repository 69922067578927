        import React, {useState, useEffect} from 'react';
        import Box from "@mui/material/Box";
        import Grid from "@mui/material/Grid";

        import { TextField, Typography, Paper,  Dialog} from "@mui/material";
        // import jsonData from "../config/dir.json";

        import AccordionBasic from "../../create-report/shared-components/general-table/components/accordion/AccordionBasic.jsx"
        import { useDispatch, useSelector } from 'react-redux';

        import { createDoc } from '../../create-doc/write-document/writeReport.js';
        import useNavigate from "../../../../../components/tables/general-table/hooks/useNavigate";
        import axios from "axios";
        import * as docx from "docx-preview";
        import MappedText from "../../create-report/shared-components/mapText.js";
        import ClinicalEvidenceSection from "./clinical-evidence/ClinicalEvidenceSection.js";
        import Interpretation from "./interpretation/Interpretation.js";
        import GeneralInfoSection from "./general-info-section/GeneralInfoSection.js";

        import genomeCoverageData from "./annexes/germline/data-fake/genomeCoverageData.js";
        import coverageGenesData from './annexes/germline/data-fake/coverageGenesData.js';
        import genotypePhenotypeData from './annexes/germline/data-fake/genotypePhenotypeData.js';
        import variantMetricsData from './annexes/germline/data-fake/variantMetricsData.js';
        import AnnexesSomaticSection from "./annexes/somatic/components/AnnexesSomaticSection.js";
        import AnnexesGermlineSection from "./annexes/germline/components/AnnexesGermlineSection.js";
        import GeneticFindingsSection from "./genetic_findings/GeneticFindingsSection.js";
        import somaticCoverageGenesData from "./annexes/somatic/data-fake/somaticCoverageGenesData.js";
        import exomeCoverageData from "./annexes/somatic/data-fake/exomeCoverageData.js";
        import getReferences from "./references/referenceConfig.js";
        import Disclaimer from './disclaimer/Disclaimer.js';
        import getDisclaimer from './disclaimer/disclaimerConfig.js';
        import TechnicalLimitations from './technical_limitations/TechnicalLimitations.js';
        import getTechnicalLimitations from './technical_limitations/technicalLimitationsConfig.js';
        import References from "./references/References.js";
        import {useParams} from "react-router-dom";
        import {loadingAllInfo} from "../../../../../reducers/slices/tables/table.thunk";
        import { toggleRefresh} from '../../../../../reducers/slices/tables/table.slice';
        import Pharmacogenomics from './annexes/germline/components/Pharmacogenomics.js';
        import {useTranslation} from "react-i18next";
        import CloseIcon from '@mui/icons-material/Close';        
        import PharmacogenomicsInterpretation from './annexes/germline/components/PharmacogenomicsInterpretation.js';
        import CaButton from "../../../../../gpap-lib/components/shared-components/CaButton.js"
        import { renderToStringAlt } from "../../create-report/shared-components/renderToString.js";    


        function NewClinicalReport () {

            const [docUrl, setDocUrl] = useState(null);


            const dispatch = useDispatch();

            // Using the translation function and renaming it to find it easily in the code.
            const { t: translationFunc } = useTranslation();

            const { allDataApis, isLoadingApis } = useSelector((state) => state.generaltable);
            const [selectedStatus, setSelectedStatus] = useState('');
            const { id } = useParams();
            //const id = "test";
            const token = useSelector(state => state.authorization.token);
            const [clinicalReportName, setClinicalReportName] = useState('');

            //TODO, we need to re-enable these ones;
            const [dirSelected, setDirSelected] = useState({ dir_id: id });
            const analysisType = useSelector(state => state.generaltable.allDataApis?.dirs?.analysis);
            const urlBase = window.config.apis_configuration.api_nextgpap_server
          
            const navigate = useNavigate();

            const [geneticFindingsData, setGeneticFindingsData] = useState(null);
            const [interpretationData, setInterpretationData] = useState({rows: [], conclusions: ""});
            const [clinicalEvidenceData, setClinicalEvidenceData] = useState(null);
            const [pharmacogenomicsData, setPharmacogenomicsData] = useState(null);
            const [isPreviewVisible, setIsPreviewVisible] = useState(false);
            const [studyPerformed, setStudyPerformed] = useState("");



            const [annexesFields, setAnnexesFields] = useState({"family_history": "",
                "treatment": "", "sample_received": "", "treatment_given_before": "", "list_of_diag_genes": "", "dna_purity": "", "dna_integrity": "", "type_of_variants_assessed": ['Single nucleotide variants (SNVs)',
                    'Small insertions and deletions (InDels)',
                    'Copy number variants (CNVs)',
                    'Structural variants (SVs)']});



            useEffect(() => {
                dispatch(loadingAllInfo(id, "POST", token));
            }, [dispatch, id, token]);

            useEffect(() => {
                if (allDataApis?.dir) {
                    const dirFind = allDataApis.dir.find(d => d.dir_id === id);
                    setDirSelected(dirFind);
                }
            }, [allDataApis?.dir, id]);

            useEffect(() => {
                if (allDataApis?.dirs?.status) {
                    setSelectedStatus(allDataApis?.dirs?.status);
                }
            }, [allDataApis?.dirs?.status]);

                useEffect(() => {

                    dispatch(loadingAllInfo(id, "POST", token));
                }, [dispatch, id, token]);

                useEffect(() => {
                    if (allDataApis?.dir) {
                        const dirFind = allDataApis.dir.find(d => d.dir_id === id);
                        setDirSelected(dirFind);
                    }
                }, [allDataApis?.dir, id]);


      
            const createDocument = async () => {
             

                        const clinicalReportName = document.querySelector('[name="clinicalReportName"]').value;

                        const dataForDoc = {
                            clinicalEvidenceData: clinicalEvidenceData,
                            geneticFindingsData: geneticFindingsData,
                            interpretationData:  interpretationData,
                            genomeCoverageData: genomeCoverageData,
                            exomeCoverageData: exomeCoverageData,
                            coverageGenesData: coverageGenesData,
                            variantMetricsData: variantMetricsData,
                            pharmacogenomicsData: pharmacogenomicsData, 
                            genotypePhenotypeData: genotypePhenotypeData,
                            references: getReferences(analysisType),
                            disclaimer: getDisclaimer(analysisType),
                            technicalLimitations: getTechnicalLimitations(analysisType),
                            annexesFields: annexesFields
                        };

                   
                        let experimentType = allDataApis?.experiments[0]?.library_strategy;
                        const doc = await createDoc(dataForDoc, analysisType, experimentType, allDataApis, translationFunc, studyPerformed);
                        
                        
                  
                        if(doc instanceof Blob) {
                        
                            return doc;
                        } else {
                         
                            const blob = new Blob([doc], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
                            return blob;
                        }
                    };

                    const onPreviewClick = async () => {
                        try {
                            const doc = await createDocument();
                            setIsPreviewVisible(true);
                            docx.renderAsync(doc, document.getElementById("preview_container"));
                        } catch (error) {
                            console.error('Preview Error:', error);
                        }
                    };

                    const closePreview = () => {
                        setIsPreviewVisible(false);  
                        document.getElementById("preview_container").innerHTML = "";  
                    };
                
              
                    const uploadDocument = async (blob) => {
                        const formData = new FormData();
          
                        const fileExtension = analysisType === 'PDF' ? 'pdf' : 'docx';
                        formData.append("file", blob, `${clinicalReportName || 'report'}.${fileExtension}`);
                        formData.append("dir_id", dirSelected.dir_id);
                        formData.append("origin", "created_in_system");
                        formData.append("group_owner", "admin");
                        formData.append("clinical_report_name", clinicalReportName);
                        try {
                            const config = {
                                headers: {
                                  'Authorization': token,
                                }
                              };
                    
                              const response = await axios.post(
                                `${window.config.apis_configuration.api_nextgpap_server}/clinical_report`,
                                formData,
                                config 
                              );
                    
                            console.log('Document uploaded:', response.data);
                        } catch (error) {
                            console.error('Error:', error);
                        }
                    };
                    
                
                    
                    
             
                  


                    const downloadDocument = (doc, filename) => {
                        const url = URL.createObjectURL(doc);
                        const a = document.createElement("a");
                        a.href = url;
                        a.download = filename;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    };
                    const onCreateClick = async () => {
                        try {
                            const doc = await createDocument();
 
                            const fileExtension = analysisType === 'PDF' ? 'pdf' : 'docx';
                            const fileName = `${clinicalReportName || 'report'}.${fileExtension}`;
                    
                            await downloadDocument(doc, fileName);
                            await uploadDocument(doc); 
                            dispatch(toggleRefresh());
                            navigate(`/dir_overview/${id}`);
                        } catch (error) {
                            console.error('Error en onCreateClick:', error);
                        }
                    };


            const onCancelClick = () => {
                navigate(`/dir_overview/${id}`); 
            };

            const handleChange = (event) => {
                    setSelectedStatus(event.target.value);
                };


            const handleSectionUpdate = (sectionData, data_type) => {

                    //let selectedRows = rows.map(s => s.original);
                    //console.log(selectedRows);

                    if(data_type === "Clinical_Evidence"){
                        const changesDetected = JSON.stringify(sectionData) !== JSON.stringify(clinicalEvidenceData);
                        if(changesDetected){
                            setClinicalEvidenceData(sectionData);
                        }
                    }

                    else if (data_type === "Genetic_Findings") {

                        if (JSON.stringify(sectionData) !== JSON.stringify(geneticFindingsData))
                        {
                            setGeneticFindingsData(sectionData);
                        }

                        if (interpretationData) {
                            setInterpretationData(sectionData);
                        }
                    }
                    else if(data_type === "Interpretation"){

                        const changesDetected = JSON.stringify(sectionData) !== JSON.stringify(interpretationData);
                        if(changesDetected){
                            setInterpretationData(sectionData);
                        }

                    }
                     else if (data_type === "Pharmacogenomics_Germline") {
                        const changesDetected = JSON.stringify(sectionData) !== JSON.stringify(pharmacogenomicsData);
                        if(changesDetected){
                            setPharmacogenomicsData(sectionData);
                        }}
                                            
                };


            const updateAnnexesFields = (field, value) => {

                let annexes_fields = JSON.parse(JSON.stringify(annexesFields));
                annexes_fields[field] = value;
                setAnnexesFields(annexes_fields);

            }

            const groups = [
                {
                    title: <MappedText text="General Info" />,
                    content: (
                        <GeneralInfoSection
                            dirSelected={dirSelected}
                            allDataApis={allDataApis}
                            analysisType={analysisType}
                            setStudyPerformed={setStudyPerformed}
                        />
                    ),
                },
                {
                    title: <MappedText text="Genetic Findings" />,
                    content: (
                        <>
                            <GeneticFindingsSection
                                handleSectionUpdate={handleSectionUpdate}
                                url = {urlBase} token={token} report_id={allDataApis?.participants?.[0].report_id}
                                analysisType={analysisType}
                            />
                            </>
                        )
                    },
                {
                    title: <MappedText text="Interpretation"/>,
                    content: (
                        <>
                            <Interpretation
                                variants={geneticFindingsData}
                                handleSectionUpdate={handleSectionUpdate}
                                analysisType = {analysisType}
                            />
                        </>

                    ),
                },
                // Conditionally add the Clinical Evidence section based on analysisType
                ...(analysisType !== "germline"
                    ? [
                        {
                            title: <MappedText text="Clinical Evidence and Patient Management" />,
                            content: (
                                <ClinicalEvidenceSection
                                    handleSectionUpdate={handleSectionUpdate}
                                    participant={allDataApis?.participants?.[0]}
                                />
                            ),
                        },
                    ]
                    : []), // Skip the Clinical Evidence section if analysisType is "germline"
                {
                    title: <MappedText text="Disclaimer" />,
                    content: (
                        <Disclaimer analysisType={analysisType}/>
                    ),
                },
                {
                    title: <MappedText text="Technical Limitations" />,
                    content: (
                        <TechnicalLimitations analysisType={analysisType} experimentType = {allDataApis?.experiments?.[0]?.library_strategy
                        }/>
                    ),
                },
                {
                    title: <MappedText text="References"/>,
                    content: (
                        <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                            <Grid item xs={12} sm={12} md={12}>
                                <References analysisType={analysisType}/>
                            </Grid>
                        </Grid>
                    ),
                },
                {
                    title: <MappedText text="Annexes" />,
                    content: (
                        <>
                            <Grid container spacing={2} sx={{ marginBottom: 4}}>
                                <Grid item xs={12} sm={12} md={12}>
                                    {analysisType !== "germline"
                                        ?<>

                                            <AnnexesSomaticSection
                                                clinicalReportName={clinicalReportName}
                                                setClinicalReportName={setClinicalReportName}
                                                exomeCoverageData={[]}
                                                somaticCoverageGenesData={[]}
                                                updateAnnexesFields={updateAnnexesFields}
                                                annexesFields={annexesFields}
                                            />
                                        </>
                                        : (<>
                                                {/* Germline */}
                                                <AnnexesGermlineSection
                                                    clinicalReportName={clinicalReportName}
                                                    setClinicalReportName={setClinicalReportName}
                                                    genomeCoverageData={[]}
                                                    coverageGenesData={[]}
                                                    variantMetricsData={[]}
                                                    genotypePhenotypeData={[]}
                                                    //pharmacogenomicsData={pharmacogenomicsData}
                                                    report_id={allDataApis?.participants?.[0].report_id}
                                                    updateAnnexesFields={updateAnnexesFields}
                                                    annexesFields={annexesFields}
                                                />
                                                <Pharmacogenomics report_id={allDataApis?.participants?.[0].report_id} token={token} urlBase={urlBase}  onSelectedRowsChange={handleSectionUpdate} />                                                  <PharmacogenomicsInterpretation selectedRows={pharmacogenomicsData} handleSectionUpdate={handleSectionUpdate} />
                                            </>
                                        )}
                                </Grid>
                            </Grid>
                        </>

                    )
                }
            ];
            return (
                <React.Fragment>
                    <Box sx={{ flexGrow: 1, p: 2 }}>
                        {/* <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: '30px' }}>
                                <Link component={RouterLink} to="/dir_list" underline="hover" color="inherit">
                                    Interpretations Requests
                                </Link>
                                <Link component={RouterLink} to={`/dir_overview/${id}`} underline="hover" color="inherit">
                                    {id}
                                </Link>
                                <Typography color="text.primary">New Clinical Report</Typography>
                            </Breadcrumbs>*/}
                        <>
                            <Typography variant={"h4"}>
                                <MappedText text={"New Clinical Report"} />
                            </Typography>
                            <Typography style={{ fontSize: "16px", fontWeight: "400", marginBottom: '30px', marginTop: '30px' }}>
                                <MappedText
                                    text={
                                        analysisType !== "germline"
                                            ? renderToStringAlt("Welcome to the Somatic Clinical Report creation screen. In here you’ll be able to create and export a molecular genetic report document with the information you’ve reviewed and processed for this interpretation request. Read, review and complete each section and, when you’re done, click on the Create button to export your report.")
                                            : renderToStringAlt("Welcome to the Germline Clinical Report creation screen. In here you’ll be able to create and export a molecular genetic report document with the information you’ve reviewed and processed for this interpretation request. Read, review and complete each section and, when you’re done, click on the Create button to export your report.")
                                    }
                                />
                            </Typography>
                        </>
                        <TextField
                            id="outlined-basic"
                            label=<MappedText text="Report Name" />
                            name="clinicalReportName"
                            variant="outlined"
                            value={clinicalReportName}
                            onChange={(e) => setClinicalReportName(e.target.value)}
                        />

                        <Paper
                            elevation={3}
                            sx={{
                                position: "fixed",
                                bottom: 0,
                                left: 0,
                                right: 0,
                                height: "50px",
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                zIndex: 1000,
                                gap:"10px"
                            }}
                        >
                            <CaButton
                                onClick={onCancelClick}
                                variant="outlined"
                                text='Cancel'
                                size="large"
                            />
                            <CaButton
                                onClick={onPreviewClick}
                                variant="outlined"
                                text="Preview"
                                size="large"
                            />
                            <CaButton
                                onClick={onCreateClick}
                                variant="contained"
                                text="Create"
                                size="large"
                                customStyles={{marginRight: "10px"}}
                            />
                        </Paper>
                    </Box>
                        <>


                            <Box
                                sx={{
                                    position: "relative",
                                    height: "calc(100vh - 50px)",
                                    paddingBottom: "100px",
                                }}
                            >
                                <Grid container>
                                    <Grid item lg={12}>
                                        <AccordionBasic groups={groups} />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Dialog open={isPreviewVisible} onClose={closePreview} maxWidth="md">
                                <div style={{ position: 'relative', padding: '20px' }}>
                                    {/* Close Button */}
                                    <Box style={{float: "left", width: "100%", height: "40px"}}>
                                        <CaButton
                                            variant="outlined"
                                            onClick={() => setIsPreviewVisible(false)}
                                            startIcon={<CloseIcon />}
                                            text='Close'
                                        />
                                    </Box>

                                    {/* Preview Content */}
                                    <div id="preview_container" style={{
                                        zIndex: 1,
                                    }}></div>
                                </div>
                            </Dialog>
                        </>
                    </React.Fragment>
                );
            }

            export default NewClinicalReport;