import React from "react";
import { Alert } from "@mui/material";
import MappedText from "../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import { tag_dict } from "../tag_dict";
import Box from "@mui/material/Box";
import GPAP_Tooltip from "../../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import CaButton from "../../../../../../../../../../gpap-lib/components/shared-components/CaButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";

export const TagVisibilityInfo = ({tagVisibility, deleteVariantData}) => {

    switch (tagVisibility){
        case "sample_tag":
            return <React.Fragment>
                <Alert severity={"info"}><MappedText text={tag_dict["sample_tag"]}/></Alert>
                <Box p={1}>
                    <GPAP_Tooltip title="Delete">
                        <CaButton variant={"outlined"}
                                  size={"small"}
                                  aria-label="delete"
                                  onClick={() => deleteVariantData()}
                                  startIcon={ <DeleteIcon />}
                                  text={"Delete your tag"}
                        />
                    </GPAP_Tooltip>
                </Box>
            </React.Fragment>
        case "extra_tag":
            return <Alert severity={"warning"}>{tag_dict["extra_tag"]}</Alert>
        default:
            return null;
    }

}

export const TagInfo = ({variant_flagged, tagVisibility, deleteVariantData}) => {


    return  <Typography>
        {(variant_flagged)
            ?  <React.Fragment>
                <TagVisibilityInfo tagVisibility={tagVisibility} deleteVariantData={deleteVariantData}/>
            </React.Fragment>
            :  <Alert severity={"info"}><MappedText text={"Variant not tagged yet"} /></Alert>}
    </Typography>


}