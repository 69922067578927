

import React from "react";


export const tagged_variant_columns = [
    {
        Header: 'Gene',
        accessor: "gene_name",
        filter: 'fuzzyText',
        Cell: function({ value }) {
            return <div style={{ padding: "5px" }}>{value}</div>;
        }
    },
    {
        Header: 'Chromosome',
        accessor: "chromosome",
        filter: 'fuzzyText',
        Cell: function({ value }) {
            return <div style={{ padding: "5px" }}>{value}</div>;
        }
    },
    {
        Header: 'Start Position',
        accessor: "start_pos",
        filter: 'fuzzyText',
        Cell: function({ value }) {
            return <div style={{ padding: "5px" }}>{value}</div>;
        }
    },
    {
        Header: 'Significance',
        accessor: "significance",
        filter: 'fuzzyText',
        Cell: function({ value }) {
            return <div style={{ padding: "5px" }}>{value}</div>;
        }
    },
    {
        Header: 'Variant Source',
        accessor: "variant_source",
        filter: 'fuzzyText',
        Cell: function({ value }) {
            return <div style={{ padding: "5px" }}>{value}</div>;
        }
    },
    {
        Header: 'Inheritance',
        accessor: "inheritance",
        filter: 'fuzzyText',
        Cell: function({ value }) {
            return <div style={{ padding: "5px" }}>{value}</div>;
        }
    },
    {
        Header: 'Status',
        accessor: "status",
        filter: 'fuzzyText',
        Cell: function({ value }) {
            return <div style={{ padding: "5px" }}>{value}</div>;
        }
    },
    {
        Header: 'Project',
        accessor: "project",
        filter: 'fuzzyText',
        Cell: function({ value }) {
            return <div style={{ padding: "5px" }}>{value}</div>;
        }
    },
    {
        Header: 'User',
        accessor: "user",
        filter: 'fuzzyText',
        Cell: function({ value }) {
            return <div style={{ padding: "5px" }}>{value}</div>;
        }
    },
    {
        Header: 'Timestamp',
        accessor: "timestamp",
        filter: 'fuzzyText',
        Cell: function({ value }) {
            return <div style={{ padding: "5px" }}>{value}</div>;
        }
    },
    {
        Header: 'Transcript',
        accessor: "transcript",
        filter: 'fuzzyText',
        Cell: function({ value }) {
            return <div style={{ padding: "5px" }}>{value}</div>;
        }
    },
    {
        Header: 'Ref',
        accessor: "ref",
        filter: 'fuzzyText',
        Cell: function({ value }) {
            return <div style={{ padding: "5px" }}>{value}</div>;
        }
    },
    {
        Header: 'Origin',
        accessor: "origin",
        filter: 'fuzzyText',
        Cell: function({ value }) {
            return <div style={{ padding: "5px" }}>{value}</div>;
        }
    },
    {
        Header: 'Region Name',
        accessor: "region_name",
        filter: 'fuzzyText',
        Cell: function({ value }) {
            return <div style={{ padding: "5px" }}>{value}</div>;
        }
    },
    {
        Header: 'Zygosity',
        accessor: "zygosity",
        filter: 'fuzzyText',
        Cell: function({ value }) {
            return <div style={{ padding: "5px" }}>{value}</div>;
        }
    },
];
