



import React from "react";
import {extractPills} from "./functions/DataCalcutator";
import "./variant-summary.css";
import Results_Overview from "./components/variant-info/results-overview/Results_Overview";
import {Container} from "@mui/system";






function VariantSummary(props) {

    //const [key, setKey] = useState('summary');
    let pills = extractPills(props.data);



    let overview_data = {
        total: props.total,
        pills: pills
    };

    return (
         <Container maxWidth="lg">
             <Results_Overview
                 data_bool = {props.data.length === 0}
                 overview_data = { overview_data }/>
         </Container>
    )

}





export default VariantSummary;

