


import React from "react";
import {inheritances} from "../../../../../../config-files/config_inheritances";
import {bindActionCreators} from "redux";
import * as Actions from "../../../../../../actions";
import "./genotype_builder.css";
import {connect} from "react-redux";
import ShowGenotypeForm from "./components/GenotypeForm/showGenotypeForm";
import {Divider, Typography } from '@mui/material';
import MappedText from "../clinical-info/mapText";


const GenotypeBuilder = (props) => {

    const inheritance_modes = inheritances;

    let { inheritance_key } = props;  // it's an array of keys
    let selected_inheritances = [];

    if (!Array.isArray(inheritance_key)) {
        inheritance_key = [inheritance_key];
      }
    if(Array.isArray(inheritance_key) && inheritance_key.length!== 0 ){
        selected_inheritances =  inheritance_modes.filter(IM => inheritance_key.includes(IM.key)).map(inheritance => {
            return {
                label: inheritance.label,
                key: inheritance.key
            }
        });
    }

    const drawGenotypeTable = (inheritance_label, inheritance_key) => {

        return  <div id="genotype_builder">
            <div>
                <Typography align = "left" variant="h6" component="h6">
                    <MappedText text={inheritance_label} />
                </Typography>
                <Divider style={{width:  "100%", float: "left"}}/>
                <ShowGenotypeForm inheritance_key={inheritance_key} search_across={false} instandGeneticFindingsTab={props.instandGeneticFindingsTab}/>
            </div>
        </div>;
    }




    if(Array.isArray(selected_inheritances)){
        return selected_inheritances.map(function(item){

            return drawGenotypeTable(item.label, item.key)
        })
    }else{
        return null;
    }





};




function mapInheritance (state) {
    if(state.inheritance_key!== undefined)
    {
        return {inheritance_key:state.inheritance_key}
    }

}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});



export default connect(mapInheritance,mapDispatchToProps)(GenotypeBuilder);