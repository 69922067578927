
import React from "react";
import useNavigate from "../../../../../../components/tables/general-table/hooks/useNavigate";
import CaButton from "../../../../../../gpap-lib/components/shared-components/CaButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";




const BackToButton = ({page, text}) => {
    const navigate = useNavigate();

    const textBtn = (!text) ? "Restart" : text;

    const handleGoBack = () => {
        navigate(page);
    };

    return (
        <CaButton
            variant={"contained"}
            text={textBtn}
            onClick={handleGoBack}
            startIcon={<ArrowBackIcon/>}/>
    );
};

export default BackToButton;