import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import List from "@mui/material/List";
import DeleteIcon from '@mui/icons-material/Delete';
import makeStyles from "@mui/styles/makeStyles";
import { bindActionCreators } from "redux";

import * as Sample_Actions from "../../../reducers/sample_actions";
import * as Patient_Actions from "../../clinical-info/patient_actions";
import { connect } from "react-redux";
import Chip from "@mui/material/Chip";
import GPAP_Tooltip from "../../../../../../../gpap-lib/components/GPAP_Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import {capitalizeFirstLetter} from "../../../../../../../utils/textHelpers";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: 752,
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
}));

function MyExperiments(props){


    const classes = useStyles();
    const [my_data, setSelected] = useState([]);


    useEffect(function (){

        if(props.samples_selected!== undefined && props.samples_selected.length!== my_data.length){

            //put index first
            const orderedSamples = [...props.samples_selected.samples].sort((a, b) => {
                if (a.sample_type === "index") return -1;
                if (b.sample_type === "index") return 1;
                return 0;
            });

            setSelected(orderedSamples)
        }



    },[props.samples_selected]

    );

    const deleteSample = (sample_id, pheno_id) =>{

        props.samples_actions.delete_sample(sample_id);
        props.patient_actions.remove_patient(pheno_id);

    };


    const renderItems = () => {

           return my_data.map( (s,i) => <ListItem>
               <Chip
                   style={{margin: "2%"}}
                   size="small"
                   label={capitalizeFirstLetter(s.sample_type)}
                   color= {(s.sample_type === "index") ? "primary" : "default"}
               />
               <Chip
                   style={{margin: "2%"}}
                   size="small"
                   label={s.affectedStatus ? "Affected" : "Unaffected"}
                   color= {(s.affectedStatus) ? "primary" : "default"}
               />
               <Chip
                   style={{margin: "2%", background: "#78c679" }}
                   size="small"
                   label={ (s.experiment_type!== undefined && s.experiment_type!== "" ) ? s.experiment_type : "NA"}
               />
               <ListItemText
               primary= {"Experiment ID: " + s.sample_id}
               secondary={"Participant ID: " + s.pheno_id}
           />
               <ListItemSecondaryAction>
                   <GPAP_Tooltip title={"see Experiment details"}>
                       <IconButton
                           variant="link"
                           color="primary"
                           target={"_blank"}
                           href= {"/datamanagement/#/experimentinfo/" + s.sample_id}
                       >
                           <InfoIcon />
                       </IconButton>
                   </GPAP_Tooltip>
                   <IconButton edge="end" aria-label="delete">
                       <DeleteIcon onClick={()=> deleteSample(s.sample_id, s.pheno_id)}/>
                   </IconButton>
               </ListItemSecondaryAction>
           </ListItem>,);
       };



    const showList = () => {
        if(my_data.length!==0){
            return  <List dense={true}>
                    {renderItems()}
            </List>
        }
        else{
            return (props.patients.patients.length!==0)
                ? <Typography variant="h6" className={classes.title}>No Experiments available.</Typography>
                : <Typography variant="h6" className={classes.title}>No Experiments selected yet. </Typography>
        }
    };


    return <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
            {(my_data.length!==0)
                ? <Typography variant="h6" className={classes.title}>Selected Experiments</Typography>
                : null}
            <div className={classes.demo}>
                {showList()}
            </div>
        </Grid>
    </Grid>


}

//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.sample_list.length!==0)
    {
        return {samples_selected: state.sample_list, token: state.authorization.token, patients: state.patients}
    }
    else{
        return {samples_selected: [], token: state.authorization.token}
    }
}

const mapDispatchToProps = (dispatch) => ({
    samples_actions: bindActionCreators(Sample_Actions, dispatch),
    patient_actions: bindActionCreators(Patient_Actions, dispatch)
});



export default connect(mapStateToProps,mapDispatchToProps)(MyExperiments);