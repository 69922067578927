import React, { useEffect, useState } from "react";
// MUI TreeView
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'; // Correct import path
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Box from "@mui/material/Box";

export default function SampleTreeView(props) {
    const [expanded, setExpanded] = useState([]);
    const [selected, setSelected] = useState([]);
    const [items, setItems] = useState([]);
    const MAX_AGGREGATION_SIZE = 100;

    useEffect(() => {
        if (props.data !== undefined && props.data.length !== 0 && items.length === 0) {
            let mydata = props.data;
            let counter = 0;
            let data_items = [];
            mydata.map(function (d, i) {
                counter += 1;
                data_items.push({
                    id: counter.toString(),
                    type: "sample",
                    label: d.experiment,
                });
                d.genes.map((gene, index) => {
                    counter += 1;
                    data_items.push({ id: counter.toString(), type: "gene", label: gene.gene });
                });
            });

            setItems(data_items);
        }
    }, [props.data, items]);

    // when clicking an experiment
    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
        let mydata = items.filter((s) => nodeIds.includes(s.id)).filter((i) => i.type === "sample");

        let sample_array = mydata.map((g) => g.label);

        let last_sample = sample_array.length !== 0 ? [sample_array[0]] : sample_array;
        // genes
        let genes = [];
        if (last_sample.length > 0) {
            genes = props.data.find((s) => s.experiment === last_sample[0]).genes.map((g) => g.gene);
        }

        props.passSamples(last_sample, genes);
    };

    // when clicking a GENE
    const handleSelect = (event, nodeIds) => {
        setSelected(nodeIds);

        let genes = items.filter((s) => nodeIds.includes(s.id)).filter((i) => i.type === "gene");
        let gene_array = genes.filter((s) => s.id === nodeIds).map((g) => g.label);

        props.passGenes(gene_array);
    };

    const renderTree = () => {
        let counter = 0;

        return props.data.map(function (d, i) {
            counter += 1;
            let exp_label = d.experiment + " (" + d.genes.length + ")";

            return (
                <TreeItem nodeId={counter.toString()} label={exp_label} key={counter}>
                    {d.genes.map((gene, index) => {
                        counter += 1;
                        let label = gene.gene + " (" + gene.value + ")";
                        return (
                            <TreeItem nodeId={counter.toString()} label={label} key={counter} />
                        );
                    })}
                </TreeItem>
            );
        });
    };

    return (
        <Box p={1}>
            {props.data.length < MAX_AGGREGATION_SIZE ? (
                <SimpleTreeView
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    expanded={expanded}
                    selected={selected}
                    onNodeToggle={handleToggle}
                    onNodeSelect={handleSelect}
                >
                    {renderTree()}
                </SimpleTreeView>
            ) : (
                <div style={{ float: "left" }}>
                    There are too many experiments to aggregate. Narrow down your query to inspect this feature (max 100 experiments)
                </div>
            )}
        </Box>
    );
}
