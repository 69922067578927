


import React from "react";
import { Chip, Avatar, CardContent, Card, Typography } from "@mui/material"; // Updated imports for MUI 5
import cardStyles from "../../card-style";
import "../clinical-info.css";
import GPAP_Tooltip from "../../../../../../../gpap-lib/components/GPAP_Tooltip";
import GenotypeSettingsDialog from "../../GenotypeBuilder/components/GenotypeForm/GenotypeSettingsDialog";
import { GPAP_Link_Button } from "../../../../../../../gpap-lib/components/Button_Collection";
import {capitalizeFirstLetter} from "../../../../../../../utils/textHelpers";



function stringConcatenation(my_array, lookup){


    let concat_string = '';
    if(my_array!== undefined){
        let arr = my_array.map(function(o) {
            if(o!== undefined){
                return o[lookup];
            }
            else{
                return null;
            }
            }).filter(s => s!= null);

        if(arr.length>1){
            let lastElem = " and " +arr.pop();
            return concat_string+arr.join(", ")+lastElem;
        }
        else if(arr.length ===1){
            return arr[0];
        }
        else{
            return concat_string;
        }
    }


}

function getValOrNa(val){

    return (val!== "" && val!== undefined ) ? val : "Not Available"
}

function RenderPatientCard(props){


    const classes = cardStyles();
    let {participant, experiment_id, readOnly, key, sample_type} = props;

    let {url_phenostore, url_datamanagement_single} = config.apis_configuration;

    let hpo_observed = Array.isArray(participant.features) ? participant.features.filter(s => s.observed === true) : [];
    let hpo_not_observed  = Array.isArray(participant.features) ? participant.features.filter(s => s.observed === false) : [];

    let diagnosis = stringConcatenation(participant.diagnosis, "name");
    let inheritance = stringConcatenation(participant.inheritance, "label");
    let hpos_o = stringConcatenation(hpo_observed, "name");
    let hpos_no = stringConcatenation(hpo_not_observed, "name");

    let dis_array = (participant.disorders!== undefined) ? participant.disorders.map(s => s["omim"]) : [];

    let disorders = stringConcatenation(dis_array, "name");

    let genes = [];
    if(participant.genes!== undefined){
        let gene_string = participant.genes.map(d => {

            let geneName = "gene unknown";
            if(d.gene!== undefined &&  typeof d.gene === 'string'){
                geneName = d.gene
            }
            else if (d.gene!== null && d.gene.name!== undefined){
                geneName = d.gene.name
            }

            let status = (d.status!== undefined) ? " ( status:" + d.status + ")" : "(status: NA)";
            return geneName +  status });

        if(Array.isArray(gene_string)){
            genes = gene_string.toString();
            if(typeof genes === "string"){
                genes = genes.replaceAll(',', ', ');
            }
        }

    }


    let consanguinity = (participant.family_history!==undefined) ? participant.family_history.consanguinity : "NA";

    const settings = () => {

        // TODO: currently hidden. We will work on a way to display info in a more intuitive way;

        if(props.sample){


            let gt = (Array.isArray(props.sample.gt_settings)) ? props.sample.gt_settings[0] : false;

            if(gt){
                return <div style={{float: "left", width: "100%"}}>
                    <Typography variant={"subtitle1"}>Current Genotype Settings: </Typography>
                    <GPAP_Tooltip title={"Minimum read depth at variant position"}><div className={"boxSettingsAlt"}>Read Depth: {gt.dp} </div></GPAP_Tooltip>
                    <GPAP_Tooltip title={"Minimum genotype quality (Phred-scaled) for variant position"}><div className={"boxSettingsAlt"}> Min. GT quality: {gt.gq} </div></GPAP_Tooltip>
                    <GPAP_Tooltip title={"Min-Max Alternate Allele Freq"}><div className={"boxSettingsAlt"}>Min-Max A.A. Freq: {gt.ad_low} - {gt.ad_high}</div></GPAP_Tooltip>

                    { (!readOnly)
                        ? <GenotypeSettingsDialog experiment_id={experiment_id} sample={props.sample}/>
                        : null
                    }
                </div>
            }
            else{
                // TODO. Keep an eye on this error;
                console.error("Error with genotype settings.");
                return <div style={{float: "left", width: "100%"}}>
                    <Typography variant={"subtitle1"}>Current Genotype Settings: </Typography>
                    <Typography>Genotype information can not be displayed.</Typography>
                </div>
            }

        }
        else{
            return null;
        }
    };

    console.log("Sample", props.sample);

    return <Card className={classes.root} variant="outlined" key={key}>
        <CardContent>
            <div className={"patientCard"}>
                <Typography component={'span'} variant={"h6"}>
                    <strong>Local ID:</strong> {getValOrNa(participant.id)}
                </Typography>
                <div className={"gpap-title"}>
                    <div className={classes.root}>
                        <GPAP_Link_Button
                            label={experiment_id}
                            target={"_blank"}
                            href= {url_datamanagement_single + experiment_id}
                            clickable={"true"}
                            color="primary"
                        >{experiment_id}</GPAP_Link_Button>
                        <GPAP_Link_Button
                            avatar={<Avatar>PS</Avatar>}
                            component="a"
                            target={"_blank"}
                            href= {url_phenostore + "/#/summary/" + participant.report_id}
                            clickable={"true"}
                            color="primary"
                        >{participant.report_id}</GPAP_Link_Button>
                            <Chip
                                style={{marginTop: "10px"}}
                                size="small"
                                label={capitalizeFirstLetter(sample_type)}
                                color= {(sample_type === "index")
                                    ? "primary"
                                    : "default"}
                            /> </div>
                 {/*   {settings()}*/}
                </div>
                <div className={"gpap-subtitle"}>
                    <div className={"gpap-subtitle"}><strong>Sex:</strong> {getValOrNa(participant.sex)}</div>
                    <div className={"gpap-subtitle"}><strong>Affected:</strong> {getValOrNa(participant.affectedStatus)}</div>
                    <div className={"gpap-subtitle"}><strong>life status:</strong> {getValOrNa(participant.life_status)}</div>
                    <div className={"gpap-subtitle"}><strong>Consanguinity:</strong> {getValOrNa(consanguinity)}</div>
                    <div className={"gpap-subtitle"}><strong>Diagnosis:</strong> {getValOrNa(diagnosis)}</div>
                    <div className={"gpap-subtitle"}><strong>Inheritance:</strong> {getValOrNa(inheritance)}</div>
                    <div className={"gpap-subtitle"}><strong>Phenotypes (obs.):</strong> {getValOrNa(hpos_o)}</div>
                    <div className={"gpap-subtitle"}><strong>Phenotypes (not obs.):</strong> {getValOrNa(hpos_no)}</div>
                    <div className={"gpap-subtitle"}><strong>Disorders:</strong> {getValOrNa(disorders)}</div>
                    <div className={"gpap-subtitle"}><strong>Genes:</strong> {getValOrNa(genes)}</div>
                </div>
            </div>
        </CardContent>
    </Card>

}


export default RenderPatientCard;

