import React from "react";

export const DATA_HEADER = [
    {
        header: "Fusion",
        columns: [
            { filterType: {type: "none"}, nameTable: "Fusioned genes", dataSearch: "FusionName", tooltip: "" },
            { filterType: {type: "none"}, nameTable: "Junction read count", dataSearch: "JunctionReadCount", tooltip: "" },
           
        ],
    },
    {
        header: "Left Gene",
        columns: [
            { filterType: {type: "none"}, nameTable: "Left gene", dataSearch: "LeftGene", tooltip: "" },
            { filterType: {type: "none"}, nameTable: "Left breakpoint", dataSearch: "LeftBreakpoint", tooltip: "" },
        ],
    },
    {
        header: "Right Gene",
        columns: [
            { filterType: {type: "none"}, nameTable: "Right gene", dataSearch: "RightGene", tooltip: "" },
            { filterType: {type: "none"}, nameTable: "Right breakpoint", dataSearch: "RightBreakpoint", tooltip: "" },
        ],
    },
];
