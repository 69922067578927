import React from "react";
import {AlignmentType, HeadingLevel, Paragraph, TextRun} from "docx";

import { createSpacer } from "../write_utils/documentHelpers.js";
import { renderToStringAlt } from "../../../create-report/shared-components/renderToString.js";

function WriteInterpretationData(sectionData, analysisType) {
    let snv_rows = [];
    let cnv_rows = []

    if (sectionData !== null && Object.keys(sectionData).length>0) {

        snv_rows = (sectionData.rows) ? sectionData.rows.filter(s => s.associated_genes === undefined) : [];
        cnv_rows = (sectionData.rows) ? sectionData.rows.filter(s => s.associated_genes !== undefined) : [];
    }

    const renderRows = (rows) => {

        const sections = rows.map(row => {

            let transcript = (row.transcript!== undefined) ? row.transcript : "NA";

            let title;
            if (row.transcript && row.transcript !== 'None') {
                title = row.transcript;
            } else {
                title = row.sv_type;
            }

            let gene = (row.geneName!== undefined) ? row.geneName : "NA";
            let cDNA = (row.cDNA!== undefined) ? row.cDNA : "NA";

            let positionField;
            if (row.start_pos) {
                let end_pos = row.end_pos ? ":" + row.end_pos : "";
                positionField = row.chromosome + ":" + row.start_pos + end_pos;
            } else {
                positionField = "//";
            }

            const text = title + (title !== row.sv_type ? `(${gene})` : "") + (title !== row.sv_type ? `:${cDNA}` : "");
            const titlePar = new Paragraph({
                children: [
                    new TextRun({
                        text: text,
                        bold: true, // Make the text bold
                    })
                ],
                spacing: { after: 40, before: 40 },
            });

            const positionPar = new Paragraph({
                children: [
                  new TextRun({
                    text: `${renderToStringAlt("Genomic Position:")} ${positionField}`,
                  })
                ],
                spacing: { after: 40, before: 10 },
              });
              

           /* const CommentPar = new Paragraph({
                children: [ bodyText(row.comments)],
            });*/

            const CommentPar = new Paragraph({
                children: [
                    new TextRun({
                        text: row.comments || renderToStringAlt("No additional information provided."), // Default text if comments are empty or undefined
                        italics: !row.comments, // Apply italics if comments are empty or undefined
                        color: !row.comments ? "888888" : "000000", // Gray color if comments are empty or undefined, otherwise black
                    }),
                ],
            });

            const spacerPar = createSpacer(50, 50);

            return [titlePar,positionPar, CommentPar, spacerPar]; // Return an array of paragraphs
        });
        return sections.flat(); // Flatten the array of arrays
    }

    // snv and cnv sections
    const snv_sections = renderRows(snv_rows);
    const cnv_sections= renderRows(cnv_rows);

    const title = new Paragraph({
        children: [
            new TextRun({
                text: renderToStringAlt("INTERPRETATION"),
                size: 24,
                color: '#000000',
                bold: true,
                heading: HeadingLevel.HEADING_2,
                underline: true
            }),
        ]
    });

    const titleConclusions =new Paragraph({
        children: [
            new TextRun({
                text: renderToStringAlt("Conclusion"),
                size: 24,
                color: '#000000',
                bold: true,
                heading: HeadingLevel.HEADING_2,
                underline: true
            }),
        ]
    });


    const conclusionPar = new Paragraph({
        children: [
            new TextRun({
                text: (sectionData.conclusions !== "") ? sectionData.conclusions : renderToStringAlt("No additional information provided."),
                italics: !sectionData.conclusions,
                color: !sectionData.conclusions ? "888888" : "000000",
            })
        ]
    })

    const spacerPar = createSpacer(50, 50);

    const titleRecommendations = new Paragraph({
        children: [
            new TextRun({
                text: renderToStringAlt("Recommendation"),
                size: 24,
                color: '#000000',
                bold: true,
                heading: HeadingLevel.HEADING_2,
                underline: true
            }),
        ]
    });

    const recommendationsPar = new Paragraph({
        children: [
            new TextRun({
                text: (sectionData.recommendations !== "") ? sectionData.recommendations : renderToStringAlt("No additional information provided."),
                italics: !sectionData.recommendations,
                color: !sectionData.recommendations ? "888888" : "000000",
            })]
    })

    if ( analysisType === "germline"){
        return [title, ...snv_sections, ...cnv_sections, titleConclusions, conclusionPar, spacerPar, titleRecommendations, recommendationsPar];
    } else {
        // somatic does not include recommendations
        return [title, ...snv_sections, ...cnv_sections, titleConclusions, conclusionPar, spacerPar];
    }


}

export default WriteInterpretationData;



