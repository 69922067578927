import MappedText from "../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import React from "react";


function isStringOnlyEmptySpaces(input) {

    if (typeof input === "string") {
        return input.trim().length === 0 || input !== input.trim();
    } else {
        return false; 
    }

}

const getText = (val, type, action) => {
    if(val !== ""){
        if(isStringOnlyEmptySpaces(val)){
            return <MappedText text={"No leading and trailing spaces are allowed"}/>;
        }
        else{
            return <MappedText text={"Name already exists."}/>;
        }

    }
    else{
        if(val === "" && action === "save"){
            return "Give a name to the " + type;
        }
        else{
           return "";
        }
    }

}

const checkIfNameExists = (val, listNames, action) => {

    if(val!== ""){
        if(isStringOnlyEmptySpaces(val)){
            return true;
        }
        else{
            return listNames.includes(val);
        }

    }
    else if(val === ""){
        return action === "save";

    }
    else{
        return isStringOnlyEmptySpaces(val);
    }

}

export {isStringOnlyEmptySpaces, getText, checkIfNameExists}