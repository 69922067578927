

const gene_field = "gene_name";

const disease_info = {
    id: "root",
    "name": "Disease Information",
    children:[
        {
            id: "0",
            name: 'OMIM',
            url_prefix : 'https://omim.org/search/?index=entry&start=1&limit=10&sort=score+desc%2C+prefix_sort+desc&search=',
            input: ['gene_name'],
            url_appendix: '',
            description: '',
            variable_category: "gene_name"
        },
    ]
    
}

const variant_info_38 = {
    id: "root",
    "name": "Variant Information",
    children:[
        {
            id: '0',
            name: 'dbSNP',
            url_prefix: 'http://www.ncbi.nlm.nih.gov/snp/',
            input: ['dbSNP'],
            description: 'dbSNP Reference SNP (rs or RefSNP) number is a locus accession for a variant type assigned by dbSNP',
            url_appendix: '',
            variable_category: "rs"
        },
        {
            id: "1",
            name: 'Ensembl',
            url_prefix: 'http://www.ensembl.org/Homo_sapiens/Location/View?r=',
            input: ['chrom','pos_start', 'pos_end'],
            description: '',
            url_appendix: '',
            variable_category: "pos"
        },
        {
            id: "2",
            name: 'NCBI',
            url_prefix: "https://www.ncbi.nlm.nih.gov/variation/view/?assm=GCF_000001405.40&chr=",
            input: ['chrom', 'pos_start', 'pos_end'],
            description: '',
            url_appendix: '',
            variable_category: "pos"
        },
        {
            id: "3",
            name: 'DGVa',
            url_prefix: "http://dgv.tcag.ca/gb2/gbrowse/dgv2_hg38/?name=chr",
            input: ['chrom', 'pos_start', 'pos_end'],
            description: '',
            url_appendix: '',
            variable_category: "pos"
        },
        {
            id: "4",
            name: 'gnomAD',
            url_prefix : 'https://gnomad.broadinstitute.org/region/',
            input: ['chrom','pos_start', 'pos_end'],
            description: '',
            url_appendix: '?dataset=gnomad_r3',
            variable_category: "pos"
        },
        {
            id: "5",
            name: 'UCSC',
            url_prefix: "http://genome-euro.ucsc.edu/cgi-bin/hgTracks?db=hg38&lastVirtModeType=default&lastVirtModeExtraState=&virtModeType=default&virtMode=0&nonVirtPosition=&position=",
            input: ['chrom', 'pos_start', 'pos_end'],
            description: '',
            url_appendix: "&hgsid=279155592_46qebeEONPC9a28Uow1XNTe6dQuJ",
            variable_category: "pos"
        },
        {
            id: "6",
            name: 'Franklin',
            url_prefix : "https://franklin.genoox.com/clinical-db/variant/snp/chr",
            input: ['chrom', 'pos', 'ref', "alt"],
            description: '',
            url_appendix: '-hg38',
            variable_category: "pos"
        },
        {
            id: "7",
            name: 'VarSome',
            url_prefix : "https://varsome.com/variant/hg38/",
            input: ['chrom', 'pos', 'ref', "alt"],
            description: '',
            url_appendix: '',
            variable_category: "pos"
        },
        {
            id: "8",
            name: 'SpliceAI Lookup',
            url_prefix : "https://spliceailookup.broadinstitute.org/#variant=",
            input: ['chrom', 'pos', 'ref', "alt"],
            description: '',
            url_appendix: '&hg=37&distance=50&mask=0&precomputed=0',
            variable_category: "pos"
        },
        {
            id: "9",
            name: 'InterVar',
            url_prefix : "http://wintervar.wglab.org/results.pos.php?queryType=position&chr=",
            input: ['chrom', 'pos', 'ref', "alt"],
            description: '',
            url_appendix: '&build=hg38',
            variable_category: ""  // add "pos" to enable it
        },
        {
            id: "10",
            name: 'Trap Score',
            url_prefix : " http://trap-score.org/Search?query=",
            input: ['chrom', 'pos', 'ref', "alt"],
            description: '',
            url_appendix: '',
            variable_category: "pos"
        },
        {
            id: "11",
            name: 'LOVD',
            url_prefix: "https://databases.lovd.nl/shared/transcripts/",
            input: [gene_field, "transcript_id"],
            description: '',
            url_appendix: "",
            variable_category: gene_field
        }

    ],
    description: "A list of links releated to variant annotations"
};

const variant_info_cancer = {
    id: "root",
    "name": "Variant Information-Cancer",
    children:[
        // {
        //     id: "0",
        //     name: 'CiVIC',
        //     url_prefix : "https://civicdb.org/variants/",
        //     input: [gene_field],
        //     description: '',
        //     url_appendix: '/summary',
        //     variable_category: gene_field
        // },
        // {
        //     id: "1",
        //     name: 'COSMIC',
        //     url_prefix : 'http://cancer.sanger.ac.uk/cosmic/gene/overview?ln=',
        //     input: [gene_field],
        //     description: '',
        //     url_appendix: '',
        //     variable_category:  gene_field
        // },
        {
            id: "0",
            name: 'Franklin',
            url_prefix : "https://franklin.genoox.com/clinical-db/variant/snpTumor/chr",
            input: ['chrom', 'pos', 'ref', "alt"],
            description: '',
            url_appendix: '-hg38',
            variable_category: "pos"
        },
        {
            id: "1",
            name: 'VarSome',
            url_prefix : "https://varsome.com/variant/hg38/",
            input: ['chrom', 'SV_start', 'ref', "alt"],
            description: "Platform offering comprehensive variant annotation and interpretation.",
            url_appendix: '',
            variable_category: "SV_start"
        },
        

    ],
    description: "A list of links releated to variant annotations"
};

const gene_info_cancer = {
    id: "root",
    "name": "Gene Information-Cancer",
    children:[
        {
            id: "0",
            name: 'cBioportal',
            url_prefix: 'https://www.cbioportal.org/results/mutations?case_set_id=all&gene_list=',
            input: [gene_field],
            description: '',
            url_appendix: '&cancer_study_list=5c8a7d55e4b046111fee2296',
            variable_category: gene_field
        },
        {
            id: "1",
            name: 'IntoGen',
            url_prefix: 'https://www.intogen.org/search?gene=',
            url_appendix: '',
            input: [gene_field],
            description: 'Database for exploring scientific literature related to genes.',
            variable_category: gene_field
        },
        {
            id: "2",
            name: 'CiVIC',
            url_prefix: 'https://civicdb.org/genes/',
            url_appendix: '/summary',
            input: [gene_field],
            description: 'Database providing comprehensive information about genes.',
            variable_category: gene_field
        },
        {
            id: "3",
            name: 'COSMIC genes',
            url_prefix: 'https://cancer.sanger.ac.uk/cosmic/gene/analysis?ln=',
            url_appendix: '',
            input: [gene_field],
            description:'Database providing information about genes implicated in cancer.',
            variable_category: gene_field
        },
        {
            id: "4",
            name: 'COSMIC census hallmark',
            url_prefix: 'https://cancer.sanger.ac.uk/cosmic/census-page/',
            url_appendix: '',
            input: [gene_field],
            description: 'Database providing information about genes implicated in cancer.',
            variable_category: gene_field
        },
        {
            id: "5",
            name: 'GDC',
            url_prefix: 'https://portal.gdc.cancer.gov/genes/',
            url_appendix: '',
            input: ['ensembl_gene_id'],
            description: 'Database providing gene expression data across different tissues.',
            variable_category: 'ensembl_gene_id'
        },
        {
            id: "6",
            name: 'mycancergenome',
            url_prefix: 'https://www.mycancergenome.org/content/gene/',
            url_appendix: '/',
            input: [gene_field],
            description: 'Database providing allele frequencies for genes.',
            variable_category: gene_field
        },
        {
            id: "7",
            name: 'OncoKB',
            url_prefix: 'https://www.oncokb.org/gene/',
            url_appendix: '',
            input: [gene_field],
            description: 'Database containing genomic regions associated with traits from GWAS studies.',
            variable_category: gene_field
        },
    ],
    description: "A list of links releated to gene annotations"
};

const gene_info = {
    id: "root",
    "name": "Gene Information",
    children:[
        {
            id: "0",
            name: 'Ensembl',
            url_prefix: 'http://feb2014.archive.ensembl.org/Homo_sapiens/Gene/Summary?g=',
            input: [gene_field],
            description: '',
            url_appendix: '&db=core',
            variable_category: gene_field
        },
        {
            id: "1",
            name: 'PubMed',
            url_prefix : 'http://www.ncbi.nlm.nih.gov/pubmed/?term=',
            url_appendix: '+gene',
            input: [gene_field],
            description: '',
            variable_category: gene_field
        },
        {
            id: "2",
            name: 'GeneCards',
            url_prefix: 'http://www.genecards.org/cgi-bin/carddisp.pl?gene=',
            url_appendix: '',
            input: [gene_field],
            description: '',
            variable_category: gene_field
        },
        {
            id: "3",
            name: 'GTEx',
            url_prefix : 'http://www.gtexportal.org/home/gene/',
            url_appendix: '',
            input: [gene_field],
            description: '',
            variable_category: gene_field
        },
        {
            id: "4",
            name: 'ClinGen',
            url_prefix : 'https://search.clinicalgenome.org/kb/genes?page=1&size=50&search=',
            url_appendix: '',
            input: [gene_field],
            description: '',
            variable_category: gene_field
        },
        {
            id: "5",
            name: 'HGMD',
            url_prefix : 'http://www.hgmd.cf.ac.uk/ac/gene.php?gene=',
            url_appendix: '',
            input: [gene_field],
            description: '',
            variable_category: gene_field
        },
        {
            id: "6",
            name: 'GeneReviews',
            url_prefix : 'https://www.ncbi.nlm.nih.gov/books/NBK1116/?term=',
            url_appendix: '',
            input: [gene_field],
            description: '',
            variable_category: gene_field
        },
        {
            id: "7",
            name: 'DisGeNet',
            url_prefix: "https://www.disgenet.org/browser/1/1/0/",
            input: ["gene_name"],
            description: '',
            url_appendix: "",
            variable_category: "geneId"
        },
        {
            id: "8",
            name: 'ClinVar',
            url_prefix : 'https://www.ncbi.nlm.nih.gov/clinvar/?term=',
            url_appendix: '',
            input: ['clinvar_id'],
            description: '',
            variable_category: "clinvar_id"

        },
    ],
    description: "A list of links releated to gene annotations"
};



const pharmaco = {
    id: "root",
    name: "Pharmacogenomics ",
    children: [
        {
            id:  "0",
            name: 'PharmGKB ',
            url_prefix : "https://www.pharmgkb.org/search?query=",
            input: ["gene"],
            description: '',
            url_appendix: '',
            variable_category: "gene_name"
        },
        {
            id:  "1",
            name: 'PharmVar',
            url_prefix : " https://www.pharmvar.org/gene/",
            input: ["gene"],
            description: '',
            url_appendix: '',
            variable_category: "gene_name"
        }]

}


const configuration_tree_somatic = {

    "Disease Information": disease_info,
    "Variant Information General": variant_info_38  ,
    "Variant Information Cancer": variant_info_cancer  ,    
    "Gene Information General": gene_info,
    "Gene Information Cancer":gene_info_cancer,
    "Pharmacogenomics": pharmaco
};


export {configuration_tree_somatic} ;