
import React from 'react';
import Chip from "@mui/material/Chip";


const QueryChips = ({ queryNumbers }) => {

    const { snv_total, cnv_regions } = queryNumbers;



    return (
        <div className={"nVariants"}>
           {/* <Chip size="small" label={`Total: ${total}`} />*/}
            <Chip size="small" label={`SNV positions: ${ (snv_total && snv_total !== -1) ? snv_total : 'NA'}`} />
            <Chip size="small" label={`CNV regions: ${ (cnv_regions && cnv_regions !== -1) ? cnv_regions : 'NA'}`} />
        </div>
    );
};

export default QueryChips;
