


export function findMultipleExperiments(dataArray) {
    const participantIDMap = new Map();
    const multiple = [];

    for (const item of dataArray) {
        const participantID = item.Participant_ID;

        if (participantIDMap.has(participantID)) {
            if (!multiple.includes(participantID)) {
                multiple.push(participantID);
            }
        } else {
            participantIDMap.set(participantID, true);
        }
    }

    return multiple;
}