import React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import Typography from "@mui/material/Typography";
import GPAP_Tooltip from "./GPAP_Tooltip";

const MyCard = styled(Card)(({ theme }) => ({
    color: "white",
    height: "120px",
    fontSize: "22px",
    textAlign: "start",
}));

const ActionArea = styled(CardActionArea)(({ theme }) => ({
    "&:hover": {
        backgroundColor: "orange",
    },
}));

function GPAP_CardButton(props) {
    const val = props.disable ? "#d9d9d9" : props.color;

    return (
        <GPAP_Tooltip title={props.description}>
            <MyCard style={{ backgroundColor: val }} elevation={2}>
                <ActionArea
                    disabled={props.disable}
                    style={{ height: "120px" }}
                    onClick={() => {
                        props.gpap_action(props.type);
                    }}
                >
                    <CardContent>
                        <Typography variant="h6" style={{color: "white"}}>{props.text}</Typography>
                    </CardContent>
                </ActionArea>
            </MyCard>
        </GPAP_Tooltip>
    );
}

export default GPAP_CardButton;
