import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getFormSyncErrors } from "redux-form";

// MUI v5 imports
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Container from "@mui/material/Container";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import QueryBuilder from "@mui/icons-material/QueryBuilder";

// Custom components
import { GPAP_RunButton } from "../../../../../../gpap-lib/components/Button_Collection";
import GPAP_Dialog from "../../../../../../gpap-lib/components/GPAP_Dialog";
import Success from "../Success";
import Sample_Section from "../samples-section/Sample_Section";
import ClinicalInfo from "../clinical-info/ClinicalInfo";
import Inheritance_Mode_renderer from "../InheritanceMode/Inheritance_Mode_renderer";
import GB_renderer from "../GenotypeBuilder/GB_renderer";
import StartFilters from "../../../../../dashboard/components/genomics-dashboard/components/side-panels/filter-panel/components/filter-sections/start-filters/StartFilters";
import AlertDialogStepForm from "./AlertDialogStepForm";
import { AlertInfo } from "../samples-section/search-by-id/SelectionInfo";
import CustomNoGenotypeMessage from "../GenotypeBuilder/components/GenotypeForm/CustomInheritance_NoGenotype";

// Helpers and Configs
import { checkCustomGenotype } from "../GenotypeBuilder/helpers/checkCustomGenotype";
import { step_form_labels } from "../../../../../../config-files/family_stepform_sections";
import GPAP_Tooltip from "../../../../../../gpap-lib/components/GPAP_Tooltip";
import MappedText from "../clinical-info/mapText";





// Step titles



const labels= step_form_labels;

const StepForm = (props) => {

    let {experiments}  = props;
    const [activeStep, setActiveStep] = useState(0);
    const [formValues, setFormValues] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);

    const [dialogIndexes, setDialogIndexes] = useState(false);
    const [openDialogIndex, setOpenDialogIndex]= useState(false)


    const [customNoGQ, setCustomNoGQ] = useState(false);

    const { syncErrors } = props;


    useEffect(() => {
        if (dialogIndexes) {
            setOpenDialogIndex(true);
        }
    }, [dialogIndexes]);

    useEffect(() =>{
        let indexSamplesCount= props.samples.filter(sample=> sample.sample_type ==="index").length;
        if(indexSamplesCount > 1){
            setDialogIndexes(true);
        
        }else{
            setDialogIndexes(false);
        }
    },[props.samples])



    useEffect(() => {
        checkCustomGenotype(props.samples, props.inheritance, setCustomNoGQ);
      }, [props.samples, props.inheritance]);


    const handleCloseDialogIndex = () => {
        setOpenDialogIndex(false);
        setDialogIndexes(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    const checkGenotypes = () => {

        let {samples, inheritance} = props;
        if(inheritance === "custom" && activeStep === 2){

            //check samples inheritances. if there is any empty.
            return samples.filter(sample => sample.gt_settings[0].setting.length === 0).length === 0;

        }
        else{
            return true;
        }


    }

    // Proceed to next step
    const handleNext = () => {

        let bool = checkGenotypes();

        if(bool){
            window.scrollTo(0, 0);
            setActiveStep((prev) => prev + 1);
        }
        else{
            setDialogOpen(true)
        }

    }
    // Go back to prev step
    const handleBack = () => setActiveStep((prev) => prev - 1);

   const getChetCheck = () => {

       return (props.studySettings.analysis_ids.map(s => s.label).includes("compound heterozygosity") && !props.formTouched ||  (syncErrors && syncErrors.revel))

   }

   const disableBackifNeeded = () => {

       let inh_applied = props.inheritance;
       return !!inh_applied.includes("de_novo");

   }

   const getBackFromInhText =() => {

       let bool = disableBackifNeeded();

       if(bool){
           return <MappedText text={"You have selected the De Novo Inheritance. To avoid misleading scenarios, we prefer to prevent editing the analyzed experiments/participants. If you want to do so, please create a new study from scratch by clicking on Restart on the left of the screen"}/>
       } else{
           return <MappedText text={"Go back to see the clinical and experiment information."}/>
           }
   }

    const handleSteps = (step) => {
        switch (step) {
            case 0:
                return (
                    <Box>
                        <Container>
                            <Box m={2} style={{textAlign: "center"}}>
                                    <GPAP_RunButton
                                        text={"Next"}
                                        variant="contained"
                                        disabled={dialogIndexes ||props.samples.length === 0 }
                                        startIcon={<ArrowForwardIcon/>}
                                        onClick={handleNext}/>
                                </Box>
                        </Container>
                        <Sample_Section samples={experiments}/>
                    </Box>
                );
            case 1:
                return (
                    <React.Fragment>
                        <Box m={2} style={{textAlign: "center"}}>
                            <GPAP_RunButton
                                text={"Back"}
                                variant="contained"
                                disabled={false}
                                startIcon={<ArrowBackIcon/>}
                                onClick={handleBack}/>
                            <GPAP_RunButton
                                text={"Next"}
                                variant="contained"
                                disabled={(Array.isArray(props.patients.patients) && props.patients.patients.length === 0)}
                                startIcon={<ArrowForwardIcon/>}
                                onClick={handleNext}/>
                        </Box>
                        <Box p={2}>
                        </Box>
                        <Box p={2}>
                        <ClinicalInfo/>
                    </Box>
                    </React.Fragment>
                );
            case 2:
                return (
                    <Box>
                        <Box m={2} style={{textAlign: "center"}}>
                            <GPAP_Tooltip title={getBackFromInhText()}>
                                    <span>
                                        <GPAP_RunButton
                                            text={"Back"}
                                            variant="contained"
                                            disabled={disableBackifNeeded()}
                                            onClick={handleBack}
                                            startIcon={<ArrowBackIcon />}
                                        />
                                    </span>
                            </GPAP_Tooltip>
                            <GPAP_RunButton
                                    text={"Next"}
                                    variant="contained"
                                    disabled={(Array.isArray(props.inheritance) && props.inheritance.length === 0) || (customNoGQ)}
                                    startIcon={<ArrowForwardIcon/>}
                                    onClick={handleNext}/>

                            </Box>
                           
                        <CustomNoGenotypeMessage show={customNoGQ} />

                        <Inheritance_Mode_renderer/>
                        <GB_renderer/>
                    </Box>
                );

            case 3:
                return (
                    <React.Fragment>
                        <Box m={2} style={{textAlign: "center"}}>
                            <GPAP_RunButton
                                text={"Back"}
                                variant="contained"
                                disabled={false}
                                startIcon={<ArrowBackIcon/>}
                                onClick={handleBack}/>
                            <GPAP_RunButton id="run_analysis"
                                            text={"Start Analysis"}
                                            variant="contained"
                                            disabled = {getChetCheck()}
                                            startIcon={<QueryBuilder/>}
                                            onClick={() => props.run_analysis()}/>
                            {syncErrors && syncErrors.revel && (
                                <div style={{ color: 'red' }}>
                                    Revel Error: {syncErrors.revel}
                                </div>
                            )}
                        </Box>
                    <Box>
                        {
                            (getChetCheck())
                            ?  <AlertInfo severity="warning"
                                        message={"To run a C-Het analysis you must add at least one filter."}
                                        title={"No filter selected"}/>
                                : null

                        }
                        <StartFilters/>
                    </Box>
                    </React.Fragment>
                );
            default:
                break;
        }
    };

    return (
        <>
            {activeStep === labels.length ? (
                // Last Component
                <Success values={formValues} />
            ) : (
                <>
                    <AlertDialogStepForm handleClose={handleClose} open={dialogOpen}/>
                    <GPAP_Dialog
                        open={openDialogIndex}
                        handleClose={handleCloseDialogIndex}
                        title={"Multiple Index Cases Detected"}
                        text={"More than one index case was found. Please ensure that you have only a single index case to proceed with the analysis by eliminating any unnecessary experiments."}
                        onConfirmText={"ok"}
                        onExitText={false}
                        onConfirmAction={handleCloseDialogIndex}
                        type="warning"
                    />
                    <Stepper
                        activeStep={activeStep}
                        style={{ margin: "30px 0 15px" }}
                        alternativeLabel>
                        {labels.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {handleSteps(activeStep)}
                </>
            )}
        </>
    );
};


function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        if(state.sample_list!== undefined)
        {
            // get form variants, populations, snv?effects, position_roh, gene_section
            let touched = false;
            Object.keys(state.form).forEach(function(formEl){
                let VALUES = state.form[formEl].values;
                if(VALUES!== undefined){
                    Object.keys(VALUES).forEach(function(formElKey){

                            let val = VALUES[formElKey];
                            if(Array.isArray(val) && val.length !== 0){
                                touched = true
                            }
                            if (val !== null && !Array.isArray(val) ) {
                                touched = true;
                            }
                        })

                    }})


            return {
                samples: state.sample_list.samples,
                patients: state.patients,
                inheritance: state.inheritance_key,
                token:state.authorization.token,
                studySettings: state.studySettings,
                query: state.query,
                form: state.form,
                studies: state.studies,
                formTouched:touched,
                syncErrors: getFormSyncErrors('snv_effects')(state) // Replace 'snv_effects' with your form name

            }
        }
        else{
            return {samples: [],  inheritance: state.inheritance, token:state.authorization.token}
        }
    }
}



export default connect(mapStateToProps,null)(StepForm);