import React from 'react';
import { Button } from '@mui/material';
import MappedText from "../../../scenes/instand/reports/create-report/shared-components/mapText";

const CaButton = ({
    onClick,
    text,
    startIcon,
    variant = 'outlined',
    size = 'small',
    customStyles = {},
    onChange,
    href,             // Add href prop
    target,            // Add target prop
    id,
    disabled
}) => {
    const defaultStyles = {
        outlined: {
            backgroundColor: (disabled) ? "f0f0f0" : "#ffffff",
            color: disabled ? "gray" : "#3E69A9",
            borderColor: "#4768a4",
            margin: "5px",
        },
        contained: {
            backgroundColor: disabled ? "#f0f0f0" : "#3E69A9", // Light gray when disabled, dark blue when enabled
            color: disabled ? "gray" : "#ffffff",
            margin: "5px",

        }
    };
    const appliedStyles = {
        ...defaultStyles[variant],
        ...customStyles
    };

    return (
        <Button
            style={{ textTransform: 'none', boxShadow: 'none', height: "30px", width: 'auto', ...appliedStyles }}
            variant={variant}
            disabled={disabled}
            size={size}
            onClick={onClick}
            startIcon={startIcon}
            onChange={onChange}
            component={href ? "a" : "button"}  // Add this line to render <a> if href exists
            href={href}                        // Pass href to Button
            target={target}                    // Pass target to Button
            id={id}
        >
            <MappedText text={text} />
        </Button>
    );
};

export default CaButton;
