import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Select from "react-select";

// MUI v5 imports
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import TouchApp from "@mui/icons-material/TouchApp";
import {Alert, LinearProgress} from "@mui/material";  // Note: Alert is now part of @mui/material in v5

// Custom components
import { GPAP_Button } from "../../../../../../../../../gpap-lib/components/Button_Collection";
import GPAP_Tooltip from "../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import CaButton from "../../../../../../../../../gpap-lib/components/shared-components/CaButton";
import MappedText from "../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText.js";

// API and Redux actions
import * as Actions from "../../../../../../../../study-page/create-study/analysis-page/reducers/sample_actions";
import * as FilterSetActions from "../../../../../../../../../actions/index.js";
import { deleteFilterSet, get_all_filter_sets } from "../../../../../../../../../services/api/manage_study";



const useMount = (callback) => {
    React.useEffect(callback, [])
}

function FilterSelector(props){

    const [selected, setSelected] = useState(false);
    const [open, setOpen] = useState(true);
    const [applied, setApplied] = useState(false);
    const [filter_set_list, setFilterSetList] = useState([]);
    const [deleted, setDeleted] = useState(false);

    const [loading, setLoading] = useState(true);

    useMount(() => {

        updateFilterList();

    })

    useEffect(() => {
        if (props.resetSignal) {
            handleReset();
        }
    }, [props.resetSignal])


    const updateFilterList = () => {

        setLoading(true);
        get_all_filter_sets(window.config.apis_configuration.api_nextgpap_server, props.token)
            .then(r => r.json())
            .then(function(json){
                let list_names = json.data.filter(s => s.filter_set_name!=="").map(p => p.filter_set_name)

                // if filter sets in DB, avoid adding the default ones..



                let filtersList = [];
                filtersList = filtersList.concat(json.data.filter(s => s.filter_set_name!==""));
                setFilterSetList(filtersList);
            })
            .catch((error) => {
                console.error("Failed to fetch filter sets:", error);
                setFilterSetList([]); // Reset to empty in case of an error
            })
            .finally(() => {
                setLoading(false); // Hide loading after the fetch completes
            });

    }


    useEffect(function(){

        if(props.message){

            // update filter set-list;
            updateFilterList();

        }

    }, [props.message])

    const handleChange = (evt) => {
        setSelected(evt);
        setApplied(false);
    };

    // filter set reset;
    const handleReset = () => {

        setSelected(false);
        setApplied(false);
        props.reset();
        console.log("resetting filters");
        props.filterSet_Actions.reset_filter_set();

    };

    const handleSelect = () => {

        let f_selected =  filter_set_list.find(f => f.id === selected.value);
        // pass filter set to load:
        let new_filter = {...f_selected};
        console.log(new_filter);
        props.setFilterSet(new_filter);

        // setup new filter;
        props.filterSet_Actions.set_filter_set( new_filter );
        setApplied(true);

    };

    const handleDelete = () => {

        let f_selected =  filter_set_list.find(f => f.id === selected.value);

        let del_obj = {"filter_set_id": f_selected.id};

        deleteFilterSet(window.config.apis_configuration.api_nextgpap_server, props.token, del_obj)
            .then(r => r.json())
            .then(function(json){
                if(json["message"] === "Success"){
                    updateFilterList();
                    setDeleted(true);
                    setSelected(false);
                    setTimeout(() => { setDeleted(false)}, 2000);
                }
            })
    }

    // process filters -name

    const getFilterOptions = () => {
        return filter_set_list.map(s => {
            let label = (s.filter_set_name!== "") ? s.filter_set_name : "Filter - " + s.id;
            return {label:label , value: s.id}
        });
    }

    const getAlertText = () => {
        let filterName = selected.label;
        if (Object.keys(props.currentFilterSet).length === 0 && filter_set_list.length !== 0) {
            return <MappedText text={"The filters have changed"} />
        }
        else {
            return <MappedText text={`The ${filterName} filter set has been applied`}/>

        }

    }

    const getText = () => {
        return (applied) ? "Applied" : "Apply";
    }


    return <div style={{ float: "left", maxWidth: "400px" }}>
        <Box p={1} style={{borderBottom: "1px solid #bdbdbd"}}>
            <Box p={0}>
             <GPAP_Tooltip title= {<MappedText text={"GPAP Predefined Filters are curated by the GPAP team"}/>}>
                 <div className={"title_section"} onClick={() => setOpen(!open) }>
                    <div style={{float: "left"}}>
                        <IconButton
                            style={{padding: "0px"}}
                            onClick={() => setOpen(!open)  } >
                            {open ? <ExpandLess /> : <ExpandMore style={{order: -1,
                                transform: "rotate(-90deg)"}}/>}
                        </IconButton> </div>
                    <span > <MappedText text={"Select a predefined filter set"}/></span>
                </div>
             </GPAP_Tooltip>
            </Box>
            <Collapse in={open}>
                <div style={{width: "100%"}}>
                    <Box p={2}>
                        {(!deleted)
                            ?  <Select
                                id='selector_filters'
                                onChange={handleChange}
                                value={(selected) ? selected : null}
                                options={getFilterOptions()}
                                isSearchable = {true}
                                isLoading={loading}
                                placeholder={<MappedText text={"Select a predefined filter set"}/>}
                                style={{ fontSize: "13px"}}/>
                            : <LinearProgress/> }
                        <div style={{marginTop: "8px"}}>
                            <CaButton
                                text={getText()}
                                variant={'contained'}
                                disabled = {!selected||applied}
                                size={"small"}
                                onClick={() => handleSelect()}
                                startIcon={<TouchApp/>}/>
                        </div>
                        {(applied)
                            ? <Alert severity={"info"} >{getAlertText()}</Alert>
                            : null
                        }
                        {(deleted)
                            ? <Alert severity={"success"}><MappedText text={"Filter Set deleted"}/></Alert>
                            : null
                        }
                    </Box>
                </div>
            </Collapse>
        </Box>
    </div>


}

//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    return {
        token: state.authorization.token,
        currentFilterSet: state.currentFilterSet
    }
}


// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
    filterSet_Actions: bindActionCreators(FilterSetActions, dispatch)
});




export default connect(mapStateToProps,mapDispatchToProps)(FilterSelector);