import { createFilterOptions } from "@mui/material/Autocomplete";
import {getField} from "./getField";


export const filterOptions = (options, state, sampleType, results) => {
    const _filterOptions = createFilterOptions();
    let filteredResults = _filterOptions(options, state);
    const field = getField(sampleType);

    if (results !== undefined && results.length > 10) {
        filteredResults = filteredResults.slice(0, 10);
        const obj = {
            [field]: `+ ${results.length - 10} matches.`,
            Participant_ID: "",
            library_strategy: ""
        };

        if (!filteredResults.some((result) => result[field] === obj[field])) {
            filteredResults.push(obj);
        }
    }

    return filteredResults;
};
