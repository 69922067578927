import React from 'react';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import {Grid} from "@mui/material";
import MappedText from '../../reports/create-report/shared-components/mapText';


export const ControlExperiment = (props) => {

    const { title, errorMessage, value, id } = props;

    return (
        <>
            <Grid item xs={4}>
                <InputLabel sx={{mb:2}}><MappedText text={title}/> <span style={{ color: 'firebrick' }}><MappedText text={"Required"}/></span> </InputLabel>
                <TextField
                    error={errorMessage !== ""}
                    required disabled fullWidth
                    id={id}
                    helperText={errorMessage !== "" ? errorMessage : false}
                    variant="outlined"
                    InputProps={{ readOnly: true,}}
                    value={value}
                />
            </Grid> 
        </>
    );
}