

export const findTaggedVariant = (sampleIds, USER_TAGS, user) => {
    let taggedVariant = USER_TAGS.find((tag) => sampleIds.includes(tag.sample));

    if (taggedVariant === undefined) {
        taggedVariant = USER_TAGS.find((tag) => tag.user === user);
    }

    return taggedVariant;
};