


import React from "react";
import {Alert} from "@mui/material";

function AlertGPAP(props) {
    return <Alert elevation={6} variant="filled" {...props} />;
}


export {AlertGPAP}