
import React, { Fragment, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import TextField from "@mui/material/TextField";
import { GPAP_Button } from "../../../../../../../../../gpap-lib/components/Button_Collection";
import CaButton from "../../../../../../../../../gpap-lib/components/shared-components/CaButton";
import MappedText from "../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import { AlertInfo } from "../../../../../../../../study-page/create-study/analysis-page/components/samples-section/search-by-id/SelectionInfo";
import { checkIfNameExists, getText } from "./func";




function SaveFilterSet(props){

    const [openPanel, setOpenSavePanel] = useState(false);
    const [filterInfo, setFilterInfo] = useState({
        name: "",
        description: ""
    });
    const [nameError, setNameError] = useState(false);



    const setFilterDetails = (key, val) => {

        let bool= false;
        if(key === "name"){
            bool = checkIfNameExists(val, props.listNames, "save")
        }

        setNameError(bool);

        let infoSet = {...filterInfo};
        infoSet[key]= val;
        setFilterInfo(infoSet);


    }

    const saveFilterSet = () => {


        props.saveFilterSet(filterInfo);

    }


    return  <Fragment>
            <CaButton
                text={"Save Filter-Set"}
                variant={"contained"}
                disabled={props.disable}
                onClick={function (e) {setOpenSavePanel(!openPanel)}}>
            </CaButton>
        <Collapse in={openPanel}>
            <Grid container direction={"row"}>
                <Grid item lg={6}>
                    <Box p={2}>
                        <TextField
                            error={nameError}
                            style={{width: "70%"}}
                            id="filter_name"
                            label= {<MappedText text={"Filter Name"}/>}
                            helperText= {(checkIfNameExists(filterInfo["name"], props.listNames, "save")) ? getText(filterInfo["name"], "filter-set", "save" ) : ""}
                            onChange={(e=> {
                                let val = e.target.value;
                                setFilterDetails("name", val);
                            })}/>
                    </Box>
                </Grid>
                <Grid item lg={6}>
                    <Box p={2}>
                        <TextField
                            style={{width: "70%"}}
                            id="filter_description"
                            label= {<MappedText text={"Filter Description"}/>}
                            helperText={<MappedText text={"filter_description"}/>}
                            onChange={(e=> {
                                let val = e.target.value;
                                setFilterDetails("description", val);
                            })}/>
                    </Box>
                </Grid>
                <Grid item lg={6}>
                    <Box p={2}>
                        <GPAP_Button
                                     variant={"contained"}
                                     onClick={saveFilterSet}
                                     disabled = {checkIfNameExists(filterInfo["name"], props.listNames, "save")} ><MappedText text="Save Filter"/></GPAP_Button>
                        {(props.message)
                            ? <AlertInfo
                                message={<MappedText text={"Filters saved in the database. You can find the filter-set in the section below."}/>}
                                severity={"success"}
                                title={<MappedText text={"Filter Set Saved Correctly"}/>}/>
                            : null}
                    </Box>
                </Grid>
            </Grid>
        </Collapse>
        </Fragment>






}

export default SaveFilterSet;