


import React, {useState} from 'react';
import Popover from '@mui/material/Popover';
import Box from "@mui/material/Box";
import QueryFilters from "../query-applied-filters/QueryFilters";
import CaButton from "../../../../../../../../gpap-lib/components/shared-components/CaButton";




export default function ViewAppliedFilters(props) {


    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <React.Fragment>
            <CaButton
                variant={"outlined"}
                id={"review_filters"}
                color = {( anchorEl!== null ? "primary" : "default" )}
                aria-describedby={id} onClick={handleClick}
                text={"View Applied Filters"}
            />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}>
                <Box p={0} style={{maxHeight:"400px", width: "400px", overflowY: "auto", padding: "1%"}}>
                    <Box p={0}>
                       <QueryFilters whichQuery={props.whichQuery}/>
                    </Box>
                </Box>

            </Popover>
        </React.Fragment>
    );
}