

export const getFallbackIndex = (patients) => {
    if (patients && patients.length > 0) {
        const firstPatient = patients[0];
        return getIdFromIndex(firstPatient);
    }
    return null; // Return null or handle cases when no patients are found
};


export const getIdFromIndex = (index) => {
    return index.hasOwnProperty("report_id") ? index.report_id : index.id;
};
