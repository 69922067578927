

// regionUtils.js



import {date_generator} from "../../../../../../../../../func/helpers";
import {getChr} from "../mapChrom";
import {getGenomicBuildValue} from "./getGenomicBuildValue";

export const prepareRegionObject = (data_tag, state) => {
    let date = date_generator();
    let chromosome = (typeof data_tag["chromosome"] === "string") ? data_tag["chromosome"] : data_tag["chromosome"].toString();

    // region:
    let regionField = getChr(chromosome) + ":" + data_tag["start_pos"] + ":" + data_tag["end_pos"];

    let region_obj = {
        "region": regionField,
        "field": "regions",
        "variants": [
            {
                "genomic_build": getGenomicBuildValue(),
                "chromosome": getChr(chromosome),
                "dbSNP": data_tag["rs"],
                "start_pos": data_tag["start_pos"],
                "end_pos": data_tag["end_pos"],
                "ref": data_tag["ref"],
                "alt": data_tag["alt"],
                "cDNA": data_tag["cDNA"],
                "protein": data_tag["protein"],
                "transcript": data_tag["transcript"],
                "zygosity": data_tag["zygosity"],
                "inheritance": data_tag["inheritance"],
                "significance": data_tag["significance"],
                "variant_source": "GPAP",
                "comments": data_tag["comments"],
                "origin": data_tag["origin"],
                "sample": data_tag["sample"],
                "timestamp": date,
                "sv_type": data_tag["sv_type"],
                "variant_type": data_tag["variant_type"],
                "project": data_tag["project"],
                "associated_genes": Array.isArray(data_tag["gene"]) ? data_tag["gene"].toString() : data_tag["gene"]
            }
        ]
    };

    if (state.causative) {
        region_obj["status"] = "Causative";
    }

    return region_obj;
};
