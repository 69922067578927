



import * as React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";


export default function AlertDialogStepForm(props) {

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Missing information: genotype configuration"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      There are individuals without a configured genotype. Please configure a genotype for each individual or remove them from the analysis.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose}>Ok</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}