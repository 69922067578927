import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import _ from "lodash";

function GeneralAutoComplete(props) {
    const [tags, setTags] = useState([]);
    const [options, setOptions] = useState([]);

    const onInputChange = (event, value) => {
        props.onInputChange(value);
    };

    useEffect(() => {
        setOptions(props.results);
    }, [props.results]);

    useEffect(() => {
        if (props.default_tags !== undefined) {
            setTags([...props.default_tags]);
        }
    }, [props.default_tags]);

    const onTagsChange = (values, reason) => {
        if (props.passSelected !== undefined) {
            if (values !== undefined && values !== null) {
                props.passSelected(values, reason);
            } else {
                props.passSelected(undefined);
            }
        }
    };

    return (
        <Autocomplete
            multiple
            options={options}
            autoHighlight
            getOptionLabel={(option) => (option.label !== undefined ? option.label : "")}
            value={tags}
            onChange={(event, newValue, reason) => {
                setTags([...newValue]);
                onTagsChange([...newValue], reason);
            }}
            onInputChange={_.debounce(onInputChange, 1000)} // on every input change hitting my api
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={props.title}
                    margin="normal"
                    fullWidth
                />
            )}
        />
    );
}

export default GeneralAutoComplete;
