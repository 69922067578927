



const HEADERS_TRANSCRIPTS_38 = ["Transcript ID", "Biotype", "MANE Select", "MANE Clinical Plus",  "Exon rank", "Functional class", "Aminoacid length", "Nucleotide", "varSEAK", "Aminoacid change", "Impact", "Consequence"];

const HEADERS_TRANSCRIPTS_37 = ["Transcript ID", "Biotype",  "Exon rank", "Functional class", "Aminoacid length", "Nucleotide", "varSEAK", "Aminoacid change", "Impact", "Consequence"];



export {HEADERS_TRANSCRIPTS_38, HEADERS_TRANSCRIPTS_37};