

import React from "react";
import { Box, Typography, Divider } from "@mui/material"; // Updated to MUI v5
import { mapping } from "./mapping";
import { dict_classes_variants } from "../../../side-panels/filter-panel/components/filter-sections/variant-section/variantConfig";
import { getLabelChrom } from "../../../side-panels/filter-panel/components/filter-sections/position-section/Position_Section";
import MappedText from "../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import "./query-filters.css";

function getVal(key, val){

    if(val === undefined || val === null){
        if(key!== undefined){
            return key;
        }
        else{
            return "";
        }
    }
    else{
        return key + ":" + val;
    }

}

const TitleBadge = ({background, text}) => (
    <div style={{ borderRadius: "5px", padding: "2px", background: background, color: "white", display: "block", maxWidth: "max-content" }}>{text}</div>
);

const CustomBox = ({ type, label, n_genes, version, list }) => (
    <Box p={1}>
        <Typography variant={"subtitle2"}>
            <TitleBadge background={"darkorange"} text={type}/>
{/*
            <div style={{ borderRadius: "5px", padding: "2px", background: "darkorange", color: "white", display: "block", maxWidth: "max-content" }}>{type}</div>
*/}
            <MappedText text={getVal(label)} />
            {getVal(" genes", n_genes)}{getVal(", v", version)}
        </Typography>
        {list && list.map((item, index) => (
            <div key={index}>{item}</div>
        ))}
    </Box>
);

const HPOComponent = ({ item }) => {
    let hpo_selected = [];
    item.list.forEach(s => {
        hpo_selected = hpo_selected.concat(s.list);
    });
    return (
        <React.Fragment>
            <Divider />
            <Box p={1}>
                <Typography variant={"subtitle2"}>
                    <TitleBadge background={"green"} text={"HPO derived genes"}/>
                    {hpo_selected.length} HPOs selected for {item.n_genes} genes
                </Typography>
                <div>{hpo_selected.map(s => s.label).toString()}</div>
            </Box>
        </React.Fragment>
    );
};

function renderGeneLists(genes) {


    if (genes !== undefined) {
        const lists = genes.filter(s => s.hasOwnProperty("id")).map((item, index) => {
            if (item.type === "HPO") {
                return <HPOComponent key={index} item={item} />;
            } else {
                return <CustomBox key={index} {...item} />;
            }
        });

        if (lists.length !== 0) {
            return (
                <div>
                    <div className={"filter_value"}><MappedText text="Lists applied:" /></div>
                    {lists.map((g, index) => <Typography key={index} variant={"subtitle2"}>{g}</Typography>)}
                </div>
            );
        } else {
            return null;
        }
    } else {
        return null;
    }
}


function getLabel(title){
    let labels= {"Compound_in": "C-Het Applied", "FreqInt": "Internal Freq.", "Cnv_germline": "Include CNVs"};

    return labels[title] !== undefined ? labels[title] : title;

}

function sortPositions(array) {
    return array.sort((a, b) => {
        // Compare chrom numerically
        const chromA = parseInt(a.chrom, 10);
        const chromB = parseInt(b.chrom, 10);
        if (chromA !== chromB) {
            return chromA - chromB;
        }

        // Compare pos numerically
        if (a.pos !== b.pos) {
            return a.pos - b.pos;
        }

        // Compare end_pos numerically
        return a.end_pos - b.end_pos;
    });
}


function FieldRendering(list_of_filters, d, genes){
    if(d=== "gene")
    {
        let n_genes = Object.keys(list_of_filters[d]);
        let str = (n_genes.length > 1) ? " genes " : " gene ";

        return <div>
            <div className={"field_title"} style={{ fontWeight: "bold"}} ><MappedText text="Gene Filtering"/></div>
            <div className={"filter_value"}> A total of {n_genes.length}  {str}  selected  </div>
            {renderGeneLists(genes)}
            <div className={"sublist"}>
                {Object.values(list_of_filters[d]).toString()}
            </div>
        </div>
    } //TODO: differentiate between runs of homozygosity and rest;
    else if(d==="chrom"){
        let chrom = Object.keys(list_of_filters[d]);
        let pos_array = Object.values(list_of_filters[d]);

        // sort array

        let sortedArray = sortPositions(pos_array);

        let chromList = sortedArray.map(function(c){

            let start = (c.pos !== -1) ? c.pos : "--";
            let end = (c.end_pos !== -1) ? c.end_pos : "--";

            let label = getLabelChrom(c.chrom);

            return <div> chrom: {label}, start: {start}, end: {end}</div>
        })

        let list = (chrom.length>0) ?  <div className={"sublist"}>
            {chromList}
        </div> : null



        let str = (chrom.length > 1) ? " chromosomes " : " chromosome ";

        return <div>
            <div className={"field_title"} style={{ fontWeight: "bold"}} ><MappedText text="Positions"/></div>
            <div className={"filter_value"}> {chrom.length}  {str} selected:  </div>
            {list}
        </div>
    }
    else{

        let item = (mapping.find(m => m.key=== d));
        let title = (item!== undefined) ? item.label : d.charAt(0).toUpperCase() + d.slice(1);
        let set_keys = list_of_filters[d];
        if (set_keys!== undefined){

            // if it s a boolean

            if(typeof set_keys === "boolean" ){
                return <div>
                    <div className={"field_title"} style={{ fontWeight: "bold"}} ><MappedText text={getLabel(title)}/></div>
                    <div className={"filter_value"}>
                        {JSON.stringify(set_keys)}
                    </div>
                </div>
            }
            else if(typeof set_keys === "number" || typeof set_keys === "string" ){
                return <div>
                    <div className={"field_title"} style={{ fontWeight: "bold"}} >{title}</div>
                    <div className={"filter_value"}>
                        {set_keys}
                    </div>
                </div>
            }
            else{
                let values=''
                // if it's an object
                if (set_keys !==null){
                        //for vatiantconsrquences we do not want to show false filter
                 values = Object.keys(set_keys).filter(val=>(d!=="variantconsequences" || list_of_filters[d][val])).map(function(val){
                    // if that's an object
                    if(typeof list_of_filters[d]=== "object"){
                        let obj = list_of_filters[d];

                        // if value is not object
                        if(typeof obj[val] !== "object")
                        {
                            // find possible label match;
                            let possible_val_match = undefined;
                            if(dict_classes_variants[d]!== undefined){
                                possible_val_match =dict_classes_variants[d] .find(s => s.value === val);
                            }

                            let value = (possible_val_match!== undefined) ? possible_val_match.label : val;
                            return (typeof obj[val] == "boolean")
                                ? <Box> {value}</Box>
                                : <Box><div>{val}: {obj[val]} </div></Box>;
                        }
                        else{
                            return <div>
                                <div> <MappedText text="Filter can't be displayed"/></div>
                            </div>
                        }

                    }
                    else{return <div>Mmm...error</div>}


                })}
                 else{
                
                     values = <div>Mmm...error</div>}
                
                ;

                return <div>
                    <div className={"field_title"} style={{ fontWeight: "bold"}} >{title}</div>
                    <div className={"filter_value"}>
                        {values !== undefined ? values : "erro"}
                    </div>
                </div>
            }


        }
        else{
            return null;
        }
    }
}


export default FieldRendering;