import * as COLLECTIONS from "../../../../../../../config-files/filter_collections";

export function check_samples(samples){

    if(samples !== undefined && samples.values!== undefined && samples.values.samples!== undefined)
    {
        let test = samples.values.samples[0];
       if(test !== undefined)
           if(Object.keys(test).length >0 ) return samples.values.samples;
           else{return []}
       else { return []}
    }
    else{
        return [];
    }
}

export function check_variants(variants){
    if(variants !== undefined && variants.values!== undefined)
    {
        let classes = COLLECTIONS.variant_section_classes;
        let vals = {'variant_types': []};
        classes.forEach(function(c){
            if(variants.values.hasOwnProperty(c))
            {
                if(variants.values[c]!== null && Array.isArray(variants.values[c]))
                {
                    let items = variants.values[c].map( item => item.value);
                    // don't know why, later values becomes an array
                    let myobj = {key: c, values: {}};
                    items.forEach(function(item){
                        myobj.values[item] = true;
                    });                // maybe check with query-template;
                    vals['variant_types'].push(myobj)
                }
                else{
                    vals['variant_types'].push({key: c, values: {}})
                }
            }

        });
        return vals;
    }



}



// TODO: GENERALIZE;
export function check_snv_effects(snv_effects){
    if(snv_effects !== undefined && snv_effects.values!== undefined) {
        let classes = COLLECTIONS.snv_effects;
        let filters = {'snv_effects': []};
        classes.forEach(function (c) {
            if (snv_effects.values.hasOwnProperty(c)) {
                if (snv_effects.values[c] !== null) {

                    if(c!== 'cadd_phred' && c!== 'revel' && c!== 'spliceai_max_ds'&& c!== 'OCScore')

                    {
                            //let vals = snv_effects.values[c];

                            let items = snv_effects.values[c].map(item => item.value);
                            // don't know why, later values becomes an array
                            let myobj = {key: c, values: {}};
                            items.forEach(function (item) {
                                myobj.values[item] = true;
                            });                // maybe check with query-template;
                            filters['snv_effects'].push(myobj)

                    }
                    else{
                        // take single value, not from array. This was for a Slider.
                        //let my_obj = {key: c, value: snv_effects.values[c][1]};
                        let value ;
                        if (snv_effects.values[c]==""){
                            value= null;
                        }
                        else
                        {
                            value=snv_effects.values[c];
                        }
                        let my_obj = {key: c, value: value};

                        filters['snv_effects'].push(my_obj)
                    }

                }

                else if((c === "cadd_phred" || c=== 'revel' || c==='spliceai_max_ds'|| c==="OCScore" ) && snv_effects.values[c]== null ){
             

                    filters['snv_effects'].push({key: c, values: null})
                }
                else{
                    filters['snv_effects'].push({key: c, values: {}})
                }
            }
        });
        return filters;
    }}


export function getFormValues(formItem, formKey) {
    if (formItem !== undefined && formItem.values !== undefined) {
        let classes = COLLECTIONS[formKey];
        let filters = {};
        filters[formKey] = [];
        classes.forEach(function (c) {
            if (formItem.values.hasOwnProperty(c)) {
                if (formItem.values[c] !== null) {
                    let value = formItem.values[c];
                    // if items === 0;
                    let val = (value === 0 || isNaN(value)) ? null : value;
                    let my_obj = {key: c, value: val};
                    filters[formKey].push(my_obj)

                }
            }
        });
        return filters;
    }
}


export function getPosition(formItem, formKey){
    if (formItem !== undefined && formItem.values !== undefined) {
        let classes = COLLECTIONS[formKey];
        let filters = {};
        filters[formKey] = [];
        classes.forEach(function (c) {
            if(formItem.values[c]!== null ){
                if(typeof formItem.values[c] == "object" && formItem.values[c].value!== undefined){
                    let my_obj = {key: c, value: formItem.values[c].value};
                    filters[formKey].push(my_obj)
                }
                else{
                    let my_obj = {key: c, value: formItem.values[c]};
                    filters[formKey].push(my_obj)
                }

            }

        });

        if(Array.isArray(filters.position_roh) && Array.isArray(filters.position_roh[0].value)){
            return undefined
        }
        else{
            return filters;
        }

    }

}

export function omitFields(originalObj, referenceObj) {
    const keysToKeep = Object.keys(referenceObj);
    const filteredObject = {};
  
    keysToKeep.forEach(key => {
      if (originalObj.hasOwnProperty(key)) {
        filteredObject[key] = originalObj[key];
      }
    });
  
    return filteredObject;
  }



export function convertChromosome(chrom){

    const special_dict = {"MT":"23", "X": "24", "Y": "25"};

    return (special_dict[chrom] !== undefined) ? special_dict[chrom] : chrom;

}