import {makeStyles} from "@mui/styles";


const useFilterStyles = makeStyles(() => ({
    table: {
        minWidth: "600px"
    },
    header: {marginLeft: "20px", fontWeight: "500", width: "100%",  color: "white"},
    accordion_header: {background: "#3182bd", color: "white", width: "100%"},
    icon_style: {color: "white"}
}));

export default useFilterStyles;