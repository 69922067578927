import React, { useState } from 'react';

// MUI Components
import { makeStyles } from '@mui/styles'; // Note: makeStyles is part of @mui/styles package

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';

// Custom Components
import GPAP_Tooltip from "../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import GPAP_Dialog from '../../../../../../../../../gpap-lib/components/GPAP_Dialog';
import MappedText from '../../../../../../../../instand/reports/create-report/shared-components/mapText';

// Formatters
import { cnv_positionFormatters } from "./cnv_position_formatter";
import { linkFormatters } from "./snv_links_formatter";

// MUI TreeView
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'; // Correct import path
import { TreeItem } from '@mui/x-tree-view/TreeItem';


const useStyles = makeStyles({
    "@global": {
        ".MuiTreeItem-root > .MuiTreeItem-content .MuiTreeItem-label":{
            color: "#08519c",
            fontWeight: "400"
        },
        ".MuiTreeItem-root > .MuiTreeItem-content .MuiTreeItem-iconContainer":{
            width: "8px"
        },
        root: {
            flexGrow: 1,
            maxWidth: 400,
        },
    }
});

export default function TreeViewObject(props) {
    const classes = useStyles();

    const [openDialog, setOpenDialog] = useState(false);
    const defaultDialogObj = {title: "No Variant Selected", text: "Please select a variant to see the external resources"};
    const [dialogInfo, setDialogInfo] = useState(defaultDialogObj);

    const {data} = props;

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    const openLink = (link) => {
        if (link.includes("undefined")) {
            // alert("This variant doesn't have the information requested");
        } else if (link === "Not applicable") {
            // alert("This resource is meant only for SNVs, not InDELs or CNVs");
        } else {
            window.open(link, '_blank', 'noopener,noreferrer');
        }
    };

    const handleClick = (node, inactive) => {
        let link = '';
        console.log("handle click");
        const special_dict = {"23":"MT", "24": "X", "25": "Y"};

        if(inactive){
            alert("Info not available");
        }
        else if(node.id!== "root"){
            if(props.variant_current!== false && props.data_type !== "CNV"){
                let prefix = (node.url_prefix !== undefined ) ? node.url_prefix : "";
                let chrom = (special_dict[props.variant_current.chrom]!== undefined ) ? special_dict[props.variant_current.chrom] : props.variant_current.chrom.toString();

                const defaultLinkFormatter = () => `${prefix}${props.variant_current[node.variable_category]}${node.url_appendix}`;

                const linkFormatter = linkFormatters[node.name];
                if (linkFormatter) {
                    link = linkFormatter(prefix, chrom, props, node);
                } else {
                    link = defaultLinkFormatter();
                }

                openLink(link);
            }
            else if(props.data_type === "CNV"){
                let prefix = ( node.url_prefix !== undefined ) ? node.url_prefix : "";
                link = prefix + props.variant_current[node.variable_category] + node.url_appendix;

                let val = props.variant_current[node.variable_category];

                if(node.variable_category === "SV_start"){

                    let chrom = (special_dict[props.variant_current.SV_chrom]!== undefined) ? special_dict[props.variant_current.SV_chrom] : props.variant_current.SV_chrom.toString();
                    let start = props.variant_current.SV_start;
                    let end = props.variant_current.SV_end;
                    let sv_type = props.variant_current.SV_type;


                    const positionFormatter = cnv_positionFormatters[node.name];
                    if (positionFormatter) {
                        const position = positionFormatter(chrom, start, end, sv_type);
                        link = `${prefix}${position}${node.url_appendix}`;

                        // adding an exception because this CONTROLFREEC tool does not give the right breakpoints;
                        if( (node.name === "Franklin" || node.name === "VarSome") && props.variant_current.Tool === "CONTROLFREEC"){
                            link = link + "undefined";
                        }
                        if(link.includes("undefined")){
                            const text = "One or more fields are not available to correctly redirect you  to the Portal";
                            setDialogInfo({ title: "Information missing", text });
                            setOpenDialog(true);
                        }
                    }

                }

                if(Array.isArray(val)){

                    if (val.length > 1) {
                        const text = `${node.name} Portal does not support querying multiple genes simultaneously. Please note that this URL is designed to handle queries for individual genes only. Search for this gene in the Split View. `;
                        setDialogInfo({ title: `${node.name} Portal: Query Limitation`, text });
                        setOpenDialog(true);
                    } else if (val.length === 1) {
                        const link = `${prefix}${val[0]}${node.url_appendix}`;
                        openLink(link);
                    } else {
                        const text = "No genes to search for.";
                        setDialogInfo({ title: "Empty search", text });
                        setOpenDialog(true);
                    }

                }
                else{
                    openLink(link);
                }

            }
            else{
                setDialogInfo(defaultDialogObj);
                setOpenDialog(true);
            }
        }
    };

    const renderDialog = () => {
        return <GPAP_Dialog
            open={openDialog}
            handleClose={handleCloseDialog}
            title= {dialogInfo.title}
            text= {dialogInfo.text}
            onExitText={false}
            onConfirmText={"Ok"}
            onExitAction={false}
            onConfirmAction={handleCloseDialog}
            type="warning"
        />;
    }

    const checkVariantExists = (node) => {
        if (node.id === "root" || !props.variant_current) {
            return true; // Assuming disabled by default if no variant or if node is root
        }

        if (props.data_type === "CNV") {
            if( (node.name === "Franklin" || node.name === "VarSome") && props.variant_current.Tool === "CONTROLFREEC"){
                return false;
            }
            if(node.name === "NCBI"){
                return false;
            }
        }

        if (props.variant_current.indel && node.name === "Regulation Spotter") {
            return false;
        }

        if(node.variable_category === ""){
            return true;
        }
        else{
            return props.variant_current.hasOwnProperty(node.variable_category);
        }
    };

    const giveLabel = (node) => {
        return  <GPAP_Tooltip
            title={node.description}
            /*title= {<MappedText text={(node.description!== undefined) ? node.description : node.name}/>}*/

        >
            <Box> <MappedText text={node.name}/> </Box>
        </GPAP_Tooltip>
    };

    const renderTree = (nodes) => {
        let not_available = !checkVariantExists(nodes);
        let style= {fontWeight: "500", color: "#08519c"};

        if(not_available){
            style= {fontWeight: "500", textDecoration: "line-through"};
        }
        else{
            if(nodes.id !== "root"){
                style= {fontSize: "0.9rem"};
            }
        }

        return (
            <TreeItem
                style={style}
                /*onLabelClick={() => handleClick(nodes, not_available)}*/
                key={nodes.id}
                id={nodes.name + "_" + nodes.id}
                itemId={nodes.name + "_" + nodes.id}
                nodeId={nodes.name + "_" + nodes.id}
                label={
                    <div onClick={() => handleClick(nodes, not_available)}>
                        {giveLabel(nodes)}
                    </div>
                }
                /*label={giveLabel(nodes)}*/
            >
                {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
            </TreeItem>
        );
    };

    return (
        <div style={{float: "left", width: "100%"}} key={props.key}>
            <SimpleTreeView
                className={classes.root}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpanded={ [] }
                defaultExpandIcon={<ChevronRightIcon />}>
                {renderTree(data)}
            </SimpleTreeView>
            {renderDialog()}
        </div>
    );
}
