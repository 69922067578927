

// React and Redux imports
import React, { Fragment, useState } from 'react';
import { connect } from "react-redux";

// Material UI imports
import { Popover, Typography, Box, Grid, Divider } from '@mui/material';

// Material UI Icons
import BlurOnIcon from '@mui/icons-material/BlurOn';

// Custom Components
import CaButton from '../../../../../../../../../../gpap-lib/components/shared-components/CaButton';


let config_pred = {
    "cadd_phred": "CADD",
    "revel_score": "Revel Score",
    "sift_pred": "SIFT",
    "sift_score": "SIFT Score",
    "polyphen2_hvar_pred": "Polyphen2",
    "polyphen2_hvar_score": "Polyphen2 score",
    "mt": "MT Score",
    "mutationtaster_pred": "MT",
    "phylop46way_placental": "phyloP",
    "gerp_rs": "GERP++",
    "siphy_29way_pi": "SiPhy",
    "spliceai_max_ds": "SpliceAI Max DS",
    "spliceai_ds_ag": "SpliceAI Acceptor Gain",
    "spliceai_ds_al": "SpliceAI Acceptor Loss",
    "spliceai_ds_dg": "SpliceAI Donor Gain",
    "spliceai_ds_dl": "SpliceAI Donor Loss",
    "onco_classifier":"Onco Classifier",
    "intervarclasses":"InterVAR"
};


function PredictorsPopOver(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    const renderData = () => {


        let variant_current = false;
        if(!isDisabled()){
            
            let selectedDataRow = props.selected_table_rows.find(s => s.data_type === props.dataType);

            if(selectedDataRow!== undefined){
                let variantObj = selectedDataRow.data_row;
                variant_current = variantObj[0];
            }
        }

        if(variant_current ){

            let grid = Object.keys(config_pred).map(function(field,index){
                let val = (variant_current[field]!== undefined && (variant_current[field])!== "") ? variant_current[field] : "NA";
                let background = (val=== "NA") ? "#f0f0f0" : "white";

                return <Fragment key={index}>
                        <Grid item lg={6} key={index}><Typography variant={"subtitle1"} style={{fontWeight: "500", display: "inline-block"}}>{config_pred[field]}</Typography></Grid>
                        <Grid item lg={6} key={index} style={{background: background, borderRadius: "3px", border: "1px solid", borderColor: background }}>{val}</Grid>
                </Fragment>

            });

            return <Fragment>
                {grid}
            </Fragment>

        }
        else{
            return <div>Predictions info not available</div>
        }


    };

    const isDisabled = () => {

        let selectedDataRow = props.selected_table_rows.find(s => s.data_type === props.dataType);

        return  selectedDataRow.data_row.length === 0;

    };


    return (
        <div style={{ display:"inline-block",  margin: ".5%"}}>
            <CaButton
                aria-describedby={id}
                variant="outlined"
                onClick={handleClick}
                text="Predictors"
                startIcon={<BlurOnIcon/>}/>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box p={2} style={{width: "400px" }}>
                   <Grid container direction={"row"}>
                       <Box p={1}><Typography  variant={"body1"} style={{fontWeight: "500"}}>Pathogeniticy predictions</Typography></Box>
                       <Grid item lg={12}><Divider/></Grid>
                       {renderData()}
                    </Grid>
                </Box>
            </Popover>
        </div>
    );
}

function mapStateToProps (state) {
    if(state.selected_variant!==undefined)
    {
        return {selected_table_rows: state.selected_table_rows}
    }
    else{
        return {selected_table_rows: []}
    }
}

export default connect(mapStateToProps)(PredictorsPopOver);