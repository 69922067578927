

import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";

import "./query_top_bar.css";

import {GPAP_Button_Light} from "../../../../../../../../../gpap-lib/components/Button_Collection";
import {AlertInfo} from "../../../../../../../../study-page/create-study/analysis-page/components/samples-section/search-by-id/SelectionInfo";
import ClearIcon from '@mui/icons-material/Clear';


import {bindActionCreators} from "redux";
import {getCurrentAnalysis} from "../../../../results/components/variant-table/getAnnotations";

import FilterSet_SavePanel from "./FilterSet_SavePanel";
import MappedText from "../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import * as FilterSetActions from "../../../../../../../../../actions/index.js"
import { getFormSyncErrors } from 'redux-form';
import CaButton from "../../../../../../../../../gpap-lib/components/shared-components/CaButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";



function useRefHook() {
    const ref = useRef(null);

    useEffect(() => {
        // Code to be executed when the component using the hook mounts
        // or when the ref value changes

        // You can perform any desired operations on the ref here
    }, [ref]);

    return ref;
}


function QueryTopBar(props){

    const [running, setRunning] = useState(false);
    const { syncErrors } = props;

    const runQuery = () => {


        props.queryRunner();
        setRunning(true);
        setTimeout(function(){  setRunning(false) }, 1000);

    }




    const resetQuery = () =>{

        props.resetQuery()
        props.filterSet_Actions.reset_filter_set();
    }


    const getAlertMessage = () => {

        let { disable, requireGenes } = props;

        let message= (requireGenes)
            ? "Please, you select genes to run a query."
            : "Experiments not configured correctly." ;

        return (disable)
            ? <AlertInfo severity="warning" message={message} title={"No genes selected"}/>
            : null;

    }



    const getChetCheck = () => {

        let analysis = getCurrentAnalysis(props.studySettings, props.studyBucket);


        // inheritance_mode id === 3 is C-het;
        return (analysis.analysis.inheritance_mode_id === 3 && !props.formTouched || (syncErrors && syncErrors.revel))

    }



    return (
        <React.Fragment>
            <div className={"bar"}>
                <Box p={0}>
                    <Typography variant={"h5"} style={{marginLeft: "8px"}}>
                       <MappedText text={"Filter Panel"}/>
                    </Typography>
                </Box>
                <Box p={1}  style={{borderBottom: "1px solid #bdbdbd"}}>
                    {getAlertMessage()}
                    {syncErrors && syncErrors.revel && (
                        <div style={{ color: 'red' }}>
                            Revel Error: {syncErrors.revel}
                        </div>
                    )}
                    <CaButton size={"sm"}
                                 variant={"contained"}
                                 text={"Run Query"}
                                 disabled={props.disable || getChetCheck()}
                                 onClick={function (e) {runQuery()}}>
                         </CaButton>
                    <CaButton
                        text={"Reset all filters"}
                        size={"sm"}
                        variant={"outlined"}
                        onClick={resetQuery}
                        startIcon={<ClearIcon/>}/>
                    <FilterSet_SavePanel/>
                </Box>
                {(getChetCheck()
                    ? <AlertInfo severity="warning"
                                 message={"To run a C-Het analysis you must add at least one filter."}
                                 title={"No filter selected"}/>
                    : null)}
            </div>

        </React.Fragment>
    )


}


export function map_enable_query (state) {
    if(state.sample_list!==undefined) {

        let validTypes = ["family_trio", "somatic", "instand"];
        let requireGenes = !validTypes.includes(state.studySettings.analysisType); // case search across all or cohort;

        let bool = state.sample_list.samples.length === 0 || ( requireGenes && state.geneList.geneList.length === 0  );
        let touched = false;
        Object.keys(state.form).forEach(function(formEl){
            let VALUES = state.form[formEl].values;
            if(VALUES!== undefined){
                Object.keys(VALUES).forEach(function(formElKey){

                    let val = VALUES[formElKey];
                    if(Array.isArray(val) && val.length !== 0){
                        touched = true
                    }
                    if (val !== null && !Array.isArray(val) ) {
                        touched = true;
                    }
                })

            }})

        return {
            disable: bool,
            requireGenes: requireGenes,
            token: state.authorization.token,
            formTouched:touched,
            studySettings: state.studySettings,
            studyBucket: state.studyBucket,
            syncErrors: getFormSyncErrors('snv_effects')(state) // Replace 'snv_effects' with your form name

        }
    }
    else{
        return {disable: true}
    }
}
const mapDispatchToProps = (dispatch) => ({
    filterSet_Actions: bindActionCreators(FilterSetActions, dispatch)
});


export default connect(map_enable_query, mapDispatchToProps)(QueryTopBar);