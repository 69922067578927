


import React  from "react";
// import children

import "../css/bottompanels.css"

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import PathwaysReactome from "./PathwaysReactome";

import HMDB from "./HMDB";
import HpsCreg from "./HpsCreg";
import MappedText from "../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";


function External_Databases(props){

   return <div style={{padding: "10px"}}>
       <Grid container direction={"column"} spacing={2}>
           <Grid item lg={12}>
               <Grid container direction={"column"} spacing={1}>
                   <Grid item lg={12}>
                       <Box>
                           <Typography variant={"subtitle2"}> <MappedText text="We are working to integrate more resources in this section"/> </Typography>
                       </Box>
                       <Box>
                           <Typography variant={"h6"}><MappedText text="Pathways"/></Typography>
                           <Divider/>
                       </Box>
                   </Grid>
                  {/* <Wikipathways input={props.data.wiki}/>*/}
                   <PathwaysReactome input={props.data.pathways}/>
                  {/* <QuickGO input={props.data.quickGo}/>*/}
                   <HpsCreg input={props.data.hpscreg}/>
                   <HMDB input={props.data.hmtdb}/>

               </Grid>
           </Grid>
           {/*<Grid item lg={12}>
               <Grid container direction={"row"} spacing={1}>
                   <Grid item lg={12}>
                       <Box>
                           <Typography variant={"h6"}>Other Resources</Typography>
                           <Divider/>
                       </Box>
                   </Grid>
                   <Grid item lg={12}>
                       <AlfaDB/>
                   </Grid>
                   <Grid item lg={12}>
                       <div className={"my_paper_box"}>
                           <Box p={1}>
                               <Typography variant={"subtitle1"}>Biosoftware - RD-Connect</Typography>
                               <div>{(props.data.biosoftware) ? JSON.stringify(props.data.biosoftware) : "No Associations"} </div>
                           </Box>
                       </div>
                   </Grid>
                   <Grid item lg={12}>
                      <EdgarDB/>
                   </Grid>
                   <Grid item lg={12}>
                        <HMDB/>
                   </Grid>
                   <Grid item lg={12}>
                       <div className={"my_paper_box"}>
                           <EbiPanel data = { props.data.ebi_annotation }/>
                       </div>
                   </Grid>

               </Grid>

           </Grid>*/}
       </Grid>

   </div>



}



export default External_Databases;