import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// MUI v5 imports
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import { BarChartOutlined } from "@mui/icons-material";

// Custom components and CSS
import "../toolbar_results.css";
import GPAP_Tooltip from "../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import PluginMenu from "../Plugins/PluginMenu";
import VariantSummary from "../../../../variant-tabs/VariantSummary";
import ViewAppliedFilters from "../../view-applied-filters/ViewAppliedFilters";
import ExportVariants from "../export/ExportVariants";
import MappedText from "../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";

// Redux actions
import * as SidebarActions from "../../../../../../../../../components/SideNavigation/sidebar_actions";
import FilterButton from "./FilterButton";

function ToolBar_New(props) {
    const [checked, setChecked] = useState(false);

    const handleChange = () => {
        setChecked(!checked);
    };

    return (
        <div className={"toolbar_results"}>
            <FilterButton analysisType={props.studySettings.analysisType} sidebarActions={props.sidebarActions} />
            <GPAP_Tooltip title={<MappedText text={"Variants distributions - (based on max 3000 entries)"} />}>
                <IconButton
                    id={"charts_variants"}
                    color={checked ? "primary" : "default"}
                    aria-label="Details"
                    onClick={handleChange}
                >
                    <BarChartOutlined />
                </IconButton>
            </GPAP_Tooltip>
            <PluginMenu data={props.data} passAnnotations={props.passAnnotations} />
            {props.studySettings.analysisType !== "instand" && <ExportVariants />}
            <ViewAppliedFilters whichQuery="current" />

            <div>
                <Collapse in={checked}>
                    <VariantSummary data={props.data} total={[]} />
                </Collapse>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    if (state.authorization !== undefined) {
        if (state.queryContainer !== undefined) {
            return { studySettings: state.studySettings };
        }
    }
}

const mapDispatchToProps = (dispatch) => ({
    sidebarActions: bindActionCreators(SidebarActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ToolBar_New);
