import {
    ADD_APPLIED_LIST,
    ADD_GENES_TO_SPECIFIC_GENELIST,
    ADD_TO_ALL_GENES,
    INIT_APPLIED_LISTS,
    INTERSECT_GENE_LIST, REMOVE_ALL_APPLIED_LISTS,
    REMOVE_APPLIED_LIST,
    REMOVE_GENES_FROM_LIST,
    REPLACE_GENES_TO_SPECIFIC_GENELIST,
    RESET_GENELIST,
    RESET_GENES,
    RESET_LIST_TYPE,
    SET_GENE_OPERATION
} from "./actions";


export function intersection_method() {
    let result = [];
    let lists = [];

    if(arguments.length === 1) {
        lists = arguments[0];
    } else {
        for (let i=0; i < arguments.length; i++) {
            lists.push(arguments[i])
        }

    }

    for(let i = 0; i < lists.length; i++) {
        let currentList = lists[i];
        for(let y = 0; y < currentList.length; y++) {
            let currentValue = currentList[y];
            if(result.indexOf(currentValue) === -1) {
                if(lists.filter(function(obj) { return obj.indexOf(currentValue) === -1 }).length === 0) {
                    result.push(currentValue);
                }
            }
        }
    }
    return result;
}

const geneList_initialState = {
    geneList:[],
    genes: [],
    predefined_genes: [],
    disease: [],
    symptoms:[],
    pathways: [],
    applied_lists: [],
    operator:"union"
};

function mapGenes(state_new){
    let lists =  ["genes", "predefined_genes", "disease", "symptoms", "pathways"];
    let tot_genes= [];
    lists.forEach(function(list_name){
        tot_genes= tot_genes.concat(state_new[list_name])
    });
    // distinct genes;
    return [...new Set(tot_genes)];
}


export const geneListReducer = (state= geneList_initialState, action) => {

    switch (action.type) {
        case ADD_TO_ALL_GENES:
            let all_gene_list= state.geneList;
            let add_genes = action.genes;
            let c_state = Object.assign({}, state);
            c_state.geneList = add_genes;

            return c_state;

        // intersect or join
        case INTERSECT_GENE_LIST:
            let current_genes= state.geneList;
            let additional_genes = action.genes;

            let inter= [];

            let update_state = Object.assign({}, state);
            if(current_genes.length === 0 ){
                // just add the list
                update_state.geneList = additional_genes;
                update_state[action.key] = additional_genes;
            }
            else{
                inter = intersection_method(additional_genes,current_genes);
                update_state.geneList = inter;
                update_state[action.key] = additional_genes;
            }

           /* else if(additional_genes.length>current_genes.length)
            {inter= additional_genes.filter(element => current_genes.includes(element));}
            else{inter= current_genes.filter(element => additional_genes.includes(element));}*/



            return update_state;
        case ADD_GENES_TO_SPECIFIC_GENELIST:
            let copy_state = Object.assign({}, state, {operator: state.operator});
            let genes = copy_state[action.key].concat(action.genes);
            // unique;
            copy_state[action.key] = [...new Set(genes)];
            let geneList = mapGenes(copy_state);
            copy_state["geneList"] = [...geneList];

            return copy_state;
        case REPLACE_GENES_TO_SPECIFIC_GENELIST:
            // create new state instance
            let state_new = Object.assign({}, state, {operator: state.operator});
            // update the specific list of genes;

            state_new[action.key] = [...new Set(action.genes)];
            let tot_genes = mapGenes(state_new);
            // search for unique
            state_new["geneList"] = tot_genes;
            return state_new;
        case SET_GENE_OPERATION:
            // here I need to work around the results...

            // take all lists and produce the new gene-list;
            let current_state = Object.assign({}, state);
            let final_list = [];

            let lists =  ["genes", "predefined_genes", "disease", "symptoms", "pathways"];

            let not_empty = lists.filter(el => current_state[el].length!== 0);

            if(action.gene_operation === "intersection"){

                final_list = intersection_method(not_empty.map(el => state[el]));
            }
            else{
                not_empty.forEach(function(el){

                    final_list = final_list.concat(current_state[el]);
                    final_list = [...new Set(final_list)];

                })
            }

            // return the result;

            return Object.assign({}, state, {
                operator: action.gene_operation,
                geneList: final_list
            });
        case RESET_GENES:
            let current_operator = state.operator;
            geneList_initialState["operator"] = current_operator;
            return geneList_initialState;
        case RESET_GENELIST:
            let state_r = Object.assign({}, state, {operator: state.operator});
            state_r[action.key] = [];
            // search for unique
            state_r["geneList"] = mapGenes(state_r);
            return state_r;
        case REMOVE_GENES_FROM_LIST:
            let new_state = Object.assign({}, state, {operator: state.operator});

            if(Array.isArray(action.genes)){
                action.genes.forEach(function(gene){
                    const index =   new_state[action.key].indexOf(gene);
                    if (index > -1) {new_state[action.key].splice(index, 1);}
                });
                // search for unique
                new_state["geneList"] = mapGenes(new_state);
            }

            return new_state;
        case INIT_APPLIED_LISTS:
            let lists_default = action.lists;
            state.applied_lists.length = 0 ;
            state.applied_lists.push(...lists_default);
            return state;
        case ADD_APPLIED_LIST:
            let list = action.list;
            // only one type of list is allowed;
            let list_without_list_type= state.applied_lists.filter(item => item.type !== action.list.type)
            let index = list_without_list_type.map(s => s.id).indexOf(list.id);
            if( index === -1)
            {
                list_without_list_type.push(action.list);
            }

            return Object.assign({}, state, {applied_lists: list_without_list_type});
        case REMOVE_ALL_APPLIED_LISTS:
            console.log("reset genes");
            return Object.assign({}, state, {applied_lists: []});
        case REMOVE_APPLIED_LIST:
            let new_array = state.applied_lists.filter(function(item) {
                return item.id !== action.id
            });
            return Object.assign({}, state, {applied_lists: new_array});
        case RESET_LIST_TYPE:
            let new_list_array = state.applied_lists.filter(function(item) {
                return item.type !== action.list_type
            });
            return Object.assign({}, state, {applied_lists: new_list_array});
        default:
            return state
    }
};