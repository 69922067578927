
import React from "react";
import InfoIcon from '@mui/icons-material/Info';

import GPAP_Tooltip from "./GPAP_Tooltip";


function GPAP_InfoIcon(props){

    let default_color = "primary";
    let anchor = props.anchor;
    let style;
    if(anchor!== undefined){
        style= {
                position: "relative",
                top: "-30px",
                left: "15px",

        }
    }
    else{
        let color = props.color !== undefined ? props.color : "#3182bd";
        style= { color: color, float: "right", marginLeft: "10px"};
    }

    if(props.color){

        return <GPAP_Tooltip title={props.title}>
            <InfoIcon style={style} size={"small"} color={props.color}/>
        </GPAP_Tooltip>
    }
    else{
        style= (anchor!== undefined) ? style :  { float: "right", marginLeft: "10px"};

        return <GPAP_Tooltip title={props.title}>
            <InfoIcon style={style} size={"small"} color={default_color}/>
        </GPAP_Tooltip>
    }


}

export default GPAP_InfoIcon