

const query_sample = {
    "size":3000,
    "from":0,
    "fromCNV":0,
    "chrom":[],
    "indel":false,
    "freqInt": null,
    "svn":false,
    "genotypefeatures":{"other":false,"coding":false,"RNA":false},
    "variantclasses":{"high": false, "low": false, "moderate": false, "modifier": false},
    "variantconsequences": {
    "transcript_ablation": false,
    "splice_acceptor_variant": false,
    "splice_donor_variant": false,
    "stop_gained": false,
    "frameshift_variant": false,
    "stop_lost": false,
    "start_lost": false,
    "transcript_amplification": false,
    "feature_elongation": false,
    "feature_truncation": false,
    "inframe_insertion": false,
    "inframe_deletion": false,
    "missense_variant": false,
    "protein_altering_variant": false,
    "splice_donor_5th_base_variant": false,
    "splice_region_variant": false,
    "splice_donor_region_variant": false,
    "splice_polypyrimidine_tract_variant": false,
    "incomplete_terminal_codon_variant": false,
    "start_retained_variant": false,
    "stop_retained_variant": false,
    "synonymous_variant": false,
    "coding_sequence_variant": false,
    "mature_miRNA_variant": false,
    "prime_5_UTR_variant": false,
    "prime_3_UTR_variant": false,
    "non_coding_transcript_exon_variant": false,
    "intron_variant": false,
    "NMD_transcript_variant": false,
    "non_coding_transcript_variant": false,
    "coding_transcript_variant": false,
    "upstream_gene_variant": false,
    "downstream_gene_variant": false,
    "TFBS_ablation": false,
    "TFBS_amplification": false,
    "TF_binding_site_variant": false,
    "regulatory_region_ablation": false,
    "regulatory_region_amplification": false,
    "regulatory_region_variant": false,
    "intergenic_variant": false,
    "sequence_variant": false
},
    "mutationtaster":{"A":false,"D":false,"P":false},
    "intervarclasses":{"P":false,"LP":false,"B":false, "LB":false,"VUS":false},
    "clinvarclasses":{"P":false,"L":false,"A":false, "U":false,"C":false,"D":false},
    "onco_filter":{"K":false,"P1":false,"P2":false,"PP":false},
    "onco_classifier":{"O":false,"LO":false,"VUS":false,"B":false,"LB":false},
    "polyphen2hvarpred":{"D":false,"P":false,"B":false},
    "population":{"gnomad_af":null,"gp1_af":null,"exac":null},
    "siftpred":{"D":false,"T":false},
    "gnomad_filter":{"pass":false,"nonpass":false},
    "gene":[],
    "samples_germline":[],
    "samples_somatic":[],
    "compound_in":false,
    "cadd_phred":null,
    "cosmic":false,
    "qc_filter":{"dp_tumor":10,"dp_control":10,"dp_ref_tumor":10,"dp_alt_tumor":3,"vaf_tumor_low":0.05,"vaf_tumor_high":0.8},
    "nprograms":0,
    "programs_filter":{"mutect":false,"strelka":false,"caveman":false,"muse":false,"lancet":false},
    "cnv_germline":true,
    "cnv_somatic":false
};


const default_query = {
    "size":3000,
    "from":0,
    "fromCNV":0,
    "chrom":[],
    "freqInt": null,
    "indel":false,
    "svn":false,
    "genotypefeatures":{"other":false,"coding":false,"RNA":false},
    "variantclasses":{"high":false,"low":false,"moderate":false,"modifier":false},
    "mutationtaster":{"A":false,"D":false,"P":false},
    "intervarclasses":{"P":false,"LP":false,"B":false, "LB":false,"VUS":false},
    "clinvarclasses":{"P":false,"L":false,"A":false, "U":false,"C":false,"D":false},
    "onco_classifier": {"B": false, "LB": false, "LO": false, "O": false, "VUS": false},
    "onco_filter":{"K":false,"P1":false,"P2":false,"PP":false},
    "polyphen2hvarpred":{"D":false,"P":false,"B":false},
    "population":{"gnomad_af":null,"gp1_af":null,"exac":null},
    "siftpred":{"D":false,"T":false},
    "gnomad_filter":{"pass":false,"nonpass":false},
    "gene":[],
    "samples_germline":[{"sample_id":"Case11C","gt":["0/1"],"dp":10,"gq":30,"ad_low":0.2,"ad_high":0.8,"index":true}],
    "samples_somatic":[],
    "compound_in":false,
    "cadd_phred":null,
    "cosmic":false,
    "qc_filter":{"dp_tumor":10,"dp_control":10,"dp_ref_tumor":10,"dp_alt_tumor":3,"vaf_tumor_low":0.05,"vaf_tumor_high":0.8},
    "nprograms":0,
    "programs_filter":{"mutect":false,"strelka":false,"caveman":false,"muse":false,"lancet":false},
    "cnv_germline":false,"cnv_somatic":false,
    "variantconsequences": {
    "transcript_ablation": false,
    "splice_acceptor_variant": false,
    "splice_donor_variant": false,
    "stop_gained": false,
    "frameshift_variant": false,
    "stop_lost": false,
    "start_lost": false,
    "transcript_amplification": false,
    "feature_elongation": false,
    "feature_truncation": false,
    "inframe_insertion": false,
    "inframe_deletion": false,
    "missense_variant": false,
    "protein_altering_variant": false,
    "splice_donor_5th_base_variant": false,
    "splice_region_variant": false,
    "splice_donor_region_variant": false,
    "splice_polypyrimidine_tract_variant": false,
    "incomplete_terminal_codon_variant": false,
    "start_retained_variant": false,
    "stop_retained_variant": false,
    "synonymous_variant": false,
    "coding_sequence_variant": false,
    "mature_miRNA_variant": false,
    "prime_5_UTR_variant": false,
    "prime_3_UTR_variant": false,
    "non_coding_transcript_exon_variant": false,
    "intron_variant": false,
    "NMD_transcript_variant": false,
    "non_coding_transcript_variant": false,
    "coding_transcript_variant": false,
    "upstream_gene_variant": false,
    "downstream_gene_variant": false,
    "TFBS_ablation": false,
    "TFBS_amplification": false,
    "TF_binding_site_variant": false,
    "regulatory_region_ablation": false,
    "regulatory_region_amplification": false,
    "regulatory_region_variant": false,
    "intergenic_variant": false,
    "sequence_variant": false
}
};




const default_search_across = {"size":0,"from":0,"chrom":[],"indel":false,"svn":false,"genotypefeatures":{"other":false,"coding":false,"RNA":false},
    "variantclasses":{"high":false,"low":false,"moderate":false,"modifier":false},"intervarclasses":{"P":false,"LP":false,"B":false, "LB":false,"VUS":false},
    "mutationtaster":{"A":false,"D":false,"P":false},"clinvarclasses":{"P":false,"L":false,"A":false,"U":false,"C":false,"D":false},"onco_filter":{"K":false,"P1":false,"P2":false,"PP":false},"polyphen2hvarpred":{"D":false,"P":false,"B":false},"population":{"gnomad_af":null,"gp1_af":null,"exac":null},"siftpred":{"D":false,"T":false},"gnomad_filter":{"pass":false,"nonpass":false},"gene":["BRCA1"],"samples_germline":[{"sample_id":"ALL_SAMPLES","gt":["0/1"],"dp":10,"gq":30,"ad_low":0.2,"ad_high":0.8,"index":1}],"samples_somatic":[],"compound_in":false,"cadd_phred":null,"cosmic":false,"qc_filter":{"dp_tumor":10,"dp_control":10,"dp_ref_tumor":10,"dp_alt_tumor":3,"vaf_tumor_low":0.05,"vaf_tumor_high":0.8},"nprograms":0,"programs_filter":{"mutect":false,"strelka":false,"caveman":false,"muse":false,"lancet":false}}

    // filter collection





export {query_sample, default_query, default_search_across}
