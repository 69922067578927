



import React from 'react';

import {NavLink} from "react-router-dom";
import {GPAP_Button_Orange} from "../../gpap-lib/components/Button_Collection";

import PlaygroundVersion from "./PlaygroundVersion";
import GPAP_Header from '../../components/header/GPAP_Header';
import {Grid, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";


function Home(){


        return(
               <div className="main-content" style={{backgroundImage: "url(" + window.config.static_files + "/img/home_background_small.png" +")"}}>
               <GPAP_Header/>
                   <Paper>
                     <div className="welcome-message">
                       <Grid
                           container
                           spacing={2}
                           direction="column"
                           justifyContent="flex-start"
                           alignItems="center">
                           <Grid item>
                               <Box p={2}>
                                   <Typography variant={"h4"} align={"center"}> Welcome to the new version of the Genomic Analysis module</Typography>
                                   <Typography variant={"h6"} align={"center"}> Analyse integrated genome-phenome data from individual cases, families and cohorts </Typography>
                               </Box>
                           </Grid>
                           <PlaygroundVersion/>
                           <Grid item>
                               <Box p={1}>
                                   <GPAP_Button_Orange color="inherit" ><NavLink exact to="/home" style={{textDecoration:"none", color:"white"}}> Access Genomics Module</NavLink></GPAP_Button_Orange>
                               </Box>
                           </Grid>


                           <Grid item lg={7}>
                                   <Box p={2}>
                                       <Typography variant={"h6"}> Help us improve, leave your feedback</Typography>
                                       <Box p={1}>
                                           <Typography variant={"body1"}>
                                               You can let us know about your experience with the Next-GPAP. You can fill in  <a href={" https://forms.gle/dAUJxUcWhrs8Cz9z5"}>this simple form</a>, it will take not more than 5 minutes.
                                               This will help us to improve this new application and facilitate your analyses in the future. Thanks!
                                               You can always contact our team at <a href="mailto:help@rd-connect.eu">help@rd-connect.eu</a>.
                                               <br/>
                                           </Typography>

                                       </Box>
                                   </Box>
                           </Grid>
                         {/*  <Grid item lg={7}>
                                   <Box p={2}>
                                       <Typography variant={"h6"} align={"start"}> If you want to use the previous version of the RD-Connect GPAP Genomic Analysis, access the module below </Typography>
                                   </Box>
                           </Grid>*/}
                          {/* {
                               renderItems()
                           }*/}

                       </Grid>
                   </div>
                   </Paper>
               </div>
        )

}



export default Home;