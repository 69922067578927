
import React from "react";
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";



export function SampleCell( {sample_case, isCohortOrSA}){


    return <Grid item lg={2}>
        <Typography variant="h7" component="h7" style={{color: "#4575B4", fontWeight: "bold"}}>
            {(isCohortOrSA)
                ? "All Experiments"
                : sample_case.id}
        </Typography>
    </Grid>

}


export function IndexCell(props){

    let {sample_case} = props;

    return <Grid item lg={1}>
        {((sample_case.case === "index") || (sample_case.case === "tumor"))
            ? <Chip label={ sample_case.case} color="primary"/>
            : <Chip label={ sample_case.case}/>
        }
    </Grid>
}