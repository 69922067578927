import React, {Fragment, useEffect, useState} from "react";
import { DialogTitle } from "../../../../../side-panels/study-store/dialog_components";
import MappedText from "../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Alert } from "@mui/material"; // Updated from @mui/lab
import { Dialog, DialogActions, DialogContent } from "@mui/material";

import getHelperText from "../helper-text/HelperText";
import { checkIfNameExists } from "../../../../../side-panels/filter-panel/components/query-top-bar/func";
import AnalysisInfo from "./AnalysisInfo";





function StudySaveDialog({handleClose, currentStudy, open, studyDetails, setStudyDetails, handleChangePermission, saveAllStudy, list_of_studies, permission}) {

    const [nameError, setNameError] = useState(false);

// Utility for validating study names and updating error state
    const validateAndSetNameError = (val) => {
        const isNameInvalid = checkIfNameExists(
            val,
            list_of_studies || [],
            currentStudy?.id !== undefined ? "update" : "save"
        );
        setNameError(isNameInvalid);
        return isNameInvalid;
    };

/*// Validate study name on list changes or initial load
    useEffect(() => {
        if (currentStudy?.studyName) {
            validateAndSetNameError(currentStudy.studyName);
        }
    }, [list_of_studies.length]);*/

// Handle study name input change
    const handleStudyNameChange = (e) => {
        const updatedStudyName = e.target.value;
        const updatedDetails = { ...studyDetails, studyName: updatedStudyName };

        validateAndSetNameError(updatedStudyName);
        setStudyDetails(updatedDetails);
    };

// Handle save action
    const handleSave = () => {

        console.log("handle save");
        saveAllStudy();

    };


    return (<Fragment>
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                {currentStudy && currentStudy.id !== undefined ? (
                    <MappedText text="Update Study Information"/>
                ) : (
                    <MappedText text="Save Study"/>
                )}
            </DialogTitle>
            <DialogContent dividers>
                <Grid container direction="row">
                    <Grid item lg={12}>
                        <MappedText text="Fill in some information to save your work."/>
                    </Grid>
                    <Grid item lg={12}>
                        <AnalysisInfo currentStudy = {currentStudy}/>
                    </Grid>
                    <Grid item lg={12}>
                        <Box p={2}>
                            <TextField
                                style={{width: "70%"}}
                                id="study_name"
                                inputProps={{maxLength: 50}}
                                fullWidth
                                error={nameError}
                                label={currentStudy.studyName}
                                helperText={getHelperText(currentStudy, studyDetails, list_of_studies)}
                                onChange={ (e) => handleStudyNameChange(e)}
                            />
                        </Box>
                    </Grid>
                    <Grid item lg={12}>
                        <Box p={2}>
                            <TextField
                                id="description"
                                helperText={<MappedText text="Study description. Max 50 chars"/>}
                                inputProps={{maxLength: 50}}
                                label={currentStudy.description}
                                multiline
                                fullWidth
                                onChange={e => {
                                    let val = e.target.value;
                                    let info = {...studyDetails};
                                    info.description = val;
                                    setStudyDetails(info);
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item lg={12}>
                        <Box p={2}>
                            <TextField
                                id="standard-select-permission"
                                select
                                label={<MappedText text="Select"/>}
                                value={permission}
                                onChange={handleChangePermission}
                                helperText={<MappedText text="Please select study visibility"/>}
                            >
                                {[
                                    {value: "user", label: "Private"},
                                    {value: "group", label: "Share with your groups"},
                                    {value: "public", label: "Public"}
                                ].map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        <MappedText text={option.label}/>
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    </Grid>
                    <Grid item lg={12}>
                        <Box p={2}>
                            <Typography variant="subtitle1">
                                # analysis: {currentStudy.analyses.length}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    <MappedText text="Cancel"/>
                </Button>
                <Button autoFocus onClick={handleSave} color="primary" disabled={nameError}>
                    <MappedText text="Save changes"/>
                </Button>
            </DialogActions>
        </Dialog>
    </Fragment>)

}


export default StudySaveDialog;


