import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CustomTab from "../../tables/general-table/pages/CustomTab";
import Tabs from "@mui/material/Tabs";
import {IconButton, Dialog, DialogContent, Box} from "@mui/material";
import PestControlIcon from '@mui/icons-material/PestControl';
import CloseIcon from '@mui/icons-material/Close';
import MappedText from '../../../scenes/study-page/create-study/analysis-page/components/clinical-info/mapText';


const TabNavigation = ({ classes, isReportingPage, handleChange, value }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        className={classes.tabs}
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: '#3e699e',
          },
        }}
      >
        {!isReportingPage && (
          <CustomTab
            label="Interpretation Requests"
            component={Link}
            to="/dir_list"
            className={classes.tab}
            sx={{ fontSize: '16px', fontWeight: '400', width: '210px' }}
          />
        )}
        <CustomTab
          label="Docs"
          component={Link}
          to="/reporting_doc"
          target="_blank"
          className={classes.tab}
          sx={{ fontSize: '16px', fontWeight: '400', width: '100px' }}
        />
        <CustomTab
          label="Contact"
          component={Link}
          to="/contact"
          className={classes.tab}
          sx={{ fontSize: '16px', fontWeight: '400', width: '100px' }}
        />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 20px',
          }}
        >
          <IconButton
            onClick={handleOpen}
            sx={{
              width: '40px',
              height: '40px',
              color: '#d32f2f',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '&:hover': {
                backgroundColor: '#cc3333', 
                color: '#ffffff',
              },
            }}
          >
            <PestControlIcon />
          </IconButton>
        </Box>
      </Tabs>

      {/* Modal */}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            overflow: 'visible', 
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 16px',
            borderBottom: '1px solid #ddd',
          }}
        >
          <Box sx={{ fontWeight: 'bold', fontSize: '18px' }}><MappedText text="Bug Report Form" /></Box>
          <IconButton onClick={handleClose} sx={{ color: '#555' }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent
          sx={{
            padding: 0,
            height: '600px', 
            overflow: 'hidden', 
          }}
        >
          <iframe
            src="https://forms.fillout.com/t/x9bVZMBZjLus"
            title="Bug Report Form"
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
              overflowY: 'auto', 
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TabNavigation;
