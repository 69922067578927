import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { get_hmtdb } from "../../../../../../../../../services/api/variant_external_calls";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import MappedText from "../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";


function HMDB(props){

    const [data, setData] = useState(false);
    const [msg, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    useEffect( function () {

        const L = props.selected_variant.length;
        let variantObj = props.selected_variant[L - 1];

        let variant =  (variantObj!== undefined && Array.isArray(variantObj.variant) && variantObj.variant.length>0)
            ? variantObj.variant[0]
            : undefined;


        if(variant!== undefined){
            let {chrom, alt, ref, pos} = variant;
            setLoading(true);
            setError(false);
            setMessage("");


            if([23, "23", "MT"].includes(chrom)){
                get_hmtdb(chrom, pos, pos, ref, alt, props.token)
                    .then(function(resp){
                        if(resp.ok){
                            return resp.json()
                        }
                        else{
                            setError(true);
                            setLoading(false);
                        }
                    })
                    .then(function(json){

                    if(Object.keys(json).length>0){
                        setData(json);
                        setLoading(false);
                    }
                    else{
                        setLoading(false);
                        setMessage("Information not available");
                    }


                })
            }
            else{
                setMessage("Information is only requested for positions in MT DNA")
            }


        }

    }, [props.selected_variant])


    const renderInfo = () =>
    {
        if(loading){
            return <Box p={1}>
                <CircularProgress/>
            </Box>
        }
        else if(message){
            return <Box p={1}>
                <Typography variant={"subtitle2"}>
                    {message}
                </Typography>
            </Box>
        }
        else if(msg){
            return <Box p={1}>
                <Typography variant={"subtitle2"}>
                    Request Error for selected position
                </Typography>
            </Box>
        }
        else{
        return  <div> {(data )
                ? renderPanel()
                : <MappedText text="No Associations"/>} </div>
        }


    }

    const getField = (obj, field) => {

        return (obj && obj.hasOwnProperty(field) && obj[field] !== null)
            ? <span style={{color: "rgb(49, 130, 189)"}}>{obj[field]}</span>
            : <span style={{color: "gray"}}>NA</span>;

    }

    const renderPanel = () => {

        if(data && Object.keys(data)){
            return  <div className={"boxResults_long"}><Grid container direction={"row"}>
                        <Grid item lg={12}>
                            <Typography variant={"subtitle2"}>Pathogenicity: {getField(data, "pathogenicity")}</Typography>
                            <Typography variant={"subtitle2"}>Haplogroups: {getField(data, "haplogroups")}</Typography>
                            <Divider/>
                            <Typography>CrossRef:</Typography>
                            <Typography variant={"subtitle2"}>Genomes1K_hetero: {getField(data, "mitomap_associated_disease")}</Typography>
                            <Typography variant={"subtitle2"}>Genomes1K_hetero: {getField(data, "pubmed_ids")}</Typography>
                            <Typography variant={"subtitle2"} >Genomes1K_hetero: {getField(data["Plasmy"], "genomes1K_hetero")}</Typography>
                            <Typography variant={"subtitle2"}>Genomes1K_homo: {getField(data["Plasmy"], "genomes1K_homo")}</Typography>
                            <Typography variant={"subtitle2"}>Mitomap_hetero: {getField(data["Plasmy"], "mitomap_hetero")}</Typography>
                            <Typography variant={"subtitle2"}>Mitomap_homo: {getField(data["Plasmy"], "mitomap_homo")}</Typography>
                            <Divider/>
                            <Typography variant={"subtitle2"}>Predictions:</Typography>
                            <Typography variant={"subtitle2"}>Mut. Pred.: {getField(data["Predict"], "mutPred_pred")}</Typography>
                            <Typography variant={"subtitle2"}>Polyphen2_humDiv: {getField(data["Predict"], "polyphen2_humDiv_pred")}</Typography>
                            <Typography variant={"subtitle2"}>Polyphen2 HumVar: {getField(data["Predict"], "polyphen2_humVar_pred")}</Typography>
                            <Typography variant={"subtitle2"}>SNP GO: {getField(data["Predict"], "snp_go_pred")}</Typography>
                        </Grid>
                    </Grid>
            </div>
        }
        else{
            return <Typography>No information available</Typography>
        }




    }

    return  <div className={"my_paper_box_long"}>
        <Box p={1}>
            <Typography variant={"subtitle1"}><MappedText text="HmtDB - Human Mitochondrial Database"/></Typography>
            <Divider/>
            {renderInfo()}
        </Box>
    </div>





}

function mapStateToProps (state) {
    if(state.selected_variant!==undefined)
    {
        return {
            token: state.authorization.token,
            selected_variant: state.selected_variant,
            patients: state.patients}
    }
    else{
        return {snv: []}
    }
}

export default connect(mapStateToProps,null)(HMDB);