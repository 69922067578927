import FormControl from '@mui/material/FormControl'; // Updated to @mui/material
import FormLabel from '@mui/material/FormLabel'; // Updated to @mui/material
import RadioGroup from '@mui/material/RadioGroup'; // Updated to @mui/material
import FormControlLabel from '@mui/material/FormControlLabel'; // Updated to @mui/material
import Radio from '@mui/material/Radio'; // Updated to @mui/material
import React from 'react';
import MappedText from '../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText';



// TODO: Convert to switch;
function OperatorChoice(props){


    let {selectedOption, handleOptionChange} = props;

    return  <FormControl component="fieldset"
                         style={{float: "left"}}>
        <FormLabel component="legend"><MappedText text={"Operator"}/></FormLabel>
        <RadioGroup row aria-label="operator"
                    name="operator"
                    value={selectedOption}
                    onChange={handleOptionChange}>
            <FormControlLabel value="union" control={<Radio />} label=<MappedText text={"Union"}/> />
            <FormControlLabel value="intersection" control={<Radio />} label= <MappedText text={"Intersection"}/>/>
        </RadioGroup>
    </FormControl>



}

export default OperatorChoice;