
import React, { useEffect, useState } from "react";
import Badge from "@mui/material/Badge"; // Updated to MUI v5
import PeopleIcon from "@mui/icons-material/People"; // Updated to MUI v5
import { getSingleCohort } from "../../../../../../../../services/api/get";
import { connect } from "react-redux";


function SamplesIcon(props){

    let {selected_cohort} = props;
    const [init, setInit] = useState();
    const [cohort, setCohort] = useState(undefined);

    useEffect(function(){
        if(!init){
            if(selected_cohort!== undefined){
                if (typeof selected_cohort !== "object"){
                    getSingleCohort(config.apis_configuration.api_cohortapp, props.token, selected_cohort)
                        .then(s => s.json())
                        .then(json => {
                            setCohort(json)
                            setInit(true);
                        });
                }
            }
        }
    })

    return <Badge color="primary" max={999}
                  overlap="rectangular"
                  badgeContent={(cohort!== undefined) ? cohort.cohort.total_patients : props.n_samples} >
        <PeopleIcon />
    </Badge>



}

//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.patients.length!==0)
    {
        return {
            selected_cohort: state.selected_cohort,
            token: state.authorization.token
        }
    }
    else{
        return {patients: [], token: state.authorization.token}
    }
}




export default connect(mapStateToProps,null)(SamplesIcon);