import React from "react";
import {DATA_HEADER} from "./data_header";
import CustomTable from "../../../components/tables/general-table/components/tables/CustomTable";
import {useSelector} from "react-redux";

function Fusions_Table(props){

  const {allDataApis } = useSelector((state) => state.generaltable);
  const selectedDir = allDataApis?.dir?.find(d => d.dir_id === allDataApis?.dirs?.dir_id);


    return <CustomTable
        dataType={"fusions"}
        tableName={"fusions"}
        url={props.url}
        method= "POST"
        dataSend = {{
          "fields": [],
          "filtered": [
            {
              "id": "Experiment_ID",
              "value": [selectedDir?.tumor_experiment_id]
            }
          ],
          "page": 1,
          "pageSize": 1000,
          "sorted": []
        }}  

 
        token={props.token}
        locationData="items"
        // classRow= "Fusions"
        customFeatures= {[
            { toggle: false },
            { expanding: false },
            { showHeader: true },
        ]}

        dataHeader={DATA_HEADER}
       
    />


}

export default Fusions_Table
