import React from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import CustomTable from "../../../components/tables/general-table/components/tables/CustomTable";

import { CLINICAL_DATA_HEADER } from "./config/clinical_data_header";

export default function ClinicalReportTable() {

  const token = useSelector(state => state.authorization.token);
  const { id } = useParams(); 
  const refresh = useSelector((state) => state.generaltable.refresh);
  const url = `${window.config.apis_configuration.api_nextgpap_server}/reports_by_dir/${id}`;
  const urlDownload = `${window.config.apis_configuration.api_nextgpap_server}`;

 
  return (
    <Grid container direction={"row"}>
      <Grid item lg={12}>
        <CustomTable
          key={refresh}
          tableName={"clinical_reports"}
          url={url}
          idRelation={"clinical_report_id"}
          urlDownload={urlDownload}
          token={token}
          locationData="clinical_reports"
          dataHeader={CLINICAL_DATA_HEADER}
          reportType="clinical"
          customFeatures={[
            { toggle: false },
            { expanding: false },
            { showHeader: false },
            {
              actions: {
              downloadDoc: true,
              delete: true,
              },
            },
          ]}
          buttons={[
            {
              nameButton: "Reports",
            },
            {
                nameButton: "Upload",
              },
          ]}
        />
      </Grid>
    </Grid>
  );
}
