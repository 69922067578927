import React from "react";
import { Box } from "@mui/material";
import Headings from './header/Headings';

export default function HomeInstandLayout({ component, isReportingRoute }) {
    return (
      <Box sx={{ backgroundColor: "#fff", width: "100%" }}>
        <Headings project="instand" isReportingRoute={isReportingRoute} />
        <Box>{component}</Box>
      </Box>
    );
  }
  