import {cnv_variant_type_opts, origin_opts, type_opts, variant_type_opts} from "../tag_form_options";

export const getInitialFormOptions = (dataType) => ({
    participant_id: "xxxx",
    samples: [{ value: "none", label: "none" }],
    genes: [{ value: "none", label: "none" }],
    transcripts: [{ value: "none", label: "none" }],
    zygosity_opts: [{ value: "none", label: "none" }],
    inheritance_opts: [{ value: "none", label: "none" }],
    significance_opts: [{ value: "none", label: "none" }],
    origin: origin_opts,
    variant_type: type_opts,
    sv_type: dataType === "CNV" ? cnv_variant_type_opts : variant_type_opts,
    project: []
});
