const prepareObject = (source, data, mapping) => {
    const output = {};

    // Map properties based on the provided mapping
    Object.entries(mapping).forEach(([sourceKey, outputKey]) => {
        if (source[sourceKey] && source[sourceKey].length > 0) {
            output[outputKey] = source[sourceKey];
        } else {
            output[outputKey] = "NA";
        }
    });

    // Set the interpretation property from data.comments
    output.interpretation = data?.comments || "";

    return output;
};

// Example usage for Pharmacogenomics
export const preparePharmacogenomicsObject = (pharmacogenomics, data) => {
    const pharmacogenomicsMapping = {
        "Gene": "gene",
        "Genotype": "genotype",
        "Haplotype1": "haplotype1",
        "Haplotype2": "haplotype2",
        "Phenotype": "phenotype",
        "VariantData": "variant_data"
    };

    return prepareObject(pharmacogenomics, data, pharmacogenomicsMapping);
};

// Example usage for Treatment
export const prepareTreatmentObject = (treatment, data) => {
    const treatmentMapping = {
        "gene_name": "gene",
        "hgvs_p": "variant",
        "match_type": "match_type",
        "Cancer_Type": "cancer_type",
        "Therapies": "therapy",
        "Therapy_status": "therapy_status",
        "Evidence_level": "evidence_level"
    };

    return prepareObject(treatment, data, treatmentMapping);
};
