import React, { useRef, useState } from "react";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// MUI Components
import { Box, Typography, Grid, Card, CardActions, CardContent, Grow, TextField, InputAdornment, MenuItem } from '@mui/material';
import { Folder as FolderIcon, SkipNext } from '@mui/icons-material';

// Custom Components
import { GPAP_Button } from "../../../../gpap-lib/components/Button_Collection";

// Actions
import * as Actions from "../../../../actions";
import * as StudyActions from "../../../dashboard/reducers/study_actions";
import {makeStyles} from "@mui/styles";


const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 25,
    },
    pos: {
        marginBottom: 12,
    },
    margin: {
        margin: theme.spacing(1),
        width: "80%"
    },
    box:{
        margin: "1%"
    }
}));

function IntroStudy(props){

    const classes = useStyles();
    const nameRef = useRef('')
    const descriptionRef = useRef('')
    const [permission, setPermission] = useState("user");
    const [edited, set] = useState(false);


    const setEdited = () => {

        if(nameRef.current.value !== "" ){
            set(true);
        }
        else if(edited && nameRef.current.value === ""){
            set(false);
        }


    }

    const handleSave = () => {

        // save studyName, description and permission;

        // TODO: do some sort of validation first;


        props.studyActions.change_study_name({
            studyID: props.studyID,
            studyName: nameRef.current.value,
            description: descriptionRef.current.value,
            permission: permission
        })

        props.handleHide()

    }

    const handleSkip = () => {

        props.handleHide()

    }

    const handleChange = (event) => {
        setPermission(event.target.value);
    }

    return    <Grow in={true}>
        <Box p={2}>
        <Card className={classes.root}>
            <CardContent>
                <Grid container direction={"column"} alignItems={"center"}>
                    <Grid item lg={8}>
                        <Box p={2}>
                            <Typography variant={"h5"} align={"center"} paragraph={true}>
                                Hi! We are going to create the first analysis of a new GPAP Study
                            </Typography>
                        </Box>
                        <Box p={2}>
                            <Typography variant={"body1"} align={"justify"}>
                                A study is a collection of analyses (experiments(s) and inheritance type).
                                You can add the name and a short description for this study. Define the visibility level, and you are ready to go!
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={8}>
                        <Box p={2}>
                            <form className={classes.root} noValidate>
                                <div className={classes.box}>
                                <TextField
                                    placeholder={"write a title for this new study"}
                                    className={classes.margin}
                                    onChange={ () => setEdited()}
                                    id="input-with-icon-textfield"
                                    label="Study Name"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FolderIcon style={{ color: "#4575B4" }}/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    inputRef={nameRef}
                                />
                                </div>
                                <div className={classes.box}>
                                    <TextField
                                        className={classes.margin}
                                        id="standard-multiline-static"
                                        label="Description of the study"
                                        multiline
                                        placeholder="a short description"
                                        inputRef={descriptionRef}
                                    />
                                </div>
                                <div className={classes.box}>
                                    <TextField
                                        className={classes.margin}
                                        id="standard-select-permission"
                                        select
                                        label="Select"
                                        value={permission}
                                        onChange={handleChange}
                                        helperText="Please select study visibility">
                                        {[{value: "user", label: "Private"}, {value: "group", label: "Share with your groups"}, {value: "public", label: "Public"}].map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </form>

                        </Box>
                        <Box p={2}>
                            <Typography variant={"h6"} align={"center"} paragraph={true}>
                               The study will NOT BE <strong>saved automatically</strong>. You can save the study in the genomics dashboard once performed the first analysis.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                { (! edited )
                    ? <GPAP_Button onClick={handleSkip} startIcon={<SkipNext/>}>Skip this step</GPAP_Button>
                    : <GPAP_Button onClick={handleSave} >Ok, Continue</GPAP_Button>
                }

            </CardActions>
        </Card>



    </Box>
    </Grow>

}


// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
    studyActions: bindActionCreators(StudyActions, dispatch)
});




export default connect(null ,mapDispatchToProps)(IntroStudy);
