

import React,{useEffect, useState} from 'react';
import { Grid, Box, Typography, } from "@mui/material";
import GeneralTable from "../../../../../create-report/shared-components/general-table/GeneralTable.js";
import { PHARMACOGENOMICS } from "../config/pharmacogenomics.js";
import { useSelector } from 'react-redux';
import { getParticipant } from '../../../../../../../../services/api/get.js';
import MappedText from '../../../../shared-components/mapText.js';


export default function Pharmacogenomics({ report_id, onSelectedRowsChange}) {
    const token = useSelector(state => state.authorization.token);
    const [sectionData, setData] = useState([])
    const urlBase = window.config.apis_configuration.api_phenostore;

    useEffect(() => {
        const fetchData = async () => {
            if (!report_id) return;

            try {
                const response = await getParticipant(urlBase,token,report_id);
                const data = await response.json();
                if (data && data.tagged_pharmacogenomics) {
                    setData(data.tagged_pharmacogenomics);
                }
            } catch (error) {
                console.error("Error fetching data from API:", error);
            }
        };

        fetchData();
    }, [urlBase,token,report_id]);

    const handleSelectedRows = (rows) => {
        let sectionData = rows.map(s => s.original);
        let dataType ="Pharmacogenomics_Germline";
        if (onSelectedRowsChange) {
            onSelectedRowsChange(sectionData, dataType);
        }
    };


    return (
        <Grid item xs={12}>
            <Box p={1}>
                <Box>
                <Typography variant='h6' style={{ fontWeight: '500', marginBottom: '20px' }}><MappedText text="Annex 5: Pharmacogenomics"></MappedText></Typography>
                <Typography variant='body1'><MappedText text="These are the haplotypes that were tagged in the Pharmacogenomics tab of this Interpretation Request.Check all the haplotypes that you want to display in the final report."></MappedText></Typography>
                <GeneralTable 
                data={sectionData} 
                columns={ PHARMACOGENOMICS} 
                showCheckbox={true}
                handleSelectedRows={handleSelectedRows}    />
                </Box>
            </Box>
        </Grid>
    );
}
