import React from 'react';
import { Grid, Typography, Box, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MappedText from "../../../../../../../../../instand/reports/create-report/shared-components/mapText";
import CaButton from "../../../../../../../../../../gpap-lib/components/shared-components/CaButton";


const GenericForm = ({
                         isFlagged,
                         deleteFunction,
                         deleteSpec,
                         dataType,
                         handleTextChange,
                         dataComments,
                         mappedTextIntro, // Unique intro text for each form
                         deleteMappedText // Unique delete text for each form
                     }) => {
    const size = 12;

    return (
        <Grid container direction={"column"}>
            <Grid item lg={size}>
                {isFlagged ? (
                    <Grid item lg={size} style={{ backgroundColor: '#c6dbef', padding: '10px', borderRadius: '5px' }}>
                        <Typography variant={"subtitle1"}>
                            <MappedText text={`${mappedTextIntro} has already been tagged and will be displayed in the `} />
                            <span style={{ fontWeight: 'bold' }}><MappedText text={"Report"} /></span>
                            <MappedText text={" module. Editing this tag will modify the interpretation comment."} />
                        </Typography>
                    </Grid>
                ) : (
                    <Typography variant={"subtitle1"}>
                        <MappedText text={`Tagging a ${mappedTextIntro} will make it available to add in a `} />
                        <span style={{ fontWeight: 'bold' }}><MappedText text={"Report"} /></span>.
                    </Typography>
                )}
            </Grid>

            <Grid item lg={size}>
                <Box p={2}>
                    <TextField
                        style={{ width: "100%" }}
                        id="standard-multiline-static"
                        label={<MappedText text={"Interpretation"} />}
                        multiline
                        rows={4}
                        onChange={handleTextChange}
                        defaultValue={dataComments}
                    />
                </Box>
            </Grid>

            {isFlagged && (
                <Grid item lg={size}>
                    <div>
                        <CaButton
                            variant={"contained"}
                            text={deleteMappedText}
                            aria-label="delete"
                            onClick={() => deleteFunction(deleteSpec, dataType)}
                            startIcon={<DeleteIcon />}
                        />
                    </div>
                </Grid>
            )}
        </Grid>
    );
};

export default GenericForm;
