import React from "react";
import { Tooltip, Chip, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getCategoryInfo } from "./evidences_config";
import MappedText from "../../../../../../instand/reports/create-report/shared-components/mapText";

const CustomTooltip = styled(({ className, arrowColor, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ arrowColor }) => ({
    [`& .MuiTooltip-tooltip`]: {
        backgroundColor: "#fff",
        color: "#000",
        fontSize: "0.875rem",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
        padding: "10px 16px 16px",
        borderRadius: "4px",
        marginTop: "6px",
        gap: "10px",
    },
    [`& .MuiTooltip-arrow`]: {
        color: arrowColor,  
    },
}));


const renderTooltipContent = (category) => {
    const { color, tooltip } = category;

    if (!category || !category.label || !category.tooltip) return null;
    const highlightedTexts = {
        benign: ["not considered to be the cause"],
        likelyBenign: ["unlikely to be the cause"],
        vus: ["there is insufficient or conflicting evidence to confirm this"],
        likelyOncogenic: ["probable cause"],
        oncogenic: ["cause"]
    };

    const categoryKey = category.label.toLowerCase().replace(" ", "");
    const wordsToHighlight = highlightedTexts[categoryKey] || [];

    const parts = tooltip.split(" ").map((word, index) => {
        const shouldHighlight = wordsToHighlight.some(highlightedText => 
            word.toLowerCase().includes(highlightedText.toLowerCase())
        );
        return (
            <Typography
                component="span"
                key={index}
                style={{ color: shouldHighlight ? color : "inherit", fontWeight: shouldHighlight ? "bold" : "normal" }}
            >
                <MappedText text={word}/>{" "}
            </Typography>
        );
    });

    return <>{parts}</>;
};
const renderCategoryChip = (ocScore) => {
    const { label, color, tooltip } = getCategoryInfo(ocScore);
    return (
        <CustomTooltip
             title={renderTooltipContent(getCategoryInfo(ocScore))}
             arrow
            arrowColor={color} >
            <Chip
                size="small"
                variant="filled"
                label={label}
                style={{ backgroundColor: color, color: "#fff", marginRight: "10px" }}
            />
        </CustomTooltip>
    );
};

const renderScoreChip = (ocScore) => {
    return (
        <CustomTooltip
            title={
                <Box style={{display: "flex", flexDirection: "column"}}>
                    <Typography variant="caption" style={{ color: "gray", fontStyle: "italic" }}><MappedText text="How this score is calculated?" /></Typography>
                    <Typography variant="caption"><strong style={{ color: "#2E7D32" }}><MappedText text="Benign" /></strong> [{"<"}=-7]</Typography>
                    <Typography variant="caption"><strong style={{ color: "#8BD452" }}><MappedText text="Likely Benign" /></strong> [-6,-1]</Typography>
                    <Typography variant="caption"><strong style={{ color: "#666666" }}>VUS</strong> [0,5]</Typography>
                    <Typography variant="caption"><strong style={{ color: "#F27645 " }}><MappedText text="Likely Oncogenic"/></strong> [6,9]</Typography>
                    <Typography variant="caption"><strong style={{ color: "#A32D1D" }}><MappedText text="Oncogenic"/></strong> [{">"}=10]</Typography>
                </Box>
               
            } 
            arrow
            arrowColor="#616161E5" 
        >
            <Chip
                size="small"
                variant="filled"
                label={`Score: ${ocScore}`}
                style={{ backgroundColor: "#00000014", color: "#000" }}
            />
        </CustomTooltip>
    );
};

const ScoreCategoryChips = ({ ocScore }) => (
    <Box display="flex" alignItems="center">
        {renderCategoryChip(ocScore)}
        {renderScoreChip(ocScore)}
    </Box>
);

export default ScoreCategoryChips;
