import React from "react";


import { Grid, Typography } from "@mui/material"; // Ensure you're using the correct MUI imports
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'; // Ensure you're using MUI icons
import MappedText from "../../../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import CaButton from "../../../../../../../../../../../../gpap-lib/components/shared-components/CaButton"; // Adjust the path as needed

const NextButton = ({ extractedHpo, searchedHpos, setStep }) => {
    const allow = extractedHpo.length !== 0 || searchedHpos.length !== 0;

    return (
        <Grid item>
            <Typography variant={"subtitle2"}>
                <MappedText text={"In the next step, you can extract genes from HPOs and add them to your queries"} />
            </Typography>
            <CaButton
                variant={"contained"}
                disabled={!allow}
                onClick={() => setStep("step2")}
                style={{ float: "right" }}
                text={"Next"}
                endIcon={<ArrowForwardIcon />}
            />
        </Grid>
    );
};

export default NextButton;
