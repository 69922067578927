import {
  createTitle,
  createKeyValueParagraph,
  createParagraphWithSpacing,
  createBoldUnderlinedParagraph,
  createHeadTitle,
  createSubTitle,
  createSpacer,
} from '../../write-sections/write_utils/documentHelpers';

function AnnexesSomatic(allDataApis, annexesFields) {
  const structure = [
    { type: 'headTitle', label: 'ANNEXES' },
    { type: 'spacer', before: 50, after: 50 },
    { type: 'paragraphWithSpacing', label: 'The following annexes complete the report, which should be reviewed as a whole.' },
    { type: 'spacer', before: 50, after: 50 },
    { type: 'title', label: 'ANNEX I: CLINICAL RECORD' },
    { type: 'title', label: 'ANNEX II: TUMOUR GENOMIC CHARACTERISTICS' },
    { type: 'title', label: 'ANNEX III: OTHER BIOMARKERS' },
    { type: 'title', label: 'ANNEX IV: METHODOLOGY' },
    { type: 'title', label: 'ANNEX V: QUALITY CONTROL METRICS' },
    { type: 'boldUnderlinedParagraph', label: 'ANNEX I: CLINICAL RECORD' },
    { type: 'paragraphWithSpacing', label: 'Cancer type', bold: true },
    { type: 'paragraphWithSpacing', label: allDataApis?.participants?.[0]?.cancer_type || 'NA' },
    { type: 'paragraphWithSpacing', label: 'Family history', bold: true },
    { type: 'paragraphWithSpacing', label: annexesFields?.family_history || 'NA' },
    { type: 'paragraphWithSpacing', label: 'Treatment', bold: true },
    { type: 'paragraphWithSpacing', label: annexesFields?.treatment || 'NA' },
    { type: 'boldUnderlinedParagraph', label: 'ANNEX II: TUMOUR GENOMIC CHARACTERISTICS' },
    { type: 'keyValueParagraph', label: 'Purity', value: allDataApis?.tumor?.tumor_purity || 'Tumor purity cannot be determined for this sample.' },
    { type: 'keyValueParagraph', label: 'Average ploidy', value: allDataApis?.tumor?.average_ploidy || 'Average ploidy cannot be determined for this sample.' },
    { type: 'boldUnderlinedParagraph', label: 'ANNEX III: OTHER BIOMARKERS' },
    { type: 'keyValueParagraph', label: 'Microsatellite status', value: allDataApis?.tumor?.microsatellite_status || 'Microsatellite status cannot be determined for this sample.' },
    { type: 'keyValueParagraph', label: 'Tumour mutation load', value: allDataApis?.tumor?.tumor_mutational_load || 'Tumour mutation load cannot be determined for this sample.' },
    { type: 'keyValueParagraph', label: 'Tumour mutational burden', value: allDataApis?.tumor?.tumor_mutational_burden || 'Tumour mutational burden cannot be determined for this sample.' },
    { type: 'keyValueParagraph', label: 'Homologous Recombination Deficiency', value: allDataApis?.tumor?.homologous_recombination_deficiency_status || 'Homologous Recombination Deficiency cannot be determined for this sample.' },
    { type: 'keyValueParagraph', label: 'Mutational profiles', value: allDataApis?.tumor?.mutational_profiles || 'Mutational profiles cannot be determined for this sample.' },
    { type: 'keyValueParagraph', label: 'Molecular tissue of origin prediction', value: allDataApis?.tumor?.molecular_tissue || 'Molecular tissue of origin prediction cannot be determined for this sample.' },
    { type: 'keyValueParagraph', label: 'Tumor specific viral insertions', value: allDataApis?.tumor?.tumor_specific || 'Tumor specific viral insertions cannot be determined for this sample.' },
    { type: 'boldUnderlinedParagraph', label: 'ANNEX IV: METHODOLOGY' },
    { type: 'keyValueParagraph', label: 'Test method', value: `${allDataApis?.experiments?.[0]?.library_strategy || 'NA'} using the ${allDataApis?.experiments?.[0]?.kit || 'NA'}` },
    { type: 'keyValueParagraph', label: 'Type of variants assessed', value: allDataApis?.type_of_variants_assessed || 'NA' },
    { type: 'keyValueParagraph', label: 'Sample received', value: annexesFields?.sample_received || 'NA' },
    { type: 'keyValueParagraph', label: 'Sample type', value: allDataApis?.samples?.sample_type || 'NA' },
    { type: 'keyValueParagraph', label: 'Sample condition', value: allDataApis?.samples?.sample_status || 'NA' },
    { type: 'keyValueParagraph', label: 'Site of tumour', value: allDataApis?.samples?.sample_tissue_of_origin || 'NA' },
    { type: 'keyValueParagraph', label: 'Treatment given before sample was taken', value: annexesFields?.treatment_given_before || 'NA' },
    { type: 'keyValueParagraph', label: 'Date of sample collection', value: allDataApis?.samples?.date_of_sample_collection || 'NA' },
    { type: 'spacer', before: 50, after: 50 },
    { type: 'subTitle', label: 'Sample information' },
    { type: 'spacer', before: 50, after: 50 },
    { type: 'keyValueParagraph', label: 'DNA purity', value: annexesFields?.dna_purity || 'NA' },
    { type: 'keyValueParagraph', label: 'DNA integrity', value: annexesFields?.dna_integrity || 'NA' },
    { type: 'spacer', before: 50, after: 50 },
    { type: 'subTitle', label: 'Sample processing' },
    { type: 'spacer', before: 50, after: 50 },
    { type: 'keyValueParagraph', label: 'Center', value: allDataApis?.centre || 'NA' },
    { type: 'keyValueParagraph', label: 'Sequencing platform', value: allDataApis?.sequencing_platform || 'NA' },
    { type: 'keyValueParagraph', label: 'Fragment (size and type)', value: allDataApis?.fragment || 'NA' },
    { type: 'spacer', before: 50, after: 50 },
    { type: 'subTitle', label: 'Bioinformatics analysis' },
    { type: 'spacer', before: 50, after: 50 },
    { type: 'keyValueParagraph', label: 'Human reference genome', value: allDataApis?.human_reference_genome || 'NA' },
    { type: 'keyValueParagraph', label: 'Aligner', value: allDataApis?.aligner || 'NA' },
    { type: 'keyValueParagraph', label: 'Mark duplicates', value: allDataApis?.mark_duplicates || 'NA' },
    { type: 'keyValueParagraph', label: 'Base recalibration', value: allDataApis?.base_recalibration || 'NA' },
    { type: 'keyValueParagraph', label: 'Smart variants caller', value: allDataApis?.smart_variants_caller || 'NA' },
    { type: 'keyValueParagraph', label: 'Analysis pipeline', value: allDataApis?.analysis_pipeline || 'NA' },
    { type: 'spacer', before: 50, after: 50 },
  ];


  const content = structure.map((item) => {
    switch (item.type) {
      case 'headTitle':
        return createHeadTitle(item.label);
      case 'spacer':
        return createSpacer(item.before, item.after);
      case 'paragraphWithSpacing':
        return createParagraphWithSpacing(item.label, item.bold || false);
      case 'title':
        return createTitle(item.label);
      case 'subTitle':
        return createSubTitle(item.label);
      case 'boldUnderlinedParagraph':
        return createBoldUnderlinedParagraph(item.label);
      case 'keyValueParagraph':
        return createKeyValueParagraph(item.label, item.value);
      default:
        return null;
    }
  });

  return content;
}

export default AnnexesSomatic;
