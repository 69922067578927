


export const splitTooltips = [
    {
        referenceTable: "AnnotSV reported pathogenic",
        dataSearch: "SV_type",
        rowSelect: [
            {
                replaceWith: "P_loss_source",
                dataSearch: "P_loss_source",
                value: "DEL",
            },
        ],
    },
    {
        referenceTable: "AnnotSV reported pathogenic",
        dataSearch: "SV_type",
        rowSelect: [
            {
                replaceWith: "P_gain_source",
                dataSearch: "P_gain_source",
                value: "DUP",
            },
        ],
    },
    {
        referenceTable: "AnnotSV reported pathogenic",
        dataSearch: "SV_type",
        rowSelect: [
            {
                replaceWith: "P_ins_source",
                dataSearch: "P_ins_source",
                value: "INS",
            },
        ],
    },
    {
        referenceTable: "AnnotSV population AFMax",
        dataSearch: "SV_type",
        rowSelect: [
            {
                replaceWith: "B_loss_source",
                dataSearch: "B_loss_source",
                value: "DEL",
            },
        ],
    },
    {
        referenceTable: "AnnotSV population AFMax",
        dataSearch: "SV_type",
        rowSelect: [
            {
                replaceWith: "B_gain_source",
                dataSearch: "B_gain_source",
                value: "DUP",
            },
        ],
    },
    {
        referenceTable: "AnnotSV population AFMax",
        dataSearch: "SV_type",
        rowSelect: [
            {
                replaceWith: "B_ins_source",
                dataSearch: "B_ins_source",
                value: "INS",
            },
        ],
    },

];