
import React, {useEffect} from "react";

import {useState} from "react";
import Analysis_Page from "../analysis-page/Analysis_Page";

import {Redirect} from "react-router-dom";
import {connect} from "react-redux";

import SearchAcrossAll from "../search-across-all/SearchAcrossView";
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";

function NewStudy(props){


    const { t, i18n } = useTranslation();
    const [experiments, setExperiments] = useState([]);
    const [goGenomics, setGoToGenomics] = useState(false);
    const [initialized, setInitialized] = useState(false);

    const [samples, setSamples]= useState([]);


    const [study_type, setStudyType] = useState(false);


    useEffect(function(){

        if(!initialized){
            console.log("Check status");
            let type = (props.location.state!== undefined) ? props.location.state.key : "none";
            setStudyType(type);
            setInitialized(true);
        }
    }, [ study_type, goGenomics ]);




    const go_to_genomics = (samples) => {

        if(samples){
            setSamples(samples);
        }
        setGoToGenomics(true)
    };


    const renderView = () => {

        if(goGenomics){
            return <Redirect
                to={{
                    pathname: "genomics",
                    state: {
                        samples_cohort: samples,
                        loadStudy: true
                    } }}/>;
        }
        else if(study_type === "none")
        {
            return <Redirect
                to={{pathname: "home"}}/>;
        }
        else if(study_type === "family" ){
            return <Analysis_Page
                experiments={experiments}
                queryRunner = { go_to_genomics }
            />;
        }
        else if(study_type === "cohort"){

            let cohort_id = (props.location.state!== undefined) ? props.location.state.handle : false

            return <SearchAcrossAll
                studyType = {"cohort"}
                queryRunner = { go_to_genomics }
                cohortID = {cohort_id}
            />
        }
        else if(study_type === "search_across"){
            return <SearchAcrossAll
                studyType = {"search_across"}
                queryRunner = { go_to_genomics }
            />
        }
        else{
            return <Typography>Loading List Experiments</Typography>}

    };


    return renderView();





}


//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        if(state.sample_list!== undefined)
        {
            return {
                token:state.authorization.token,
                studySettings: state.studySettings,
            }
        }
        else{
            return {token:state.authorization.token}
        }
    }
}




export default connect(mapStateToProps,null)(NewStudy);