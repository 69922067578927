


import React, { useState, useEffect } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Button} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from "@mui/material/styles"; // Replacing makeStyles
import MappedText from "../../scenes/instand/reports/create-report/shared-components/mapText";
import {makeStyles} from "@mui/styles";
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from "@mui/icons-material/Info";



const useStyles = makeStyles((theme) => ({
    dialog: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        maxWidth:"300px",
    },
    iconStyle: {
        width: "62px",
        height: "62px",
        marginTop: '16px'
    },
}));

const iconConfig = {
    warning: {
        color: "#FFC107",
        element: WarningIcon,
    },
    error: {
        color: "#F44336",
        element: ErrorIcon,
    },
    success: {
        color: "#31A354",
        element: CheckCircleIcon,
    },
    alert: {
        color: "#F44336",
        element: CancelIcon,
    },
    delete: {
        color: "#C03B2E",
        element: DeleteIcon,
    },
    default: {
        color: "#f0f0f0",
        element: InfoIcon,
    }
};


export default function GPAP_Dialog({ open, handleClose, title, text, onExitText, onConfirmText, onExitAction, onConfirmAction, type, onConfirmId }){
    const classes = useStyles();

    const [dialogOpen, setDialogOpen] = useState(open);

    const handleDialogClose = () => {
        setDialogOpen(false);
        handleClose();
    };

    useEffect(() => {
        setDialogOpen(open);
    }, [open]);



    const { color, element } = iconConfig[type];

    let IconElement = element;

    return (
        <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
            <DialogContent className={classes.dialog}>
                <IconElement fontSize="large" style={{color: color}} className={classes.iconStyle}/> 
                <DialogTitle id="alert-dialog-title" style={{textAlign: "center" }}>
                    <MappedText text={title}/>
                </DialogTitle>
                <DialogContentText id="alert-dialog-description"  style={{ textAlign: 'center' }}>
                        <MappedText text={text}/>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ display:"flex", justifyContent:"center"}}>
                {(onExitText)
                    ? <Button onClick={onExitAction}  style={{
                            textTransform: 'none',
                            border: 'none',
                            color: '#8E8E8E',
                        }}><MappedText text={onExitText} /></Button> 
                    : null}
                {(onConfirmText)
                    ? <Button onClick={onConfirmAction}   style={{
                            textTransform: 'none',
                            border: 'none',
                            color,
                        }} autoFocus id={onConfirmId}><MappedText text={onConfirmText}/></Button>
                    : null}
        </DialogActions>
        </Dialog>
    );
};