import {AlignmentType, HeadingLevel, Paragraph, TextRun, BorderStyle} from "docx";
import { drawGeneticFindingsTable } from "../write_utils/drawGeneticFindingsTable.js";
import {bodyText, TEXT_FOR_NEGATIVE_CLINICAL_EVIDENCE, TEXT_FOR_NEGATIVE_SNV_SOMATIC, TEXT_FOR_NEGATIVE_SNV_GERMLINE,TEXT_FOR_NEGATIVE_CNV_SOMATIC, TEXT_FOR_NEGATIVE_CNV_GERMLINE} from "../../config/template_config.js";
import {splitSNVandCNV} from "../../../create-report/sections/genetic_findings/utils.js";
import { createSpacer } from "../write_utils/documentHelpers.js";
import { renderToStringAlt } from "../../../create-report/shared-components/renderToString.js";

const headers_CNV_GERMLINE = [
    "Genomic breakpoints detected*",
    "Included gene(s)***",
    "Type",
    "Clinical significance**",
    "Zygosity",
];
const dataKeys_CNV_GERMLINE = [
    "region",
    "associated_genes",
    "sv_type",
    "significance",
    "zygosity",
];
const headers_CNV_SOMATIC = [
    "Genomic breakpoints detected*",
    "Included gene(s)***",
    "Origin",
    "Type",
    "Clinical significance**",
    "Zygosity",
];
const dataKeys_CNV_SOMATIC = [
    "region",
    "associated_genes",
    "origin",
    "sv_type",
    "significance",
    "zygosity",
];

const headers_SNV_GERMLINE = [
    "Gene", 
    "Genomic position*",
    "Transcript",
    "cDNA",
    "Protein",
    "Clinical significance**",
    "Zygosity",
];
const dataKeys_SNV_GERMLINE = [
    "geneName",
    "start_pos",
    "transcript",
    "cDNA",
    "protein",
    "significance",
    "zygosity",
];
const headers_SNV_SOMATIC = [
    "Gene",
    "Origin",
    "Genomic position*",
    "cDNA",
    "Protein",
    "Clinical significance**",
    "Zygosity",
];
const dataKeys_SNV_SOMATIC = [
    "geneName",
    "origin",
    "start_pos",
    "cDNA",
    "protein",
    "significance",
    "zygosity",
];

const formatAssociatedGenes = (genes) => {
    const geneList = genes.split(',');
    if (geneList.length > 10) {
        return `[${geneList[0]}, ${geneList[1]}, ..., ${geneList[geneList.length - 2]},${geneList[geneList.length - 1]}]`;
    }
    return `${geneList.join(", ")}`;
};

function WriteGeneticFindingsSection(sectionData, analysisType){


    let snv_data = [];
    let cnv_data = []


    if(sectionData){
        if(sectionData.rows && sectionData.rows.length>0){
            let obj  = splitSNVandCNV(sectionData.rows);
            snv_data=obj.snv_data;
            cnv_data = obj.cnv_data.map(variant => ({
                ...variant,
                associated_genes: formatAssociatedGenes(variant.associated_genes)
            }));
        }
    }

    const headers_CNV = analysisType === 'germline' ? headers_CNV_GERMLINE : headers_CNV_SOMATIC;
    const dataKeys_CNV = analysisType === 'germline' ? dataKeys_CNV_GERMLINE : dataKeys_CNV_SOMATIC;
    const headers_SNV = analysisType === 'germline' ? headers_SNV_GERMLINE : headers_SNV_SOMATIC;
    const dataKeys_SNV = analysisType === 'germline' ? dataKeys_SNV_GERMLINE : dataKeys_SNV_SOMATIC;

    const textDisclaimer = analysisType === 'germline' ?
        "**Based upon ACMG and ClinGen recommendations (Richards et al. 2015, Riggs et al. 2020 and ClinGen)" :
        "**Based upon ClinGen, CGC and VICC recommendations (Horak et al. 2022)";

    const TEXT_FOR_NEGATIVE_SNV = analysisType === 'germline' ? TEXT_FOR_NEGATIVE_SNV_GERMLINE : TEXT_FOR_NEGATIVE_SNV_SOMATIC;

    const TEXT_FOR_NEGATIVE_CNV = analysisType === 'germline' ? TEXT_FOR_NEGATIVE_CNV_GERMLINE : TEXT_FOR_NEGATIVE_CNV_SOMATIC;



    return [
        
        new Paragraph({
            children: [
                new TextRun({
                    text: renderToStringAlt("GENETIC FINDINGS"),
                    size: 11*2,
                    underline: {
                        type: 'single',
                        color: '000000',
                    },
                    bold: true,
                }),
            ],            
        }),
        new Paragraph({
            text: "",
        }),
        new Paragraph({
            children: [
                new TextRun({
                    text: renderToStringAlt("SNV and InDels"),
                    size: 11*2,
                    bold: true,
                }),
            ],
        }),
        drawGeneticFindingsTable(snv_data, headers_SNV, dataKeys_SNV, renderToStringAlt(TEXT_FOR_NEGATIVE_SNV.text)),
        ...(snv_data.length > 0 ? [
        new Paragraph({
            children: [
                new TextRun({
                    text: renderToStringAlt("*Based upon reference genome version GRCh38"),
                    size: 8*2,
                    color: "#757575"
                }),
            ],
        }),
        new Paragraph({
            children: [
                new TextRun({
                    text: renderToStringAlt(textDisclaimer),
                    size: 8*2,
                    color: "#757575"
                }),
            ],
        }),
        ] : []),
        new Paragraph({
            text: "",
        }),
        new Paragraph({
            children: [
                new TextRun({

                    text: renderToStringAlt("CNVs and SVs"),
                    size: 11*2,
                    bold: true,
                }),
            ],
        }),
        drawGeneticFindingsTable(cnv_data, headers_CNV, dataKeys_CNV, renderToStringAlt(TEXT_FOR_NEGATIVE_CNV.text)),
        ...(cnv_data.length > 0 ? [
        new Paragraph({
            children: [
                new TextRun({
                    text: renderToStringAlt("*Based upon reference genome version GRCh38."),
                    size: 8*2,
                    color: "#757575"
                }),
            ],
            
        }),
        new Paragraph({
            children: [
                new TextRun({
                    text: renderToStringAlt(textDisclaimer),
                    size: 8*2,
                    color: "#757575"
                }),
            ],
        }),
        new Paragraph({
            children: [
                new TextRun({
                    text: renderToStringAlt("***This column includes a range of genes. Because of the size of it, the platform can’t display them all in the exported report but you can review them in the Genetic Findings view."),
                    size: 8*2,
                    color: "#757575"
                }),
            ],
            
        }),
        ] : []),
        createSpacer(50, 50),

    ];


}


export default WriteGeneticFindingsSection;