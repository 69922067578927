



export const SELECTED_VARIANT = "selectedVariant";
export const SET_SELECTED_TABLE_ROWS = "setSelectedTableRows";

export const RESET_SELECTED_TABLE_ROWS = "resetSelectedTableRows";

export const RESET_ROW = "resetRow"

export function selectedVariant(variant) {
    console.log("set selected variant")
    return {
        type: SELECTED_VARIANT,
        variant: variant }
}

export function setSelectedTableRows(data) {

    return {
        type: SET_SELECTED_TABLE_ROWS,
        data: data,
    }
}

export function resetSelectedTableRows() {

    return {
        type: RESET_SELECTED_TABLE_ROWS,

    }
}

export function resetRow(data_type) {

    return {
        type: RESET_ROW,
        data_type: data_type

    }
}