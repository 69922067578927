import React, { useEffect } from 'react';
import { withStyles } from '@mui/styles'; // Updated to @mui/styles
import Dialog from '@mui/material/Dialog'; // Updated to @mui/material
import MuiDialogContent from '@mui/material/DialogContent'; // Updated to @mui/material
import MuiDialogActions from '@mui/material/DialogActions'; // Updated to @mui/material
import DialogTitle from '@mui/material/DialogTitle'; // Updated to @mui/material
import DialogAnalysisContent from "./DialogAnalysisContent";
import { bindActionCreators } from "redux";
import * as StudyActions from "../../../../../../../reducers/study_actions";
import * as SidebarActions from "../../../../../../../../../components/SideNavigation/sidebar_actions";
import { connect } from "react-redux";
import MappedText from '../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText';


const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function AddAnalysisDialog(props) {


    const [open, setOpen] = React.useState(props.open);

    useEffect(function(){

        if(props.open!== open){
            setOpen(props.open)
        }


    }, [props.open])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (type) => {
        setOpen(false);
        props.setClose(false);
    };


    return (
        <React.Fragment>
            <Dialog maxWidth="lg" onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <MappedText text="Create New Analysis"/>
                </DialogTitle>
                <DialogContent dividers>
                    <DialogAnalysisContent
                        handleClose = {handleClose} queryRunner = {props.queryRunner} instandGeneticFindingsTab={props.instandGeneticFindingsTab}
                    />
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    studyActions: bindActionCreators(StudyActions, dispatch),
    sidebarActions: bindActionCreators(SidebarActions, dispatch)
});



export default connect(null, mapDispatchToProps)(AddAnalysisDialog);