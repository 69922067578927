
import React from "react";



const getDisgenetTooltip  = () =>  {


    return     <div><span>
        Browse DisGeNET to explore the level of evidence connecting genes, variants, and diseases to generate a panel based on your patient phenotypes
        <br/> <a style={{color: "orange"}} href="http://disgenet.org/" target="_blank">http://disgenet.org/</a></span></div>


}

const getHPOTooltip = () =>{

    return <div><span>Browse the HPO ontology to generate a virtual gene panel based on your patient clinical signs<br/> <a style={{color: "orange"}}  href="https://hpo.jax.org/app/" target="_blank">https://hpo.jax.org/app/</a></span></div>

}


const TextSection = (patient_bool) => {

    if(!patient_bool){
        return "First, extract or search for HPO terms below. When you made a selection, collect the associated genes with the HPO ontology or Disgenet"
    }
    else{
        return "Search for HPO terms below. When you made a selection, collect the associated genes with the HPO ontology or Disgenet"

    }


}




export {getDisgenetTooltip, getHPOTooltip, TextSection}