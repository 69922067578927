import React from "react";
import Typography from "@mui/material/Typography";
import {CircularProgress} from "@mui/material";


export const LoadingComponent = () => {

    return <React.Fragment>
        <div style={{float: "left", width: "100%"}}>
            <Typography variant="h6"
                        style={{
                            flex: '1 1 100%',
                            color: "rgb(69, 117, 180)"
                        }}
                        component="div"> <CircularProgress size={"14px"}/> Loading Participant Data ...
            </Typography>
        </div>
    </React.Fragment>

}
