




export const getExperimentsViewBody= (distinctValues) => {

    const PAGE_SIZE = 30000

    return {
        "page": 1, "fields": ["Participant_ID", "ExperimentID", "LocalExperimentID", "project", "erns"],
        "pageSize": PAGE_SIZE, "sorted": [],
        "filtered": [{"id": "ExperimentID", "value": distinctValues} ],
        "aggregates": ["project", "tissue", "erns"]
    };


}

export const getParticipantBySampleBody = (participants) => {

    const PAGE_SIZE = 1000;

    return {
        "page": 1,
        "pageSize": PAGE_SIZE,
        "sorted": [],
        "filtered": [{"id": "report_id", "value": participants}],
        "aggrKeys": [
            "sex",
            "affectedStatus",
            "solved",
            "index",
            "consanguinity"
        ]
    };



}
