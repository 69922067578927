import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import DataField from '../../dir_overview/Datafield';
import MappedText from '../../reports/create-report/shared-components/mapText';

const ExperimentData = ({ dmExperimentInfo, dmSampleInfo, sampleType }) => {
    if (dmExperimentInfo === undefined) return null;

    return(        
        <Accordion defaultExpanded> 
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                <Typography variant={"h6"}> {sampleType} <MappedText text={"Experiment and Sample Info"}/> </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{backgroundColor: "#FAFAFA"}}>
                {/*First Row Experiment Metadata*/}
                <Grid item container>
                    <Grid container sx={{ mt: 2, mb: 2 }}> 
                        <Grid item xs={12} sm={6} md={2}>
                            <DataField title="Experiment ID" value={dmExperimentInfo?.ExperimentID} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <DataField title="Submitter ID" value={dmExperimentInfo?.Submitter_ID} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <DataField title="Library Strategy" value={dmExperimentInfo?.library_strategy} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <DataField title="Kit" value={dmExperimentInfo?.kit} />
                        </Grid>                                                                                   
                    </Grid>
                </Grid>        
                {/*Second Row Sample Fields*/}
                {dmSampleInfo && (
                    <>
                        <Grid item container sx={{ mt: 2, mb: 2 }}>
                            <Grid item xs={12} sm={6} md={2}>
                                <DataField title="Sample ID" value={dmSampleInfo?.sampleID} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={2} >
                                <DataField title="Sample tissue of origin" value={dmSampleInfo?.sample_tissue_of_origin} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={8}>
                                <DataField title="Sample tissue of origin status" value={dmSampleInfo?.sample_tissue_of_origin_status} />
                            </Grid>
                        </Grid>
                        <Grid item container sx={{ mt: 2, mb: 2 }}>
                            <Grid item xs={12} sm={6} md={2}>
                                <DataField title="Sample type" value={dmSampleInfo?.sample_type} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={2}>
                                <DataField title="Percentage of tumoral cells" value={dmSampleInfo?.percentage_of_tumoral_cells + "%"} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={8}>
                                <DataField title="Fixative" value={dmSampleInfo?.fixative} />
                            </Grid>
                        </Grid> 
                    </>    
                )}              
            </AccordionDetails>            
        </Accordion>
    );
}

export default ExperimentData;
