import {makeStyles} from "@mui/styles";


const cardStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        fontWeight: "400",
        justifyContent: 'start',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));

export default cardStyles;