import React, { useState } from 'react';
import { connect } from "react-redux";

// Material-UI components
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

// Icons
import CloseIcon from '@mui/icons-material/Close';

// Services
import { exomiser_query, get_exomiser_results, getHPO_terms } from "../../../../../../../../../../services/api/get";
import auth from "../../../../../../../../../../services/session/Auth";

// Components and styles
import GeneralAutoComplete from "../../../../../side-panels/filter-panel/components/filter-sections/gene-section/components/GeneralAutoComplete";
import { handleResponse } from "../../../../../side-panels/filter-panel/components/filter-sections/gene-section/components/async-selection/handlers";
import { GPAP_Button_Light } from "../../../../../../../../../../gpap-lib/components/Button_Collection";

// Custom styles
import "./exomiser.css";
import {makeStyles} from "@mui/styles";


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap'
    },
    appBar: {
      //position: 'absolute',
    },
    loading:{
      padding: '0px 200px'
    },
    list:{
        position: 'relative',
        overflow: 'auto',
        maxHeight: 200
    },
    title: {
      fontSize: 14,
    },
  }));


function TransitionRight(props) {
    return <Slide {...props} direction="right" />;
}
  
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

//Inheritance stuff
const inh_dominant = {"HP:0000006":"Autosomal dominant", "HP:0001444":"Autosomal dominant somatic cell mutation", "HP:0003745":"Sporadic", "HP:0001470":"Sex-limited autosomal dominant"};
const inh_recessive = {"HP:0000007":"Autosomal recessive"};
const inh_x = {"HP:0001417":"X-linked","HP:0001419":"X-linked recessive","HP:0001423":"X-linked dominant"};

//Generate vcf file
function saveVCFfile(data, sample){

  //separators
  let token_separator_field = "\t";
  let token_separator_line = '\n';
  let token_emtpy_string = '.';
  let header_line = '##fileformat=VCFv4.1\n';

  //info of tab lines
  let main_line_string = "";
  let full_line_string = "";

  //info affected sample
  let affected_sample_is_first = true;
  let affected_sample_index = 0;

  //looking for the affected sample, in case of some it takes the first.
  /*scope.data.inheritance.forEach(function(x,i){if (x.index && affected_sample_is_first) {
      affected_sample_name = x.sample_id;
      affected_sample_is_first = false;
  }});*/

  let affected_sample_name = sample;

  //init column titles
  let columns_header = "#CHROM" + token_separator_field + "POS" + token_separator_field + "ID" + token_separator_field + "REF" + token_separator_field + "ALT" + token_separator_field +  "QUAL" + token_separator_field + "FILTER"
  + token_separator_field + "INFO" + token_separator_field + "FORMAT" + token_separator_field + affected_sample_name + token_separator_line;
  //fi column titles

  for (var i = 0; i < data.length; i++) {

      for (var j = 0; j < data[i].samples_germline.length; j++) {
        if ((data[i].samples_germline[j].sample === affected_sample_name) && (affected_sample_is_first)){ 
          affected_sample_index = j;
          affected_sample_is_first = false
        } 
      }

      //If ID column is empty,replace it with a "."
      if (data[i].rs === undefined){
          data[i].rs = "."
      }

      if (data[i].samples_germline[affected_sample_index].gt  === undefined){
        data[i].samples_germline[affected_sample_index].gt = "."
      }

      if (data[i].samples_germline[affected_sample_index].gq  === undefined){
        data[i].samples_germline[affected_sample_index].gq = "."
      }

      if (data[i].samples_germline[affected_sample_index].dp  === undefined){
        data[i].samples_germline[affected_sample_index].dp = "."
      }


      //main table
      main_line_string = data[i].chrom + token_separator_field + data[i].pos + token_separator_field + data[i].rs + token_separator_field + data[i].ref + token_separator_field
      + data[i].alt + token_separator_field + token_emtpy_string + token_separator_field + token_emtpy_string + token_separator_field + token_emtpy_string + token_separator_field + "GT:GQ:DP" + token_separator_field + data[i].samples_germline[affected_sample_index].gt + ":" + data[i].samples_germline[affected_sample_index].gq + ":" + data[i].samples_germline[affected_sample_index].dp;

      full_line_string = full_line_string + (main_line_string + token_separator_line);
  }

  //generating vcf blob
  let vcf_blob = new Blob([header_line + columns_header + full_line_string], {type: "octet/stream"});

  return vcf_blob;
}


function ExomiserDialog(props) {

  const classes = useStyles();

  const [open, setOpen] = React.useState(true);
  const [resultsOpen, setResultsOpen] = React.useState(false);
  const [resultsLoading, setResultsLoading] = useState(false);
  const [exoResults, setExoResults] = useState(false);
  const [exoFile, setExoFile] = useState("");
  const [HTMLReport, setHTMLReport] = useState("");

  const [transition, setTransition] = useState(undefined);
  const [snack_open, snack_setOpen] = useState(false);
  const [vertical, setVertical] = useState('top');
  const [horizontal, setHorizontal] = useState('center');
  const [errorMsg, setErrorMsg] = useState(null);

  
  //Exomiser endpoint
  let {api_exomiser} = config.apis_configuration;

  //Get clinical data from props
    //


    let PATIENT= props.patients.patients[0];

  let hpos = (PATIENT !== undefined && PATIENT.features !== undefined) ? PATIENT.features.map(function(s) {
      return { id: s.id, label: s.name };
  }) : [];
  
  let inheritance = (PATIENT !== undefined && PATIENT.inheritance) ? PATIENT.inheritance : "";

  let sampleCase = props.samples.samples[0];

  //Get sample name
  let sample = (sampleCase!== undefined) ? sampleCase.sample_id : "";

  //Get variants
  let variants = props.variants;

  //Map inheritance. Get first? Does not matter since Exomiser uses all the inheritances
  //let inheritance_ids = inheritance.map(key => key.id)
  let state_inheritance = "UNDEFINED"

  if (inheritance.length > 0) {
      let first_inher = inheritance[0].id
      if  (first_inher in inh_dominant)
        state_inheritance = "AUTOSOMAL_DOMINANT"

      else if (first_inher in inh_recessive)
      state_inheritance = "AUTOSOMAL_RECESSIVE"

      else if (first_inher in inh_x)
      state_inheritance = "X_RECESSIVE"

      else
       state_inheritance = "UNDEFINED"
  }

  const [currentHPOs, setCurrentHPOs] = React.useState(hpos);
  const [currentInheritance, setCurrentInheritance] = React.useState(state_inheritance);
  const [currentPrioritiser, setCurrentPrioritiser] = React.useState('PHENIX_PRIORITY');


  const [hpo_search, setHPOs] = useState([]);
  const [hpo_list, setFullList] = useState([]);
  const [selected, setSelected] = useState([]);


  // This is componentWillUnmount
  /*useEffect(() => {
    // Update the document title using the browser API
    //setOpen(true);
    console.log("paa")
  });*/


   //Api calls errors
   function handleErrors(response) {
      if (!response.ok) {
          setResultsLoading(false);
          setErrorMsg("An error occured")
          //throw Error(response.statusText);
      }
      return response;
    }


  const handleInheritanceChange = (event) => {
    setCurrentInheritance(event.target.value);
  };

  const handlePrioritiserChange = (event) => {
    setCurrentPrioritiser(event.target.value);
  };

  const handleAddHPO = (hpo) => {
    if (hpo.length > 0 ) {

      let current_ids = currentHPOs.map(s => s.id);

      hpo.forEach(function(el){
          // if not in the list, includes...
          if ((el!==undefined) && (el.refCode!==undefined) && (!current_ids.includes(el.refCode))){
              let newElement = {"id": el.refCode, "label": el.value, "type": "phenotype", "observed": "yes", "severity": []};
              setCurrentHPOs(currentHPOs => [...currentHPOs, newElement]);
          }

      })


    }
  };


  const handleClickOpen = () => {
    setErrorMsg(null);
    setExoResults(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    snack_setOpen(false);
  };


  //Exomiser request
  const handleSubmit = () => {
    
    //Variants limit
    let threshold = 999;

    setErrorMsg(null)

    if (variants.length > threshold ){
      setTransition(() => TransitionRight);
       snack_setOpen(true);
       setOpen(false);
    }

    else{
    setResultsLoading(true)
    setExoResults(false);
    //setErrorMsg(null)

    let _date = new Date();
    let _time = _date.getTime();
    let _user_name = auth.decoded().preferred_username

    //File name with time & user
    let _file_pref =  _user_name + "_" + "_" + _time;
    setExoFile(_file_pref);

    let json_data = JSON.stringify({"sample":sample, "features":currentHPOs, "prioritiser":currentPrioritiser, "inheritance":currentInheritance})
    let vcf_data = saveVCFfile(variants,sample);

    let _json_file  = new File([json_data], _file_pref + ".json");
    let _vcf_file  = new File([vcf_data],   _file_pref + ".vcf");

    //Formdata object where all the files that will be sent are appended
    let formData = new FormData();

    formData.append('vcf_file', _vcf_file);
    formData.append('json_file', _json_file);

    exomiser_query(api_exomiser, props.token, formData)
    .then(handleErrors)
    .then(resp => {
      if(resp.ok){
        setExoResults(true)
      }
      setResultsLoading(false)
     })
   }
  };

  const handleResultsOpen = () => {
      get_exomiser_results(api_exomiser, props.token, exoFile)
    .then(handleErrors)
    .then(resp => resp.text())
    .then(text => {
      if(text){
        setHTMLReport(text);
        setResultsOpen(true);
    }
    })

};

const handleResultsClose = () => {
  setResultsOpen(false);
};


const getResults = (s) => {
    if(s!== ""){
        getHPO_terms(config.apis_configuration.api_phenostore, props.token, s).
           then(r => r.json()).
           then(json => {
            const resp = handleResponse("HPO", json);
            setHPOs(resp);
            setFullList(json.terms);
        })
    }

};

const passSelected = (obj, reason) => {

    // get the hpo item and add it;
    //let hpo_to_add_array = obj.map(function(selected){ return hpo_list.find(s => s.id === selected.refCode);});
    //handleAddHPO(hpo_to_add_array);

    handleAddHPO(obj);

    if(reason === "clear"){
        setSelected([])
        setCurrentHPOs([])
    }
    else{
        setSelected(obj)
    }
    
    //When removing an HPO 
    if(reason === "remove-option"){
      setCurrentHPOs(obj)
    }

};


  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" onClose={handleClose}>
         <Box display="flex" alignItems="center">
                <Box flexGrow={1} >  Exomiser: Prioritise variants based on variant pathogenicity and clinical information.</Box>
                <Box>
                    <IconButton onClick={handleClose}>
                          <CloseIcon />
                    </IconButton>
                </Box>
          </Box>

        </DialogTitle>

        <DialogContent>

          <Box p={1}>
              <Typography variant={"body1"}>
                  The Exomiser plugin is able to prioritise variants from whole-exome (<a href={"https://hpo.jax.org/app/tools/exomiser"} target={"_blank"}>Exomiser</a>) or whole-genome sequencing data (<a href={"https://hpo.jax.org/app/tools/genomiser"} target={"_blank"}>Genomiser</a>) based on clinical information.
                  <br/>
              </Typography>
              <Typography variant={"subtitle1"}>***For GPAP performance reasons, Exomiser can only be run with 1000 variants or less.
              </Typography>
          </Box>

          <Typography className={classes.title} color="textSecondary" gutterBottom>
           HPO term(s)
           </Typography>

                    {currentHPOs.length === 0 &&
                    <Typography variant="h6" component="h2">
                        None
                    </Typography> }


                  <Grid item lg={12} id="async_hpo">
                      <GeneralAutoComplete
                          //noOptionsText={"Start your search..."}
                          results = {hpo_search}
                          onInputChange = {getResults}
                          title={"Search for HPO terms"}
                          passSelected = {passSelected}
                          default_tags = {currentHPOs}
                      />

                  </Grid>

                  <br></br>

                <Grid item xs={12}>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                   Inheritance model
                  </Typography>

                  <FormControl style= {{marginTop:''}} component="fieldset">
                   <Select
                    labelId="demo-simple-select-label2"
                    id="demo-simple-select2"
                    value={currentInheritance}
                    onChange={handleInheritanceChange}
                   >
                   <MenuItem value="UNDEFINED">None</MenuItem>
                   <MenuItem value="AUTOSOMAL_DOMINANT">Autosomal dominant</MenuItem>
                   <MenuItem value="AUTOSOMAL_RECESSIVE">Autosomal recessive</MenuItem>
                   <MenuItem value="X_RECESSIVE">X-linked</MenuItem>
                  </Select>
                 </FormControl>
                </Grid>

              <br></br>

              <Grid item xs={12}>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
               Choose Prioritiser
              </Typography>

              <FormControl style= {{marginTop:''}} component="fieldset">
               <Select
               labelId="demo-simple-select-label"
               id="demo-simple-select"
               value={currentPrioritiser}
               onChange={handlePrioritiserChange}
              >
              <MenuItem value="PHENIX_PRIORITY">PhenIX (compare phenotypes against human only)</MenuItem>
              <MenuItem value="PHIVE_PRIORITY">PHIVE (compare phenotypes against mouse only)</MenuItem>
              <MenuItem value="HI_PHIVE_PRIORITY">hiPHIVE (compare phenotypes against all species)</MenuItem>
              </Select>
             </FormControl>
             </Grid>

             <br></br>

             {resultsLoading &&
                <div className={classes.loading}>
                  <CircularProgress  />
                </div>
             }

             {errorMsg &&
              <Typography variant="h4" style={{padding:"0px 200px"}}>
               {errorMsg}
             </Typography> 
             }

        </DialogContent>

        <DialogActions>
            <GPAP_Button_Light onClick={handleClose} autoFocus>
                Close
            </GPAP_Button_Light>
          <Button onClick={handleSubmit} variant="contained" color="primary" autoFocus>
            Submit
          </Button>

          {exoResults &&
            <Button onClick={handleResultsOpen} variant="contained" color="secondary" autoFocus>
               Results
            </Button>
          }
        </DialogActions>
      </Dialog>


      <Dialog fullScreen open={resultsOpen} onClose={handleResultsClose} TransitionComponent={Transition}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Button edge="start" color="inherit" onClick={handleResultsClose} aria-label="close" startIcon={    <CloseIcon />}>
                Go Back to Variant Results
            </Button>
            <Typography variant="h6">
              Exomiser results
            </Typography>
          </Toolbar>
        </AppBar>

        <div className="container" dangerouslySetInnerHTML={{__html: HTMLReport}}></div>
      </Dialog>


      { (snack_open)  &&
                <Snackbar open={snack_open}
                          TransitionComponent={transition}
                          autoHideDuration={6000}
                          anchorOrigin={{ vertical, horizontal }}
                          onClose={handleSnackBarClose}>
                    <Alert  severity="warning">
                    For performance reasons, Exomiser can only be run with 1000 variants or less.
                    </Alert>
                </Snackbar>
      }
        </React.Fragment>
  );
}

function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {token: state.authorization.token, patients: state.patients, samples: state.sample_list}
    }
  }


export default connect(mapStateToProps)(ExomiserDialog);
