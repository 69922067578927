
import React, { Component } from "react";
import {Checkbox, FormControlLabel} from "@mui/material";






class MyCheckbox extends Component {


    constructor(props){
        super(props);

        // set initial state;
        const { checked } = props;
        this.state = {
            bool: checked
        };

        this.handleChange = this.handleChange.bind(this);


    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevState.bool!== this.state.bool){
            this.setState({
                bool: this.state.bool
            });


        }

    }

    handleChange(event){

        if(!this.props.readOnly){
            this.props.updateSample({key: this.props.val, val: event.target.checked});
            this.setState({
                bool: event.target.checked
            })
        }


    }


    render() {

        let props = this.props;
        let checked;
         if(this.props.readOnly){
             checked = props.checked;
         }
         else{
             checked= this.state.bool;
             
             //For the case of custom inh where readOnly is false
             if (this.props.custom_inher_gt)
                checked = props.checked
         }


     return ( <FormControlLabel
             control={
                 <Checkbox
                     checked={checked}
                     disabled={this.props.readOnly && !checked}
                     onChange = { this.handleChange}
                     color="primary"
                     value={props.val}
                 />
             }
             label={props.cbkey}
         />

     );

 }


}

export default MyCheckbox