import React, {useEffect, useState} from "react";
import {connect} from "react-redux";


import GPAP_InfoIcon from "../../../gpap-lib/components/GPAP_InfoIcon";
import ErrorIcon from '@mui/icons-material/Error';
import {CircularProgress} from "@mui/material";
import Typography from "@mui/material/Typography";
import {LoadingComponent, loadingIcon} from "./LoadingComponent";


// Reusable InfoBox component
const InfoBox = ({ icon, message }) => (
    <div style={{ float: "left", width: "95%" }}>
        <Typography
            variant="h6"
            style={{
                flex: '1 1 100%',
                color: "rgb(69, 117, 180)",
            }}
            component="div"
        >
            {icon} {message}
        </Typography>
    </div>
);

function ParticipantHeader(props){

    let {rowsL, total} = props;


    if(rowsL!== undefined){

        let s = `${rowsL} participant${rowsL !== 1 ? 's' : ''} with selected variant(s)`;


        if(props.loadingParticipants){
            // show loading
            return <LoadingComponent/>

        }
        else if (total === null) {
            return (
                <>
                    <InfoBox
                        icon={<ErrorIcon color="danger" />}
                        message="Error fetching participants"
                    />
                </>
            );
        } else {
            return (
                <>
                    <InfoBox
                        icon={null}
                        message={s}
                    />
                    <div>
                        <GPAP_InfoIcon title="For technical limitations of our servers, we only display the first 1000 participants for a query. Please narrow your search if needed." />
                    </div>
                </>
            );
        }
    }


}


//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.patients.length!==0)
    {
        return {
            token: state.authorization.token
        }
    }
    else{
        return {patients: [], token: state.authorization.token}
    }
}




export default connect(mapStateToProps,null)(ParticipantHeader);