import {TabStopType,TabStopPosition , Paragraph, TextRun, HeadingLevel, AlignmentType, Table, TableRow, TableCell, WidthType, VerticalAlign, ShadingType, BorderStyle } from "docx";
import { renderToStringAlt } from "../../../create-report/shared-components/renderToString";


export const createKeyValueParagraph = (key, value) => {
  const localizedKey = renderToStringAlt(key);

  return new Paragraph({
    children: [
      new TextRun({
        text: `${localizedKey}: `,
        bold: true,
      }),
      new TextRun({
        text: String(value), 
      }),
    ],
    tabStops: [
      {
        type: TabStopType.RIGHT,
        position: TabStopPosition.MAX, 
      },
    ],
  });
};

export const createBoldUnderlinedTextRun = (text) => {
  const localizedText = renderToStringAlt(text);
  return new TextRun({ text: localizedText, bold: true, underline: true });
};

export const createSpacer = (spaceBefore = 200, spaceAfter = 200) => {
  return new Paragraph({
    children: [
      new TextRun({
        text: '', 
      }),
    ],
    spacing: {
      before: spaceBefore, 
      after: spaceAfter,   
    },
  });
};

export const createBoldUnderlinedParagraph = (text) => {
  return new Paragraph({
    children: [createBoldUnderlinedTextRun(text)],
    spacing: {
      before: 200, 
      after: 200,   
    },
  });
};
export const createKeyValueTable = (rows) => {
  return new Table({
    rows: rows,
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
  });
};



export const createBoldTextRun = (text) => {
  const localizedText = renderToStringAlt(text);
  return new TextRun({ text: localizedText, bold: true });
};

export const createParagraphWithSpacing = (text, bold = false, spaceAfter = 120) => {
  const localizedText = renderToStringAlt(text);
  return new Paragraph({
    children: bold ? [createBoldTextRun(localizedText)] : [new TextRun({ text: localizedText })],
    spacing: { after: spaceAfter },
  });
};

export const createTitle = (title) => {
  return new Paragraph({
    children: [
      new TextRun({

        text: renderToStringAlt(title),
        bold: true,
        color: "000000", 
      })
    ],
    heading: HeadingLevel.HEADING_3,
  });
};
export const createSubTitle = (title) => {
  return new Paragraph({
    children: [
      new TextRun({
        text: renderToStringAlt(title),
        bold: true,
        size: 22 
      })
    ],
  });
};

export const createCaption = (title) => {
  return new Paragraph({
      children: [
          new TextRun({
              text: renderToStringAlt(title),
              font: "Times New Roman",
              size: 16,
              bold: false,
              color: "757575",
          }),
      ],
  });
};

export const createHeadTitle = (title) => {
  return new Paragraph({
    children: [
      new TextRun({
        text: renderToStringAlt(title),
        bold: true,
        color: "000000",
      })
    ],
    heading: HeadingLevel.HEADING_1,
  });
};

export const createCenteredTitle = (title) => {
  return new Paragraph({
    text: renderToStringAlt(title),
    font: "Roboto",
    heading: HeadingLevel.HEADING_1,
    alignment: AlignmentType.CENTER,
    color: "000000",
    size: 24,
  });
};

export const createTwoColumnRow = (label1, content1, label2, content2) => {
  return new TableRow({
    children: [
      new TableCell({
        children: [
          new Paragraph({
            children: [new TextRun({ font: "Roboto", text: `${label1}: `, bold: true }), new TextRun(content1)],
            spacing: { after: 120 },
          }),
        ],
        verticalAlign: VerticalAlign.CENTER,
        width: { size: 50, type: WidthType.PERCENTAGE },
      }),
      new TableCell({
        children: [
          new Paragraph({
            children: [new TextRun({ font: "Roboto", text: `${label2}: `, bold: true }), new TextRun(content2)],
            spacing: { after: 120 },
          }),
        ],
        verticalAlign: VerticalAlign.CENTER,
        width: { size: 50, type: WidthType.PERCENTAGE },
      }),
    ],
  });
};

export const createInfoAlert = (text) => {
  return new Paragraph({
    children: [
      new TextRun({
        text: renderToStringAlt(text),
        color: "0000FF", 
        bold: true
      })
    ],
    spacing: {
      before: 200,
      after: 200
    }
  });
};

export const createItalicsParagraph = (key, value) => {
  const localizedKey = renderToStringAlt(key);

  return new Paragraph({
    children: [
      new TextRun({
        text: `${localizedKey}: `,
        bold: true,
      }),
      new TextRun({
        text: String(value),
        italics: true,
        color: "888888" 
      }),
    ],
    tabStops: [
      {
        type: TabStopType.RIGHT,
        position: TabStopPosition.MAX,
      },
    ],
  });
};
export const createSingleRow = (label, content) => {
  return new TableRow({
    children: [
      new TableCell({
        children: [
          new Paragraph({
            children: [new TextRun({ font: "Roboto", text: label, bold: true })],
            spacing: {
              after: 200,
            },
          }),
        ],
        shading: {
          type: ShadingType.CLEAR,
          fill: "ffffff",
        },
        borders: {
          bottom: {
            color: "000000",
            space: 1,
            style: BorderStyle.SINGLE,
            size: 6,
          },
        },
      }),
      new TableCell({
        children: [
          new Paragraph({
            children: [new TextRun({ font: "Roboto", text: content })],
            spacing: {
              after: 200,
            },
          }),
        ],
        borders: {
          bottom: {
            color: "000000",
            space: 1,
            style: BorderStyle.SINGLE,
            size: 6,
          },
        },
      }),
    ],
  });
};
