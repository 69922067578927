

import React, { useState } from "react";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as StudyActions from "../../../../reducers/study_actions";
import * as Actions from "../../../../../../actions";

// Material-UI
import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import { orange } from '@mui/material/colors';

// Services
import { delete_query_db } from "../../../../../../services/api/manage_study";
import { getCurrentAnalysis } from "./components/variant-table/getAnnotations";

// Components
import GPAP_Tooltip from "../../../../../../gpap-lib/components/GPAP_Tooltip";
import GPAP_Orange_Tooltip from "../../../../../../gpap-lib/components/GPAP_Orange_Tooltip";
import MappedText from "../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import {CustomQueryTabs, QueryTab} from "./CustomQueryTabs";



function QueryTabs(props){

    const [queryID, setQueryId] = useState(false);
    const [differentResultWarning, setdifferentResultWarning] = useState(false);


    const handleDelete = (query) => {

        let analysis = getCurrentAnalysis(props.studySettings, props.studyBucket);



        if(analysis!== undefined){

            if(analysis.analysis.queries.length<2){
                alert("You can not delete the only query in the analysis. An analysis must always have at least a query ");
            }
            else{
                let study_id = analysis.analysis.studyLocalId;
                if("query_id" in query){
                    if (window.confirm('This action will delete the query from the DB and this analysis permanently?'))
                    {
                        // TODO: add some checks if whether the function worked or not;
                        props.studyActions.delete_query(query, study_id);
                        delete_query( config.apis_configuration.api_nextgpap_server, props.token, query )
                            .then(r => r.json())
                            .then(json => {

                            })
                    }
                }
                else{
                    if (window.confirm('This action will delete the query from this session and from this analysis permanently?')) {
                        props.studyActions.delete_query(query, study_id);
                    }
                }
            }

        }



    }

    const renderQueriesTabs = (text1,number, text2, query) => {

        return <QueryTab
            key={text1}
            label={
            <div style={{padding: "8px", textTransform: "capitalize"}}>
                <GPAP_Tooltip title={<MappedText text={"The initial count of SNVs identified during the first execution of this query."}/>}>
                    <div style={{float: "left"}}>
                 {getLabel(text1, number)}
            </div>
                </GPAP_Tooltip>

          <div style={{float: "right"}}>
                <IconButton style={{padding: "0px"}}
                            size="small"
                            onClick={() => { handleDelete(query) }}>
                      <CloseIcon />
                    </IconButton>

          </div>
                  </div>

        } />

    };

    const getQueryIndex = () => {

        let analysis = getCurrentAnalysis(props.studySettings, props.studyBucket);

        if(analysis.analysis !== undefined && analysis.analysis && analysis.analysis.queries.length!== 0){

                // current analysis
                let query_id = (props.studySettings.current_query!== undefined) ? props.studySettings.current_query : queryID;
                if(query_id!== null){

                    return analysis.analysis.queries.map(function (e) {
                        return e.localId;
                    }).indexOf(query_id);
                }
                else{
                    return 0;
                }
            }
        else{
            return 0
        }
    };

    const getLabel = (text, number) => {

        if(number === -1){
            return <span>{text}
                <GPAP_Orange_Tooltip placement="top" style={{ backgroundColor: orange[500] }} title={<MappedText text={"This query did not return any variants"}/>}>
                    <ErrorIcon fontSize={"small"} style={{ color: orange[500] }}/>
                </GPAP_Orange_Tooltip>
            </span>
        }
        else{

            let query_name;
            let type = props.studySettings.analysisType !== "family_trio" && props.studySettings.analysisType !== "instand";

            if(type){
                query_name = text;
            }
            else{
            // if familyAnalysis case or instand
                query_name = text + "( " + number + " )";
            }

            return query_name
        }

    }

    const renderTabs = () => {

        let analysis = getCurrentAnalysis(props.studySettings, props.studyBucket);

        if(analysis !== undefined && analysis.analysis){

            return analysis.analysis.queries.map(query => {
                let last_output = query.outputs[query.outputs.length-1];

               /* let last_total= 0;
                if (props.total!== undefined) {
                    last_total=props.total 
                }
                else{
                last_total=last_output.n_variants;
                }
                if ((props.total !== last_output.n_variants) && last_output.n_variants!== -1 && differentResultWarning === false) {
                    setdifferentResultWarning(true);
                    alert("For some reason the number of results from the query has changed. It might be that some of the filters has changed, if you think that the data has changed,please contact to the Platform responsibles");

                }*/


                return renderQueriesTabs(query.name,last_output.n_variants, 200, query )
            })
        }
        else{
            return null;
        }

    };

    const handleChange = (event, newValue) => {


        //
        let analysis = getCurrentAnalysis(props.studySettings, props.studyBucket);

        if(analysis!== undefined ){

            let query = analysis.analysis.queries[newValue];

            if(query!== undefined){
                // analyses id
                let query_id = query.localId;
                props.studyActions.setStudySettings({current_query: query_id});
                setQueryId(query_id);
                props.switchQuery(query_id);

            }

        }

    };

    return  <div style={{float: "left", width: "100%"}} >
        <CustomQueryTabs
            id={"QueryTabs"}
            value={getQueryIndex()}
            onChange={handleChange }
            indicatorColor="secondary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto">
            {renderTabs()}
        </CustomQueryTabs>
    </div>
}


function mapStateToProps (state) {
    if(state.studyBucket!==undefined)
    {
        return {
            token: state.authorization.token,
            studySettings: state.studySettings,
            studyBucket: state.studyBucket
        }
    }
    else{
        return {snv: []}
    }
}

// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
    studyActions: bindActionCreators(StudyActions, dispatch)
});







export default connect(mapStateToProps,mapDispatchToProps)(QueryTabs);
