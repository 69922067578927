import React, { useState } from "react";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import getCells from "./getCells";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { styled } from '@mui/system'; // Use styled instead of makeStyles
import ExperimentRow from "./ExperimentRow";
import {makeStyles} from "@mui/styles";


const useStyles = makeStyles((theme) => ({
    selected: {
        backgroundColor: "#deebf7 !important",
        "&:hover": {
            backgroundColor: "#deebf7 !important",
        },
    },
}));


function ParticipantRow(props){

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const {participant_id, isItemSelected, row, index, labelId} = props;
    const origin = window.location.origin;

    return (
        <React.Fragment>
            <TableRow
                hover
                onClick={(event) => props.handleClick(event, row[participant_id])}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={index}
                selected={isItemSelected}
                classes={{selected: classes.selected}}
            >
                <TableCell>
                    {(props.experiments.length >0)
                        ? <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }</IconButton>
                        : null
                    }
                </TableCell>
               {/* <TableCell padding="checkbox"  >
                    <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                    />
                </TableCell>*/}
                <TableCell component="th" id={labelId} scope="row" padding="none">
                    <Link color="primary" target="_blank" href={origin + "/phenostore/#/summary/" + row[participant_id]}>{row[participant_id]}</Link>
                </TableCell>
                {getCells(row)}
            </TableRow>
            <TableRow key={"experiments_" + index }>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Experiments ({ props.experiments.length})
                            </Typography>
                             <Table size="small" aria-label="experiments">
                                                            <TableHead>
                                                                <TableRow key={"experiments_header" + index}>
                                                                    <TableCell style={{fontWeight: "bold"}}>Experiment ID</TableCell>
                                                                    <TableCell style={{fontWeight: "bold"}}>ERNs</TableCell>
                                                                    <TableCell align="right" style={{fontWeight: "bold"}}>Processed</TableCell>
                                                                    <TableCell align="right" style={{fontWeight: "bold"}}>Kit</TableCell>
                                                                    <TableCell align="right" style={{fontWeight: "bold"}}>Library Strategy</TableCell>
                                                                    <TableCell align="right" style={{fontWeight: "bold"}}>Tissue</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {props.experiments.map((experimentRow) => (
                                                                    <ExperimentRow experimentRow={experimentRow}/>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}


export default ParticipantRow;