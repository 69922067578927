import React, { useEffect, useState } from 'react';

// MUI Components
import { CircularProgress, Box } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

// Custom Components and Utilities
import All_Filters_Accordion from "../../filter-accordion-panels/All_Filters_Accordion";
import { getCurrentQuery, query_difference } from "../../../../../results/components/query-applied-filters/query_difference";
import { default_query, query_sample } from "../../../../../../../../../../services/api/genomics_query_samples/query_sample";
import { GPAP_Button_Light } from "../../../../../../../../../../gpap-lib/components/Button_Collection";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as Actions from "../../../../../../../../../../actions";
import * as StudyActions from "../../../../../../../../reducers/study_actions";
import * as QueryActions from "../../../../../../../../../../actions";
import {makeStyles} from "@mui/styles";



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        margin: "1%",
        float: "left"
    },
    heading: {
        //fontSize: theme.typography.pxToRem(17),
        flexBasis: '33.33%',
        flexShrink: 0,
        fontWeight: "500"
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

function StartFiltersAccordion(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [pointers, setPointers] = useState({})

    const [reset, setReset] = useState(false);


    useEffect(function(){

        // get default
        let difference = {};
        let query1 = default_query;
        let query2 = getCurrentQuery(props.studies, props.studySettings);
        if(query2){
            difference = query_difference(query1, query2);

            setPointers(difference);
        }



    },[props.form])

    useEffect(function(){

        if(reset){
            setTimeout(()=> setReset(false), 500);
        }

    },[reset])

    const resetQuery = () => {


        let { actions } = props;
        let query_to_reset = {...query_sample};

        actions.updateQuery(query_to_reset);
        // reset;

        setReset(true)
    };


    return (
        <React.Fragment>
            <div style={{width: "100%", float: "left"}}>
                <GPAP_Button_Light
                    size={"small"}
                    style={{float:"right"}}
                    onClick={resetQuery}
                    startIcon={<ClearIcon/>}>Reset Variant Filters</GPAP_Button_Light></div>
        <div className={classes.root}>
            {
                (reset)
                ? <Box p={2}><CircularProgress/></Box>
                : <All_Filters_Accordion
                        default_filters={props.default_filters}
                        pointers={ pointers }
                        somatic = {props.somatic}/>
            }
        </div>
        </React.Fragment>
    );
}

function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {

        const somatic = state?.sample_list?.somatic_samples?.[0]?.experiment_somatic === "somatic" ? "somatic" : false;
        
        if(state.query !== undefined)
        {
            return { query: {... state.query},
                token: state.authorization.token,
                studies: state.studyBucket.studies,
                studySettings: state.studySettings,
                form: state.form,
                somatic:somatic 

            }
        }

    }
}

// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
    studyActions: bindActionCreators(StudyActions, dispatch),
    queryActions: bindActionCreators(QueryActions, dispatch)
});




export default connect(mapStateToProps,mapDispatchToProps)(StartFiltersAccordion);