import React, { useState } from "react";
import { connect } from "react-redux";

// API and Custom Imports
import { getHPO_terms } from "../../../../../../../../../../../../services/api/get";
import { DataList_View } from "../DataList_View";
import GeneralAutoComplete from "../GeneralAutoComplete";
import { handleResponse } from "../async-selection/handlers";
import { AlertInfo } from "../../../../../../../../../../../study-page/create-study/analysis-page/components/samples-section/search-by-id/SelectionInfo";
import {getDisgenetTooltip, getHPOTooltip, TextSection} from "./tooltips_conf";
import MappedText from "../../../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import CaButton from "../../../../../../../../../../../../gpap-lib/components/shared-components/CaButton";

// Custom Components
import {
    GPAP_Button,
    GPAP_Button_Light
} from "../../../../../../../../../../../../gpap-lib/components/Button_Collection";
import GPAP_Tooltip from "../../../../../../../../../../../../gpap-lib/components/GPAP_Tooltip";

// MUI v5 imports
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteForeverOutlined from "@mui/icons-material/DeleteForeverOutlined";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { Alert } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import NextButton from "./NextButton";


function formatHPOs (extracted_hpo, removeHPO) {

    return extracted_hpo.map(function(d, index){

        let background = ( index % 2 === 0 ) ? "#f0f0f0" : "white";

        return <div key={index} className={"fake_hpo_label"} style={{background: background}}>
            <div className={"hpo_text"}>{d.value}</div>
            <IconButton
                style={{float: "right", padding: "0px"}}
                onClick={()=> removeHPO(d.value)}
                tabIndex="-1" aria-label="Remove"><DeleteIcon/> </IconButton></div>
    });
}




function HPO_QueryBuilder(props){

    const [step, setStep] = useState("step1");
    const [value, setOperation] = useState("union");
    const [method, setMethod] = useState("");
    const [added_to_query, setAddToQuery] = useState(false);
    const [results, setResults] = useState([]);
    const [selected_values, setSelected] = useState([]);

    let {patientsBool, requestFailed, hpo_bool, extracted_hpo, geneList, searched_hpos} = props;


    const back_button = () => {

        return <Grid item>
            <Box p={1}>
            <GPAP_Button_Light onClick={()=> setStep("step1")} style={{float:"left"}} startIcon={<ArrowBackIcon/>}>
                <MappedText text={"Back"}/>
            </GPAP_Button_Light>
            </Box>
        </Grid>


    };

    const getButtonOperation = () =>{


        if(value ==="union"){
           return  <Box p={0}>
               <GPAP_Tooltip title={<MappedText text={"Intersect the genes extracted from the HPOs "}/>}>
                   <CaButton
                       text={"Apply Intersection of Genes"}
                       variant={"contained"}
                       size="small"
                       onClick={() => {
                            setOperation("intersection");
                            props.make_intersection("intersection")
                        }}/>
            </GPAP_Tooltip>
           </Box>
        }
        else{
            return  <Box p={0}>
                    <GPAP_Tooltip title={<MappedText text={"Make union of extracted genes (default)"}/>}>
                        <CaButton
                            text={"Apply Union of Genes"}
                            variant={"contained"}
                            size="small"
                            onClick={() => {
                                setOperation("union");
                                props.make_intersection("union")
                            }}
                            />
            </GPAP_Tooltip>
            </Box>
        }
    };


    const init = () => {
        // reinitialize values;
        setStep("step1")
        setAddToQuery(false);
        setMethod("")

    }

    // box of selected genes;
    const geneViewBox = () => {

        if(geneList.length!== 0  ){
            return <React.Fragment>
                {(!added_to_query)
                    ? <div><Alert severity={"warning"}><MappedText text="You didn't include the genes in your query yet." /> </Alert></div>
                    : <div><Alert severity={"success"}><MappedText text="You included the genes in your query. You made an " />{value} <MappedText text=" between the HPO genes" /></Alert></div>
                }
                <Grid item lg={12}>
                    <Box m={0}>
                        <CaButton
                            text={"Reset HPO gene list"}
                            variant={"contained"}
                                      color="secondary"
                                      size="small"
                                      onClick={() => {
                            init();
                            props.reset()
                        }} startIcon={<CancelIcon/>}/>
                        {' '}
                        <CaButton
                            variant={"contained"}
                            text={"Add Genes to Query"}
                            size="small"
                            style={{marginRight: "1%"}}
                            onClick={() => {
                                setAddToQuery(true);
                                let uniques = [...new Set(geneList)];
                                props.add_genes_to_store(uniques);
                            }}
                            startIcon={<CheckCircleIcon/>}/>
                    </Box>
                </Grid>
                    <Grid item lg={12}>
                    <Paper elevation={0} style={{float: "left", width: "100%"}}>
                        <DataList_View
                            dataList={[...new Set(geneList)]}
                            color={"#f7fbff"}
                            title={"Genes"}
                            label={"Current Selected Genes "}/></Paper>
                </Grid>

            </React.Fragment>
        }
        else if (geneList.length === 0 && value === "intersection"){
            return <AlertInfo severity={"warning"}
                              message={<MappedText text={"No genes shared across all HPOs"}/> }
                              title={<MappedText text={"No Genes"}/>}/>
        }
        else {
            return null;
        }


    };


    // display if any the list of selected genes
    const showGeneList = () => {

        return  geneViewBox()

    };

    const getResults = (s) => {

        if(s!== ""){
            getHPO_terms(config.apis_configuration.api_phenostore, props.token, s)
                .then(r => r.json())
                .then(json => {
                    const resp = handleResponse("HPO", json);
                    setResults(resp);
                })
        }
    };


    const getInputValues = (obj) => {

        // STORE INPUT VALUES;
        setSelected(obj);
        // PASS DATA TO PARENT

        props.passData(obj);


    };

    const cleanHPOs = () => {
        props.resetHPOs();
    }


    const step1 = () => {

        let text = TextSection(props.patientBool);


        return <React.Fragment>
            {
                (extracted_hpo.length!==0)
                        ?  <Box>
                        <GPAP_Tooltip title={<MappedText text={"Clear current HPO selection"}/>}>
                            <IconButton onClick={()=> cleanHPOs()}><DeleteForeverOutlined/></IconButton>
                        </GPAP_Tooltip>
                        <Typography variant={"subtitle1"}>
                            <MappedText text="HPOs collected from analysed index case"/> ({extracted_hpo.length})
                        </Typography>
                    </Box>
                        :  <Typography variant={"subtitle1"}>
                        {text}
                        </Typography>
            }
            <div className={"field_container"}>
                <Grid container direction={"column"}>
                    <Grid item>
                        <div className={'section'}>
                            {(extracted_hpo.length ===0 && !props.patientBool)
                                ? <GPAP_Button
                                    style={{margin: "0px"}}
                                    variant={"outlined"}
                                    size="small"
                                    onClick={props.extract_patients_HPOs}
                                    disabled={patientsBool}><MappedText text="Get HPOs from Participants"/></GPAP_Button>
                                :
                                <div>{formatHPOs(extracted_hpo, props.removeHPO)}</div>
                            }
                        </div>
                    </Grid>
                    <Grid item>
                        <Box m={0}>
                            <GeneralAutoComplete
                                results = {results}
                                onInputChange = {getResults}
                                title=<MappedText text={"search for HPO terms"}/>
                                passSelected = {getInputValues}
                                default_tags = {selected_values}
                            />
                            <div>{requestFailed}</div>
                        </Box>
                    </Grid>
                    <NextButton
                        extractedHpo={extracted_hpo}
                        searchedHpos={searched_hpos}
                        setStep={setStep}
                    />
                </Grid>
            </div>
        </React.Fragment>



    };

   const getStyle = (term) => {
       return (method === term) ? {background: "orange"} : null;
    }


    const step2 = () => {


       let tot_genes = extracted_hpo.length + searched_hpos.length;

       return  (!hpo_bool)
            ? <React.Fragment>
               <Grid container
                     direction={"row"}>
                   <Grid item lg={12}> {back_button()}</Grid>
                <Grid item lg={12}>
                    <Box m={1}>
                        <Typography variant={"subtitle2"}><MappedText text="You have now"/> {tot_genes} <MappedText text="HPO terms selected"/></Typography>
                    </Box>
                        <Divider/>
                </Grid>
                   <Grid item lg={12}><Box p={1}>
                       <Typography variant={"h6"}><MappedText text="Select Resource:"/></Typography>
                   </Box>
                   </Grid>
                <Grid item lg={12}>
                        <div>
                            <GPAP_Tooltip
                                interactive
                                title={ getHPOTooltip() }>

                                <CaButton
                                    variant={"contained"}
                                    text={"HPO Ontology"}
                                    style={{ ...getStyle("HPO"), textTransform: 'none' }} // Merge styles with any other styles needed
                                    onClick={() => {
                                        setMethod("HPO")
                                        props.apply_HPO_Ontology()
                                    }}
                                    disabled={hpo_bool}
                                />
                            </GPAP_Tooltip>
                            <GPAP_Tooltip
                                interactive
                                title={  getDisgenetTooltip() }>
                                <CaButton
                                    variant={"contained"}
                                    disabled={props.umls_array.length === 0}
                                    text={"Disgenet"}
                                    style={{ ...getStyle("DISGENET"), textTransform: 'none' }} // Merge styles with any other styles needed
                                    onClick={() => {
                                        setMethod("DISGENET")
                                        props.applyDisGenet()
                                    }}
                                    />
                            </GPAP_Tooltip>{' '}
                        </div>
                    { (props.loading) ? <CircularProgress/> : null}
                </Grid>
                   <Grid item lg={12}>
                       <Typography variant={"h6"}><MappedText text="Select Method:"/></Typography>
                       <Typography variant={"subtitle2"}>{value} <MappedText text="of genes in this section is applied "/></Typography>
                       {getButtonOperation()}
                   </Grid>
                   {showGeneList()}
               </Grid>
            </React.Fragment>
            : null



    };


    const renderStep = () => {

        switch (step) {
            case "step1":
                return step1();
            case "step2":
                return step2();
            default:
                return step1();

        }

    };



    return <React.Fragment>
        {renderStep()}
    </React.Fragment>





}


function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {
            token: state.authorization.token,
            }
    }
}


export default connect(mapStateToProps)(HPO_QueryBuilder);