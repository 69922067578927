
import React from "react";

import VChart from "../../../gpap-lib/components/bar-chart/VChart";

import {Grid} from "@mui/material";
import Box from "@mui/material/Box";




function AggregationView(props){



    let data = [];

    Object.keys(props.initData).forEach(function(itemKey){

            let chart_data = [];

            let all_subItemKeys =  Object.keys(props.initData[itemKey]);  //e.g. affected, unaffected;

            all_subItemKeys.forEach(function(subItem){

                let val = props.initData[itemKey][subItem];
                chart_data.push({d: subItem, key: subItem, value: val})

            })

            let obj = {label: itemKey, data: chart_data};
            data.push(obj);

    })


    const showCharts = (data) => {


       return data.map(function(item){

            return  <Grid item lg={4}>
                <div className={'small_panel_title'}>{item.label}</div>
                <VChart initData = {item.data} data = {item.data} title={item.label} />
            </Grid>


        })

    }

    return <Box p={1}>

        <Grid container >
            {showCharts(data)}
        </Grid>

    </Box>


}



export default AggregationView;