


// 1. React and built-in hooks first
import React, { useEffect, useState } from "react";

// 2. Third-party libraries (e.g., Redux, i18next, Material-UI)
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";

// 3. Material-UI components
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

// 4. Local imports (services, actions, components, config files)
import * as StudyActions from "../dashboard/reducers/study_actions";
import { get_all_studies } from "../../services/api/manage_study";
import GeneralGPAPTable from "../../components/tables/general-gpap-table/GeneralGPAPTable";
import { study_columns } from "../../config-files/table-config/study_columns";

// 5. CSS and stylesheets (always last)
import "./study-view.css";




function StudyView(props){

    const { t, i18n } = useTranslation();
    const [loaded, setLoaded] = useState(false);
    const [studies, setStudies] = useState(undefined);
    const [error, setError] = useState(undefined);


    useEffect(function(){

        if(!loaded){
            refreshStudies()
        }

    },[]);

    const refreshStudies = () => {
        get_all_studies(config.apis_configuration.api_nextgpap_server, props.token)
            .then((resp) => {
                if (!resp.ok) {
                    throw new Error('Failed to fetch studies');
                }
                return resp.json();
            })
            .then((json) => {
                if (json.message === "Success") {
                    setLoaded(true);
                    setStudies(json);
                } else {
                    throw new Error('Unexpected response from server');
                }
            })
            .catch((error) => {
                setLoaded(false);
                console.error("Error fetching studies:", error);
                // Optional: Add error handling logic here (e.g., show a notification or error message)

                setError("Error: Could not load studies. Please try again later.")
            });
    };



    if(studies !== undefined && studies.hasOwnProperty("data")){
        studies.data.sort(function(a, b) {
            let a_date = a.creationDate
            let b_date= b.creationDate
            return new Date(b_date) - new Date(a_date) })
    }


    return  <div className={"studySaved"}>
    <Grid container direction={"row"} alignItems={"center"}>
        <Grid item lg={12}>
            {
                (studies!== undefined) ?
                    <React.Fragment>
                        <div style={{width: '100%', padding:"1%"}}>
                            <Typography variant={"h4"}> Available studies </Typography>
                        </div>
                        {
                            (!error) ?    <GeneralGPAPTable data={ studies.data }
                                                            columns={study_columns}/>
                                : <div>{error}</div>
                        }
                    </React.Fragment>
                     : null
            }

        </Grid>
    </Grid>
    </div>

}


function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {
            token: state.authorization.token,
            studySettings: state.studySettings
        }
    }
}
const mapDispatchToProps = (dispatch) => ({

    studyActions: bindActionCreators(StudyActions, dispatch)

});


export default connect(mapStateToProps, mapDispatchToProps)(StudyView);
