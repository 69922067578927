


import React from 'react';
import "../../dashboard/components/genomics-dashboard/components/results/components/query-applied-filters/query-filters.css";
import Typography from "@mui/material/Typography"; // Updated import for MUI v5
import { default_query } from "../../../services/api/genomics_query_samples/query_sample";
import Box from "@mui/material/Box";
import FieldRendering from "../../dashboard/components/genomics-dashboard/components/results/components/query-applied-filters/FieldRendering";
import { query_difference } from "../../dashboard/components/genomics-dashboard/components/results/components/query-applied-filters/query_difference";
import MappedText from '../../study-page/create-study/analysis-page/components/clinical-info/mapText';

// this is Showing the Filters applied;

export default function QueryFilters_View(props){

    // check_diff
    let {filtersObj, title} = props;

    // get currentQuery only when loaded;



    // get extra field

    const getFilterList = () => {


        if(filtersObj!== undefined)
        {
            // default query is a sample of a query WITHOUT filters applied.
            let query_1 = default_query;
            let query_2 = filtersObj;
            let list_of_filters = query_difference(query_1,query_2);
            // some mapping

            // remove size
            delete list_of_filters["size"]
            if("svn" in list_of_filters){
                delete Object.assign(list_of_filters, {["snv"]: list_of_filters["svn"] })["svn"];
            }

            let filters = Object.keys(list_of_filters).map(d => FieldRendering(list_of_filters, d, currentQuery.extra  )).filter(s => s!== null)
            if(filters.length>0){
                return( <Box m={1}>{filters}</Box>)
            }
            else
            {
                return  <div>
                    <Typography variant={"subtitle2"}>None</Typography>
                </div>
            }

        }
        else
        {
            return  <div>
                <Typography variant={"subtitle2"}>None</Typography>
            </div>
        }

    }


    let currentQuery = filtersObj;

    if(currentQuery!== undefined){
        let variant_type = ("extra" in currentQuery) ? currentQuery.extra.find(s => s["variant_type"] !== undefined) : undefined;
        return <Box p={1}>
            <Typography variant={"body1"}
                        style={{color: "#4575B4", fontWeight: "bold"}} ><MappedText text="Applied filters:"/></Typography>
            <div>
             {/*   {(variant_type!== undefined && variant_type.variant_type!== "GPAP")
                    ? <React.Fragment>
                        <div className={"field_title"} style={{ fontWeight: "bold"}} >Tag variants </div>
                        <div className={"filter_value"}> {variant_type.variant_type} </div>
                    </React.Fragment>
                    : null
                }*/}
                {getFilterList()}
            </div>
        </Box>
    }
    else{
     return <Box p={2}>
            <Typography variant={"h6"} style={{color: "#4575B4", fontWeight: "bold"}} ><MappedText text="No filters found"/></Typography>
        </Box>
    }

}


