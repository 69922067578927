


import React from 'react';

import StudyItem from "./StudyItem";
import {makeStyles} from "@mui/styles";
import {List} from "@mui/material";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: "100%",
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default function List_Queries(props) {

    const classes = useStyles();
    let studies = props.studies;

    // filter out studies that do not have analyses
    let studyItem = studies.filter(s => s.analyses.length!==0).map(function(study){
        return <StudyItem
            queryDelete = { props.queryDelete }
            study={study} queryViewer={props.queryViewer}/>});


    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
        >
            {studyItem}
        </List>
    );
}