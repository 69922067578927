import { Stack } from "@mui/material";
import React from "react";
import ComponentSwitch from "../components/switches/Switch";
import { useDispatch, useSelector } from "react-redux";
import { setTable } from "../../../../reducers/slices/tables/table.slice";

  
const useViewFeatures = (states, customFeatures) => {

  useDispatch();

  return (
    <Stack flexDirection="row">
      {customFeatures?.map((item) => {
        if (item.switchFS) {
          return (
            <Stack direction="row" spacing={1}>
              <ComponentSwitch states={states} />
            </Stack>
          );
        } 
      })}
    </Stack>
  );
};

   
export default   useViewFeatures;