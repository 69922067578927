

import React from "react";
import {Alert} from "@mui/material";
import MappedText
    from "../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";



const AnalysisInfo = ({currentStudy}) => {

    let analysis_without_queries = false;
    if (currentStudy) {
        currentStudy.analyses.forEach(function (s) {
            if (s.queries.length === 0) {
                analysis_without_queries = true;
            }
        })
    }

    return (analysis_without_queries) ?
        <Alert severity={"warning"}><MappedText text="Analyses without queries will not be saved"/></Alert> : null;
}

export default AnalysisInfo;