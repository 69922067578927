

import React, { Component } from "react";
import { makeStyles } from '@mui/styles'; // MUI 5 equivalent for makeStyles
import "./results-overview.css";

// GPAP Components
import VChart from "../../../../../../../../../gpap-lib/components/bar-chart/VChart";

// MUI Components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { connect } from "react-redux";

// Custom Components
import MappedText from "../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";




const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        height: 140,
        width: 100,
    },
    control: {
        padding: theme.spacing(2),
    },
}));



function getValues(pills, variable){

    return pills.find(item => item.key === variable).values;
}


class Results_Overview extends Component {

    constructor(props) {
        super(props);

        this.state = {
            queryDiff:undefined
        };
        this.getArrow_Stats = this.getArrow_Stats.bind(this);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.props.overview_data){
            if(this.props.overview_data.total!== prevProps.overview_data.total){
                let queryDiff = this.props.overview_data.total - prevProps.overview_data.total;
                this.setState({queryDiff:queryDiff})
            }
        }

    }



    getArrow_Stats() {
        let {queryDiff} = this.state;

     /*   if (queryDiff < 0) {
            return <div className={"stats_down"}><FontAwesomeIcon icon={faArrowDown}/> ({queryDiff}) </div>
        } else if (queryDiff > 0) {
            return <div className={"stats_up"}><FontAwesomeIcon icon={faArrowUp}/> ({queryDiff}) </div>
        } else if (queryDiff === 0) {
            return <div className={"stats_equal"}><FontAwesomeIcon icon={faEquals}/> ({queryDiff}) </div>

        }*/
    }


    render(){

        //const classes = useStyles();
        let { pills, total } = this.props.overview_data;

        // create data
        let obj = [{d: "NA", key: "gnomAD", value: 0}, {d: "<0.001", key: "gnomAD", value: 0},{d: "<0.01", key: "gnomAD", value: 0},{d: "<0.0001", key: "gnomAD", value: 0}];
        let pop_obj =  pills.find(s=> s.key === "population_gnomad_af_na");

        if(pop_obj!== undefined){
            pop_obj.values.forEach(function(item){

                let val = item.d;
                // thresholds - 0 - 0.0001, 0.001, 0.1
                if(val === 0 ){
                    obj.find(s => s.d === "NA").value = obj.find(s => s.d === "NA").value+1;
                }
                else if(val>0 && val<0.0001){
                    obj.find(s => s.d === "<0.0001").value = obj.find(s => s.d === "<0.0001").value+1;
                }
                else if(val>0.0001 && val<0.001){
                    obj.find(s => s.d === "<0.001").value = obj.find(s => s.d === "<0.001").value+1;
                }
                else if(val>0.001 && val<0.01){
                    obj.find(s => s.d === "<0.01").value = obj.find(s => s.d === "<0.01").value+1;
                }


            });
        }


        if(this.props.data_bool){
            return   <Grid container style={{flexGrow: "1"}} spacing={2}>
                <Grid item lg={12}>
                    {
                        (this.props.studySettings.analysisType!== "cohort" && this.props.studySettings.analysisType!== "search_across")
                            ?<Alert severity={"warning"} style={{fontWeight: "500"}}>No Variants for this query</Alert>
                            : <Alert severity={"warning"} style={{fontWeight: "500"}}>Run a new query, or click on a gene on the left if you are in a search across all / cohort study</Alert>

                    }
                </Grid>
            </Grid>
        }
        else{
            return (
                <Grid container style={{flexGrow: "1"}} spacing={2}>
                    <Grid item lg={12}> <Typography variant={"h6"} color={"textSecondary"}><MappedText text="Variants distributions"/> </Typography></Grid>
                    <Grid item lg={4}>
                        <div className={'small_panel_title'}><MappedText text="Effect Impact"/></div>
                        <VChart initData = {getValues(pills, 'effect')} data = {getValues(pills, 'effect')} title={"Effect Impact"} />
                    </Grid>
                    <Grid item lg={4}>
                        <div className={'small_panel_title'}>ClinVar</div>
                        <VChart initData = {getValues(pills, 'clinvar')} data = {getValues(pills, 'clinvar')} title={"ClinVar"}/>
                    </Grid>
                    <Grid item lg={4}>
                        <div className={'small_panel_title'}><MappedText text="GnomAD Population"/></div>
                        <div className={"population_Box"}>
                            <VChart initData = {obj} data={obj} title={"gnomAD"}/>
                        </div>
                    </Grid>
                </Grid>
            )
        }




    }
}




function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {
            studySettings: state.studySettings,
        }
    }
}



export default connect(mapStateToProps, null)(Results_Overview);