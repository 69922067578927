

import {SET_SELECTED_TABLE_ROWS, RESET_SELECTED_TABLE_ROWS, RESET_ROW} from "./actions"



// TODO: review this with;
const selected_table_rowInit = [
    {data_type: "SNV_Germline", data_row: []},
    {data_type: "SNV_Somatic", data_row: []},
    {data_type: "CNV", data_row: []},
    {data_type: "Treatment_Somatic", data_row: []},
    {data_type: "Pharmacogenomics_Germline", data_row: []},
]

export const setSelectedTableRows = (state= selected_table_rowInit, action) => {

    switch (action.type) {
        case SET_SELECTED_TABLE_ROWS:
            const data = action.data;

            // Create a new state where all `data_row` values are emptied except the target one
            return state.map(item => {
                if (item.data_type === data.data_type) {
                    return { ...item, data_row: data.data_row }; // Update the target item
                }
                return { ...item, data_row: [] }; // Reset others
            });
        case RESET_SELECTED_TABLE_ROWS:
            return selected_table_rowInit;
        case RESET_ROW:
            const resetDataType = action.data_type;

            // Reset only the specified row
            return state.map(item => {
                if (item.data_type === resetDataType) {
                    return { ...item, data_row: [] }; // Reset the specified row
                }
                return item; // Keep other rows as is
            });
        default:
            return state
    }
};
