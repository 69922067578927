// Table Config Imports
import {
    table_config_compact_38,
    table_config_search_across,
    table_config_compact_somatic,
    table_function_config
} from "../../../../../../../../../config-files/table-config/snv-table/table_config";

// React and Material UI Imports
import React from "react";
import { TableCell, TableSortLabel } from "@mui/material";

// Custom Functions and Components
import { colorCodeClinVar, formatEffect, getPredictorValue, getSlidePopulation, colorOncoEvidences } from "./cell_renderers";
import GPAP_Tooltip from "../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import GPAP_InfoIcon from "../../../../../../../../../gpap-lib/components/GPAP_InfoIcon";
import MappedText from "../../../../../../../../instand/reports/create-report/shared-components/mapText";

// CSS Import
import "./row-style.css";
import {makeStyles} from "@mui/styles";
import LabelIcon from "@mui/icons-material/Label";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: 8,
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    truncate:{
        width: "250px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
}
}));


function getConfig(configType){

    let table_config;
    if(configType === "default")
    {
        table_config= table_config_compact_38;
    }
    else if(configType === "somatic"){
        table_config = table_config_compact_somatic;
    }
    else{
        table_config = table_config_search_across;
    }

    return table_config;

}


function getMainHeaders(configType, annotations){

    const table_config = getConfig(configType);

    let headers_main= table_config.map(function(mainH,index){
        let colSpan = mainH.subheadings.length;

        if(!annotations && mainH.mainHeading.props && mainH.mainHeading.props.text === "Clinical association"){
            // TODO: create a configuration to organize this;
            // there are more options by default if hg38;
            
            //Check if it is somatic and assign colSpan accordingly
            const containsId = mainH.subheadings.find(obj => obj.id === "OCScore");

            if (containsId) {
                 colSpan = 6;
            }
            else{
                colSpan = 4;
            }
        }


        return <TableCell
                            key={"tcell_" + index}
                            align="center"
                            colSpan={colSpan}
                            style={{
                              borderRight: "1px solid white",
                              color: "white",
                              background: "#4575B4"
                          }}
        >
          <MappedText text={mainH.mainHeading}></MappedText>
        </TableCell>



    });

    let head_1 = <TableCell style={{ background: "#4575B4" }} colSpan={1} />

    let headers = [<TableCell  key={"tcell_s" + 0} style={{ background: "#4575B4" }} align="center" colSpan={1}/>]

    return [head_1, headers, headers_main];
}



function getContent (d) {

    let content = d.label;

    const divText = <div> <MappedText text= {d.label}/> </div>
    if(d.id ==="acmg_verdict"){

        content = <GPAP_Tooltip title={"Varsome ACMG classification"}>{divText}</GPAP_Tooltip>
    }
    else if (d.id === "acmg_classifications"){
        content = <GPAP_Tooltip title={"Varsome ACMG Criteria"}>{divText}</GPAP_Tooltip>
    }

    else if (d.id === "mane_annotation"){
            content = <GPAP_Tooltip
            leaveDelay={1000}
            title={(
            <span>
            MANE Select is the transcript agreed as universal standard for clinical reporting,{" "}
                    <a
                        href="https://www.ncbi.nlm.nih.gov/refseq/MANE/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'orange' }}
                    >
            https://www.ncbi.nlm.nih.gov/refseq/MANE/
             </a>
           </span>
            )}
            interactive={true} // Set interactive to true to make the content within the tooltip clickable
        >
                {divText}
        </GPAP_Tooltip>
    }


    else if (d.id === "omim"){
        content = <GPAP_Tooltip
        leaveDelay={1000}
        title={(
        <span>
        The annotation is done with periodic OMIM updates through an internal API built with the same rationale as the OMIM one,{" "}
                <a
                    href="https://www.omim.org/api"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'orange' }}
                >
        https://www.omim.org/api
         </a>
       </span>
        )}
        interactive={true} // Set interactive to true to make the content within the tooltip clickable
    >
            {divText}
    </GPAP_Tooltip>
}

    else if (d.id === "intervar_evidence"){
        content = <GPAP_Tooltip
            //enterDelay={500}
            leaveDelay={1000}
            title={(
                <span>
          Clinical significance available for exonic SNVs and based on InterVar,{" "}
                    <a
                        href="https://wintervar.wglab.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'orange' }}
                    >
            https://wintervar.wglab.org/
          </a>
        </span>
            )}
            interactive={true} // Set interactive to true to make the content within the tooltip clickable
        >
            {divText}
        </GPAP_Tooltip>
    }

    return content;

}



function getHeaders(orderBy, onRequestSort, createSortHandler, order, configType, annotations){

    const classes = useStyles();

    const table_config = getConfig(configType);

    let headers = table_config.map(function(mainH){

        let subheadings = mainH.subheadings;

        if(!annotations){
            subheadings = subheadings.filter(s => !["acmg_verdict", "acmg_classifications"].includes(s.id))
        }

        return subheadings.map(function(d){

            let content = getContent(d);

            let tableCell = <TableCell
                key={d.id}
                align={"center"}
                padding={d.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === d.id ? order : false}>
                <TableSortLabel
                    active={orderBy === d.id}
                    direction={orderBy === d.id ? order : 'asc'}
                    onClick={createSortHandler(d.id, d.numeric)}>
                     <span> {content}</span>
                    {orderBy === d.id ? (
                        <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                    ) : null}
                </TableSortLabel>
            </TableCell>


            return (d.tooltip!== undefined)
                ? <GPAP_Tooltip title={d.tooltip}>{tableCell}</GPAP_Tooltip>
                : tableCell

        })


    });

    let cell_one = <TableCell colSpan={1} />

    let tableHeaders = [ cell_one,
        <TableCell
            padding="checkbox">
            <LabelIcon color={"primary"}/>
        </TableCell>]

    return tableHeaders.concat(headers);

}



function getRow(row, labelId,flagged, configType, annotations, dataType){

    const table_config = getConfig(configType);

    const tooltip = (value, tip, val_check) => {

        if(value === val_check && tip!==undefined){
            return <GPAP_InfoIcon
                anchor={"true"}
                title={tip}/>
        }
        else if(value === val_check && tip ===undefined){
            return <GPAP_InfoIcon
                style={{
                    position: "relative",
                    top: "-30px",
                    left: "15px"
                }}
                title={"No Info available"}/>
        }


    };

    return table_config.map(function(mainH){

        let subHeadings = mainH.subheadings;

        if(!annotations){
            subHeadings = subHeadings.filter(s => !["acmg_verdict", "acmg_classifications"].includes(s.id))
        }


        return subHeadings.map(function(d, index){

            let col_header=d.id;
            let value= row[col_header];
            if(col_header === "gene_name"){

                let final_value= (value === undefined ) ? "NA" : value;
                final_value = (typeof value === "object") ? JSON.stringify(final_value) : final_value;

                return    <TableCell component="th" id={labelId}
                                     key={col_header + "_" +  index}
                                     align="center"
                                     scope="row"
                                     padding="none">
                    <strong>{final_value}</strong>
                </TableCell>
            }
            else if(col_header === "Evidence"){
                return  <TableCell align="center" key={col_header + "_" + index}>{colorOncoEvidences(value)}</TableCell>
            }
            else if(col_header === "effect_impact"){
                return <TableCell align="center">{formatEffect(value)}</TableCell>

            }
            else if(col_header === "clinvar_clnsig"){
                return <TableCell align="center" key={col_header + "_" +  index}>
                    {colorCodeClinVar(value, "|", dataType)} {tooltip(value, row["clinvar_clnsigconf"], "C") }
                </TableCell>
            }
            else if(col_header === "intervar_automated"){
                return <TableCell align="center" key={col_header + "_" +  index}>
                    {colorCodeClinVar(value, ",")} {tooltip(value, row["intervar_automated"], "C") }
                </TableCell>
            }
            else if( mainH.mainHeading === "Population frequency" && col_header!== "n_exp" ){
                return <TableCell key={col_header + "_" +  index} align="center">{getSlidePopulation(value)}</TableCell>
            }
            else if( mainH.mainHeading === "Predictors" ){
                return <TableCell key={col_header + "_" +  index} align="center">{getPredictorValue(value, col_header)}</TableCell>
            }
            else if( col_header === "acmg_classifications" ){
                return <TableCell key={col_header + "_" +  index} align="center">{(value!== undefined) ? value.toString() : ""}</TableCell>
            }
           
            else if( col_header === "mane_annotation" ){

                let hasManeSelectOrClinicalPlus = false;

                // Iterate over the transcripts_all array to check if mane_select or mane_plus_clinical exists
                if (row.transcripts_all) {
                  for (let i = 0; i < row.transcripts_all.length; i++) {
                    const transcript = row.transcripts_all[i];
                    if (transcript.mane_select || transcript.mane_plus_clinical) {
                      hasManeSelectOrClinicalPlus = true;
                      break; 
                    }
                  }
                }
 
                return <TableCell key={col_header + "_" + index} align="center"> {hasManeSelectOrClinicalPlus ? "YES" : "NO"} </TableCell>
            }
            
            else
            {

                let final_value= (value === undefined || value === "") ? "NA" : value;
                let cell_value = (typeof value === "object") ? JSON.stringify(final_value) : final_value;

                return <TableCell align="center" key={col_header + "_" +  index}>
                    {
                        (cell_value.length > 20)
                        ? <GPAP_Tooltip title={cell_value}>
                                <div className={"truncate"}>{cell_value}</div>
                            </GPAP_Tooltip>
                            : (cell_value === "NA") ?  <div style={{color: "#aaaaaa"}}>NA</div> : <GPAP_Tooltip title={cell_value}><div className={"truncate"}>{cell_value}</div></GPAP_Tooltip>
                    }

                </TableCell>
            }

        })

    });
}



export {getMainHeaders, getHeaders, getRow}