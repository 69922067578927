import React, { useState } from "react";
import { AppBar, Toolbar, Typography, Box, IconButton, Menu, MenuItem, ListItemIcon } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import SettingsIcon from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import MappedText from "../../../scenes/study-page/create-study/analysis-page/components/clinical-info/mapText";
import LanguageSelector from "../../header/LanguageSelector";
import GPAP_Logo from '../../../../resources/GPAP-Cancer.png';
import TabNavigation from "./TabNavigation";
import { Link, useLocation, useHistory } from 'react-router-dom';
import { connect } from "react-redux";
import auth from '../../../services/session/Auth';
import { makeStyles } from '@mui/styles';
import Settings from "@mui/icons-material/Settings";

const useStyles = makeStyles((theme) => ({
    tabs: {
        '& .MuiTabs-indicator': {
            height: 6,
            color: '#3e699e'
        },
    },
}));

let configOptions = [
    { hash: "#/dir_list", value: 0 },
    { hash: "#/reporting_doc/", value: 1 },
    { hash: "#/contact", value: 2 },
];

let configComplete = [
    { hash: "/#/dar_list", value: 0 },
    { hash: "#/dir_list", value: 1 },
    { hash: "#/reporting_doc/", value: 2 },
    { hash: "#/contact", value:3 },
];

const isLot3 = window?.config?.instand_config?.lots?.lot3 || null;


function getInitVal() {


    let config_urls = (window.config?.instand_config?.lots?.lot3) ? configComplete : configOptions;

    let hash = config_urls.find(s => window.location.hash.includes(s.hash));
    return hash ? hash.value : -1;
}


function InstandHeader({authorization, isReportingRoute}){

    const [anchorEl, setAnchorEl] = useState(null);
    const [value, setValue] = useState(getInitVal());
    const history = useHistory();
    const classes = useStyles();
    const location = useLocation();
    const isReportingPage = location.pathname.endsWith('/reporting');
    const isClinicsPage = location.pathname.endsWith('/clinics');
    

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleAuthAction = () => {
        if (auth.user.authenticated) {
            auth.user.keycloak.logout().then(() => {
                console.log("Logout successful");
                history.push('/');
            }).catch((e) => {
                console.error("Logout failed", e);
            });
        } else {
            history.push('/dir_list');
        }
    };
   

    return (
        <AppBar
            position="static"
            style={{ background: "white" }}
            elevation={isReportingRoute ? 0 : undefined}
            sx={{
                boxShadow: isReportingRoute ? "none" : undefined,
                backgroundColor: isReportingRoute ? "transparent" : undefined,
                margin: 0,
                padding: 0,
                width: '100%',
            }}
        >
            <Toolbar>
                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                    <Link to="/reporting">
                        <img src={GPAP_Logo} alt="GPAP" style={{ width: "250px" }} />
                    </Link>
                </Typography>

                {!isClinicsPage && (
                    <Box>
                        <TabNavigation
                            classes={classes}
                            isReportingPage={isReportingPage}
                            handleChange={handleChange}
                            value={value}
                            isLot3 = {false} // to enable later on
                        />
                    </Box>
                )}

                {!isClinicsPage && <LanguageSelector />}

                {!isClinicsPage && (
                    <IconButton
                        disableRipple
                        sx={{
                            marginLeft: '10px',
                            backgroundColor: 'rgba(62, 105, 158, 0.1)',
                            color: "#3e699e",
                            width: '78px',
                            height: '39px',
                            borderRadius: '50px'
                        }}
                        onClick={handleClick}
                    >
                        <Avatar
                            sx={{
                                width: '30px',
                                height: '30px',
                                fontSize: '16px',
                                marginRight: '10px',
                                bgcolor: "#3e699e"
                            }}
                        >
                            {authorization?.preferredName ? authorization.preferredName.charAt(0).toUpperCase() : ''}
                        </Avatar>
                        <SettingsIcon sx={{ width: '20px', height: '20px' }} />
                    </IconButton>
                )}

                {!isClinicsPage && (
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                    >
                          <MenuItem onClick={handleClose}>{authorization?.preferredName}</MenuItem>
                          {!isReportingPage && (
                          <MenuItem onClick={handleClose} component={Link} to="/instand_settings">
                            <ListItemIcon><Settings fontSize="small" /></ListItemIcon>
                            <MappedText text={"Settings"}/>
                          </MenuItem>
                          )}
                          <MenuItem onClick={handleAuthAction}>
                            <ListItemIcon><Logout fontSize="small" /></ListItemIcon>
                            <MappedText text={auth.user.authenticated ? "Logout" : "Login"}/>
                          </MenuItem>
                    </Menu>
                )}
            </Toolbar>
        </AppBar>
    );

}

function mapStateToProps(state) {
    return { authorization: state.authorization };
}

export default connect(mapStateToProps)(InstandHeader);
