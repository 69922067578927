import {
  createTitle,
  createKeyValueParagraph,
  createParagraphWithSpacing,
  createBoldUnderlinedParagraph,
  createHeadTitle,
  createSubTitle,
  createSpacer,
} from '../../write-sections/write_utils/documentHelpers';

function AnnexesGermline(allDataApis, annexesFields) {
  const structure = [
    { type: 'headTitle', label: 'ANNEXES' },
    { type: 'spacer', before: 50, after: 50 },
    { type: 'paragraphWithSpacing', label: 'The following annexes complete the report, which should be reviewed as a whole.' },
    { type: 'spacer', before: 50, after: 50 },
    { type: 'title', label: 'ANNEX I: CLINICAL RECORD' },
    { type: 'title', label: 'ANNEX II: METHODOLOGY' },
    { type: 'title', label: 'ANNEX III: QUALITY CONTROL METRICS' },
    { type: 'title', label: 'ANNEX IV: VARIANT METRICS AND GENOTYPE-PHENOTYPE ASSOCIATIONS' },
    { type: 'title', label: 'ANNEX V: PHARMACOGENOMICS' },
    { type: 'boldUnderlinedParagraph', label: 'ANNEX I: CLINICAL RECORD' },
    { type: 'paragraphWithSpacing', label: 'Clinical symptoms and physical findings', bold: true },
    { type: 'paragraphWithSpacing', label: allDataApis?.participants?.[0]?.features
      ?.filter((feature) => feature.observed === true)
      ?.map((feature) => feature.name)
      ?.join(', ') || 'NA' },
    { type: 'paragraphWithSpacing', label: 'Family history', bold: true },
    { type: 'paragraphWithSpacing', label: annexesFields?.family_history || 'NA' },
    { type: 'boldUnderlinedParagraph', label: 'ANNEX II: METHODOLOGY' },
    { type: 'keyValueParagraph', label: 'Test method', value: `${allDataApis?.experiments?.[0]?.library_strategy || 'NA'} using the ${allDataApis?.experiments?.[0]?.kit || 'NA'}` },
    { type: 'keyValueParagraph', label: 'Type of variants assessed', value: annexesFields?.type_of_variants_assessed?.toString() || 'NA' },
    { type: 'keyValueParagraph', label: 'Sample received', value: annexesFields?.sample_received || 'NA' },
    { type: 'keyValueParagraph', label: 'Sample type', value: allDataApis?.samples?.sample_type || 'NA' },
    { type: 'keyValueParagraph', label: 'Date of sample collection', value: allDataApis?.samples?.date_of_sample_collection || 'NA' },
    { type: 'spacer', before: 50, after: 50 },
    { type: 'subTitle', label: 'Sample processing:' },
    { type: 'spacer', before: 50, after: 50 },
    { type: 'keyValueParagraph', label: 'Center', value: allDataApis?.centre || 'NA' },
    { type: 'keyValueParagraph', label: 'Sequencing platform', value: allDataApis?.sequencing_platform || 'NA' },
    { type: 'keyValueParagraph', label: 'Fragment (size and type)', value: allDataApis?.fragment || 'NA' },
    { type: 'spacer', before: 50, after: 50 },
    { type: 'subTitle', label: 'Bioinformatics analysis:' },
    { type: 'spacer', before: 50, after: 50 },
    { type: 'keyValueParagraph', label: 'Human reference genome', value: allDataApis?.human_reference_genome || 'NA' },
    { type: 'keyValueParagraph', label: 'Aligner', value: allDataApis?.aligner || 'NA' },
    { type: 'keyValueParagraph', label: 'Mark duplicates', value: allDataApis?.mark_duplicates || 'NA' },
    { type: 'keyValueParagraph', label: 'Base recalibration', value: allDataApis?.base_recalibration || 'NA' },
    { type: 'keyValueParagraph', label: 'Smart variants caller', value: allDataApis?.smart_variants_caller || 'NA' },
    { type: 'keyValueParagraph', label: 'Analysis pipeline', value: allDataApis?.analysis_pipeline || 'NA' },
  ];

  const content = structure.map((item) => {
    switch (item.type) {
      case 'headTitle':
        return createHeadTitle(item.label);
      case 'spacer':
        return createSpacer(item.before, item.after);
      case 'paragraphWithSpacing':
        return createParagraphWithSpacing(item.label, item.bold || false);
      case 'title':
        return createTitle(item.label);
      case 'subTitle':
        return createSubTitle(item.label);
      case 'boldUnderlinedParagraph':
        return createBoldUnderlinedParagraph(item.label);
      case 'keyValueParagraph':
        return createKeyValueParagraph(item.label, item.value);
      default:
        return null;
    }
  });

  return content;
}

export default AnnexesGermline;
