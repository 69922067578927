

// React and State management
import React, { useState } from "react";

// Material UI imports
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, DialogContentText, Typography, Box } from "@mui/material";

// Custom Components
import { GPAP_Button } from "../../../../../../../../../../gpap-lib/components/Button_Collection";

// API services
import { getVarSomeAnnotations } from "../../../../../../../../../../services/api/get";

function VarSomeDialog(props){


    const [open, setOpen] = useState(true);
    const [token, setToken]  = useState(false);
    const [loading, setLoading]  = useState(false);
    const [results, setResults]  = useState([]);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {

        setResults([]);
        props.handleVarsome();
    };

    const makeCall = () => {

        setLoading(true);

        // make call;

        let body_post = props.variants.map(obj => "chr" + obj.chrom + ":" + obj.pos + ":" + obj.ref + ":" + obj.alt)
        let data = {"variants": body_post};

        getVarSomeAnnotations( token, data )
            .then(r => r.json())
            .then(json => {

               setResults(json)
               setLoading(false);
               props.passAnnotations(json)

            })

    }

    const handleText = (e) => {

        setToken(e.target.value);

    }


    const renderTokenForm = () => {

        return <React.Fragment>
            <DialogContentText>
            To annotate the variants, please provide your personal VarSome API token. This will allow GPAP to collect <a href="https://varsome.com/about/resources/acmg-implementation/" target="_blank">ACMG classifications from VarSome.
                </a> VarSome's high-performance variant annotation Application Programming Interface (API) is a paid service that makes it possible to annotate variants using data from <a href="https://varsome.com/datasources/" target="_blank"> +130 genomic databases </a>, including the ACMG class. Contact the VarSome team and learn more about VarSome API <a href="https://landing.varsome.com/varsome-api" target="_blank">here</a>.  

            </DialogContentText>
            <TextField
                onChange={handleText}
                autoFocus
                margin="dense"
                id="token"
                label="Personal VarSome API token"
                type="password"
                fullWidth
                variant="standard"
            />
                <GPAP_Button variant="contained" onClick={makeCall} disabled={!token}>
                   Annotate variants
                </GPAP_Button>
        </React.Fragment>


    }

    const displayContent = () => {

        if(loading){
            return <CircularProgress/>
        }
        else if(token && results.length>0){
            return <Box>
                <Typography> Annotation Result </Typography>
                We collected annotations for {results.length} variants. You can now see them in the variant table.
                Annotations provided by <a href="https://varsome.com/" target="_blank">https://varsome.com/  </a> 
            </Box>
        }
        else{
            return renderTokenForm();
        }
    }

    return (
            <Dialog open={open} onClose={handleClose}>
                <img src={window.config.static_files + "/img/nextgpap/varsome_img.png"} style={{width:"200px", margin: "10px"}}/>
                <DialogTitle>Annotate with VarSome</DialogTitle>
                <DialogContent>
                { displayContent()}
                </DialogContent>

                <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
            </Dialog>
    );


}


export default VarSomeDialog;