

export const chipACMG = [
    {
        referenceColumn: "ACMG Classific.",
        cells: [
            {
                data: "Likely pathogenic",
                color: "#FD7645",
                textColor: "white",
            },
            {
                data: "Benign",
                color: "#31A354",
                textColor: "white",
            },
            {
                data: "Uncertain significance",
                color: "grey",
                textColor: "white",
            },
            {
                data: "Pathogenic",
                color: "#DD4434",
                textColor: "white",
            },
        ],
    },
]