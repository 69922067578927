import React, {useEffect, useState} from "react";
import {Button, Card, CardContent, Chip, Grid, Typography} from "@mui/material";
import {Link, useHistory} from "react-router-dom";
import Container from '@mui/material/Container';
import MappedText from "../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import Box from "@mui/material/Box";

import {useDispatch} from "react-redux";

// actions;
import {resetSamples} from "../../study-page/create-study/analysis-page/reducers/sample_actions";
import {reset_patients} from "../../study-page/create-study/analysis-page/components/clinical-info/patient_actions";
import {reset_filter_set, reset_selected_cohort} from "../../../actions";
import {resetStudySettings} from "../../dashboard/reducers/study_actions";
import {GPAP_Button} from "../../../gpap-lib/components/Button_Collection";
import Divider from "@mui/material/Divider";
import { useSelector } from 'react-redux';


export default function DirDashboard (){

    const history = useHistory();
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState(false);
    const DIR_ID = "U354627";
    const [initialized, setInitialized] = useState(false);

    const {allDataApis } = useSelector((state) => state.generaltable);

    useEffect(() => {

        if(!initialized){
            resetAll();
            setInitialized(true);
        }


    }, [])


    const handleButtonClick = () => {
        if (redirect) {
            history.push('/dir_overview/' + DIR_ID);
        }
    };


    const resetAll = () => {


        dispatch(resetSamples())
        dispatch(reset_patients())
        dispatch(reset_filter_set())
        dispatch(reset_selected_cohort())
        dispatch(resetStudySettings())
    }

    const getPriorityColor = (priority) => {
        const colors = {
            "High": "#C03B2E",    
            "Medium": "#F3B82A",  
            "Low": "#4CAF50"      
        };
        return colors[priority] || "grey";

    }

    const analysisType = useSelector(state => state.generaltable.allDataApis?.dirs?.analysis);

    const generaltable = useSelector(state => state.generaltable);
    console.log(generaltable);
    console.log("analysisType:", analysisType);
    const renderExampleDIRs = () => {


        let dirArray = [{ dir_id: "Z839524", patient_id: "P0879912", deadline: "Monday May 6th, 2024", priority: "Medium", analysis_type: "germline"},
        { dir_id: "Q858357", patient_id: "P0015715", deadline: "Monday May 6th, 2024", priority: "Medium", analysis_type: "somatic_tumor_normal"}]
        
        

        return dirArray.map(s => <Grid item lg={3}  m={2}>
            <Card >
                <CardContent m={6}>
                    <Typography variant="h5" mb={1}>{s.dir_id}</Typography>
                    <Chip label={s.analysis_type} size="small"/>
                    <Typography variant="body2" mt={1}><strong>Patient ID:</strong> {s.patient_id}</Typography> 
                    <Typography variant="body2">
                        <strong>Priority: </strong>
                        <Box 
                            component="span"
                            width={10} 
                            height={10} 
                            bgcolor={getPriorityColor(s.priority)}
                            borderRadius="50%"
                            display="inline-block"
                            mx={1} 
                        />
                        {s.priority}
                    </Typography>
                    <Typography variant="body2"><strong>Deadline:</strong>{s.deadline}</Typography>
                    <Box display="flex" justifyContent="flex-end">
                    <Button
                    variant="contained"
                        style={{
                            backgroundColor: "#3E69A9", 
                            color: "white",
                            borderRadius: "4px", 
                            textTransform: "none", 
                            padding: "6px 12px", 
                            marginTop: "8px", 
                            alignItems: "right",
                            textDecoration: 'none', 
                        }}
                    href={`#/dir_overview/${s.dir_id}`}
                >
                        <MappedText text={"Edit"}/>
                    </Button>
                    </Box> 
                </CardContent>
            </Card>
        </Grid>)


    }
    //value={allDataApis?.dirs?.analysis}


    return(
        <Container>
            <Grid container direction={"row"}>
            <Grid item lg={12}>
                <Typography variant={"h2"}>
                    <MappedText text = {"Dashboard"}/>
                </Typography>
            </Grid>
                <Grid item lg={3}>
                    <Box p={2}>
                        <Link to="/dir_list">
                            <Button variant="outlined"> <MappedText text ="View Request List"/></Button>
                        </Link>
                    </Box>
                </Grid>
                <Divider/>
                <Grid item lg={12}>
                    <Box p={2}>
                        <Typography variant={"h3"}>
                            <MappedText text = {"Recent Work"}/>
                        </Typography>
                    </Box>
                    <Box p={2}>
                        {renderExampleDIRs()}
                    </Box>
                </Grid>

            </Grid>
        </Container>


    )
}