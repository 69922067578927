


export const handleExperiments = (experiment, case_name, affectedStatus, member, actions) => {
    const experiments = Array.isArray(experiment) ? experiment : [experiment];

    experiments.forEach(exp => {
        const experimentType = exp.library_strategy;
        if (exp.ExperimentID !== "") {
            actions.setSamples({
                sample_id: exp.ExperimentID,
                pheno_id: member.display_name,
                sample_type: case_name,
                affectedStatus: affectedStatus,
                sample_from: undefined,
                gt: undefined,
                experiment_type: experimentType
            });
        }
    });
}