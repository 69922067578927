

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";



import useTableStyles from "../../../../../../../../../components/tables/ParticipantTable/styles_table";
import MappedText from "../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import Variant_Info from "../../../../variant-tabs/components/variant-info/Variant_Info";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";



export const GPAP_Table_Toolbar = (props) => {

    const classes = useTableStyles();
    const { numSelected, selected, type } = props;


    const renderVariantInfo = () => {

        return  <React.Fragment>
            <div style={{width: "99%"}}>
                <Variant_Info
                    tagged_variants={props.tagged_variants}
                    selected_variant={selected}
                    all_tagged_variants = { props.all_tagged_variants }
                    annotate_variants = {props.annotate_variants}
                    getAllTaggedVariants = {props.getAllTaggedVariants }
                    passAnnotations={ props.passAnnotations }  // not really used
                    dataType={props.dataType}
                />
            </div>
            {/*<Box p={2}>
                {(props.participants.length !== 0)
                    ? <Typography className={classes.title} color="inherit" variant="subtitle1"
                                  component="div">{props.participants.length} Participants Selected : {props.participants[0]}</Typography>
                    : null
                }
            </Box>*/}
        </React.Fragment>

    }
    return (
        <Toolbar
            style={{width:"100%", float :"left", height: "100%"}}
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}>
            {renderVariantInfo()}
        </Toolbar>
    );
};

GPAP_Table_Toolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};