



import React, {useEffect, useRef, useState} from 'react';
import { styled } from '@mui/material/styles'; // Replace makeStyles with styled
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { bindActionCreators } from 'redux';
import * as Actions from '../actions';
import { connect } from 'react-redux';
import { getAnalysisAnnotations } from '../getAnnotations';
import { variantFinder } from './variant_finder';
import { GPAP_Table_Toolbar } from './GPAP_Table_Toolbar';
import { getOMIMInfoElastic } from '../../../../../../../../../services/api/get';

// icons
import EnhancedTableHead from './EnhancedTableHead';
import GPAP_Table_Row from './gpap_table_row/GPAP_Table_Row';
import ErrorBoundaryGPAP from '../../../../../../../../../../ErrorBoundaryGPAP';

// MUI components
import Typography from '@mui/material/Typography'; // Updated import path
import Box from '@mui/material/Box'; // Updated import path
import MappedText from '../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText';
import {makeStyles} from "@mui/styles";

const order_clinVar = ["P", "LP", "VUS", "C", "LB", "B", "Drug", "Other", "NA"]


function descendingComparator(a, b, orderBy, orderByNumeric, orderDir) {

    let default_null = orderByNumeric ? -1 : "NA";

    if(orderBy === "clinvar_clnsig"){

        let A = (a[orderBy] !== undefined) ? a[orderBy].split("|")[0] : "NA";
        let B = (b[orderBy] !== undefined) ? b[orderBy].split("|")[0] : "NA";
        return order_clinVar.indexOf(B) - order_clinVar.indexOf(A)
    }
    else{
        let term_one = (b[orderBy]!== undefined) ? b[orderBy]: default_null;
        let term_two = ( a[orderBy]!== undefined) ?  a[orderBy] : default_null;

        if (term_one < term_two) {
            return -1;
        }
        if (term_one > term_two) {
            return 1;
        }
        return 0;
    }


}

function getComparator(order, orderBy, orderByNumeric) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy, orderByNumeric, order)
        : (a, b) => -descendingComparator(a, b, orderBy, orderByNumeric, order);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}





const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        float: "left"
    },
    paper: {
        width: '100%',
        marginBottom: 8,

    },
    container: {
        maxHeight: 640,
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));




function giveUniqueNames(data){

        if(data!==0 && Array.isArray(data)){
            return data.map(function(variant, index){

                variant["uniqueName"] = variant["gene_name"] + "_" + index;
                return variant;

            })
        }
        else{
            return [];
        }

}

function GPAP_Table(props) {

    const classes = useStyles();

    const INIT_RPP=10;
    const REQUEST_SIZE =  (window.config.max_sortable_svn_rows) ? window.config.max_sortable_svn_rows : 1000;
    const [rows,setRows] = useState(giveUniqueNames(props.data));
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('gene');
    const [orderByNumeric, setSortNumeric] = useState(false);
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(INIT_RPP);
    const [totalPages, setTotalPages] = useState(rows.length/INIT_RPP);
    const [loading, setLoading] = useState(false);
    const [currentFrom, setCurrentFrom] = useState(0);
    const [variantShow, setVariantShow] = useState([]);
    const [viewMode, setViewMode] = useState(true);
    const [OMIM, setOMIM] = useState([]);
    const [queryID, setQueryId] = useState(undefined);
    const [sortingAction, setSortingAction] = useState(false);

    const hasRunRef = useRef(false);
   

    //Get OMIM data
    const omimUrls = () =>{

        setOMIM([]);
           
        //let rows_to_query = props.data.slice(page* rowsPerPage, page * rowsPerPage + rowsPerPage)
        let rows_to_query = (sortingAction) ? stableSort(rows, getComparator(order, orderBy, orderByNumeric)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage): props.data.slice(page* rowsPerPage, page * rowsPerPage + rowsPerPage);

        const promises = rows_to_query.map(item => {
            let gene = (item.gene_name !== "" ? item.gene_name : 'nothing');
        
            return getOMIMInfoElastic(config.apis_configuration.api_nextgpap_server_elastic, gene, props.token)
                .then(response => {
                    if (!response.ok) {
                        // If response status is not OK, throw an error
                        return { error: true };
                    }
                    return response.json();
                })
                .then(data => {

                    // If data is not an array or has length <= 1, return it as is
                    if (!data || !Array.isArray(data) || data.length <= 1) {
                        return data; 
                    }
                    
                    // Else, Sort the data based on the presence and position of the gene in geneSymbols
                    return data.sort((a, b) => {
                        const indexA = a.geneSymbols.indexOf(gene);
                        const indexB = b.geneSymbols.indexOf(gene);
        
                        // If gene is not found in both a and b, keep their original order
                        if (indexA === -1 && indexB === -1) return 0;

                        // If gene is not found in a but found in b, b comes first
                        if (indexA === -1) return 1;

                        // If gene is found in a but not in b, a comes first
                        if (indexB === -1) return -1;

                        // If gene is found in both, sort by the position of the gene
                        return indexA - indexB;
        
                    });
                })
                .catch(error => {
                    console.error('Fetch error:', error);
                    return { error: true };
                });
        });
      
        Promise.all(promises)
            .then(results => {

            const numErrors = 5;

            // Mocking an array of Error instances
            //results = Array.from({ length: numErrors }, (_, index) => new Error(`Failed to fetch OMIM for index ${index}`));


            const _omims = results.map(result => {


              if (result instanceof Error || "error" in result) {
                  // Handle the case where the promise fails
                  return "Service Unavailable";
              } 

              //Directly from OMIM
              /*else if (result.omim.searchResponse.entryList.length > 0){

             let phenotype_list;

             if ((result.omim.searchResponse.entryList[0].entry.geneMap !== undefined) && (result.omim.searchResponse.entryList[0].entry.geneMap.phenotypeMapList !== undefined)){
               phenotype_list = result.omim.searchResponse.entryList[0].entry.geneMap.phenotypeMapList
             }

             else if (result.omim.searchResponse.entryList[0].entry.phenotypeMapList !== undefined) {
               phenotype_list = result.omim.searchResponse.entryList[0].entry.phenotypeMapList;
             } 

             else{
                phenotype_list = []
             }
              
              if ((phenotype_list && phenotype_list.length > 0)){

                let omim_results = phenotype_list.map(x => ({Phenotype: x.phenotypeMap.phenotype, Inheritance: x.phenotypeMap.phenotypeInheritance}))

                let f_omim_results = omim_results.map(function(item) {
                    let inheritance = (item["Inheritance"] !== null ? item["Inheritance"] : "Unknown inheritance")
                    let phenotype = item["Phenotype"]

                    return phenotype + "," + inheritance;
                  });
                
                return f_omim_results.join(";");
              }


              else{
                  return "No OMIM entry"
              }
             
            }*/


             //From our Elastic OMIM
             else if (result.length > 0){

             let phenotype_list = [];

             result.forEach((item) => {
                if (item.hasOwnProperty('phenotypeMapList')) {
                    item.phenotypeMapList.forEach((phenotypeMapObj) => {
                        if (phenotypeMapObj.hasOwnProperty('phenotypeMap')) {
                            phenotype_list.push(phenotypeMapObj);
                        }
                    });
                }
             });
             

             if ((phenotype_list && phenotype_list.length > 0)){

                let omim_results = phenotype_list.map(x => ({Phenotype: x.phenotypeMap.phenotype, Inheritance: x.phenotypeMap.phenotypeInheritance}))

                let f_omim_results = omim_results.map(function(item) {
                    let inheritance = (item["Inheritance"] !== null && item["Inheritance"] !== "" ? item["Inheritance"] : "Unknown inheritance")
                    let phenotype = item["Phenotype"]

                    return phenotype + "," + inheritance;
                  });
                
                return f_omim_results.join(";");
              }


              else{
                  return "No OMIM entry"
              }
             
            }


            else {
                return "No OMIM entry"
            }
          });

          setOMIM(_omims)
        })
            .catch(error => {
                // Handle any errors that occur during Promise.all
                return "Service Unavailable";
            })
            .finally(() => {
                // This block will execute regardless of whether the promises were resolved or rejected
                console.log("All promises have been settled.");
            });
      };


    // control Table Update when new data come in;
    useEffect(() => {

        if(props.loading!==loading ){
            setLoading(props.loading);
            setRows(giveUniqueNames(props.data));
            setVariantShow([]);
            setSelected([]);
        }
       /* else if(props.viewMode!==viewMode){
            setViewMode(props.viewMode);
            setRows(giveUniqueNames(props.data));
        }*/
        else if(props.data.length>0){
            if("acmg_verdict" in props.data[0]){
                setRows(giveUniqueNames(props.data));
            }
            setTotalPages(rows.length/rowsPerPage);
        }
        else if(props.data.length !== rows.length){
            // TODO: to carefully observe ....
            setRows(giveUniqueNames(props.data));
            setVariantShow([]);
            setSelected([]);

        }


    }, [props.loading, props.viewMode, props.data,  props.pid_array]); // Only re-run the effect if count changes

    useEffect(() => {

        const isCNVSelected = props.cnv_selected_row.length !== 0;

        console.log("GPAP Table")
        if (isCNVSelected && variantShow.length>0 && selected.length>0) {
            setVariantShow([]);
            setSelected([]);
            saveVariantToStore([]);
        } else {
            console.log("Keep all the same :)");
        }

        hasRunRef.current = true;
    }, [props.cnv_selected_row.length]);


     // Run this only when the page changes to call omim
     useEffect(() => {
      
        if (!props.loading)
         omimUrls();

        // if the query changes, bring the table again to page 0;
        if(queryID !== props.studySettings.current_query){
            setQueryId(props.studySettings.current_query);
            setPage(0);
            setTotalPages(rows.length/rowsPerPage);
        }

      }, [
          props.studySettings.current_query,
          props.loading, 
          page
         , props.pid_array.length,
         rowsPerPage
     ]);


     useEffect(()=> {
         updateSelectedVariantStatus();
     },[props.all_tagged_variants])

    useEffect(()=> {
        updateSelectedVariantStatus();
    },[props.all_tagged_variants])

     
     //Run this when the query tab changes to set default sorting props
     useEffect(()=> {
        setOrderBy('gene');
        setOrder('asc');
        setSortNumeric(false)
        setSortingAction(false)

    },[props.studySettings.current_query])


    //Run this when sorting is applied to call OMIM again
    useEffect(()=> {
        if (!props.loading)
            omimUrls();

    },[order, orderBy])

    
    const handleRequestSort = (event, property, numeric) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setSortNumeric(numeric);
        setSortingAction(true);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.gene_name );
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    const handleClick = (event, name) => {


        console.log("GPAP Row clicked");
        // get position instead;
        if(Array.isArray(selected) && selected.includes(name)){
                setSelected([]);
                getSelectedItem([]);

        }
        else{
            setSelected([name]);
            getSelectedItem([name]);

        }
    };

    const handleChangePage = (event, newPage) => {

        let baseline = currentFrom/rowsPerPage;
        let p = (baseline + newPage);
        let range = [currentFrom/rowsPerPage, totalPages];
        let bool = (p<range[0] || p>=range[1]);

        if( bool )
        {
          // eg. currentFrom = 0
          // rowsPerPage= 25
          let current_from = (newPage >0 ) ? currentFrom + REQUEST_SIZE : currentFrom - REQUEST_SIZE;
          setCurrentFrom(current_from);
          props.fetchData(current_from);
          setLoading(true);
          // e.g. 2000/25= 80 pages loaded
          setTotalPages((current_from + REQUEST_SIZE)/rowsPerPage) ;
          setPage(0)
        }
        else{
            setPage(newPage);
            setLoading(false);
        }
    };


    const getSelectedItem = (newSelected) => {
        if (!newSelected || newSelected.length === 0) {
            // Clear state if no selection
            saveVariantToStore([]);
            setVariantShow([]);
            return;
        }

        // Filter rows based on selection
        const selectedRows = rows.filter(row => newSelected.includes(row.uniqueName));

        // Find the first selected row
        const firstRow = rows.find(row => row.uniqueName === newSelected[0]);

        if (!firstRow) {
            console.warn("First selected item not found in rows.");
            return;
        }

        // Add tagging information to the selected rows
        const taggedRows = selectedRows.map(row => ({
            ...row,
            isTagged: isFlagged(firstRow),
        }));

        // Update state
        saveVariantToStore(taggedRows);
        setVariantShow(taggedRows);
    };


    const getDataType = () => {

        return (props.somatic_samples && props.somatic_samples.length > 0) ? "SNV_Somatic" : "SNV_Germline";

    }

    const saveVariantToStore = (variant) => {

        const variant_type = getDataType() ;

        if(variant.length === 0){
            props.actions.resetRow({
                data_type: variant_type,
            })
        }
        else{
            props.actions.setSelectedTableRows({
                data_row : variant,
                data_type: variant_type,

            })
        }



    }

    const updateSelectedVariantStatus = () => {

        if(variantShow.length>0){
            let copyVariant = [...variantShow];

            copyVariant.forEach(function(d){
                d["isTagged"] = isFlagged(copyVariant[0]);
            })

            saveVariantToStore(copyVariant);
        }

    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setTotalPages(rows.length/parseInt(event.target.value, 10));

    };


    const isSelected = (name) => selected.indexOf(name) !== -1;


    const isFlagged = (row) => {
        // chrom ,  start_pos, gene

        let annotations = getAnalysisAnnotations(props.studySettings, props.studyBucket);
        let tagged_variants = props.all_tagged_variants;

        let variant_found = variantFinder(row, annotations, tagged_variants, "SNV_Germline");

        if(variant_found!== undefined && variant_found.length!==0){

            return variant_found;
        }
        else{
            return false;
        }

    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);


    const getTableContent = () => {

        
        //OMIM data info for each row
        //let _sub_rows = rows.slice(page* rowsPerPage, page * rowsPerPage + rowsPerPage);
        let _sub_rows  = (sortingAction) ? stableSort(rows, getComparator(order, orderBy, orderByNumeric)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage): rows.slice(page* rowsPerPage, page * rowsPerPage + rowsPerPage);


        if (OMIM.length > 0){
          _sub_rows.forEach(function(element, i) {  _sub_rows[i].omim = OMIM[i] });
        }

        else {
         _sub_rows.forEach(function(element, i) {  _sub_rows[i].omim = "Loading..." });
        }

        return  stableSort(rows, getComparator(order, orderBy, orderByNumeric))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {

                const flagged = isFlagged(row);
                // flagged somewhere else

                const isItemSelected = isSelected(row.uniqueName);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <GPAP_Table_Row
                        row={row}
                        index = {index}
                        flagged = {flagged}
                        isItemSelected = {isItemSelected}
                        labelId= { labelId }
                        handleClick = {handleClick}
                        type={props.type}
                        studySettings = {props.studySettings}
                        studyBucket = {props.studyBucket}
                        annotations_cols = {(rows.length!==0 && "acmg_verdict" in rows[0])}
                        dataType={getDataType()}
                  />
                );
            })
    };

    const getText =() => ({ from, to, count }) => {

        let pageN = (currentFrom/rowsPerPage) + page;
        let pageN_label = (pageN === 0 ) ? "1" : pageN +1;
        return `Page ${pageN_label} , Displaying rows ${currentFrom+ from}-${currentFrom+ to} of total ${rows.length} `;



    };


    if(props.data.length === 0 && props.type=== "search_across" && (props.total>0 || props.pid_array.length>0)){
       return  <Box p={2}>
           <Typography variant={"h6"}>
           <MappedText text={"Load variants by clicking on a gene or experiment on the left, or a participant in the table above."} />
        </Typography>
       </Box>
    }
    else{
        return (
            <div className={classes.root} id={"gpap_table"}>
                <Paper className={classes.paper} elevation={0}>
                    <ErrorBoundaryGPAP/>
                    <GPAP_Table_Toolbar
                        numSelected={selected.length}
                        selected={variantShow}
                        participants = {props.participants}
                        selectedExperiments = {props.selectedExperiments}
                        tagged_variants = {props.all_tagged_variants}
                        total={props.total}
                        type={props.type}
                        all_tagged_variants = { props.all_tagged_variants }
                        annotate_variants = {props.annotate_variants}
                        getAllTaggedVariants = {props.getAllTaggedVariants }
                        passAnnotations={ props.passAnnotations }
                        dataType={getDataType()}
                    />
                    <ErrorBoundaryGPAP/>
                    <TableContainer
                        id={"style-9"}
                        className={classes.container}>

                        <Table
                            stickyHeader
                            aria-label="sticky table"
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}>
                            <EnhancedTableHead
                                type = {props.type}
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={props.total}
                                annotations_cols = {(rows.length!==0 && "acmg_verdict" in rows[0])}
                            />
                            <TableBody>
                                {(!loading)
                                    ? (rows.length>0)
                                        ? getTableContent()
                                        : <><TableRow></TableRow>
                                            <TableRow style={{background: "#fafafa"}}>
                                            <TableCell colSpan={20} align="center" style={{padding: "24px"}}>
                                                <Typography
                                                    variant="body1"
                                                    style={{
                                                        fontSize: '16px',
                                                        color: 'gray',
                                                        textAlign: 'left',
                                                        fontWeight: "bold",
                                                        paddingTop: "24px",
                                                        paddingLeft: "24px"
                                                    }}
                                                >
                                                    <MappedText text={"No variants have passed the selected filters."}/>
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    style={{
                                                        fontSize: '16px',
                                                        color: 'gray',
                                                        textAlign: 'left',
                                                        paddingLeft: "24px",
                                                        paddingBottom: "24px"
                                                    }}
                                                >
                                                    <MappedText text={"To display variants, try using less restrictive filters or expanding the gene list"}/>                                                
                                                </Typography>
                                            </TableCell>
                                        </TableRow></>
                                    : null }

                              {/*  {emptyRows > 0 && (
                                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}*/}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                      /*  loading={loading}*/
                        onPageChange={handleChangePage}
                        nextIconButtonProps={{disabled: ( loading || rowsPerPage > rows.length || (Math.ceil(totalPages)-1) === page) }}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelDisplayedRows={getText()}
                        backIconButtonProps={{disabled: currentFrom/rowsPerPage + page === 0}}

                        /*ActionsComponent={TablePaginationActions}*/
                    />
                    
                </Paper>

            </div>
        );
    }



}

function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {
            token: state.authorization.token,
            studySettings: state.studySettings,
            studyBucket: state.studyBucket,
            cnv_selected_row: state.selected_table_rows.find(s => s.data_type === "CNV").data_row,
            somatic_samples: state.sample_list.somatic_samples}
    }
    else{
        return {snv: []}
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});


export default connect(mapStateToProps,mapDispatchToProps)(GPAP_Table);
