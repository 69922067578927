import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { PhenostoreDiagnosis, PhenostoreObservedSymptoms  } from '../UtilCreateDir';
import MappedText from '../../reports/create-report/shared-components/mapText';
import DataField from '../../dir_overview/Datafield';

const PhenostoreData = ({ phenostoreInfo }) => {
    if (phenostoreInfo === undefined) return null;

    return (
        <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                <Typography variant={"h6"}> <MappedText text={"Patient and Clinical Info"}/> </Typography>
            </AccordionSummary>            
            <AccordionDetails sx={{backgroundColor: "#FAFAFA"}}>
                <Grid container sx={{ mt: 2, mb: 2 }}> 
                    {/*First Row*/}
                    <Grid item container>
                        <Grid item xs={12} sm={6} md={2}>
                            <DataField title="Patient ID" value={phenostoreInfo?.report_id} />
                        </Grid> 
                        <Grid item xs={12} sm={6} md={2}>
                            <DataField title="Sex" value={phenostoreInfo?.sex} />
                        </Grid> 
                    </Grid>
                    {/*Second Row*/}
                    <Grid item container sx={{ mt: 2, mb: 2 }}>
                        <Grid item xs={12} sm={6} md={6}>
                            <DataField title="Clinical referral" value={phenostoreInfo?.referral} />
                        </Grid>   
                        {/* Observed Symptoms */}      
                        <Grid item xs={12} sm={6} md={6}>
                            <Typography sx={{ color: '#626262', fontWeight:'400', fontSize:'14px' }}><MappedText text={"Observed Symptoms"}/></Typography>
                            <PhenostoreObservedSymptoms symptoms={phenostoreInfo?.features} />
                        </Grid>
                    </Grid>
                    {/*Third Row*/}
                    <Grid item container sx={{ mt: 2, mb: 2 }}>
                        <Grid item xs={12}>
                            <PhenostoreDiagnosis value={phenostoreInfo?.diagnosis} />
                        </Grid>  
                        <Grid item xs={12} sm={6} md={2}>
                            <DataField title="Cancer Type" value={phenostoreInfo?.cancer_type} />
                        </Grid>
                    </Grid>
                    {/*Fourth Row*/}
                    <Grid item container sx={{ mt: 2, mb: 2 }}>
                        <Grid item xs={12} sm={6} md={2}>
                            <DataField title="Case number pathology" value={phenostoreInfo?.case_number_pathology} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <DataField title="Treatment history" value={phenostoreInfo?.treatmenthistory} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <DataField title="Treatment response" value={phenostoreInfo?.treatment_response} />
                        </Grid>
                    </Grid>                                        
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}

export default PhenostoreData;
