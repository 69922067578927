
import React from "react";
import { StudyType } from "../StudyType";

// MUI Components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";


function CreateStudy(){

    return <Grid container direction={"row"}>
        <Grid item lg={12}>
            <Box p={2}>
                <Typography variant={"h5"}>Create a new Study</Typography>
            </Box>
        </Grid>
        <Grid item lg={12}>
            <Box p={2}>
                <StudyType/>
            </Box>

        </Grid>
    </Grid>



}


export default CreateStudy;