



import React from "react";
import RenderPatientCard from "./RenderPatientCard";
import LinearProgress from "@mui/material/LinearProgress";



const PatientCard = ({sampleID, phenostore_participants, sample_type, experiment_id}) => {

    let phenostore_id = "report_id";

    // move index participant in front;

    let pid_case = phenostore_participants.find(s => s[phenostore_id] === sampleID);
        if (pid_case!== undefined) {
            return <RenderPatientCard
                participant={pid_case}
                experiment_id = {experiment_id}
                sample_type = {sample_type}

            />
        }
    return  <div>
                <LinearProgress/>
            </div>
};



export default PatientCard;