export const prepareSNVData = (data_copy, selectedDataRow, rowDataType) => {
    let transcript = selectedDataRow["transcripts_all"].find(s => s.transcript_id === data_copy['transcript']);

    let cDNA = transcript ? transcript.codon_change : "";
    let protein = transcript ? transcript.amino_acid_change : "";
    let origin = (rowDataType === "SNV_Somatic") ? "somatic" : data_copy["origin"];

    return {
        sample: data_copy["sample"],
        gene: data_copy["gene"],
        rs: data_copy["rs"],
        chromosome: data_copy["chromosome"],
        transcript: data_copy['transcript'],
        ref: data_copy["ref"],
        alt: data_copy["alt"],
        start_pos: data_copy['start_pos'],
        zygosity: data_copy['zygosity'],
        cDNA,
        protein,
        inheritance: data_copy["inheritance"],
        origin,
        significance: data_copy["significance"],
        variant_source: "GPAP",
        comments: data_copy["comments"],
        project: data_copy["project"],
        variant_type: data_copy["variant_type"] || "",
        sv_type: data_copy["sv_type"] || "SNV-InDel"
    };
};