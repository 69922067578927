import { Box, Button, Checkbox, Dialog, DialogTitle, Typography } from "@mui/material";
import React from "react";
import BasicPopover from "../popovers/BasicPopover";
import { ViewColumn } from "@mui/icons-material";
import CaButton from "../../../../gpap-lib/components/shared-components/CaButton";
import MappedText from "../../../../scenes/instand/reports/create-report/shared-components/mapText";

const ButtonGroup = ({
  allColumns,
  getToggleHideAllColumnsProps,
  buttons,
  states,
  renderFeatures,
}) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', gap: "3px", width: "100%", }}>
   
      <Box display={"flex"} gap={"100px"}>

          <CaButton
            variant="outlined"
            onClick={handleDialogOpen}
            aria-controls="column-hiding"
            aria-expanded={dialogOpen}
            startIcon={<ViewColumn />}
            text={"Columns"}
          />
          {renderFeatures}
        </Box>

        {buttons &&
          buttons.map((button) => {
            if (button.type === "popover") {
              return (
                states.rowIdSelected && <BasicPopover
                states={states}
                text={button.nameButton}
                render={button.template}
                data={button.data}
                buttonProps={{
                  style: { padding: '5px 10px', textTransform: 'none',  color: '#4768a4', borderColor: '#4768a4' }, 
                  size: 'small',
                }}
              />
              );
            }

            return (
              states.rowIdSelected && (
                <Button
                  id="Buttons"
                  onClick={() => {
                    states.setOpen(true);
                    states.setRender(button.template);
                    states.setDataButton(button.data);
                  }}
                  style={{ textTransform: 'none' }} 
                  size="small"
                >
                  {button.nameButton}
                </Button>
              )
            );
          })
        }
      </div>
    
      <Dialog onClose={handleDialogClose} open={dialogOpen}>
        <DialogTitle style={{ textAlign: 'center', marginTop: '30px', marginBottom: '-20px' }}><MappedText text={"Column Hiding"}/></DialogTitle>
        <Box id="column-hiding" my={2}>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Checkbox {...getToggleHideAllColumnsProps()} indeterminate style={{marginBottom: '20px' }}  />
            <Typography style={{ fontWeight: 'bold', marginBottom: '20px',fontFamily: 'Roboto!important' }}><MappedText text={"Toggle All"}/></Typography>
          </Box>

          <Box display="flex" flexWrap="wrap">
            {allColumns.map((column, index) => (
              <Box key={column.id} flex="1" minWidth="50%">
                <Typography style={{ fontFamily: 'Roboto!important' }}>
                  <Checkbox {...column.getToggleHiddenProps()} />
                  {column.id.charAt(0).toUpperCase() + column.id.slice(1)}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default ButtonGroup;
