

export function getExperimentForMember(member, sample_array, aligned, selectedValue) {
    let experiment;

    if (!aligned) {
        if (selectedValue !== undefined && member.display_name === selectedValue.Participant_ID) {
            experiment = sample_array.items.find(s => s.Participant_ID === member.display_name && s.ExperimentID === selectedValue.ExperimentID);
        } else {
            // Adding all experiments for the member
            experiment = sample_array.items.filter(s => s.Participant_ID === member.display_name);
        }
    } else {
        experiment = sample_array.items.find(s => s.Participant_ID === member.display_name);
    }

    return experiment;
}