import React from 'react';

import MappedText from "./analysis-page/components/clinical-info/mapText";
import {makeStyles} from "@mui/styles";
import {Alert} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    alert: {
        margin: theme.spacing(2),
    },
}));

const WarningBox = ({ text, iconBool }) => {
    const classes = useStyles();

    const icon = (iconBool === undefined)

    return (
        <Alert severity="warning" className={classes.alert} icon={icon}>
            {typeof text === "string" ? <MappedText text={text} /> : text}
        </Alert>
    );
};

export default WarningBox;