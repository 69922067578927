import React, { useEffect,useState } from 'react';
import _, { set } from 'lodash';

import { experiments_view_new } from '../../../../services/api/get';

import auth from '../../../../services/session/Auth'
import {Autocomplete, TextField} from "@mui/material";

const config = window.config;
const URL_API_DM = config.apis_configuration.api_data_management; 


export const SearchDM = (props) => {

    const [tags, setTags] = useState([]);
    const [results, setResults] = useState([]);
    const [optionsText, setOptionsText] = useState("Start your search...");
    
    const REQUEST_N = 30000;

    const onTagsChange = (event, values) => { setTags(values);}

    const onSelectOpen = (event,value) => { 
        // 0. Treat Special case where a participant filter is set
        if (props.filterParticipant.filter && props.filterParticipant.participantId !== ""){
            let payload = { 
                page:  1, pageSize: REQUEST_N,
                filtered:[{id: "sample_type", value:[props.sampleType]},{id: "Participant_ID", value: [props.filterParticipant.participantId]}],
                fields:["ExperimentID"]
            };
            callExperimentsView(payload)
        }
    }

    useEffect(() => {
        // This will output an array of objects
        // given by Autocomplete options property.
        if(props.passSelected !== undefined ){
            if(tags !== undefined && tags !== null){
                props.passSelected(tags);
            } else {
                props.passSelected(undefined);
            }
        }
    },[tags])

    // Call REST DM Experiments View
    const callExperimentsView = (body) => {
        // Query API 
        experiments_view_new(URL_API_DM,auth.getToken(),body)
        .then((response) => {
            if (response.ok) return response.json();
            else throw new Error(response.statusText);
        })
        .then((data) => {
            if(data) setResults(data.items);
            else setOptionsText("Sorry! Connection Error to get experiments list!");
        })
        .catch((error) => {
            console.log(error);
            setOptionsText("Sorry! Connection Error to get experiments list!");
        });
    }

    const onInputChange = (event,value) => {
        // 0. Treat Special case where a participant filter is set
        let payload = {page:  1, pageSize: REQUEST_N};
        // 1. Check string size, wait for at least more than two letters
        if (value !== ""){
            if (value.length > -1) {
                if (value.length < 2) {
                    setOptionsText("Please keep typing, at least 2 characters required...");
                } else {
                    setOptionsText("Searching...");
                }
                // Enough characters to trigger queries
                if(value.length > 1) {
                    if (props.field === "tumor_experiment_id" || props.field === "control_experiment_id") {
                        // Define body post 
                        payload["filtered"] = [{id: "ExperimentID", value: [value]},{id: "sample_type", value:[props.sampleType]}];
                        payload["fields"] = ["ExperimentID"];
                        // Filter by participant if required
                        if (props.filterParticipant.filter) {
                            payload.filtered.push({id: "Participant_ID", value: [props.filterParticipant.participantId]});
                        } 
                    } else if (props.field === "tumor_participant_id" || props.field === "control_participant_id") {
                        // Define body post 
                        payload["filtered"] = [{id: "Participant_ID", value: [value]},{id: "sample_type", value:[props.sampleType]}];
                        payload["fields"] = ["Participant_ID"];
                    }   
                    // Query API 
                    callExperimentsView(payload)
                } else {
                    setResults([]);
                    setOptionsText("Keep typing...")
                }
            } else {
                setResults([]);
                setOptionsText("Start your search...");
            }
        }
    }

    //Field to show 
    let field = "ExperimentID";
    if (props.field === "tumor_participant_id" || props.field === "control_participant_id") {
        field = "Participant_ID"
    } 
    // Get Options to display on the serach box
    let optionsToDisplay = [];
    results.forEach((result) => {
        if(optionsToDisplay.find(option => option[field] === result[field]) === undefined){
            optionsToDisplay.push(result);
        }
    });

    return (
        <>
            <Autocomplete
                options={optionsToDisplay}
                noOptionsText={optionsText}
                autoHighlight={true}
                getOptionLabel={(option) => (option[field] !== undefined ? option[field] : "")}
                onChange={onTagsChange}
                onOpen={onSelectOpen}
                renderOption={(props, option, { inputValue }) => {
                    if (option[field] !== undefined) {
                        if (option[field].includes("matches")) {
                            return (
                                <li {...props} style={{ fontWeight: "bold" }}>
                                    {option[field]}
                                </li>
                            );
                        } else {
                            return (
                                <li {...props}>
                                    {option[field]}
                                </li>
                            );
                        }
                    }
                    return null; // Optional fallback
                }}
                onInputChange={_.debounce(onInputChange, 1000)} // Debouncing input changes to hit API
                getOptionDisabled={(option) => option[field]?.includes("matches")}
                renderInput={(params) => (
                    <TextField
                        error={props.error}
                        {...params}
                        variant="outlined"
                        label={props.title}
                        margin="normal"
                        fullWidth
                        helperText={props.errorMessage !== "" ? props.errorMessage : ""}
                    />
                )}
            />
        </>
    );
}