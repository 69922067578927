import React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';

const StyledChip = styled(Chip)(({ theme }) => ({
    backgroundColor: '#3182bd',
    color: '#fff',
}));

export const GPAPChip = ({ label, ...props }) => (
    <StyledChip
        label={label}
        {...props}
    />
);
