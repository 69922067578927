import React, {useEffect, useState} from "react";


// This is creating issues if we only do an import from mui5

import { AlertGPAP } from '../../../../../../../../gpap-lib/components/Alerts';
import {Alert, Snackbar} from "@mui/material";


const CustomNoGenotypeMessage = ({ show }) => {

    let msg = 'Custom Inheritance - You need to select at least one genotype per sample in order to continue'

    const [snackOpen, setSnackOpen] = useState(false);
    const [snackText, setSnackText] = useState(msg);
    const [snackColor, setSnackColor] = useState('warning');

    let vertical ="top";
    let horizontal = "center";

    useEffect(()=> {
        if (show){
            setSnackOpen(true)
        }
    },[show])


    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false)
    };


    //Render
    if (!show) return null;

    return (
        <React.Fragment>
            <Snackbar anchorOrigin={ {vertical, horizontal }} open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert
                    onClose={handleSnackClose}
                    severity={snackColor}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackText}
                </Alert>
            </Snackbar>


           {/* <Snackbar
                anchorOrigin={ {vertical, horizontal }}
                open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose}>
                <AlertGPAP onClose={handleSnackClose} severity={snackColor}>

                </AlertGPAP>
            </Snackbar>*/}
        </React.Fragment>

    );
};

export default CustomNoGenotypeMessage;