import React, {Component} from 'react'
import "./results.css";
import Variant_Table from "./components/variant-table/gpap-table/Variant_Table";
import GeneralQueryView from "./components/general-query-view/GeneralQueryView";

import {connect} from 'react-redux';
import {getTotalFromJson} from "./getTotalFromJson";
import createVariantRow from "./components/createVariantRow";

// MUI 5 imports
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {mergeHits} from "./mergeHits";

class Results extends Component {

    constructor(props){
        super(props);
        this.state = {
            index:0,
            annotations:[],
            cnvTotal: 0,
            cnvRegions:0
        };

        this.getTableData = this.getTableData.bind(this);
        this.setIndex = this.setIndex.bind(this);
        this.passAnnotations = this.passAnnotations.bind(this);
    }



    getTableData(variants){

        let data = [];
        const self = this;
        
        if(variants!== undefined){


            const { hits: { hits: variantHits } } = variants; // Destructuring for clarity
            const { summary } = this.props;

            const type = summary ? "search_across" : "default"; // Ternary operator cleaned up

            const mergedHits = type === "search_across" ? mergeHits(variantHits) : variantHits;



            mergedHits.forEach(function(hit){

                let obj = hit._source;

                // how many genes in effs;

                let gene_names = [...new Set(hit.fields.effs.map(s => s.gene_name))];

                // filter out undefined;

                gene_names = gene_names.filter(s => s !== undefined);

                gene_names.forEach(function(geneName){

                       let row=  createVariantRow(obj, hit, self.state.annotations, self.props.analysisType, self.props.samples, geneName);
                       data.push(row);

                })


            });
        }

        return data;
    }


    setIndex(index){
        this.setState({index: index})
    }

    passAnnotations(annotations){

        // parse annotations;

        this.setState({
            annotations: annotations
        })

    }

    getCNVTotal = (summary) =>{


        this.setState({
            cnvTotal: summary.tot,
            cnvRegions: summary.full_tot
        })

    }


    render() {

        let snv;
        let cnv;
        let self = this;
        let { variants } = this.props;
        let variant_data = undefined;

        
        if(variants!== undefined && variants.snv!== undefined){
            snv = variants.snv;
            //create Variant Table Data;
            variant_data = this.getTableData(snv);

        }  // extract results;


        const { summary } = this.props;

        const type = summary ? "search_across" : "default"; // Ternary operator cleaned up

        let snvTotal;
        if(type === "search_across"){
            snvTotal = variant_data?.length;
        }
        else{
            let {snv_total} = getTotalFromJson(variants)
            snvTotal = snv_total
        }

        const cnv_total = this.state.cnvTotal !== -1 ? this.state.cnvTotal : 0;
        const cnv_regions = this.state.cnvRegions !== -1 ? this.state.cnvRegions : 0;

        const total = snvTotal + cnv_total;

        let queryNumbers = {
            total: total,
            snv_total: snvTotal,
            cnv_total: cnv_total,
            cnv_regions : cnv_regions
        };

        let classSize = "results_view_compact";
        if (this.props.size === 'extended') classSize = "results_view_extended";

        return (
            <div className={classSize}>
                <Backdrop style={{color:"white", zIndex: "100"}}  open={self.props.loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                        <GeneralQueryView
                            variants_n = {queryNumbers}
                            queryRunner={self.props.run_query}
                        />
                        <Variant_Table
                            cnv_data = {true}
                            loading={self.props.loading}
                            loadingParticipants = {self.props.loadingParticipants}
                            variants = {variant_data}
                            setIndex = {self.setIndex}
                            total = {snvTotal}
                            type={self.props.type}
                            summary = {self.props.summary}
                            sample_agg = {self.props.sample_agg}
                            queryRunner={self.props.queryRunner}
                            runSearchAcrossQuery = {self.props.runSearchAcrossQuery}
                            fetchData={ self.props.fetchData}
                            sampleList = {self.props.sampleList}
                            participants = {self.props.participants}
                            switchAnalysis = {self.props.switchAnalysis}
                            switchQuery = {self.props.switchQuery}
                            all_tagged_variants = {self.props.all_tagged_variants}
                            getAllTaggedVariants = {self.props.getAllTaggedVariants}
                            passAnnotations = {self.passAnnotations}
                            resetAll = { self.props.resetAll }
                            requestStatus = {self.props.requestStatus}
                            getCNVTotal = {this.getCNVTotal}
                        />
            </div>
        )
    }
}


function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {
            patients: state.patients.patients,
            analysisType: state.studySettings.analysisType,
            samples: state.sample_list.samples
        }
    }
}



export default connect(mapStateToProps)(Results);
