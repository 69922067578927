import React from "react";
import {Box, Typography } from '@mui/material';
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    box: {
        padding: theme.spacing(3)
    },
    title: {
        marginTop: 30
    }
}));

const Success = () => {
    const classes = useStyles();
    return (
        <Box className={classes.box}>
            <Typography variant="h2" align="center">
                Thank you!
            </Typography>
            <Typography component="p" align="center" className={classes.title}>
                You will get an email with further instructions
            </Typography>
        </Box>
    );
};

export default Success;