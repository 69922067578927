export const prepareCNVData = (data_copy) => {
    return {
        sample: data_copy["sample"],
        gene: data_copy["gene"],
        rs: data_copy["rs"],
        chromosome: data_copy["chromosome"],
        transcript: data_copy['transcript'],
        ref: data_copy["ref"],
        alt: data_copy["alt"],
        start_pos: data_copy['start_pos'],
        end_pos: data_copy["end_pos"],
        zygosity: data_copy['zygosity'],
        inheritance: data_copy["inheritance"],
        origin: data_copy["origin"],
        significance: data_copy["significance"],
        variant_source: "GPAP",
        comments: data_copy["comments"],
        project: data_copy["project"],
        variant_type: data_copy["variant_type"] || "",
        sv_type: data_copy["sv_type"] || ""
    };
};