
import React, { useEffect, useState } from "react";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// MUI Components
import { Box, Typography } from '@mui/material';

// Custom Components
import MappedText from '../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText';

// Actions
import * as Actions from "../../../../../../../../../../actions";

function Chromosome_Selected(props){

    const [chrom_list, setListChrom] = useState([]);
    useEffect(function(){

        if(props.query.chrom!== undefined){
            setListChrom(props.query.chrom)
        }


    }, [props.query, props.form])



    return <Box p={2} >
        {(chrom_list.length!== 0 ) ? <Typography variant={"subtitle1"}><MappedText text={"Selected Positions"}/></Typography> : "" }
            <div>
                {
                    chrom_list.map(chr => <div> <strong>Chr:</strong> {chr.chrom}, <i>start pos:</i> {chr.pos},  <i>end pos.</i> {chr.end_pos}</div>)
                }
            </div>
        </Box>



}


function mapFormValuesToProps (state) {
    if(state.authorization!==undefined)
    {

        return {
            form: state.form,
            query: state.query };

    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});



export default connect(mapFormValuesToProps,mapDispatchToProps)(Chromosome_Selected);