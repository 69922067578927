import Chart from "../../../../../../../../gpap-lib/components/bar-chart/Chart";
import React,{useState} from "react";
import {bindActionCreators} from "redux";
import * as Actions from "../../../../../../../../actions";
import {connect} from "react-redux";


import GPAP_Tooltip from "../../../../../../../../gpap-lib/components/GPAP_Tooltip";

import {GPAP_Button_Light} from "../../../../../../../../gpap-lib/components/Button_Collection";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import {BarChartOutlined} from "@mui/icons-material";
import CaButton from "../../../../../../../../gpap-lib/components/shared-components/CaButton";





function ChromosomeChart(props){

    const [checked, setChecked] = useState(false);


    const handleChange = () => {

        setChecked(!checked);

    };


    const renderChromosomeChart = () => {

        if(props.total>1000){
            return  <div className="Chart"> <Typography>Only Variants from Chromosome 1 are shown.</Typography></div>
        }
        else if(props.data.length === 0){
            return null;
        }
        else{
            return <Chart initData = {props.data} data = {props.data} chartTitle = {""} selectedData={props.selectedData}/>
        }

    };




    return <div style={{textAlign:"left", marginLeft: "10px"}}>
        <GPAP_Tooltip title="# variants grouped by chromosome" >
            <CaButton variant={"outlined"}
                      text={"Chromosome Summary"}
                      startIcon={   <BarChartOutlined/>}
                      onClick={ handleChange } />
        </GPAP_Tooltip>
        <div>
            <Collapse in={checked}>
                {renderChromosomeChart()}
            </Collapse>
        </div>
    </div>

}


function mapStateToProps (state) {
    if(state.selected_variant!==undefined) {

        return {selectedData: {d: parseInt(state.selected_variant.chrom)}}

    }
        else{
            return {selectedData:{d: undefined}}

        }

}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});



export default connect(mapStateToProps,mapDispatchToProps)(ChromosomeChart);




