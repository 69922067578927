import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { get_sample, experiments_view_new, getParticipant } from '../../../services/api/get';
import auth from '../../../services/session/Auth';
import ExperimentData from './components/ExperimentData';
import PhenostoreData from './components/PhenostoreData';


const config = window.config;
const URL_API_DM = config.apis_configuration.api_data_management;
const phenostore_api = window.config.apis_configuration.api_phenostore;

export const ExperimentParticipantMetadata = (props) => {

    const [dmExperimentInfo, setDmExperimentInfo] = useState();
    const [dmSampleInfo, setDmSampleInfo] = useState();
    const [phenostoreInfo, setPhenostoreInfo] = useState();
    const [isLoading, setLoading] = useState(true);

    const {experimentId, sampleType, analysis_type} = props;

   

    //Load Information from Experiment Datamanagement and Participant
    useEffect(() => {
        if (experimentId === "") return;
        // 1 Body post definition to get Experiment DM information
        const body = { 
            "page": 1, "pageSize": 2,
            "fields": ["ExperimentID","Participant_ID","Submitter_ID", "library_strategy","kit","sample"],
            "filtered": [ {"id":"ExperimentID", "value": [experimentId]}]  
        };
        // 2 Query DM experiment view 
        experiments_view_new(URL_API_DM, auth.getToken(),body)
        .then((response) => {
            if (response.ok) return response.json();
            else throw new Error(response.statusText);
        })
        .then((expResponse) => {
            const experiments = expResponse.items;
            if (experiments.length > 0) {
                setDmExperimentInfo(experiments[0]);
                // 3 Get Sample linked to Experiment
                const sampleId = experiments[0].sample;
                if (sampleId !== null && sampleId !== "" && sampleId !== "None") {
                    get_sample(URL_API_DM, auth.getToken(), sampleId)
                    .then((response) => {
                        if (response.ok) return response.json();
                        else throw new Error(response.statusText);
                    })
                    .then((sampleResponse) => {
                        setDmSampleInfo(sampleResponse);          
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });        
    }, [experimentId]);


    //Load Participant Information
    useEffect(() => {
        if (dmExperimentInfo != undefined) {
            getParticipant(phenostore_api, auth.getToken(),dmExperimentInfo.Participant_ID)
            .then((response) => {
                if (response.ok) return response.json();
                else throw new Error(response.statusText);
            })
            .then((phenoResponse) => {
                //Store Phenostore response
                setPhenostoreInfo(phenoResponse);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
        }
    }, [dmExperimentInfo]);
    
    if (isLoading && experimentId !== ""){
        return (
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
        );
    } else if (experimentId === "") {
        return null;
    }

    return(
        <>
            {/* Patient and clinical information */}
            <PhenostoreData phenostoreInfo={phenostoreInfo} />
            {/* Experiment and Sample information */}
            <ExperimentData 
                dmExperimentInfo={dmExperimentInfo} 
                dmSampleInfo={dmSampleInfo} 
                sampleType={sampleType} 
            />
        </>
    );
}
