

import {convertInchesToTwip, Paragraph, TextRun} from "docx";

const fontDoc= "Times New Roman";

const table_margins = {
    top: convertInchesToTwip(0.20),
    bottom: convertInchesToTwip(0.20),
    left: convertInchesToTwip(0.20),
};


function getKeyValField(key,val){

    let value = (val!== "") ? val : "NA";

    return new Paragraph({
        children: [new TextRun({text: key,  bold: true, font: fontDoc, size: 22*2}), new TextRun({text: value, font: fontDoc, size: 22})],
    })
}

function bodyText(text){

    return new TextRun({
        text: text,
        font: fontDoc,
        size: 22
    })

}




const TEXT_FOR_NEGATIVE_SNV_SOMATIC = {key:"negative_snv_somatic", text: "No oncogenic or likely oncogenic single nucleotide variant(s) or small indel(s) have been detected. This result does not exclude a possible genetic origin as the cause of the disorder."};
const TEXT_FOR_NEGATIVE_SNV_GERMLINE = {key:"negative_snv_germline", text:"No pathogenic or likely pathogenic single nucleotide variant(s) or small indel(s) have been detected. This result does not exclude a possible genetic origin as the cause of the disorder."};
const TEXT_FOR_NEGATIVE_CNV_SOMATIC = {key:"negative_cnv_somatic", text:"No oncogenic or likely oncogenic copy number variant(s) or structural variant(s) have been detected. This result does not exclude a possible genetic origin as the cause of the disorder"};
const TEXT_FOR_NEGATIVE_CNV_GERMLINE = {key:"negative_cnv_germline", text:"No pathogenic or likely pathogenic copy number variant(s) or structural variant(s) have been detected. This result does not exclude a possible genetic origin as the cause of the disorder"};

const TEXT_FOR_NEGATIVE_CLINICAL_EVIDENCE = {key:"negative_clinical_evidence", text:"No clinical evidences have been detected"};

const NEGATIVE_TARGETED_GENES={key: "no_genes", text: "No genes were targeted"}


export {table_margins, getKeyValField,  bodyText, TEXT_FOR_NEGATIVE_SNV_SOMATIC, TEXT_FOR_NEGATIVE_SNV_GERMLINE,TEXT_FOR_NEGATIVE_CNV_SOMATIC, TEXT_FOR_NEGATIVE_CNV_GERMLINE, TEXT_FOR_NEGATIVE_CLINICAL_EVIDENCE, fontDoc, NEGATIVE_TARGETED_GENES}