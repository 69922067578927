



import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "./external-sources.css";

// Local Component Imports
import TreeViewObject from "./tree-view-object/TreeViewObject";
import WarningAlert from "./WarningAlert";

// Custom Components
import { GPAPChip } from "../../../../../../../../gpap-lib/components/GPAPChip";
import MappedText from "../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";

// Configuration Files
import { configuration_tree, external_resources_config } from "../../../../../../../../config-files/external_resources_config";
import configuration_tree_CNVs from "../../../../../../../../config-files/external_resources_cnvs_config";
import { configuration_tree_somatic } from "../../../../../../../../config-files/external_resources_somatic_config";


// A Window for all the external resources available when a variant is selected;




const textDict = [
    { key: "SNV_Germline", text: "SNV Germline variant selected. Click to navigate to resource" },
    { key: "SNV_Somatic", text: "Somatic SNV variant selected. Click to navigate to resource" },
    { key: "CNV", text: "CNV variant selected. Click to navigate to resource" },
]


const links_config = [
    { key: "SNV_Germline", config: configuration_tree },
    { key: "SNV_Somatic", config: configuration_tree_somatic },
    { key: "CNV", config: configuration_tree_CNVs },
];

const noGeneLabel = {label: "No gene selected"};

const genomeBuilds = [
    { key: "hg37", label: "Genome build: GRCh37 / hg19" },
    { key: "hg38", label: "Genome build: GRCh38 / hg38" }
];

class ExternalLinks extends Component {

    constructor(props){
        super(props);
        this.state = {
            gene: noGeneLabel.label,
            position: "pos",
            links_configuration : false,
            data_type: false,
            variant_current: false,
            geneId:false,
            ensembl_gene_id: false
        };

        this.createListItem = this.createListItem.bind(this);
        this.renderTreeView = this.renderTreeView.bind(this);
        this.updateStateFromProps = this.updateStateFromProps.bind(this);


        // Accessing the correct genome build based on window.config.genome_ref
        this.genomeRef = genomeBuilds.find(g => g.key === (window.config.genome_ref === "hg38" ? "hg38" : "hg37")).label;

    }



    componentDidUpdate(prevProps, prevState) {
        // Check if selected_table_rows prop has changed
        if (this.props.selected_table_rows !== prevProps.selected_table_rows) {
            this.updateStateFromProps();
        }
    }

    updateStateFromProps() {
        const { selected_table_rows } = this.props;
        let variant_current = false;
        let data_type = false;
        let geneId = this.state.geneId; // Keep the current geneId
        let ensembl_gene_id = this.state.ensembl_gene_id;
        let links_configuration;

        // Find the selected data row
        const selectedDataRow = selected_table_rows.find(s => s.data_row.length > 0);

       

        if (selectedDataRow) {
            const variantObj = selectedDataRow.data_row;
            data_type = selectedDataRow.data_type;

            if (typeof data_type !== "string") {
                console.warn("data_type is not a string:", data_type);
            }

            const linksConfigItem = links_config.find(item => item.key === String(data_type));
            links_configuration = linksConfigItem ? linksConfigItem.config : null;

            if (variantObj.length > 0 && textDict.find(item => item.key.toString() === data_type.toString())) {
                variant_current = variantObj[0];
            }

            let geneField = (typeof data_type === "string" && data_type.toUpperCase() === "CNV") ? "Gene_name" : "gene_name";
            let geneName = ( typeof data_type === "string" && data_type.toUpperCase() === "CNV" && Array.isArray(variant_current[geneField]) && variant_current[geneField].length>0)
                ? variant_current[geneField][0]
                : variant_current[geneField];

            if (variant_current && variant_current[geneField] !== undefined) {
                // Fetch gene ID only if gene name is different
                if (variant_current[geneField] !== this.state.variant_current[geneField]) {

                    fetch(`https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=gene&term=${geneName}[Gene Name]&retmode=json`)
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error(`Network response was not ok: ${response.statusText}`);
                            }
                            return response.json();
                        })
                        .then((data) => {
                            if (data.esearchresult && data.esearchresult.idlist.length > 0) {
                                this.setState({ geneId: data.esearchresult.idlist[0] });
                            } else {
                                console.error('No gene ID found');
                            }
                        })
                        .catch((error) => {
                            console.error('Error fetching gene ID:', error);
                        });
                }

                let ensembl_gene_id = false;
                if (variant_current.transcripts_all && variant_current.transcripts_all.length > 0) {
                    ensembl_gene_id = variant_current.transcripts_all[0].gene_id;
                }

                this.setState({
                    gene: geneName,
                    position: variant_current.pos,
                    variant_current,
                    links_configuration,
                    data_type,
                    geneId, // Restore the current geneId
                    ensembl_gene_id

                });
            }
        }
        else{
            this.setState({
                gene: noGeneLabel.label,
                position: "pos",
                links_configuration : false,
                data_type: false,
                variant_current: false,
                geneId:false,
                ensembl_gene_id: false
            });
        }
    }




    createListItem(variable, selected_variant){

        let gene_name_config_vals = external_resources_config.find(c => c.key === variable).values;


        return gene_name_config_vals.map(function(val){
            let link = '';
            if(selected_variant!== false){
                link = val.url_prefix+ selected_variant[variable] + val.url_appendix;
                if (variable === "ensembl_gene_id" && selected_variant.ensembl_gene_id) {
                    link = val.url_prefix + selected_variant.ensembl_gene_id + val.url_appendix;
                }
            }
            return (
                <div className={"resource_link"} key={val.label}>
                <Button disable={isNaN(link)} size={"sm"} color={"primary"} href={link} target={"blank"}>
                {val.label}
            </Button>
                </div>)
        })
    }






    renderTreeView(item,  variant_current, gene, pos, expanded, index, data_type){


        // features // todo: think later where to put this it.. only one resource needs the hpos at the moment..

        let {samples, patients} = this.props;

        let hpo = []
        if(variant_current && variant_current.hasOwnProperty("samples_germline") && Array.isArray(variant_current["samples_germline"])){
            let sample_ids = variant_current.samples_germline.map(s => s.sample);
            sample_ids.forEach(function(id){

                let v_sample = samples.find(s => s.sample_id === id)
                if(v_sample!== undefined){
                    let pid = v_sample.pheno_id;
                    let f_pat = patients.find(pat => pat.id === pid);
                    if(f_pat!== undefined){
                        hpo = hpo.concat(f_pat.features);
                    }

                }
            })
        }



        return <TreeViewObject
            key={index}
            expanded={ expanded }
            data = {item}
            variant_current={variant_current}
            gene={gene}
            pos={pos}
            hpos={hpo}
            data_type = {data_type}
        />


    }




    render() {

        const self = this;

        let {gene,position, links_configuration, data_type, variant_current, geneId, ensembl_gene_id} = self.state;


        // Only render this...taking variable from state;


        let variant_selected;
        if(variant_current){
            variant_selected = {...variant_current};
            variant_selected.geneId = geneId;
            variant_selected.ensembl_gene_id = ensembl_gene_id;
        }

        // Assuming data_type is a string and item.key is a number
        const textItem = textDict.find(item => String(item.key) === String(data_type));

        return (<div className={'mycontainer'} style={{  padding: "10px"}}>
            <Typography variant={"h6"}><MappedText text={"External Links"}/></Typography>
            <GPAPChip
                size="small"
                label={self.genomeRef}
            />


            {(variant_current === undefined || variant_current === false)
                ? <WarningAlert/>
               /* ? <Typography variant={"subtitle2"}><WarningIcon color="secondary" style={{margin:"5px 5px 0 0" }}/>
                    <MappedText text="No variants selected. Please select a variant to display the links"/>
            </Typography>*/
                : <Typography variant={"subtitle2"}><MappedText text={textItem.text} /></Typography>}
            <div className={"list_group_container"}>
                {
                    Object.keys(links_configuration).map(function(keyItem, index){
                return self.renderTreeView(links_configuration[keyItem], variant_selected, gene, position, index === 0, index, data_type)})
                }
            </div>
        </div>)

    }
}


function mapStateToProps (state) {
    if(state.selected_variant!==undefined)
    {
        return {
            selected_variant: state.selected_variant,
            selected_table_rows: state.selected_table_rows,
            samples : state.sample_list.samples,
            patients: state.patients.patients
        }
    }
    else{
        return {selected_variant: [],    selected_table_rows: []}
    }
}

export default connect(mapStateToProps)(ExternalLinks);