
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import CustomTable from "../../../components/tables/general-table/components/tables/CustomTable";
import { PATIENT_DATA_HEADER } from "./config/patient_data_header";

export default function PatientReportTable() {

  const token = useSelector(state => state.authorization.token);
  const { id } = useParams(); 
  const url = `${window.config.apis_configuration.api_nextgpap_server}/reports_by_dir/${id}`;
  const urlDownload = `${window.config.apis_configuration.api_nextgpap_server}`;
  const refresh = useSelector((state) => state.generaltable.refresh);


  return (
    <Grid container direction={"row"}>
      <Grid item lg={12}>
        <CustomTable
          key={refresh}
          tableName={"patient"}
          url={url}
          urlDownload={urlDownload}
          token={token}
          idRelation={"patient_report_id"}
          locationData="patient_reports"
          dataHeader={PATIENT_DATA_HEADER}
          reportType="patient"
          customFeatures={[
            { toggle: false },
            { expanding: false },
            { showHeader: false },
            {
              actions: {
                downloadDoc: true,
                delete: true,
              },
            },
          ]}
          buttons={[
            {
                nameButton: "Upload",
              },
          ]}
        />
      </Grid>
    </Grid>
  );
}
