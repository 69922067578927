


import React from 'react'
import GPAP_Table from "./GPAP_Table";
import {CssBaseline} from "@mui/material";



  function SNV_Table(props) {
  
    return (
      <React.Fragment>
         <CssBaseline/>
         <GPAP_Table
             // props need for tag and label
             tagged_variants = {props.tagged_variants}
             all_tagged_variants = { props.all_tagged_variants }
             annotate_variants = {props.annotate_variants}
             getAllTaggedVariants = {props.getAllTaggedVariants }
             passAnnotations={ props.passAnnotations }
             // other props
             type={props.type}
             total={props.total}
             loading={props.loading}
             fetchData={props.fetchData}
             queryRunner={props.queryRunner}
             data={props.data}
             selectedExperiments = {props.selectedExperiments}
             participants = {props.participants}
             pid_array={props.pid_array}
         />

         </React.Fragment>
      
    )
  }
  
  export default SNV_Table
