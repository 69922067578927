


// React Imports
import React, { useEffect, useState } from 'react';

// Material UI Imports
import { makeStyles } from '@mui/styles'; // Updated to @mui/styles for MUI v5
import { Dialog, DialogTitle, IconButton } from '@mui/material'; // Updated to @mui/material
import CloseIcon from '@mui/icons-material/Close'; // Updated to @mui/icons-material

// Redux Imports
import { bindActionCreators } from 'redux';
import * as SidebarActions from '../../../../components/SideNavigation/sidebar_actions';
import { connect } from 'react-redux';

// Custom Components Imports
import DrawerProvider from '../genomics-dashboard/components/DrawerProvider';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    drawer_root:{
        left: "64px",
        width: "450px",
    },
    dialogTitle : {
        justifyContent: "space-between",
        alignItems: "end",
        padding: 0
}
});

function GPAP_Drawer(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    useEffect(() => {
       setOpen(props.open)
    }, [props.open]);


    const handleClose = () => {
        setOpen(false);
        props.sidebarActions.setView({page: "home"});
    };


    return (
            <Dialog
                maxWidth={"lg"}
                onClose={handleClose}
                open={open}>
                <DialogTitle disableTypography className={classes.dialogTitle}>
                    <IconButton style={{float: "right"}} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                        <DrawerProvider
                            samples={props.samples}
                            resetQuery = {props.resetQuery}
                            queryRunner = { props.queryRunner }
                            variants = {props.variants}
                            queryViewer = { props.queryViewer }
                            queryDelete = { props.queryDelete }
                            cohorts={props.cohorts}
                            filters = {props.filters}
                            sidebarPage={props.sidebarSettings.page}
                        />
            </Dialog>


    );
}



function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {token: state.authorization.token, sidebarSettings: state.sidebarSettings}
    }
}
const mapDispatchToProps = (dispatch) => ({
    sidebarActions: bindActionCreators(SidebarActions, dispatch)
});



//export default ViewManager;

export default connect(mapStateToProps, mapDispatchToProps)(GPAP_Drawer);