// React and Redux imports
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as StudyActions from "../../../../../../reducers/study_actions";
import * as SidebarActions from "../../../../../../../../components/SideNavigation/sidebar_actions";

// MUI 5 imports
import { Box, Grid, TextField, InputAdornment } from "@mui/material";
import { green } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";

// MUI Icons
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpIcon from '@mui/icons-material/Help';

// Custom Components and Libraries
import GPAP_Tooltip from "../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import { GPAP_Button_Light } from "../../../../../../../../gpap-lib/components/Button_Collection";
import SaveDialog from "../toolbar/save_dialog/SaveDialog";
import ShareDialog from "../toolbar/share/ShareDialog";
import AnalysisCompleteDialog from "./analysis-complete-dialog/AnalysisCompleteDialog";
import QueryNameEdit from "./QueryNameEdit";
import AddAnalysisButton from "./AddAnalysisButton";
import SamplesIcon from "./SamplesIcon";
import Tour from "reactour";
import { tourSteps } from "../../../../../../../../config-files/tourSteps";
import { get_all_studies } from "../../../../../../../../services/api/manage_study";
import MappedText from "../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";

// CSS import
import "./general-query-view.css";

// Helper imports
import { getCurrentAnalysis } from "../variant-table/getAnnotations";
import CaButton from "../../../../../../../../gpap-lib/components/shared-components/CaButton";



function StudyInfoEdit (props)  {

    const [edit, setEditing] = useState(false);
    const [name, setName] = useState(props.studyName);
    const [tourOpen, setTour] = useState(false);
    const [studies_not_loaded, setStudiesLoaded] = useState(false);
    const [study_names, setStudyNames] = useState([]);

    useEffect(() => {
        if (!studies_not_loaded) {
            get_all_studies(config.apis_configuration.api_nextgpap_server, props.token)
                .then(resp => resp.json())
                .then(json => {
                    if (json.message === "Success") {
                        let names = json.data.filter(s => s.owner === true).map(s => s.studyName);
                        setStudiesLoaded(true); // Set this here to avoid infinite loop
                        setStudyNames(names);
                    }
                });
        }
    }, [studies_not_loaded, props.token]);


    useEffect(function(){

        setName(props.studyName);

    },[props.studyName])


    const handleChange = (evt) => {
        setName(evt.target.value)
    };

    const saveName = () => {
        setEditing(false);

        props.studyActions.change_study_name({
            studyID: props.studyID,
            studyName: name
        })
    };

    const noEdit = () => {

        let studyName = (props.studyName!== name) ? name  : props.studyName;
        let analysis = getCurrentAnalysis(props.studySettings,props.studyBucket);
        let n_samples = 0;
        if(analysis && analysis.analysis!== undefined){
            n_samples = analysis.analysis.hasOwnProperty("samples") ? analysis.analysis.samples.length : "NA";
        }


        return <React.Fragment>
            <Grid
                id={"study_toolbar"}
                container
                  direction="row"
                  alignItems="flex-start">

                <Grid item lg={12}>
               {/* <AddAnalysisButton queryRunner={props.queryRunner}/>*/}
                    <SaveDialog
                        list_of_studies = {study_names}/>
                    <div className={"tools_box"}>
                       {/* <GPAP_Tooltip title="Current work">
                            <IconButton id="list_work" aria-label="history" onClick={()=> props.sidebarActions.setView({page:"history"})}>
                                <StorageIcon />
                            </IconButton>
                        </GPAP_Tooltip>*/}
                        <ShareDialog
                            study_id_db = {props.studySettings.study_id_db}
                        />

                        <GPAP_Tooltip title="Participants">
                            <IconButton id="see_participants"
                                        color={"primary"}
                                        aria-label={<MappedText text={"Save"} />}
                                        onClick={()=>           props.sidebarActions.setView({page:"cases"})}>
                            <SamplesIcon n_samples={n_samples}/>
                            </IconButton>

                        </GPAP_Tooltip>
                        <AnalysisCompleteDialog/>
                    </div>
                    <div className={"info_box"} >
                        {
                            (!edit)
                            ? <div className={"info_box"}>
                                    <b>Study:</b> {studyName} </div>
                                : editing()
                        }

                        <div style={{float:"left"}}><div className={"info_box"} > <b>Analysis:</b> {props.analysisName ? props.analysisName : ''}  </div></div>
                        <QueryNameEdit
                            queryName={ props.queryName}
                            variants_n = { props.variants_n }/>
                    </div>
                    <GPAP_Tooltip title={"Not sure what to do? Start a tour of the dashboard"}>
                        <div style={{float:"right", display: "block"}}>
                            <CaButton variant={"outlined"}
                                      text={"Get a tour"}
                                      onClick={setTourState}
                                      startIcon={<HelpIcon/>}/>
                        </div>
                    </GPAP_Tooltip>
                </Grid>
            </Grid>
        </React.Fragment>
    };

    const editing = () => {
        return <React.Fragment>
            <div style={{float: "left"}}>
                <TextField
                    id="standard-basic"
                    label={"editing"}
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EditIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <IconButton onClick={() => saveName()} color={green[500]}><CheckCircleIcon/></IconButton>
            </div>
        </React.Fragment>
    };

    const setTourState = () => {

        setTour(!tourOpen);

    }

    return <Box m={1}>
        <Tour
            steps={tourSteps}
            isOpen={tourOpen}
            onRequestClose={setTourState}
        />
        { noEdit() }
    </Box>

}



function mapStateToProps (state) {

    if(state.authorization!==undefined)
    {

        return {
            studySettings: state.studySettings,
            studyBucket: state.studyBucket,
            patients: state.patients,
            token: state.authorization.token,
        }


    }

}

// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    studyActions: bindActionCreators(StudyActions, dispatch),
    sidebarActions: bindActionCreators(SidebarActions, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(StudyInfoEdit);