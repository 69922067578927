import { AlignmentType, HeadingLevel, Paragraph } from "docx";
import { drawBasicTable } from "../../write-sections/write_utils/drawTables_examples.js";
import { renderToStringAlt } from "../../../create-report/shared-components/renderToString.js";

const headers = [
    "Gene",
    "Genotype",
    "Phenotype",
    "Haplotype1",
    "Haplotype2",
    "Variant Data",
];

const dataKeys = [
    "gene",
    "genotype",
    "phenotype",
    "haplotype1",
    "haplotype2",
    "variant_data",
];

function WritePharmacogenomicsSection(sectionData) {
    let rows = sectionData && Array.isArray(sectionData) ? sectionData : [];

    if(sectionData !== null && sectionData.rows){

        rows = sectionData.rows;

    }


    return [
        drawBasicTable(rows, headers, dataKeys, renderToStringAlt("No haplotypes have been detected")),
      
        new Paragraph({
            text: "",
        }),
    ];
}

export default WritePharmacogenomicsSection;