

import StarIcon from "@mui/icons-material/Star";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CancelIcon from "@mui/icons-material/Cancel";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";



const labelIcons = {
    "Candidate": StarIcon,
    "Follow-Up": WatchLaterIcon,
    "Discarded": CancelIcon,
    "Important": PriorityHighIcon
};

export default labelIcons