
import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';


import _ from 'lodash';
import {connect} from "react-redux";
import {getField} from "./getField";
import {TextField} from "@mui/material";
import ExperimentInfo from "./ExperimentInfo";
import RenderOption from "./RenderOption";
import {filterOptions} from "./filterOptions";
import {fetchSearchResults} from "./fetchSearchResults";



class SearchID extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tags: [],
            results: [],
            options_text: "Start your search..."
        };
        this.onTagsChange = this.onTagsChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);

        this.REQUEST_N = 30000;

    }


    onTagsChange = (event, values) => {
        this.setState({
            tags: values
        }, () => {
            // This will output an array of objects
            // given by Autocomplete options property.
            if(this.props.passSelected !== undefined ){

                if(values!== undefined && values!== null){
                    this.props.passSelected(values);
                }
                else{
                    this.props.passSelected(undefined);
                }

            }
        });
    };

    onInputChange(event, value) {
        let field = "report_id";
        if (this.props.field !== undefined) {
            field = this.props.field;
        }

        let totalRows = this.REQUEST_N;

        if (value !== "") {
            if (value.length > -1) {
                if (value.length < 2 && (this.props.sampleType === "report_id" || this.props.sampleType === "id" || this.props.sampleType === "famid")) {
                    this.setState({ options_text: "Please keep typing, at least 2 characters required...", error_msg: false });
                } else {
                    this.setState({ options_text: "Searching...", error_msg: false });
                }

                if (value.length > 1 && (this.props.sampleType === "report_id" || this.props.sampleType === "id" || this.props.sampleType === "famid")) {
                    fetchSearchResults(value, this.props.sampleType, this.props.token, field, totalRows)
                        .then((results) => {
                            this.setState({
                                results: results,
                                options_text: results.length === 0 ? "No options" : this.state.options_text
                            });
                        })
                        .catch((error) => {
                            this.setState({ options_text: error });
                        });
                } else if (this.props.sampleType === "case" || this.props.sampleType === "LocalExperimentID") {
                    fetchSearchResults(value, this.props.sampleType, this.props.token, field, totalRows)
                        .then((results) => {
                            this.setState({
                                results: results,
                                options_text: results.length === 0 ? "No options" : this.state.options_text
                            });
                        })
                        .catch((error) => {
                            this.setState({ options_text: error });
                        });
                }
            } else if (this.props.sampleType === "case" || this.props.sampleType === "LocalExperimentID") {
                this.setState({ results: [], options_text: "Keep typing..." });
            } else {
                this.setState({ results: [], options_text: "Start your search..." });
            }
        }
    }





    handleFilterOptions = (options, state) => {
        return filterOptions(options, state, this.props.sampleType, this.state.results);
    };

    render() {

        let field = getField(this.props.sampleType);
        let options_display = [];

        // process results;
        this.state.results.forEach(function(s){
            if (options_display.find(opt => opt[field] === s[field]) === undefined){
                options_display.push({...s})
            }
        })

        return (
            <React.Fragment>
                <Autocomplete
                    filterOptions={this.handleFilterOptions}
                    options={options_display}
                    noOptionsText={this.state.options_text}
                    autoHighlight={true}
                    getOptionLabel={(option) => (option[field] !== undefined ? option[field] : "")}
                    onChange={this.onTagsChange}
                    renderOption={(props, option) => (
                        <RenderOption props={props} option={option} field={field} />
                    )}
                    onInputChange={_.debounce(this.onInputChange, 1000)} // Debounce remains unchanged
                    getOptionDisabled={(option) =>
                        option[field] ? option[field].includes("matches") : false
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label={this.props.title}
                            margin="normal"
                            fullWidth
                        />
                    )}
                />

                <ExperimentInfo error_msg={this.state.error_msg}
                                tags={this.state.tags}
                                field={field}/>
            </React.Fragment>
        );
    }
}


function mapPatients (state) {
    if(state.authorization!== undefined)
    {
        return {token: state.authorization.token}
    }
    else{
        return {token: ""}
    }

}




export default connect(mapPatients,null)(SearchID);