
export function getCNVSummary(items){



    const full = items.filter(s => s["Annotation_mode"] === "full").length
    const split = items.filter(s => s["Annotation_mode"] === "split").length
    const tot = items.length;

    const summary = {
        full_tot: full,
        split_tot: split,
        tot: tot
    }

    return summary;

}