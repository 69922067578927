
export const dialogConfig = {
    clinical: {
      title: "Delete Clinical Report?",
      text: "You’re about to delete this clinical report. This process cannot be undone.",
    },
    patient: {
      title: "Delete Patient Report?",
      text: "You’re about to delete this patient report. This process cannot be undone.",
    },
    dir: {
      title: "Delete Request?",
      text: "You’re about to delete this request. This process cannot be undone.",
    },
  };