

import React from "react";
import {checkIfNameExists, getText} from "../../../../../side-panels/filter-panel/components/query-top-bar/func";
import MappedText
    from "../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";



const getHelperText = (currentStudy, studyDetails, list_of_studies) => {

    let actionType = (currentStudy && currentStudy.id !== undefined)
        ? "update"
        : "save";

    //if (currentStudy.studyName !== studyDetails["studyName"]) {

    if(checkIfNameExists(studyDetails["studyName"], list_of_studies, actionType))
    {
        return getText(studyDetails["studyName"],
                "study",
                actionType)

    }
    else {
        return <MappedText text="Study title. Max 50 chars"/>;
    }

}

export default getHelperText;