

import React from "react";
import DatabaseDataRequests from "../bottom-tab-views/DatabaseDataRequests";
import MappedText from "../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";


function DatabasesAccordion(props){



    return <div>
        <Box p={1}>
            <Typography variant={"h6"}><MappedText text="External Databases"/></Typography>
            <DatabaseDataRequests/>
        </Box>
    </div>
}


export default DatabasesAccordion;