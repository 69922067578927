import React from 'react';
import { Typography } from '@mui/material';
import { ExpandMoreOutlined } from '@mui/icons-material';
import RightAlignedAccordionSummary from "../../../../../../../../../components/tables/ParticipantTable/IconRightExpansionSummary";

const RightAlignedSummary = ({ tooltipContent, infoIconElement }) => {
    return (
        <RightAlignedAccordionSummary
            style={{
                backgroundColor: "#ffffff", // Set background to white
                display: 'flex', // Ensure elements align properly
                justifyContent: 'space-between' // Push the expand icon to the right
            }}
            expandIcon={<ExpandMoreOutlined />} // Down arrow on the right
            IconButtonProps={{ edge: 'end' }} // Ensure expand icon is on the right
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography
                style={{
                    flex: '1 1 100%',
                    color: "#000000" // Set title text color to black
                }}
                variant="h6"
                component="div"
            >
                {tooltipContent !== "" && (
                    <div>{tooltipContent} {infoIconElement}</div>
                )}
            </Typography>
        </RightAlignedAccordionSummary>
    );
};

export default RightAlignedSummary;
