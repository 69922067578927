import React, {Fragment, useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {GPAP_Button} from "../../../../gpap-lib/components/Button_Collection";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import * as Actions from "../../../../actions";
import * as SampleActions from "../analysis-page/reducers/sample_actions";
import * as StudyActions from "../../../dashboard/reducers/study_actions";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {WarningMessage} from "./elements";
import GPAP_Tooltip from "../../../../gpap-lib/components/GPAP_Tooltip";
import BackToStudy_Button from "../BackToStudy_Button";
import {CohortSelectSection, FilterSection, GeneSelection, Inheritance} from "./sections";
import {sectionsForm, sectionsCohortForm} from "../../../../config-files/search_across_stepform_sections";
import QueryGenerator from "../../../dashboard/components/genomics-dashboard/components/query-generator/QueryGenerator";
import {createQuery, prepareCohort} from "./utils";
import {getCurrentAnalysis} from "../../../dashboard/components/genomics-dashboard/components/results/components/variant-table/getAnnotations";
import {getSingleCohort} from "../../../../services/api/get";
import {date_generator} from "../../../func/helpers";
import BackToButton from "../analysis-page/components/step-form/BackToButton";
import formatSamples from "../analysis-page/formatSamples";
import CaButton from "../../../../gpap-lib/components/shared-components/CaButton";



function SearchAcrossAll(props){

    const [studyInit, setupStudy] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [cohortSamples, setCohortSamples] = useState(false);
    const [selectedCohort, setCohort] = useState(false);
    // stepperHandler
    const [activeStep, setActiveStep] = useState(0);

    const [SA_Samples,setSASamples] = useState([]);


    // Proceed to next step
    const handleNext = () => {
        window.scrollTo(0, 0)
        setActiveStep((prev) => prev + 1);
    }
    // Go back to prev step
    const handleBack = () => setActiveStep((prev) => prev - 1);



    useEffect(function(){


        if(!studyInit){
            setupStudy(props.studyType);
            if(props.studyType === "search_across"){
                getSamples()
            }
            if(props.studyType === "cohort"){

                let selected_id = props.selected_cohort;
                /*if(props.cohortID){
                    selected_id = props.cohortID;
                }*/
                if(props.selected_cohort!== null){

                    getSingleCohort(config.apis_configuration.api_cohortapp, props.token, selected_id)
                        .then(r => r.json())
                        .then(cohort =>{
                            setupCohortData(cohort.cohort);
                            setActiveStep(1);
                            setInitialized(true);
                        })
                }
            }
        }

    });

    const getCohort = (cohort) => {
        getSamples(cohort);
        setInitialized(true);
    }


    const setupCohortData = (cohort) => {

        // cohort case;

        let samples = [];

        let samplesList = cohort.data;
        // avoid saving all samples.

        samplesList.forEach(function(sampleID){
            samples.push({"sample_id":sampleID,"gt":[{inheritance_key: "custom", setting: Array(0), label: "Custom Inheritance", "dp":10,"gq":30,"ad_low":0.2,"ad_high":0.8,"index":1}]});
        })

        setCohortSamples(samples);
        setCohort(cohort);


    }


    const getSamples= (cohort) => {

        let { studySettings, sampleActions } = props;
        let samples = [];
        // search across samples;
        // SEARCH ACROSS ALL
        if(cohort === undefined){

            let sample_all = {
                "sample_id":"ALL_SAMPLES",
                "gt":[
                    {inheritance_key: "custom",
                        setting: Array(0),
                        label: "Custom Inheritance",
                        "dp":10,"gq":30,"ad_low":0.2,"ad_high":0.8,"index":1}]
            };

            samples = [sample_all];

            sampleActions.setSamples({
                    sample_id: "ALL_SAMPLES",
                    pheno_id:  "",
                    sample_type: "other",
                    affectedStatus:"yes",
                    sample_from: "search_across",
                    gt: sample_all.gt
            })
            setSASamples(samples);
        }
        else{

            // set up cohort data for this component;
            setupCohortData(cohort);
            let gt_standard = [{inheritance_key: "custom", setting: Array(0),label: "Custom Inheritance","dp":10,"gq":30,"ad_low":0.2,"ad_high":0.8,"index":1}]
            sampleActions.setSamples({
                sample_id:"ALL_SAMPLES",
                pheno_id: "",
                sample_type: "other",
                affectedStatus: "yes",
                sample_from: "cohort",
                gt: gt_standard
            });


        }


        setInitialized(true);

    }

    const getAnalysisSamples = () => {

        let { studySettings, samples } = props;
        return (studySettings.analysisType === "search_across")
            ? JSON.parse(JSON.stringify(samples))
            : [{cohort_id: selectedCohort.id, cohortName: selectedCohort.cohortName, sample_id: "ALL_SAMPLES"}];

    }


    const assignAnalysis = () => {

        let { studySettings } = props;
        let studyActions = props.studyActions;

        // check the inheritances and create the analysis;
        let date = date_generator();

        // create analysis
        const analysis_id = Math.floor(Math.random() * 200);


        let analysis_samples = getAnalysisSamples();

        let new_analysis = {
            "studyLocalId": studySettings.id,
            "localId": analysis_id,
            "analysis_type": studySettings.analysisType,
            "inheritance_mode_id": 0,
            "creationDate": date,
            "samples": analysis_samples,
            "description": "",
            "queries": [],
            "labelled": {},
            "tagged": []
        };


        // add analysis to the study; we should specify to which study;
        studyActions.create_analysis(new_analysis);

        studyActions.setStudySettings(
            {  current_analysis: analysis_id,
               analysis_ids: [{id:0, label:"Custom Inheritance"}]
            }
        );

        return analysis_id;

    };


    const add_new_query =  (new_analysis_id, samples) => {

        let { studyActions, studySettings, query }  = props;

        let date = date_generator();

        const localID = new_analysis_id + "_" + 1;
        // extra fields

        let extra = [...props.applied_lists];

         if(props.studyType === "search_across"){
                 query.samples_germline = formatSamples(samples, 0);
             }
             else{
                 query.samples_germline = formatSamples(cohortSamples, 0);
          }

        const data_query = {
            study_id: studySettings.id ,
            analysis_id: new_analysis_id,
            query: createQuery(localID, new_analysis_id, query, date, extra)
        };


        studyActions.create_query(data_query);
        studyActions.setStudySettings({current_query: localID, current_analysis: new_analysis_id });

    }

    const handleRun = () => {

        // create query...

        let { studyActions, studySettings, samples }  = props;

        // assign Analysis First;

        let new_analysis_id = assignAnalysis()


        // check if inheritance is configured.
         const GT_SETTING = samples[0].gt_settings[0];
         if( GT_SETTING.setting.length!== 0 ){
            // format cohort samples correctly

             let GT_SETTINGS_COPY = {...GT_SETTING};

            // create analysis

            // run query
             if(props.studyType === "cohort"){
                 // extract samples gt settings
                 // here samples is a ALL_SAMPLES
                 let cohort = prepareCohort(cohortSamples, samples);
                 // update cohort settings in the analysis object;

                 let samples_cohort = getAnalysisSamples();
                 samples_cohort.forEach(el => el["gt"]= [GT_SETTINGS_COPY]);

                 studyActions.update_analysis_samples(samples_cohort, new_analysis_id, studySettings.id )

                 add_new_query(new_analysis_id, samples_cohort);
                 props.queryRunner(cohort)
            }else{
                 add_new_query(new_analysis_id, samples);
                 props.queryRunner()
            }

        }
        else{
            alert("Configure the inheritance to run a query")
        }

    };



    const searchAcrossStart = () => {

        return   <Fragment>
            <Typography variant={"h5"}
                        textAlign={"center"}
                        color={"textSecondary"}>
                Search Across All
            </Typography>
            <div style={{ marginTop: "1%" }}>
                {(initialized)
                    ? <Typography variant={"body1"}>Study Created</Typography>
                    : <GPAP_Button onClick={() => assignAnalysis()}>Create Analysis</GPAP_Button>
                }
            </div>

        </Fragment>

    };

    const cohortAnalysisStart = () => {
        return <Fragment>
            <Grid item lg={2}>
                <Typography variant={"h5"}
                            color={"textSecondary"}>Cohort Analysis</Typography>
            </Grid>
        </Fragment>

    };

    const isInheritanceConfigured = () => {
        let {samples} = props;
        return  samples.length !== 0 && ("gt_settings" in samples[0]) && samples[0].gt_settings[0].setting.length !== 0;

    }

    const areGenesSelected = () => {
        let {query} = props;
        let disabled = true;
        if (query.gene !== undefined && query.gene.length !== 0) {
            disabled = false
        }

        return disabled;

    }

    const isCohortInitialized = () =>
    {
        return initialized;
    }

    const RunButton = () => {

        let {studySettings, studyBucket} = props;

        let disabled = areGenesSelected();
        let samples_exist = isInheritanceConfigured();
        let current_analysis= getCurrentAnalysis(studySettings,studyBucket);
        //let samples_assigned_to_analysis =  current_analysis !== undefined && current_analysis.analysis.samples!== undefined;

        if(!disabled && samples_exist ){
            return <React.Fragment>
                <GPAP_Tooltip title={"Select the Inheritance type and at least one gene to run the query"}>
                <span>
                     <CaButton
                         text={"Run Query"}
                         onClick={handleRun}
                         disabled={disabled || !(samples_exist)}/>
                </span>
                </GPAP_Tooltip>
            </React.Fragment>
        }
        else{
            return null;
        }

    }

    const handleSteps = (step) => {


        const renderInheritance= <Grid item lg={8}>
            <Box m={1} style={{textAlign: "center"}}>
                {(props.studyType !== "search_across")
                ? <CaButton
                        text={"Back"}
                        variant="contained"
                        disabled={false}
                        color="primary"
                        startIcon={<ArrowBackIcon/>}
                        onClick={handleBack}

                    />
                : null }
                <CaButton
                    text={"Next"}
                    variant="contained"
                    disabled={!isInheritanceConfigured()}
                    color="primary"
                    startIcon={<ArrowForwardIcon/>}
                    onClick={handleNext}/>

            </Box>
            <Inheritance
                analysisType={props.studySettings.analysisType}
            />
        </Grid>

        const renderGenes = <Grid item lg={8} style={{width: "100%"}}>
            <Box m={1} style={{textAlign: "center"}}>
                <CaButton
                    text={"Back"}
                    variant="contained"
                    disabled={false}
                    color="primary"
                    startIcon={<ArrowBackIcon/>}
                    onClick={handleBack}
                />
                <CaButton text={"Next"}
                    variant="contained"
                    disabled={areGenesSelected()}
                    startIcon={<ArrowForwardIcon/>}
                    color="primary"
                    onClick={handleNext}/>

            </Box>
            <GeneSelection genes={props.geneList}/>
        </Grid>

        const renderFilters =  <Grid item lg={8} style={{width: "100%"}} >
            <Box m={1} style={{textAlign: "center"}}>
                <CaButton text={"Back"}
                          variant="contained"
                    disabled={false}
                    color="primary"
                    startIcon={<ArrowBackIcon/>}
                    onClick={handleBack}
                />
                {RunButton()}
            </Box>
            <FilterSection geneTab={false} />
        </Grid>

        const renderCohortSelect = <Grid item lg={8}>
            <Box m={1} style={{textAlign: "center"}}>
                <CaButton
                    text={"Next"}
                    variant="contained"
                    disabled={!isCohortInitialized()}
                    color="primary"
                    startIcon={<ArrowForwardIcon/>}
                    onClick={handleNext}/>

            </Box>
            <CohortSelectSection
                cohort_id_from_url={props.cohortID}
                assignAnalysis={getCohort}
            />
        </Grid>


        if(props.studyType !== "search_across"){
            switch (step) {
                case 0:
                    return renderCohortSelect;
                case 1:
                    return renderInheritance
                case 2:
                    return renderGenes
                case 3:
                    return renderFilters

                default:
                    break;
            }
        }
        else{
            switch (step) {
                case 0:
                    return renderInheritance
                case 1:
                    return renderGenes
                case 2:
                    return renderFilters
                default:
                    break;
            }
        }



    };



    const renderSection = () => {

        let sections = (props.studyType === "search_across") ? sectionsForm : sectionsCohortForm;

        return  <React.Fragment><QueryGenerator/>
           <Grid container
                 direction={"column"}
                 justifyContent="center"
                 alignItems="center"
                 spacing={2}>

               {(activeStep === 1) ? <Grid item lg={6}><WarningMessage/></Grid> : null}

               <Grid item lg={6}>
                   <Stepper
                       activeStep={activeStep}
                       alternativeLabel>
                       {sections.map((label) => (
                           <Step key={label}>
                               <StepLabel>{label}</StepLabel>
                           </Step>
                       ))}
                   </Stepper>
               </Grid>
               {handleSteps(activeStep)}
           </Grid>

        </React.Fragment>


    };

    return <Fragment>
        <Grid container direction={"row"}>
            <Grid item lg={10}>
                <GPAP_Tooltip title={"Please mind that an unsaved study will be lost if you go back to the homepage"}>
                    <BackToButton page={"/home"}/>
                </GPAP_Tooltip>
                <BackToStudy_Button/>
            </Grid>
                {(studyInit === "search_across")
                    ?  <Grid item lg={2} style={{textAlign: "end", paddingRight: "50px"}}> {searchAcrossStart()} </Grid>

                    :  <Fragment> {cohortAnalysisStart()} </Fragment>
                }

        </Grid>

        <div className={"whiteContainer"}>

            {(initialized && props.studyType === "search_across")
                ? <Fragment>  {renderSection() }
                    <div style={{textAlign: "center"}}>
                    { RunButton()}
                    </div>
            </Fragment>
                : null
            }
            {(props.studyType!== "search_across")
                ? <Fragment>  {renderSection() }
                    <div style={{textAlign: "center"}}>
                </div> </Fragment>
                : null
            }

        </div>

    </Fragment>




}



//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        if(state.sample_list!== undefined)
        {
            return {
                token:state.authorization.token,
                studySettings: state.studySettings,
                studyBucket: state.studyBucket,
                query: state.query,
                geneList: state.geneList.geneList,
                samples: state.sample_list.samples,
                applied_lists: state.geneList.applied_lists,
                selected_cohort: state.selected_cohort
            }
        }
        else{
            return {
                samples: [],
                token:state.authorization.token
            }
        }
    }
}

// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
    studyActions: bindActionCreators(StudyActions, dispatch),
    sampleActions: bindActionCreators(SampleActions, dispatch)
});




export default connect(mapStateToProps,mapDispatchToProps)(SearchAcrossAll);