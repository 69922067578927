

export const evidences_config = [
    {key: 'OVS1', values :[
        {
            'category':'OVS1',
            'evidence_family': 'Predictive Data',
            'comment' : 'Null variant in a tumour suppressor gene where loss of function causes cancer',
            'points': '8',
            'color': '#A32D1D'
        },
    ]},
    {key: 'OS1', values :[
        {
            'category':'OS1',
            'evidence_family': 'Predictive Data',
            'comment' : 'Same amino acid changed as previously established oncogenic variant.',
            'points': '4',
            'color': '#DC5B4F'
        },

    ]},
    {key: 'OS2', values :[
        {
            'category':'OS2',
            'evidence_family': 'Functional Data',
            'comment' : 'Multiple entries in ClinVar or analysis from expert group reported  as Oncogenic.',
            'points': '4',
            'color': '#DC5B4F'
        },

    ]},
    {key: 'OS3', values :[
        {
            'category':'OS3',
            'evidence_family': 'Cancer Hotspots',
            'comment' : 'Cancer hotspot with ≥ 50 samples with a somatic variant at the same amino acid position AND the same amino acid change count in  ≥ 10 samples',
            'points': '4',
            'color': '#DC5B4F'
        },

    ]},
    {key: 'OM1', values :[
        {
            'category':'OM1',
            'evidence_family': 'Functional Data',
            'comment' : 'Located in an active site domain identified with InterPro',
            'points': '2',
            'color': '#F27645'
        },

    ]},
    {key: 'OM5', values :[
        {
            'category':'OM5',
            'evidence_family': 'Functional Data',
            'comment' : 'Multiple entries in ClinVar or analysis from expert group report as Pathogenic in a disease other than cancer.',
            'points': '2',
            'color': '#F27645'
        },

    ]},
    {key: 'OM2', values :[
        {
            'category':'OM2',
            'evidence_family': 'Predictive Data',
            'comment' : 'Protein length change in relavent TSG or Oncogene',
            'points': '2',
            'color': '#F27645'
        },
    ]},
    {key: 'OM3', values :[
        {
            'category':'OM3',
            'evidence_family': 'Cancer Hotspots',
            'comment' : 'if Category_Evidence has Cancer_Hotspot then: “Cancer hotspot identified with < 50 samples with a somatic variant at the same amino acid position AND the same amino acid change count in ≥ 10 samples” If Category_Evidence has COSMIC: “Variant is also found as a Tier3/other mutation in the COSMIC database. ',
            'points': '2',
            'color': '#F27645'
        },
    ]},
    {key: 'OM4', values :[
        {
            'category':'OM4',
            'evidence_family': 'Predictive Data',
            'comment' : 'Missense variant at an amino acid residue where a different missense variant determined to be oncogenic through ≥ BLOSUM score',
            'points': '2',
            'color': '#F27645'
        },
    ]},
    {key: 'OP1', values :[
        {
            'category':'OP1',
            'evidence_family': 'Computational Evidence',
            'comment' : 'If REVEL  >= 0.82855 “In-silico tool REVEL had a dbNSFP rank REVEL score of [REVEL column] which exceeds the oncogenic threshold of 0.82855” if CADD >= 0.55040 “In-silico tool CADD had a dbNSFP rank CADD score of [CADD column] which exceeds the oncogenic threshold of 0.55040” if SIFT >= 0.45756 “In-silico tool SIFT had a dbNSFP rank SIFT score of [SIFT column] which exceeds the oncogenic threshold of 0.45756” if MutationTaster >= 0.50225 “In-silico tool MutationTaster had a dbNSFP rank MutationTaster score of [MutationTaster column] which exceeds the oncogenic threshold of 0.50225”',
            'points': '1',
            'color': '#F3B82A'
        },
    ]},
    {key: 'OP3', values :[
        {
            'category':'OP3',
            'evidence_family': 'Cancer Hotspots',
            'comment' : 'Cancer hotspot and the particular amino acid change count is below 10',
            'points': '1',
            'color': '#F3B82A'
        },
    ]},
    {key: 'OP4', values :[
        {
            'category':'OP4',
            'evidence_family': 'Population Data',
            'comment' : 'Extremly low frequency in gnomAD population databases OR AF is absent in population databases',
            'points': '1',
            'color': '#F3B82A'
        },
    ]},
    {key: 'SBP1', values :[
        {
            'category':'SBP1',
            'evidence_family': 'Computational Evidences',
            'comment' : 'If REVEL  <= 0.52089 “In-silico tool REVEL had a dbNSFP rank REVEL score of [REVEL column] which was below the benign cutoff threshold of 0.52089” if CADD <= 0.17764 “In-silico tool CADD had a dbNSFP rank CADD score of [CADD column] which was below the benign cutoff threshold of 0.17764” if SIFT <= 0.04126 “In-silico tool SIFT had a dbNSFP rank SIFTscore of [SIFT column] which was below the benign cutoff threshold of 0.04126” if MutationTaster  <= 0.11847 “In-silico tool MutationTaste had a dbNSFP rank MutationTaste score of [MutationTaste column] which was below the benign cutoff threshold of 0.11847”',
            'points': '-1',
            'color': '#8BD452'
        },
    ]},
    {key: 'SBP2', values :[
        {
            'category':'SBP2',
            'evidence_family': 'Predictive Data',
            'comment' : 'Silent mutation with no impact on splicing',
            'points': '-1',
            'color': '#8BD452'
        },
    ]},
    {key: 'SBS1', values :[
        {
            'category':'SBS1',
            'evidence_family': 'Population Data',
            'comment' : 'Popultation frequency between 1-5%  in gnomAD population databases',
            'points': '-4',
            'color': '#4CAF50'
        },
    ]},
    {key: 'SBS2', values :[
        {
            'category':'SBS2',
            'evidence_family': 'Functional Data',
            'comment' : 'Multiple entries in ClinVar or analysis from expert group report as Benign',
            'points': '-4',
            'color': '#4CAF50'
        },
    ]},
    {key: 'SBVS1', values :[
        {
            'category':'SBVS1',
            'evidence_family': 'Population Data',
            'comment' : 'Popultation frequency between >= 5%  in gnomAD population databases',
            'points': '-8',
            'color': '#2E7D32'
        },
    ]},

]

const categoryInfo ={
    benign: {
        range: [-Infinity, -7],
        label: "Benign",
        color: "#2E7D32",
        tooltip: "The variant is not considered to be the cause of the patient's cancer"
    },
    likelyBenign: {
        range: [-6, -1],
        label: "Likely benign",
        color: "#8BD452",
        tooltip: "The variant is unlikely to be the cause of the patient's cancer"
    },
    vus: {
        range: [0, 5],
        label: "VUS",
        color: "#666666",
        tooltip: "The variant shows some characteristics of being a cancer-causing mutation, but there is insufficient or conflicting evidence to confirm this"
    },
    likelyOncogenic: {
        range: [6, 9],
        label: "Likely oncogenic",
        color: "#F27645",
        tooltip: "The variant is considered the probable cause of the patient's cancer"
    },
    oncogenic: {
        range: [10, Infinity],
        label: "Oncogenic",
        color: "#A32D1D",
        tooltip: "The variant is considered the cause of the patient's cancer"
    }
};

export function getCategoryInfo(score) {
    for (const key in categoryInfo) {
        const config = categoryInfo[key];
        const [min, max] = config.range;
        if (score >= min && score <= max) {
            return config;
        }
    }
    return { label: "Unknown", color: "#000000", tooltip: "No information available" };
}