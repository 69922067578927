import React, { useEffect, useState } from "react";
import MUITable from "./MUI-table/MUI-Table";
import { bindActionCreators } from "redux";
import * as Actions from "../../../scenes/dashboard/components/genomics-dashboard/components/results/components/variant-table/actions";
import { connect } from "react-redux";
import {Accordion, AccordionDetails, Box, CircularProgress} from "@mui/material";
import { ExpandMoreOutlined } from "@mui/icons-material";
import IconLeftExpansionPanelSummary from "./LeftIconExpansion";
import ParticipantHeader from "./ParticipantHeader";
import Typography from "@mui/material/Typography";



function ParticipantTable(props){


    const [rows, setRows] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [selectedParticipant, setSelectedParticipant] = useState([]);
    // handle selection variants;

    useEffect(() => {
        const { selectedVariants, participants } = props;

        if (selectedVariants?.length > 0) {
            // Extract germline samples from selectedVariants
            const sampleArray = selectedVariants.flatMap(variant =>
                variant.samples_germline.map(germline => germline.sample)
            );

            if (participants?.map_by_exp) {
                // Filter participants based on ExperimentID
                const filteredExperiments = participants.map_by_exp.filter(exp =>
                    sampleArray.includes(exp.ExperimentID)
                );

                // Extract Participant IDs
                const participantIDs = filteredExperiments.map(exp => exp.Participant_ID);

                // Filter participants by report_id
                const participantData = participants.participants ?? [];
                const filteredRows = participantData.filter(participant =>
                    participantIDs.includes(participant.report_id)
                );

                setParticipants(participantIDs);
                setRows(filteredRows);
            }
        } else {
            // Handle case when no variants are selected
            const participantData = participants?.participants ?? [];
            setRows(participantData);
        }
    }, [props.selectedVariants, props.participants]);




    const handleSelectedParticipant = (selected, fromParticipantTable)=> {

        // reset selected variants;
        props.actions.selectedVariant([]);
        // pass samples;
        props.selectParticipants(selected, fromParticipantTable);

        if(fromParticipantTable){
            setSelectedParticipant(selected);
        }
        else{
            setSelectedParticipant([])
        }

    };

    const getTableTitleText = () => {

        return <ParticipantHeader total={props.participants.total}
                                  loadingParticipants={props.loadingParticipants}
                                  rowsL={rows.length}/>
    }


    const accordionHeader= {backgroundColor: "#f0f0f0", width: "100%"};

    return <Box p={1} style={{float: "left", width: "100%"}}>
        <Accordion defaultExpanded={false}>
            <IconLeftExpansionPanelSummary
                style={accordionHeader}
                expandIcon={<ExpandMoreOutlined />}
                IconButtonProps={{edge: 'start'}}
                aria-controls="panel1a-content"
                id="panel1a-header">
                    {getTableTitleText()}
            </IconLeftExpansionPanelSummary>
            <AccordionDetails>
                {(props.loadingParticipants)
                    ?  <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        minHeight="200px" // or any height to define the center area
                    >
                        <CircularProgress />
                        <Typography variant="body2" mt={2}> {/* Adjust typography variant as needed */}
                            Loading table data, please wait a moment.
                        </Typography>
                    </Box>
                    : <MUITable
                        loadingParticipants = {props.loadingParticipants}
                        table_page={1}
                        selectParticipants = {handleSelectedParticipant}
                        loadMore= {function(d){console.log("xxx")}}
                        total_participants={rows.length}
                        rows={rows}
                        experiments={props.sampleList}
                        total={props.participants.total}
                        aggregations={props.participants.aggregations}
                        query_id={props.query_id}
                    />
                }
            </AccordionDetails>
        </Accordion>
    </Box>




}



function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {
            token: state.authorization.token
        }
    }
    else{
        return {snv: []}
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});



//export default Variant_Table


export default connect(mapStateToProps,mapDispatchToProps)(ParticipantTable);