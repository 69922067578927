import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {CircularProgress, Grid} from "@mui/material";
import {setTable} from "../../../reducers/slices/tables/table.slice";
import {loadingAllInfo} from "../../../reducers/slices/tables/table.thunk";
import Container from '@mui/material/Container';


import {
    resetSamples,
    setSamples,
    setSomaticSamples
} from "../../study-page/create-study/analysis-page/reducers/sample_actions";
import {date_generator} from "../../func/helpers";
import {
    reset_patients
} from "../../study-page/create-study/analysis-page/components/clinical-info/patient_actions";
import {reset_selected_cohort, updateQuery} from "../../../actions";
import {create_study, create_analysis, create_query, resetStudySettings, setStudySettings} from "../../dashboard/reducers/study_actions";


import {
    resetGenes
} from "../../dashboard/components/genomics-dashboard/components/side-panels/filter-panel/components/filter-sections/actions";
import {
    reset_ranges
} from "../../dashboard/components/genomics-dashboard/components/side-panels/filter-panel/components/filter-sections/position-section/position_actions";
import {getObjectDifference} from "../../../components/tables/general-table/pages/getObjectDifference";
import GeneticFindings from "../genetic_findings/GeneticFindings";
import Reports from "../reports/Reports";
import DIR_Overview from "../dir_overview/DIR_Overview";
import formatSamples from "../../study-page/create-study/analysis-page/formatSamples";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import {default_filters} from "../../../config-files/configFilters";

import {set_filter_set, reset_filter_set} from "../../../actions";
import CustomTab from "../../../components/tables/general-table/pages/CustomTab";

import Biomarkers from "../biomarkers/biomarkers";
import Pharmacogenomics from '../pharmacogenomics_table/Pharmacogenomics';
import Fusions from '../fusions_table/Fusions';
import {add_patients} from "../../study-page/create-study/analysis-page/components/clinical-info/patient_actions";

import MappedText from '../../study-page/create-study/analysis-page/components/clinical-info/mapText';

import { setInheritance } from '../../study-page/create-study/analysis-page/reducers/inheritance_actions';
import { get_list_data } from '../../../services/api/lists_api';
import {add_genes_to_specific_genelist, replace_genes_to_specific_genelist } from '../../dashboard/components/genomics-dashboard/components/side-panels/filter-panel/components/filter-sections/actions';
import {connect} from "react-redux";
import * as Actions from '../../dashboard/components/genomics-dashboard/components/side-panels/filter-panel/components/filter-sections/actions';
import {bindActionCreators} from "redux";
import { get_filter_set, get_all_filter_sets } from '../../../services/api/manage_study';
import { add_applied_list } from '../../dashboard/components/genomics-dashboard/components/side-panels/filter-panel/components/filter-sections/actions';
import { somatic_query_default } from '../../dashboard/components/genomics-dashboard/components/results/components/variant-table/gpap-table/somatic_table/somatic_query_default';
import ClinicalEvidence from "../genetic_findings/clinical_evidence/ClinicalEvidence";
import {query_sample} from "../../../services/api/genomics_query_samples/query_sample";
import {instand_default_query} from "../../../services/api/genomics_query_samples/instand_default_query";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-dir${index}`}
      aria-labelledby={`vertical-tab-dir_menu${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


function a11yProps(index) {
    return {
        id: `vertical-tab-dir_menu${index}`,
        'aria-controls': `vertical-tabpanel-dir${index}`,
    };
}



function DIR_Menu(props) {


    const [value, setValue] = useState(0);
    const [initialized, setInitialized] = useState(false);
    const [studyId, setStudyId] = useState(0);

    const [samples, setAnalysisSamples] = useState([]);
    const [completed, setCompleted] = useState(false);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const { id, name } = useParams(); 
    const dispatch = useDispatch();
    const { isLoadingApis, allDataApis } = useSelector((state) => state.generaltable);
    const token = useSelector(state => state.authorization.token);


    // this is to check the previous values;
    const prevParticipantRef = useRef();
    const prevDirRef = useRef();

    useEffect(() => {

        if(!initialized){
            // configure study
            handleResetAll();
            setInitialized(true)
        }


       // This is the componentWillUnmount equivalent
       // It runs when moving from e.g. the Instand view to the normal GPAP where patients were not resetting 
       return () => {
        handleResetAll();
       };

    }, [])


    // Store the previous value of participant in a ref
    useEffect(() => {
        prevParticipantRef.current = allDataApis?.participants;
        prevDirRef.current = allDataApis?.dirs;
    }, [allDataApis?.participants, allDataApis?.dirs]);

    const prevParticipant = prevParticipantRef.current;
    const prevDir = prevDirRef.current;

    useEffect(() => {
    }, [allDataApis?.dirs?.analysis]);
    

    // Detect changes in participant
    useEffect(() => {


        if (allDataApis?.participants !== prevParticipant) {

            let {experiments, participants} = allDataApis;
            if(Array.isArray(experiments) && Array.isArray(participants) && experiments.length>0 && participants.length>0){
                let experiment_id = experiments[0].ExperimentID;

                let participant = participants[0];
                let pheno_id = participant.report_id;
                let somatic=  experiments[0].sample_type
                let test_sample =
                    {
                        sample_id: experiment_id,
                        pheno_id: pheno_id,
                        sample_type: (participant.index === "Yes") ? "index" : "other",
                        // TODO: to discuss with genis and leslie;
                        gt_settings: [
                            {
                                inheritance_key: 1,
                                setting: [
                                    '0/1', "1/1"
                                ],
                                label: 'Autosomal dominant',
                                ad_low: 0.2,
                                ad_high: 0.8
                            }
                        ],
                        affectedStatus: participant.affectedStatus === "Affected",
                        index: participant.index === "Yes"    ,
                        somatic: somatic ==='somatic'                }

                setupSample(test_sample, participants);
            }
            else{
                alert("data not loaded correctly");
            }




        }
    }, [allDataApis?.participants, prevParticipant]);

    // Detect changes in dir
    useEffect(() => {
        if ( allDataApis!== undefined && getObjectDifference(allDataApis.dirs, prevDir)) {
            // Dir has changed, you can do something here
            //TODO: avoid this to run twice.
            setupStudy();
        }
    }, [allDataApis?.dirs, prevDir]);


    useEffect(() => {

        if(studyId!== 0){

            // setup Analysis
            const analysis_id = Math.floor(Math.random() * 200);
            setupAnalysis(analysis_id);
            setupQuery(analysis_id);

            // avoid repetitions for all updates that occur in the component;
            //setCompleted(true);
        }

    },[studyId])

    
    // configure sample in Reducer;
    const setupSample = (test_sample, participants) => {

        // 1. set germline samples
        // Destructure the fields from the test_sample object
        const { sample_id, pheno_id, sample_type, affectedStatus, gt_settings,experiment_type,somatic } = test_sample;

        // Call the setSamples function with the destructured values


        //TODO: SET SOMATIC SAMPLES;

        // gt settings for a somatic sample are different than for the germline sample;

        /*let gt_somatic_settings = [
            {   
                "gt_tumor": [
                    "0/1"
                ],
                "dp_tumor": 20,
                "dp_control": "20",
                "tools": [
                    "dragen",
                    "mutect"
                ],
                "ad_tumor_low": "0.2",
                "ad_tumor_high": "1",
                "ad_control": "0.0",
                "pass_tumor": true,
                "pass_control": true,
                "min_num_tools": 1
            }
        ]*/
        let gt_somatic_settings = somatic_query_default.samples_somatic

        if (somatic){
        
            dispatch(setSomaticSamples(sample_id, pheno_id, sample_type, affectedStatus, "", gt_somatic_settings, "WGS","somatic"))
        }
        dispatch(setSamples({
            sample_id: sample_id,
            pheno_id: pheno_id,
            sample_type: sample_type,
            affectedStatus: affectedStatus,
            sample_from: "",
            gt: gt_settings,
            experiment_type: "WGS"
        }, ));
        dispatch(add_patients(participants))
        setAnalysisSamples([test_sample]);



    }

    const setupAnalysis = (analysis_id) => {

        let inher_mode_id = 1

        if (allDataApis.experiments && allDataApis.experiments[0].sample_type === "somatic")
            inher_mode_id = 9
      
        // first analysis to save
        let new_analysis = {
            "studyLocalId": studyId,
            "localId": analysis_id,
            "analysis_type": "instand",
            "inheritance_mode_id": inher_mode_id, // TODO: to discuss
            "creationDate": date_generator(),
            "samples": samples,
            "description": "",
            "queries": [],
            "labelled": {},
            "tagged": []
        };

        dispatch(create_analysis(new_analysis));


    }

    const getPredefinedGeneList = (gene_list_id) => {
        return new Promise((resolve, reject) => {

            let genes = [];
    
            get_list_data(token, window.config.apis_configuration.api_nextgpap_server, { "list_id": [gene_list_id] })
                .then((response) => response.json())
                .then((json) => {
                    if (json.data.length > 0) {
                            genes = json.data[0].data;
                          
                            let gene_list = json.data[0]

                            gene_list["type"] = "Predefined list";
                            gene_list["label"] = gene_list.title;

                            dispatch(add_applied_list(gene_list));
                            dispatch(add_genes_to_specific_genelist(genes, "predefined_genes"));
                            resolve(genes); // Resolve with fetched genes
                    } else {
                            resolve(genes); // Resolve with empty genes array
                    }
                    })
                    .catch((error) => {
                        reject(error);
                    }); 
        });
    };


    const getFilterSet = (filter_set_id) => {
        return new Promise((resolve, reject) => {

            let filter_set = {};
            
            get_filter_set(window.config.apis_configuration.api_nextgpap_server, token, {"filter_set_id": filter_set_id})
                .then((response) => response.json())
                .then((json) => {
                    filter_set = json.data
                    dispatch(set_filter_set(filter_set)); // current_filter_set
                    resolve(filter_set); // Resolve with the combined list of names
                })
                .catch((error) => {
                    reject(error); // Reject if there's an error fetching data
                });
        });
    };
    




    const setupQuery = async (analysis_id) => {

        let count = 1;
        const localID = analysis_id + "_" + count;
        

        //Now it is retrieved from the DIR api
        //let selected_filter = default_filters[0]; 

        // When going to a new DIR init instand default query (choose which to take)
        let query = JSON.parse(JSON.stringify(instand_default_query));
        //let query = selected_filter.filters
        //let query = {...query_sample};
    

        //SETUP FILTER SET
        if (allDataApis.dirs.filter_set && allDataApis.dirs.filter_set !== ''){
            try {
                let filter_set = await getFilterSet(allDataApis.dirs.filter_set);
                query = filter_set.filters
                    
                //Dispatch here or as currently inside the function?
                //dispatch(set_filter_set(filter_set)); // current_filter_set


            } catch (error) {
                console.error("Error fetching filter sets:", error);
            }
        }


        //SETUP PREDEFINED GENE LIST
        if (allDataApis.dirs.predefined_list && allDataApis.dirs.predefined_list !== ''){
            try {
                let genes = await getPredefinedGeneList(allDataApis.dirs.predefined_list);
                query.gene = genes

                //Dispatch here or as currently inside the function?
                //dispatch(add_genes_to_specific_genelist(genes, "genes"));

            } catch (error) {
                console.error("Error fetching predefined genes list:", error);
            }
        }


        //Format samples
        // here query does not have neither samples_germline nor samples_somatic
        query.samples_germline = formatSamples(samples, 0);
        query.samples_somatic = [];

    

        //Setup query
        const new_query = {
            study_id: studyId ,
            analysis_id: analysis_id,
            query: {
                "localId": localID ,
                "name": "query_" + 1,
                "analysis_id": analysis_id,
                "filter_set_id": -1,
                "filter_set_name": "",
                "query_name": "query_" + 1,
                "filters": JSON.stringify(query),
                "creationDate": date_generator(),
                "outputs": [{"n_variants": -1, "run_date": date_generator() }],
                "extra": []

            }
        };
        
 
        //dispatch(set_filter_set(selected_filter)); // current_filter_set
        dispatch(create_query(new_query)); // query in studyBucket
        dispatch(setStudySettings({current_query: localID}));

    }


    const setupStudy = () => {
        
        //Reset everything here as the call in the useEffect does not make the trick
        handleResetAll();

        // ideally here I should do the mapping with the DIR;

        // Create Study using create_study reducer 1
        let localID = Math.floor(Math.random()*200);
        let date = date_generator();
        let sampleStudy = {
            "localId": localID,
            "studyName": <MappedText text="New Instand DIR Study"/>,
            "description": <MappedText text="No description"/>,
            "creationDate": date,
            "samples": [],
            "analyses": [],
            "tagged_variants": [],
            "info":[]
        };

        dispatch(create_study(sampleStudy));

        // set settings. This is important to associate analysis and queries.
        // storing also dir_id variable for further purposes.
        let settings = {analysisType: "instand", id: localID, dir_id: allDataApis.dirs.dir_id, dir_db_id: allDataApis.dirs.id};
        dispatch(setStudySettings(settings));

        setStudyId(localID);


    }

    const handleResetAll = () => {
        // Dispatch each action in sequence
        dispatch(resetSamples());
        dispatch(reset_patients());
        dispatch(reset_selected_cohort());
        dispatch(resetStudySettings());
        dispatch(reset_filter_set());
        dispatch(setInheritance([]));

        // also reset geneList in the redux store;
        resetQuery()
    };

    const resetQuery = () => {
        
        let query_to_reset = {...query_sample};

        //Like it is now it is not taken so probably we have to do take it like above?
        dispatch(updateQuery(query_sample));
        dispatch(resetGenes());
        dispatch(reset_ranges());

    };


    const isSomaticSample = () => {
    
        //Based on the sample type
        //return !!(allDataApis.experiments &&
        //         allDataApis.experiments.length > 0 &&
        //         allDataApis.experiments[0].sample_type === 'somatic');
        
        //OR
        
        //Based on the analysis type
        return !!(allDataApis.dirs &&
            allDataApis.dirs.analysis.includes('somatic_tumor'));

      };

    

    useEffect(() => {

        // load all data from APIs
        dispatch(loadingAllInfo (id, "POST", token));


    }, [initialized]);

    const onSubmit = (e) => {
        e.preventDefault();
        const data = { ...allDataApis };
        data.dirName = e.target.dirName.value;
        dispatch(setTable({ option: "allDataApis", value: data }));
      };
      if (isLoadingApis) {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
            <h4>Loading ...</h4>
          </Box>
        );
      }

    const renderGermlineTabs = () => {

        return    <Tabs
            orientation="horizontal"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderBottom: 1, borderColor: 'divider' }} >
            <CustomTab label="Request Overview" {...a11yProps(0)} sx={{ width: "200px" }}/>
            <CustomTab label="Germline Genetic Findings" {...a11yProps(1)} sx={{ width: "200px" }} />
            <CustomTab label="Pharmacogenomics" {...a11yProps(2)} sx={{ width: "200px" }}/>
            <CustomTab label="Reports" {...a11yProps(3)} sx={{ width: "150px" }}/>
        </Tabs>

    }

  const renderGermlineViews = () => {

          return <Box>

              {/* Render Case overview */}
              <TabPanel value={value} index={0}>
                  <div>{allDataApis?.dirs?.Priority}</div>
                  <div>{allDataApis?.dirs?.Status}</div>
                  <DIR_Overview/>
              </TabPanel>

              {/* Render germline data */}
              <TabPanel value={value} index={1}>
                  <GeneticFindings instandGeneticFindingsTab="germline"
                                   analysisType={allDataApis?.dirs?.analysis}/>


              </TabPanel>

              {/* Render clinical evidence tab */}
              <TabPanel value={value} index={2}>
                  <Pharmacogenomics/>
              </TabPanel>


              {/* Render Reports */}
              <TabPanel value={value} index={3}>
                  <div>{allDataApis?.experiments?.Owner}</div>
                  <div>{allDataApis?.participants?.number}</div>
                  <Reports/>
              </TabPanel>

          </Box>


  }


    const renderSomaticViews = () => {

        return <Box>

            {/* Render Case overview */}
            <TabPanel value={value} index={0}>
                <div>{allDataApis?.dirs?.Priority}</div>
                <div>{allDataApis?.dirs?.Status}</div>
                <DIR_Overview/>
            </TabPanel>

            {/* Render tumor genetic overview */}
            <TabPanel value={value} index={1}>
                <Biomarkers/>
            </TabPanel>

            {/* Render somatic data */}
            <TabPanel value={value} index={2}>
                <GeneticFindings instandGeneticFindingsTab="somatic" analysisType={allDataApis?.dirs?.analysis} />
            </TabPanel>

            {/* Render clinical evidence tab */}
            <TabPanel value={value} index={3}>
                <ClinicalEvidence instandGeneticFindingsTab="somatic" />
            </TabPanel>

            {/* Render Reports */}
            <TabPanel value={value} index={4}>
                <div>{allDataApis?.experiments?.Owner}</div>
                <div>{allDataApis?.participants?.number}</div>
                <Reports/>
            </TabPanel>

        </Box>


    }
      const renderSomaticTabs = () => {

          return <Tabs
              orientation="horizontal"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderBottom: 1, borderColor: 'divider' }}
          >


              <CustomTab label="Request Overview" {...a11yProps(0)} sx={{ width: "200px" }} />
              <CustomTab label="Tumor Genetic Overview" {...a11yProps(1)} sx={{ width: "200px" }} />
              <CustomTab label="Somatic Genetic Findings" {...a11yProps(2)} sx={{ width: "200px" }} />
              <CustomTab label="Clinical Evidence" {...a11yProps(3)} sx={{ width: "200px" }} />
              <CustomTab label="Reports" {...a11yProps(4)} sx={{ width: "150px" }} />

          </Tabs>

      }
      const renderFusionTabs = () => {
        return (
            <Tabs
                orientation="horizontal"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <CustomTab label="Request Overview" {...a11yProps(0)} />
                <CustomTab label="Gene Fusions" {...a11yProps(1)} />
                {/* <CustomTab label="Clinical Evidence" {...a11yProps(2)} /> */}
                <CustomTab label="Reports" {...a11yProps(2)} /> 
            </Tabs>
        );
    };
    
    const renderFusionViews = () => {
        return (
            <Box>
           
                <TabPanel value={value} index={0}>
                    <DIR_Overview />
                </TabPanel>
  
                <TabPanel value={value} index={1}>
                    <div><Fusions/></div>
                </TabPanel>
    
                {/* <TabPanel value={value} index={2}>
                 <ClinicalEvidence instandGeneticFindingsTab="somatic" />
                </TabPanel> */}
    
                {/* Render Reports */}
                <TabPanel value={value} index={2}>
                    <Reports />
                </TabPanel>
            </Box>
        );
    };

  return (
    <Container maxWidth="xl">
        <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href={`#/dir_list`}>
            <MappedText text={"Interpretation Requests"} />
            </Link>
            <Link
                underline="hover"
                color="inherit"
                href={`#/dir_overview/${id}`}>
                {id}
            </Link>
        </Breadcrumbs>
        <Typography variant={"h4"}>
        <MappedText text={"Request"} /> <b>{id}</b>
        </Typography>
        <Grid item lg={12}>
                {allDataApis?.dirs?.analysis === 'somatic_fusion_analysis' ? renderFusionTabs() :
                 isSomaticSample() ? renderSomaticTabs() : renderGermlineTabs()}
            </Grid>
            <Grid item lg={12}>
                {allDataApis?.dirs?.analysis === 'somatic_fusion_analysis' ? renderFusionViews() :
                 isSomaticSample() ? renderSomaticViews() : renderGermlineViews()}
            </Grid>
   
    </Container>
  );
}



const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});



function mapStateToProps(state) {
    if (state.authorization !== undefined) {
        return {
            my_genes:state.geneList
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DIR_Menu);
