import AccordionSummary from "@mui/material/AccordionSummary";
import {withStyles} from "@mui/styles";


const IconLeftExpansionPanelSummary = withStyles({
    expandIcon: {
        order: -1,
        transform: "rotate(-90deg)"
    }
})(AccordionSummary);


export default IconLeftExpansionPanelSummary;