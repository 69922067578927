



const cells_config = [
    { id: 'report_id', numeric: false, disablePadding: true, label: 'Participant ID' },
    /*{ id: 'Owner', numeric: false, disablePadding: true, label: 'Owner' },*/
    { id: 'famid', numeric: false, disablePadding: false, label: 'Fam.ID' },
    { id: 'ExperimentID', numeric: false, disablePadding: false, label: 'Experiment ID' },
    { id: 'LocalExperimentID', numeric: false, disablePadding: false, label: 'Local Exp. ID' },
    { id: 'n_genes', numeric: true, disablePadding: false, label: 'Num. of Genes' },
    { id: 'project', numeric: false, disablePadding: false, label: 'Project' },
   /* { id: 'erns', numeric: false, disablePadding: false, label: 'ERNs' },*/
    { id: 'affectedStatus', numeric: false, disablePadding: false, label: 'Affected Status' },
    { id: 'consanguinity', numeric: false, disablePadding: false, label: 'Consanguinity' },
    { id: 'sex', numeric: false, disablePadding: false, label: 'Sex' },
   /* { id: 'lifeStatus', numeric: false, disablePadding: false, label: 'Life Status' },*/
    { id: 'solved', numeric: false, disablePadding: false, label: 'Solved Status' },
   /* { id: 'hpo_n', numeric: true, disablePadding: false, label: '# HPOs' },*/
    { id: 'features', numeric: false, disablePadding: false, label: 'Obs. HPOs' },
    { id: 'diagnosis', numeric: true, disablePadding: false, label: 'Diagnosis' },
  /*  { id: 'birth', numeric: true, disablePadding: false, label: 'Date of Birth' },
    { id: 'death', numeric: true, disablePadding: false, label: 'Date of Death' },*/
];


export {cells_config}