
import {connect} from "react-redux";
import {getInfoPermissions} from "../../../services/api/get";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";



let config_labels = {
    "accessible" : 'Accessible',
    "privatesamples" : 'Own',
    "sharedsamples" : 'Shared'
};


function PermissionsBox(props){


    const [data, setData] = useState(false);


    useEffect(function(){

        if(!data){
            getInfoPermissions(config.api_endpoint, props.token)
                .then(r => {
                    if(r.ok){
                        return  r.json()
                    }
                })
                .then(json => {
                    let d = json;
                    if("visiblesamples" in d){
                        delete d["visiblesamples"]
                        setData(d)
                    }
                    else{
                        setData(false);
                    }

                })
                .catch(error => alert("GPAP Genomics Server is currently unavailable. Please contact us at help@rd-connect.eu. We apologize for the inconvenience.") );
        }

    });

    const renderItems = () => {

        return Object.keys(data).map((keyItem,index) =>
            <Grid item lg={4} key={index}>
                <Paper elevation={3}>
                    <Box p={4} style={{height: "50px"}}>
                        <Grid container
                              direction="column"
                              alignItems="center"
                              justifyContent="center">
                            <Grid item lg={12}>
                                <Typography variant={"h5"} color={"textSecondary"}>{config_labels[keyItem]} </Typography>
                            </Grid>
                            <Grid item lg={12}>
                                <div >
                                    <Typography variant={"h3"}> {data[keyItem]} </Typography>
                                </div>
                            </Grid>
                        </Grid>


                    </Box>
                </Paper>
            </Grid>
        )

    };



    return <Box p ={2}>
        {(data)
        ? <div style={{padding: "2%"}}>
                <Typography variant="h6" >
                   GPAP Experiments Overview
                </Typography>
                <Grid container direction={"row"} spacing={1} style={{marginTop: "1%"}}>
                    {renderItems()}
                </Grid>
            </div>
        : null
        }
    </Box>



}


function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {
            token: state.authorization.token,
        }
    }
}



export default connect(mapStateToProps, null)(PermissionsBox);