import React from 'react';

// MUI Components
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';

// Redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as QueryActions from "../../../../../../../../../../../actions/index.js";

// Custom Components and Utilities
import { roh_parser } from "./ROH_helper";
import GPAP_Tooltip from "../../../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import MappedText from '../../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText.js';




let roh_lookup = {
    "0.5MB": 500,
    "1.0MB": 1000,
    "2.0MB": 2000
};

function RadioButtonROH(props) {
    const [value, setValue] = React.useState('null');

    const handleChange = (event) => {

        let {QueryActions, rohs} = props;
        const val = event.target.value;

        setValue(val);

        const chrs = (val === "null") ? []: roh_parser(roh_lookup[val], rohs);
        QueryActions.add_chrom(chrs);
    };

    return (
        <FormControl>
            <GPAP_Tooltip title=<MappedText text={"When applied, will return only variants that fall within a run of homozygosity of at least the length selected in the first affected sample"}/>>
            <div>
                <Typography variant={"subtitle1"}><MappedText text={"Minimum run of homozygosity length"}/></Typography>
            </div>
            </GPAP_Tooltip>

            <RadioGroup row aria-label="roh" name="roh" value={value} onChange={handleChange}>
                <FormControlLabel disabled = {props.rohs.length ===0 } value={"0.5MB"} control={<Radio />} label="0.5MB" />
                <FormControlLabel disabled = {props.rohs.length ===0 } value={"1.0MB"} control={<Radio />} label="1.0MB" />
                <FormControlLabel disabled = {props.rohs.length ===0 } value={"2.0MB"} control={<Radio />} label="2.0MB" />
                <FormControlLabel value="null" control={<Radio />} label=<MappedText text={"Not Specified"}/> />
            </RadioGroup>
        </FormControl>
    );
}

// connect to redux;

function mapFormValuesToProps (state) {
    if(state.authorization!==undefined)
    {

        return { rohs: state.roh_value};

        //return {token: state.authorization.token}
    }
}


const mapDispatchToProps = (dispatch) => ({
    QueryActions: bindActionCreators(QueryActions, dispatch)
});



//export default ViewManager;

export default connect(mapFormValuesToProps, mapDispatchToProps)(RadioButtonROH);