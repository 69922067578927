
import { lighten } from "@mui/system";
import {makeStyles} from "@mui/styles";


const useTableStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.primary.main,
                backgroundColor: "white",
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: "white",
            },
    title: {
        flex: '1 1 100%',
        textTransform: "uppercase",
        color: "white"
    },
}));


export default useTableStyles;