import {convertChromosome} from "./data-retrieve";


export function processPositions(tot_chr, samples_arr) {
    return tot_chr.concat(
        samples_arr.map(variant => {
            let chromosome = variant.hasOwnProperty("chrom") ? variant["chrom"] : variant["chromosome"];
            chromosome = convertChromosome(chromosome);
            let pos = variant.hasOwnProperty("start_pos") ? variant["start_pos"] : variant["pos"];
            let end_pos = variant.hasOwnProperty("start_pos") ? variant["start_pos"] : variant["pos"];
            return {
                chrom: chromosome,
                pos: pos,
                end_pos: end_pos,
            };
        })
    );
}