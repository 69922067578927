
import React from "react";
import FlagIcon from "@mui/icons-material/Flag";

export function getPharmacogenomicsAnnotationIcon(row, participant) {
    const { Gene, Genotype } = row.values;

    if (!Object.isExtensible(participant)) {
        participant = { ...participant };
      }

  
    // Check if tagged_treatments array exists in participant
    if (participant.tagged_pharmacogenomics) {
      // Iterate over each tagged treatment
      for (const pharma of participant.tagged_pharmacogenomics) {
        // Check if gene_name and hgvs_p match
        if (
          pharma.gene === Gene &&
          pharma.genotype === Genotype
        ) {
          // Return the FlagIcon if there is a match
          return <FlagIcon style={{ color: "#3182bd" }} />;
        }
      }
    }
  
    // Return null or an empty string if no match is found
    return null;
  }


export function pharmacogenomicsFinder(selected, participant){
       const { Gene, Genotype } = selected;

        let found = false;
        let interpretation = ""

        if (!Object.isExtensible(participant)) {
            participant = { ...participant };
        }

        // Check if tagged_treatments array exists in participant
        if (participant.tagged_pharmacogenomics) {
            // Iterate over each tagged treatment
            for (const pharma of participant.tagged_pharmacogenomics) {
              // Check if gene_name and hgvs_p match
              if (
                pharma.gene === Gene &&
                pharma.genotype === Genotype
              ) {
                found = true
                interpretation = pharma.interpretation
              }
            }
          }

          let found_info = {"found": found, "interpretation":interpretation}

          return found_info
}