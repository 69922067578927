import auth from "../../../../../services/session/Auth";


const config = window.config;
const URL_API_NS = config.apis_configuration.api_nextgpap_server;

export const deleteRequest = (idRelation) => {
    return fetch(`${URL_API_NS}/dir/${idRelation}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': auth.getToken(),
        },
    });
};

export const deleteClinicalReport = (idRelation) => {
    return fetch(`${URL_API_NS}/clinical_report/${idRelation}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': auth.getToken(),
        },
    });
};

export const deletePatientReport = (idRelation) => {
    return fetch(`${URL_API_NS}/patient_report/${idRelation}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': auth.getToken(),
        },
    });
};