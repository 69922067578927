



import React from "react";
import { styled } from "@mui/material/styles";
import { Tooltip, tooltipClasses } from "@mui/material";

const GPAP_Tooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        color: "white",
        backgroundColor: "#3182bd",
        fontSize: "12px",
    },
}));

export default GPAP_Tooltip;