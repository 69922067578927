// React Imports
import React, { useState } from "react";

// Material UI Imports
import { ListItem, ListItemIcon, ListItemText, Collapse, List, Badge, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles"; // @mui/styles is a separate package in MUI v5

// Material UI Icons Imports
import { ExpandLess, ExpandMore, Pageview as PageviewIcon, Delete as DeleteIcon } from '@mui/icons-material';

// Custom Components/Functions
import inheritances_keys from "./inheritance_list";
import { QueryList } from "./QueryList";
import getGPAPLabelIcon from "../../../../../../../../../gpap-lib/icons/GetLabelIcon";

// Redux Imports
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as StudyActions from "../../../../../../../reducers/study_actions.js";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    analysisText:{
        color: "#3182bd",
        fontWeight: 500
    },
    analysisTextSelected: {
        fontWeight:"bold",
        color: "orange"
    },
    queryText:{
        fontSize:"13px"
    },
    queryText_secondary:{
        fontSize:"11px"
    }
}));


function getLabelled(labelled){



    if (labelled!== undefined){
        let keys = Object.keys(labelled);
        return keys.map(function(key){
            if(labelled[key].length!== 0 ) {
                return  <React.Fragment>
                    <ListItem>
                        <ListItemText
                            style={{paddingLeft: "35px"}}
                            primary= {<div style={{fontWeight: 500}}><Badge
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                badgeContent={labelled[key].length}
                                color="primary">
                                {getGPAPLabelIcon(key)}
                            </Badge>{key} </div>}
                        />
                    </ListItem>
                </React.Fragment>
            }
            else{
                return null
            }
        }).filter(s => s!== null);
    }
    else{
        return null;
    }

}



function Analysis_List_Item(props) {
    const classes = useStyles();

    const {analysis, queryViewer, key } = props;

    let selected = (analysis.localId === props.current_analysis) ? "analysisTextSelected" : "analysisText";

    let color = (analysis.localId === props.current_analysis) ? "orange" : "#3182bd";

    const [open, setOpen] = useState(true);


    const handleClick = () => {
        setOpen(!open);
        if(!open){
            props.studyActions.setStudySettings({current_analysis: analysis.localId})
        }
    };

    const inheritance_list = inheritances_keys;

    let name = inheritance_list.find(s => s.key === analysis.inheritance_mode_id).label;


    return <React.Fragment>
        <ListItem
            key={key}
            onClick={handleClick}
            className={classes.nested}>
            <ListItemIcon>
                <PageviewIcon style={{color:color}} />
            </ListItemIcon>
            <ListItemText
                classes={{ primary: classes[selected] }}
                primary={name}
                secondary={<Typography color="textSecondary" variant={"subtitle2"}>{analysis.analysis_type.replace("_", " ")}</Typography>}/>
            {open ? <ExpandLess/> : <ExpandMore/>}
            {(props.readOnly) ? null : <DeleteIcon/> }
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {/*// get also tagged*/}
                {getLabelled(analysis.labelled)}
                <QueryList
                    readOnly = { props.readOnly }
                    queryDelete = { props.queryDelete }
                    analysis={analysis} queryViewer = {queryViewer} />
            </List>
        </Collapse>
    </React.Fragment>

}


function mapAnalysis (state) {
    if(state.studySettings!== undefined)
    {
        return {
            current_analysis: state.studySettings.current_analysis,
        }
    }
    else{
        return {patients: []}
    }

}


// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    studyActions: bindActionCreators(StudyActions, dispatch)
});



export default connect(mapAnalysis, mapDispatchToProps)(Analysis_List_Item);