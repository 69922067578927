import {Paragraph, TextRun, HeadingLevel, Numbering, AlignmentType} from 'docx';
import { renderToStringAlt } from '../../../create-report/shared-components/renderToString';
import { fontDoc } from '../../../create-doc/config/template_config';


const WriteTechnicalLimitations = (title, technicalLimitations, experimentType, translationFunc)=>{

  let limitationsText = technicalLimitations[experimentType];


  // translate text;

  if(limitationsText === undefined){
    limitationsText = "Information about experiment type not available";
  }

  const translatedText = (translationFunc(limitationsText));

    return [
      new Paragraph({
        children: [
          new TextRun({
            text: renderToStringAlt("TECHNICAL LIMITATIONS"),
            size: 24,
            color: '#000000',
            bold: true,
          }),
        ],
        
      }),
      new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        children: [
          new TextRun({
            text: translatedText,
            size: 20,
            font: fontDoc,

          }),
        ],
      }),
    ];
  };

export default WriteTechnicalLimitations;