

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";


// MUI imports
import IconButton from "@mui/material/IconButton";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
    Dialog,
    DialogContentText,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from "@mui/material";


// Custom components and utilities
import GPAP_Tooltip from "../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import MappedText from "../../../../../../../../instand/reports/create-report/shared-components/mapText";

// API and service functions
import { experiments_view_new, getSAVariants, getVariants } from "../../../../../../../../../services/api/get";
import { check_variant_response } from "../../../../../check_variant_response";
import save_CSV_file from "./export_variants";
import { getCurrentAnalysis } from "../../variant-table/getAnnotations";
import formatSamples from "../../../../../../../../study-page/create-study/analysis-page/formatSamples";



function ExportVariants(props){

   const [allow, setAllow] = useState(false);
   const [open, setOpen] = useState(false);
   const [message, setMessage] = useState("");


   useEffect(async function () {

       // check for ownership
       // call data management //
       let field = "IsUserOwner";

       let array_samples = (props.samples !== undefined) ? props.samples.map(s => s.sample_id) : [];

       let data_post = {
           "page": 1,
           "pageSize": 1,
           "fields": [field],
           "filtered": [{"id": "ExperimentID", "value": array_samples}],
           "aggregates": []
       };

       try {
           const response = await experiments_view_new(config.apis_configuration.api_data_management, props.token, data_post);
           const json = await response.json();

           if (Array.isArray(json?.items)) {
               let values = json.items.filter(s => !s[field]);
               if (values.length === 0) {
                   setAllow(true);
               }
           } else {
               console.log("Error in fetching");
               setAllow(false);
           }
       } catch (error) {
           setAllow(false);
           console.log("Error fetching experiments", error);
       }


   }, [props.samples]);


   const runExportQuery = () => {

       let { token, query, studySettings, studyBucket, sa_query} = props;
       let config =window.config;
       let api_endpoint  = config.api_endpoint;

       let analysis_type =  studySettings.analysisType;
       let query_to_run = {...query};

       let current_analysis = getCurrentAnalysis(studySettings, studyBucket);

       let currentQuery = current_analysis.analysis.queries.find(q => q.localId === studySettings.current_query);

       let n_variants = (currentQuery!== undefined) ? currentQuery.outputs[0].n_variants : -1;

       if(n_variants !== -1 && n_variants <=  (window.config.max_sortable_svn_rows) ? window.config.max_sortable_svn_rows : 1000){
        if(allow){
            if(analysis_type  === "search_across" || analysis_type === "cohort") {

                // genes
                let geneList = props.geneList;

                if (query_to_run.gene.length === 0) {
                    query_to_run.gene = geneList;
                }
                query_to_run.size = 0;

                if(Object.keys(sa_query).length > 1){

                    getSAVariants(api_endpoint,token, sa_query)
                        .then(response => {

                                return check_variant_response(response);

                            }
                        )
                        .then((json) => {
                            // variants
                            save_CSV_file(json);
                            
                
                        })

                }
                else{
                    setMessage("You need to select at least one variant to export. You can do so, by clicking on the gene aggregations on the left. ");
                    setOpen(true)
                }

            }
            else{


                let currentAnalysis = getCurrentAnalysis(studySettings,studyBucket);

                query_to_run.samples_germline = formatSamples(currentAnalysis.analysis.samples,0);

                getVariants(api_endpoint,token, query_to_run)
                    .then(response => {
                        return check_variant_response(response);
                    })
                    .then(json => {
                        save_CSV_file(json);
                    })
            }
        }
        else{
            setMessage("You are not the owner of all samples");
            setOpen(true)
        }
    }
    
    
   };

   const handleClose = () => {
        setOpen(false);
    }



    return <React.Fragment>
           <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="export_dialog-description"
      >
        <DialogTitle>{"Export Functionality"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="export_dialog">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
        <GPAP_Tooltip title= {<MappedText text={"Export Variants (max.1000)"}/>} >
            <IconButton  id={"exportVariants"} onClick={runExportQuery}>
                <GetAppIcon/>
            </IconButton>
        </GPAP_Tooltip>
    </React.Fragment>


}


// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        if(state.query !== undefined)
        {
            return { query: {... state.query},
                token: state.authorization.token,
                parsedToken: state.authorization.parsedToken,
                studySettings: state.studySettings,
                studyBucket: state.studyBucket,
                samples: state.sample_list.samples,
                sa_query: state.sa_query
            }
        }

    }
}



export default connect(mapStateToProps,null)(ExportVariants);