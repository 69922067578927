


export const getField = (sampleType) => {
    const fieldMapping = {
        case: "ExperimentID",
        LocalExperimentID: "LocalExperimentID",
        id: "id",
        famid: "famid",
    };

    return fieldMapping[sampleType] || "report_id";
};