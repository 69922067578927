
const empty_json = {
    "took": 0,
    "timed_out": false,
    "_shards": {
        "total": 0,
        "successful": 0,
        "skipped": 0,
        "failed": 0
    },
    "hits": {
        "total": 0,
        "max_score": 0,
        "hits": []
    },
    "aggregations": {}
};

const ERRORS = ["Too Many Requests. The query failed.", "Time out", "Server Error", "Too Many Results. The query failed."];

function check_variant_response(response){

    let statusObj = {snv: empty_json, status_code: response.status, info: "", message:""};

    if (response.status === 429) {
        statusObj.message = "Too Many Requests. The query failed and it was not added to the analysis.";
        statusObj.type = "error";
      return statusObj;
    }
    else if(response.status === 403 ){
        alert("Query Forbidden. Please, contact help@rdconnect.eu");
        statusObj.type = "error";
        return statusObj;
    }
    else if(response.status === 540){
        statusObj.message = "Too Many Results. The query was not added to the analysis.";
        statusObj.type = "error";
        return statusObj;
    }
    else if(response.status === 550){
        statusObj.message = "Time out";
        statusObj.type = "error";
        return statusObj;
    }
    else if(response.status === 500){
        let content_type = response.headers.get("Content-Type");
        statusObj.snv.hits.total= -1
        if(content_type === 'application/json'){
            statusObj.message = "Server Error. Please contact us at " +  window.config.help_email;
            statusObj.type = "error";
            return statusObj;
        }
        else if(content_type === "text/plain; charset=UTF-8"){
            console.log("Error Server")
            statusObj.message = "Server Error. Selected participants can't be analyzed. Please contact us at " +  window.config.help_email;
            statusObj.type = "error";
            return statusObj;
        }
        else{
            statusObj.message = "Server Error. Please contact us at " +  window.config.help_email;
            statusObj.type = "error";
            return statusObj;
        }
    }
    else if(response.status === 502){
        statusObj.message = "The GPAP Genomics Server is down. Sorry for the inconvenience. Please contact us at help@rd-connect.eu";
        return statusObj;
    }
    else {
        return response.json()
    }

}

export { check_variant_response, empty_json, ERRORS }