import {origin_opts} from "../tag_form_options";


export const getOriginOptions = (dataType) => {
    if (dataType === "SNV_Germline") {

        return origin_opts.filter(opt => opt.value !== "somatic");

    } else if (dataType === "SNV_Somatic") {

        return origin_opts.filter(opt => opt.value === "somatic");
    }
    return origin_opts;
};
