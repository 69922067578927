

import React, {useState,Fragment} from 'react';



import ExtensionIcon from '@mui/icons-material/Extension';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import GPAP_Tooltip from "../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import VarSomeDialog from "../../variant-table/gpap-table/components/VarSomeDialog";
import { ListItemIcon } from '@mui/material';
import Typography from '@mui/material/Typography';
import ExomiserDialog from "../../variant-table/gpap-table/components/ExomiserPage";
import MappedText from "../../../../../../../../instand/reports/create-report/shared-components/mapText";
import Box from "@mui/material/Box";




export default function PluginMenu(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [exomiserFlag, setExomiserFlag] = useState(false);
    const [varsomeFlag, setVarsomeFlag] = useState(false);

    //Get variants from props
    let variants_data = props.data


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setExomiserFlag(false);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setExomiserFlag(false);
        setVarsomeFlag(false);
    };

    const handleExomiser = () => {
        setAnchorEl(null);
        setExomiserFlag(true);
    };

    const handleVarsome = () => {
        setAnchorEl(null);
        setVarsomeFlag(!varsomeFlag);
    }


    return (
        <Fragment>
            <GPAP_Tooltip title={<MappedText text={"List of Integrated Plugins"} />}>
                <IconButton
                    id={"plugins"}
                    color={anchorEl !== null ? "primary" : "default"}
                    aria-controls="plugins-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <ExtensionIcon />
                </IconButton>
            </GPAP_Tooltip>

            <Menu
                id="plugins-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleVarsome}>
                   <Box>
                       <ListItemIcon>
                           <img
                               src={window.config.static_files + "/img/nextgpap/varsome_img.png"}
                               style={{ width: "80px" }}
                               alt={"varsome"}
                           />
                       </ListItemIcon>
                       <Typography>Annotate with VarSome</Typography>
                   </Box>
                </MenuItem>

                <MenuItem onClick={handleExomiser}>
                    <Box>
                    <ListItemIcon>
                        <img
                            src={window.config.static_files + "/img/nextgpap/exo_logo.png"}
                            style={{ width: "80px" }}
                            alt={"exomiser"}
                        />
                    </ListItemIcon>
                    <Typography>Exomiser & Genomiser</Typography>
                    </Box>
                </MenuItem>
            </Menu>

            {exomiserFlag && <ExomiserDialog variants={variants_data} />}
            {varsomeFlag &&
                <VarSomeDialog
                    handleVarsome={handleVarsome}
                    variants={variants_data}
                    passAnnotations={props.passAnnotations}
                />
            }
        </Fragment>
    );
}