
// React Imports
import React, { useState } from "react";

// Material UI Imports
import { ListItem, ListItemText, Collapse, List, Badge, Typography } from "@mui/material"; // Updated to @mui/material
import { makeStyles } from '@mui/styles'; // makeStyles is still available in @mui/styles for v5

// Material UI Icons Imports
import ExpandLess from '@mui/icons-material/ExpandLess'; // Updated to @mui/icons-material
import ExpandMore from '@mui/icons-material/ExpandMore'; // Updated to @mui/icons-material
import FlagIcon from '@mui/icons-material/Flag'; // Updated to @mui/icons-material

// Custom Components Imports
import Analysis_List_Item from "./Analysis_List_Item";

// Redux Imports
import { connect } from "react-redux";
import { getAnalysisAnnotations, getStudy } from "../../../../results/components/variant-table/getAnnotations";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    studyName:{
        fontWeight:"bold",
        color: "#4575B4"
    }
}));


function StudyItem(props){
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);


    const handleClick = () => {
        setOpen(!open);
    };

    const updateStudyName = (val) => {

        console.log(val)

    };


   /* const TextFieldEdit = (props) => <TextField
        id="standard-basic"
        label={props.study.studyName}
        onChange={updateStudyName}
    />;*/

    const getListItem = () => {

        if(!edit){
            return  <ListItem
                button
                onClick={handleClick}>
              {/*  <ListItemAvatar>
                    <Avatar>
                        <WorkIcon />
                    </Avatar>
                </ListItemAvatar>*/}
                <ListItemText
                    classes={{ primary: classes.studyName }}
                    primary={<Typography variant={"h6"}> {props.study.studyName}</Typography>} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
        }  // TODO: optimal would be to change the study name directly here;

    };



    let analyses_nested = props.study.analyses.map(function(analysis){

       return <Analysis_List_Item
           queryDelete = { props.queryDelete }
           analysis={analysis}
           queryViewer={props.queryViewer}/>

    });

    let study = getStudy(props.studySettings,props.studyBucket);
    let n_variants = (study.study.samples_variants!== undefined) ? study.study.samples_variants.length : 0;

    let now_tags = getAnalysisAnnotations(props.studySettings, props.studyBucket).tagged.length;

    return <React.Fragment>
        {getListItem()}
        <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                <ListItem>
                      <ListItemText
                    primary= {<div>
                        <Badge
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        badgeContent={0}
                        color="primary">
                        <FlagIcon style={{ color: "#3182bd" }}/>
                    </Badge>
                        {
                            now_tags  + " tagged variants"} </div>
                        }>
                      </ListItemText>
                </ListItem>
                {analyses_nested}
            </List>
        </Collapse>
    </React.Fragment>
}

function mapStateToProps (state) {
    if(state.variants!==undefined)
    {
        return {token: state.authorization.token, selected_variant: state.selected_variant, studySettings: state.studySettings, studyBucket: state.studyBucket}
    }
    else{
        return {snv: []}
    }
}



export default connect(mapStateToProps,[])(StudyItem);