import {date_generator} from "../../../../../../../../../func/helpers";
import {getChr} from "../mapChrom";
import {getGenomicBuildValue} from "./getGenomicBuildValue";

export const prepareGeneObject = (gene_identifier,data_tag, state) => {
    let date = date_generator();
    // make sure chrom is string.
    //chromosome
    let chromosome = (typeof data_tag["chromosome"] === "string" ) ? data_tag["chromosome"] : data_tag["chromosome"].toString();
    let nucleotide = "g." + data_tag["start_pos"] + ":" + data_tag["ref"] + ">" + data_tag["alt"]


    let gene_obj = {
        "gene": {"id": gene_identifier, "name": data_tag["gene"]},
        "variants": [
            {
                "genomic_build": getGenomicBuildValue(),
                "chromosome": getChr(chromosome),
                "dbSNP": data_tag["rs"],
                "start_pos": data_tag["start_pos"],
                "ref": data_tag["ref"],
                "alt": data_tag["alt"],
                "cDNA": data_tag["cDNA"],
                "protein": data_tag["protein"],
                "transcript": data_tag["transcript"],
                "zygosity": data_tag["zygosity"],
                "inheritance": data_tag["inheritance"],
                "significance": data_tag["significance"],
                "variant_source": "GPAP",
                "comments": data_tag["comments"],
                "origin": data_tag["origin"],
                "sample": data_tag["sample"],
                "timestamp": date,
                "nucleotide": nucleotide,
                "sv_type": data_tag["sv_type"],
                "variant_type": data_tag["variant_type"],
                "project": data_tag["project"]
            }]
    };

    if (state.causative) {
        gene_obj["status"] = "Causative";
    }

    return gene_obj;

};
