

export const splitColConfig = [
    {
        referenceTable: "OMIM",
        nameColumn: "AnnotSV reported pathogenic",
        dataSearch: "SV_type",
        position: "right",
        rowSelect: [
            {
                replaceWith: "P_gain_phen",
                dataSearch: "P_gain_phen",
                value: "DUP",
            },
            {
                replaceWith: "P_loss_phen",
                dataSearch: "P_loss_phen",
                value: "DEL",
            },
            {
                replaceWith: "P_ins_phen",
                dataSearch: "P_ins_phen",
                value: "INS",
            },
        ],
    },
    {
        referenceTable: "Internal Freq.",
        nameColumn: "AnnotSV population AFMax",
        dataSearch: "SV_type",
        position: "left",
        rowSelect: [
            {
                replaceWith: "B_gain_AFmax",
                dataSearch: "B_gain_AFmax",
                value: "DUP",
            },
            {
                replaceWith: "B_loss_AFmax",
                dataSearch: "B_loss_AFmax",
                value: "DEL",
            },
            {
                replaceWith: "B_ins_AFmax",
                dataSearch: "B_ins_AFmax",
                value: "INS",
            },
        ],
    },
];