

import {DateTime} from "luxon";


function date_generator(){

    let date = DateTime.local();
    return date.toLocaleString(DateTime.DATETIME_FULL);
}

function generateUniqueName(baseName) {
    return `${baseName}_${Date.now()}`;
}


export {date_generator, generateUniqueName}