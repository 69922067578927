import React from "react";
import GPAP_Tooltip from "../../../../../../../gpap-lib/components/GPAP_Tooltip";
import MappedText from "../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";


function CustomAnalysisLabel({ tooltip, text1, text2, deleteBool, handleDelete, analysisIndex }){

    return <React.Fragment>
        <GPAP_Tooltip title={tooltip} style={{whiteSpace: 'pre-line'}}>
            <div style={{float: "left"}}>
                <MappedText text={text1}/>
                {(deleteBool)
                    ? <div style={{float: "right"}}>
                        <IconButton style={{padding: "0px"}} size="small" onClick={() => {
                            handleDelete(analysisIndex)
                        }}>
                            <CloseIcon/>
                        </IconButton></div>
                    : null
                }

            </div>
        </GPAP_Tooltip>
        <span style={{fontSize: "smaller"}}>
                    <div>
                        {text2}
                    </div>

                </span>
    </React.Fragment>


}

export default CustomAnalysisLabel;


