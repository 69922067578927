



import * as RANGE_CONFIG from "../../../samples-section/config/range_slider_config";

import React from "react";
import GQForm from "./GQ_Form";


import {connect} from "react-redux";
import {Grid} from "@mui/material";


const SliderBox = (props) => {

    let { sample_id, gt_parameters, samples} = props;

    let {ad_low, ad_high }  = gt_parameters;

    let AlternateSettings = {...RANGE_CONFIG.Alternate};
    if(ad_low !== 0 && ad_high!== 0){
        AlternateSettings.defaultValue = [ad_low*100, ad_high*100];
    }
    else{
        AlternateSettings.defaultValue = [ad_low, ad_high];
    }



    if(samples){

        let sample_redux = samples.find(s => s.sample_id === sample_id);

        if(sample_redux.gt_settings !== undefined && sample_redux.gt_settings.length> 1 && sample_redux.gt_settings[0].ad_high !== ad_high && sample_redux.gt_settings[0].ad_low !== ad_low){

            AlternateSettings.defaultValue = [sample_redux.gt_settings[0].ad_low, sample_redux.gt_settings[0].ad_high];

        }


    }


    const getRenderingSettings = (sampleCase) => {
        const defaultSettings = [
            { label: 'Minimum Depth', name: 'minDepth', queryKey: "dp", config: RANGE_CONFIG.minDepth_props, tooltip: "Minimum read depth at variant position (greater depth increases reliability)" },
            { label: 'Minimum Genotype Quality', name: 'gQ_Range', queryKey: "gq", config: RANGE_CONFIG.gQ_Range_props, tooltip: "Minimum genotype quality (Phred-scaled) for variant position (higher values indicate higher confidence)" },
            { label: 'Alternate Allele Frequency', name: 'Alternate', queryKey: "ad", config: AlternateSettings, tooltip: "Minimum and Maximum proportion of reads supporting the alternative Allele" }
        ];
        
        // In the case of somatic and in the control sample keep only the relevant field
        if (sampleCase === 'control') {
            return [
                { label: 'Minimum Depth', name: 'minDepth', queryKey: "dp_control", config: RANGE_CONFIG.minDepth_props, tooltip: "Minimum read depth at variant position (greater depth increases reliability)" }
            ];
        }
        
        // In the case of somatic and in the tumor sample keep only the relevant fields
        if (sampleCase === 'tumor') {
            return [
                { label: 'Minimum Depth', name: 'minDepth', queryKey: "dp", config: RANGE_CONFIG.minDepth_props, tooltip: "Minimum read depth at variant position (greater depth increases reliability)" },
                { label: 'Alternate Allele Frequency', name: 'Alternate', queryKey: "ad", config: AlternateSettings, tooltip: "Minimum and Maximum proportion of reads supporting the alternative Allele" }
            ];
        }
    
        return defaultSettings;
    };

    
    const rendering_settings = getRenderingSettings(props.sample_case);


    return (
        <Grid container
              direction={"column"}
        >
                {rendering_settings.map(field => {
                    return (
                        <Grid lg={12}>
                            <div className="box-body">
                             <GQForm
                                 label={field.label}
                                 sample_id={sample_id}
                                 queryKey = {field.queryKey}
                                 name={field.name}
                                 config={field.config}
                                 tooltip = {field.tooltip}
                                 inheritance_key={gt_parameters.inheritance_key}
                                 inheritance_type = {gt_parameters.label}
                             />
                        </div>
                        </Grid>
                    )
                })}

        </Grid>
    )






};

//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.patients.length!==0)
    {
        return {
            patients: state.patients.patients,
            samples: state.sample_list.samples,
            token: state.authorization.token
        }
    }
    else{
        return {patients: [], samples:[], token: state.authorization.token}
    }
}




export default connect(mapStateToProps,null)(SliderBox);



/*
export default SliderBox;*/
