
import React from "react";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

const QueryTab = styled(Tab)(({ theme }) => ({
    maxWidth: "200px",
    minHeight: "25px",
    fontSize: "11px",
    color: "#08306b",
    borderRight: "1px solid #08306b",
    padding: "8px"
}));

const CustomQueryTabs = styled(Tabs)(({ theme }) => ({
    borderBottom: '1px solid #e8e8e8',
    minHeight: "25px",
    '& .MuiTabs-indicator': {
        backgroundColor: 'orange',
        height: "4px",
    },
}));

export { QueryTab, CustomQueryTabs };