
import React from "react";

// mui imports
import Typography from "@mui/material/Typography";
import {Alert, Grid} from "@mui/material";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

// local imports
import {StudyType} from "../../../../study-page/StudyType";
import BackToButton from "../../../../study-page/create-study/analysis-page/components/step-form/BackToButton";


function NoView(){


    return <Box p={2}>
        <Grid container
              direction={"row"}
              justifyContent="center"
              alignItems="center">
            <Grid item lg={12}>
                <Box p={2}>
                    <Alert severity={"warning"}
                           title={"Please select an option"}>
                        The Genomics dashboard can't be loaded with the information provided. Please, click on one of the options below to proceed.
                    </Alert>
                </Box>
            </Grid>
            <Grid item lg={12}>
                <Box p={2}>
                    <BackToButton page={"/home"}
                                  text={"See study list"}/>
                </Box>
            </Grid>
            <Grid item lg={12}>
                <Divider/>
                <StudyType/>
            </Grid>
        </Grid>
    </Box>;



}


export default NoView;