import React, { useEffect, useState } from "react";
import { axiosApi, preFilter, newColumns } from "./utils";
import TableComponentForVariants from "./TableComponentForVariants";
import { useDispatch,
 // useSelector
} from "react-redux";
import useViewFeatures from "./hooks/useViewFeatures";
import { setTable } from "./store/slices/tables/table.slice";
import {somatic_query_default} from "../../../scenes/dashboard/components/genomics-dashboard/components/results/components/variant-table/gpap-table/somatic_table/somatic_query_default";
import LoadingDataComponent from "./LoadingDataComponent";

function CustomTableForCNVs({
  url, method,
    total,
   dataType,
  locationData = false,
  dataHeader = [],
  token,
  configFilter,
  nestedInfo = [],
  nestedInfoB = [],
  split = [],
  compactColumns,
  compactColumnsSplit,
  tooltipCompact,
  tooltipCompactSplit,
  customFeatures,
  chipFull = [],
  buttons,
  type = "expanding",
  queryID,
  setLoading,
  loadingData,
  experiment,genes,getTotal, setSelected,getAllTaggedVariants,all_tagged_variants,isSNVSelected
}) {
  const [data, setData] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [dataButton, setDataButton] = useState([]);
  const [isCheck, setIsCheck] = useState(false);
  const [open, setOpen] = useState(false);
  const [rowIdSelected, setRowIdSelected] = useState(null);
  const [render, setRender] = useState(<h1>Test</h1>);
  const [resApi, setResApi] = useState([]);
  const [idExpanded, setIdExpanded] = useState({
    id: null,
    isExpanded: false,
  });
  const [testExpand, setTestExpand] = useState({});
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [genesArray, setGenes] = useState(["bootstrap"]);
  const dispatch = useDispatch();

  const states = {
    mainData,
    customFeatures,
    setMainData,
    data,
    resApi,
    isCheck,
    setIsCheck,
    open,
    setOpen,
    render,
    setRender,
    idExpanded,
    setIdExpanded,
    isOpenPopover,
    setIsOpenPopover,
    dataButton,
    setDataButton,
    testExpand,
    setTestExpand,
    rowIdSelected,
    setRowIdSelected,
    dataFilter,
    setDataFilter,
    setSelected,
    getAllTaggedVariants,
    all_tagged_variants
  };

  useEffect(() => {
    dispatch(
      setTable({
        option: "allState",
        value: { mainData, data, customFeatures },
      })
    );
  }, [dispatch]);

  const renderFeatures = useViewFeatures(states, customFeatures);

  useEffect(() => {

    let body;

    if(dataType === "somatic"){

      body = JSON.parse(JSON.stringify(somatic_query_default));

      // here update experiment id...


    }
    else{

      // CNV Body request
      body = {
        "page":1,
        "pageSize":10000,
        "fields": [],
        "sorted":[

        ],
        "filtered":[
          {"id":"Experiment_ID", "value": experiment},
        ]
      }

      if (Array.isArray(genes) && genes.length > 0){
        body["filtered"].push({"id": "Gene_name", "value": genes});

      }
    }

    setGenes(genes);
    if(JSON.stringify(genes) !== JSON.stringify(genesArray)){//!initialized) {
      setInitialized(true);
      const options = {
        url,
        method,
        token,
        isCheck,
        locationData,
        split,
        dataHeader,
        nestedInfo,
        nestedInfoB,
        setData,
        setMainData,
        setResApi,
        body,
        getTotal,
        dataType,
        setLoading
      };

      axiosApi(options);
    }

  }, [queryID]);


  useEffect(() => {

    // at the moment of creating a new query, "Run Query", take predfined gene list and get data from server

    // filter with gene list updated

  }, [] )

  const nestedColumns = isCheck
    ? nestedInfoB.map((item) => {
        return {
          Header: item.nameColumn,
          accessor: item.dataSearch,
          tooltip: item.tooltip
        };
      })
    : nestedInfo.map((item) => {
        return {
          Header: item.nameColumn,
          accessor: item.dataSearch,
          tooltip: item.tooltip
        };
      });

  const selectArray = isCheck ? split : dataHeader;

  const [preFilteredData, setPreFilteredData] = useState([]);

  useEffect(() => {
    if (configFilter) {
      return preFilter(
        mainData,
        isCheck,
        configFilter,
        compactColumns,
        compactColumnsSplit,
        setPreFilteredData,
        setDataFilter
      );
    }

    setPreFilteredData(mainData);
  }, [mainData, isCheck, configFilter]);

  useEffect(() => {
    let initialExpandState = {};

    preFilteredData.forEach((item, index) => {
      initialExpandState[index] = false;
    });

    setTestExpand(initialExpandState);
  }, [preFilteredData]);


  const columns = React.useMemo(
    () =>
      newColumns(
        selectArray,
        configFilter,
        isCheck,
        preFilteredData,
        compactColumns,
        compactColumnsSplit,
        tooltipCompact,
        tooltipCompactSplit
      ),
    [isCheck]
  );

  return (
      <>
        {(loadingData)
            ?   <LoadingDataComponent/>
            :  <TableComponentForVariants
                columns={columns}
                data={ preFilteredData}
                dataAnidada={preFilteredData}
                type={type}
                nestedColumns={nestedColumns}
                buttons={buttons}
                states={states}
                renderFeatures={renderFeatures}
                tooltipCompact={tooltipCompact}
                chipFull={chipFull}
                total={total}
                isSNVSelected = {isSNVSelected}
            />

        }
      </>
  );
}

export default CustomTableForCNVs;
