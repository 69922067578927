import { AlignmentType, HeadingLevel, Paragraph, TextRun } from "docx";
import { drawClinicalEvidenceTable } from "../write_utils/drawClinicalEvidenceTable.js";
import { TEXT_FOR_NEGATIVE_CLINICAL_EVIDENCE, fontDoc } from "../../config/template_config.js";
import { createSpacer } from "../write_utils/documentHelpers";
import { renderToStringAlt } from "../../../create-report/shared-components/renderToString";

const headers = [
  "Gene",
  "Drug",
  "Level of evidence",
  "Clinical significance",
];

const dataKeys = [
  "gene",
  "therapy",
  "evidence_level",
  "interpretation",
];

function WriteClinicalEvidenceSection(sectionData) {
  let rows = [];
  let list = [];
  let conclusion = "";

  const renderRows = (rows) => {
    const sections = rows.map((row) => {
      let gene = row.gene !== undefined ? row.gene : "NA";
      let therapy = row.therapy !== undefined ? row.therapy : "NA";

      const text = therapy + "(" + gene + ")";
      const titlePar = new Paragraph({
        children: [
          new TextRun({
            text: text,
            bold: true,
            font: fontDoc,
          }),
        ],
        spacing: { after: 40, before: 40 },
      });
      const commentText =
      row.comment || renderToStringAlt("No additional information provided");
      const CommentPar = new Paragraph({
        children: [
          new TextRun({
            text: commentText,
            italics: !row.comment,
            font: fontDoc,
            color: !row.comment ? "888888" : "000000",
          }),
        ],
      });

      const spacerPar = createSpacer(50, 50);

      return [titlePar, CommentPar, spacerPar];
    });
    return sections.flat();
  };

  if (sectionData !== null && sectionData.rows) {
    rows = sectionData.rows;
    conclusion = sectionData.conclusion;

    list =
      rows.length !== 0
        ? renderRows(rows)
        : [
            new Paragraph({
              children: [
                new TextRun({
                  text: <MappedText text="No additional information provided" />,
                  italics: true,
                  color: "888888",
                }),
              ],
            }),
          ];
  }



  return [
    new Paragraph({
      children: [
        new TextRun({
          text: renderToStringAlt(
            "CLINICAL EVIDENCE AND PATIENT MANAGEMENT"
          ),
          size: 24,
          bold: true,
          heading: HeadingLevel.HEADING_2,
          underline: true,
        }),
      ],
    }),
    new Paragraph({
      text: "",
    }),
    new Paragraph({
      children: [
        new TextRun({
          text: renderToStringAlt(
            "Only information relevant to the specific type of cancer under investigation is reported."
          ),
          size: 22,
          heading: HeadingLevel.HEADING_3,
        }),
      ],
    }),
    new Paragraph({
      text: "",
    }),
    drawClinicalEvidenceTable(
      rows,
      headers,
      dataKeys,
      renderToStringAlt(TEXT_FOR_NEGATIVE_CLINICAL_EVIDENCE.text)
    ),
    new Paragraph({
      text: "",
    }),
    new Paragraph({
      children: [
        new TextRun({
          text: renderToStringAlt("Clinical Significance"),
          size: 24,
          bold: true,
          heading: HeadingLevel.HEADING_2,
          underline: true,
        }),
      ],
    }),
    ...list,
    new Paragraph({
      children: [
        new TextRun({
          text: renderToStringAlt("Conclusion"),
          size: 24,
          bold: true,
          heading: HeadingLevel.HEADING_2,
          underline: true,
        }),
      ],
    }),
    new Paragraph({
      children: [
        new TextRun({
          text:
            conclusion !== "" ? conclusion : renderToStringAlt("No additional information provided"),
          italics: !conclusion,
          color: !conclusion ? "888888" : "000000",
          alignment: AlignmentType.LEFT,
          spacing: { after: 10, before: 10 },
        }),
      ],
    }),
  ];
}

export default WriteClinicalEvidenceSection;
