import {experiments_view_new, getParticipantBySample} from "../../../../../../../services/api/get";


export const fetchSearchResults = (value, sampleType, token, field, totalRows) => {
    return new Promise((resolve, reject) => {
        let postObject = {};

        // Default request object setup
        if (value.length > 1 && (sampleType === "report_id" || sampleType === "id" || sampleType === "famid")) {
            postObject = {
                page: 1,
                pageSize: totalRows,
                filtered: [{ id: field, value: [value] }],
                fields: [field, "sex"]
            };

            // Fetch data for participant search
            getParticipantBySample(window.config.apis_configuration.api_phenostore, token, postObject)
                .then((data) => data.json())
                .then((data) => resolve(data.rows))
                .catch((error) => reject("Connection Error"));

        } else if (sampleType === "case" || sampleType === "LocalExperimentID") {
            const field = sampleType === "case" ? "ExperimentID" : "LocalExperimentID";

            postObject = {
                page: 1,
                pageSize: totalRows,
                filtered: [{ id: field, value: [value] }],
                fields: ["ExperimentID", "Participant_ID", "library_strategy", field]
            };

            // Fetch data for experiment view
            experiments_view_new(window.config.apis_configuration.api_data_management, token, postObject)
                .then((data) => {
                    if (data.ok) return data.json();
                    else throw new Error("Failed to fetch");
                })
                .then((data) => resolve(data.items))
                .catch((error) => reject("Connection Error"));
        } else {
            resolve([]); // If no valid sample type, resolve empty array
        }
    });
};
