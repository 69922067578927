import React, { useEffect } from 'react';
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';

import { contact_user, getParticipantGroups } from "../../../services/api/get.js";




const ContactDialog = (props) => {

    let {api_phenostore} = config.apis_configuration;

    const [open, setOpen] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [group, setGroup] = React.useState(null);
    const [Msg, setMsg] = React.useState(null);

    //From props
    const { selected, numSelected } = props;
    const selected_participant = selected[0]

    useEffect(() => {        
        //setOpen(props.contactFlag)
        getParticipantGroups(api_phenostore, props.token, selected_participant)
                .then(resp => resp.json())
                .then(json => {
                    if(json.owner){
                        setGroup(json.owner[0]);
                    }
                })
        

     }, []);

    function handleErrors(response) {
        if (!response.ok) {
            setMsg("An error occured.Email was not sent.")
            setLoading(false);
            throw Error(response.statusText);
        }
        return response;
      }

    
    const handleClose = () => {
        setOpen(false);
    }

    const initContact = () => {
        setOpen(false);
        setLoading(true);

        let mail_data = {"participant_query":selected_participant, "gene_query": "", "hpo_query": "", "sample_query":"","target": "" ,"result": {"patient":{"contact":{"href":group}}}} 
    
        contact_user(config.api_endpoint, props.token, mail_data)
        .then(handleErrors)
        .then(resp => {
          if(resp){
            setMsg("E-mail was sent!")
            setLoading(false)
        }
        })

    }

    return (

        <React.Fragment>
          <div>

             {loading &&
                <div style = {{marginLeft:"-1200px"}}>
                  <CircularProgress  />
                </div>
             }

            {Msg &&
            <Typography variant="h4" style = {{marginLeft:"-1420px"}}>
              {Msg}
            </Typography> 
            }   
                
            <Dialog
             open={open}
             onClose={handleClose}
             aria-labelledby="alert-dialog-title"
             aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">
              {"Initiate contact?"}
            </DialogTitle>
           
            <DialogContent>
             <DialogContentText id="alert-dialog-description">
              An email will be sent to the submitter of the patient record in order to initiate contact. A copy of this email will also be sent to platform@rd-connect.eu to keep track of the requests initiated through the RD-Connect GPAP.
             </DialogContentText>
            </DialogContent>
           
            <DialogActions>
              <Button onClick={handleClose}>Disagree</Button>
              <Button onClick={initContact} autoFocus> Agree </Button>
            </DialogActions>
           </Dialog>
        </div>
        </React.Fragment>
                
    );
};


function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {token: state.authorization.token,sampleList: state.sample_list}
    }
  }
  

export default connect(mapStateToProps)(ContactDialog);

