import {getValueByPath, setValueByPath} from "./utilsValues";

export const dataMapper = (dataToMap, isCheck, split, dataHeader, nestedInfoB, nestedInfo) => {

    return dataToMap.map((item) => {
        const data = {};

        const mapInfo = isCheck ? split : dataHeader;

        mapInfo.forEach(({columns}) => {
            columns.forEach((col) => {
                let propertyValue = getValueByPath(item, col.dataSearch);
                if (propertyValue !== undefined) {
                    setValueByPath(data, col.dataSearch, propertyValue);
                }
            });
        });

        const fullRespApi = dataToMap;

        const nested = isCheck
            ? nestedInfoB.map(({dataSearch}) => {
                if (typeof item[dataSearch] === "object") {
                    return item[dataSearch];
                }

                return {
                    [dataSearch]: Array.isArray(item[dataSearch])
                        ? item[dataSearch]
                        : [item[dataSearch]],
                };
            })
            : nestedInfo.map(({dataSearch}) => {
                return {
                    [dataSearch]: Array.isArray(item[dataSearch])
                        ? item[dataSearch]
                        : [item[dataSearch]],
                };
            });

        return {...data, fullRespApi, nested};
    });
}