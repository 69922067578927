
import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Typography, Box } from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


const SectionCards = ({ sectionData }) => {
    return (
        <Box p={1}>
            {Object.keys(sectionData).map(sectionName => (
                <Box p={1}>  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography><b>{sectionData[sectionName].name}</b>, {sectionData[sectionName].children.length} resources</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {sectionData[sectionName].children.map(child => (
                            <Card key={child.id} variant="outlined" style={{ marginBottom: '1rem' }}>
                                <CardContent>
                                    <Typography variant="h6" component="h2">
                                        {child.name}
                                    </Typography>
                                    <Typography color="textSecondary">
                                        <strong>URL Prefix:</strong> <a target={"_blank"} href={child.url_prefix}>{child.url_prefix}</a>
                                    </Typography>
                                    <Typography color="textSecondary">
                                        <strong>URL Appendix:</strong> {child.url_appendix}
                                    </Typography>
                                    <Typography color="textSecondary">
                                        <strong>Variable Category:</strong> {child.variable_category}
                                    </Typography>
                                    <Typography variant="textSecondary" component="p">
                                        <strong>Description:</strong> {child.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </AccordionDetails>
                </Accordion>
                </Box>
            ))}
        </Box>
    );
};

export default SectionCards;
