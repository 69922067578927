

import React, { Fragment } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import BuildIcon from "@mui/icons-material/Build";
import WarningBox from "../../../../../../../../../study-page/create-study/WarningBox";

const UnderConstructionMsg = () => (
    <Fragment>
        <Box p={1}>
            <WarningBox
                iconBool={false}
                text={
                    <Stack direction="row" spacing={1} alignItems="center">
                        <BuildIcon color="warning" fontSize="small" />
                        <Typography>
                            We're currently working on enabling the functionality to edit or delete CNV Tags. If you need to edit this tag or believe it was added by mistake, please contact us at{" "}
                            <a href={`mailto:${window.config.help_email}`} style={{ textDecoration: "none", color: "inherit" }}>
                                {window.config.help_email}
                            </a>.
                        </Typography>
                    </Stack>
                }
            />
        </Box>
    </Fragment>
);

export default UnderConstructionMsg;