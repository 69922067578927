



export function save_study(urlprefix, token, data){
    return fetch(urlprefix + "/study",
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            },
            body: JSON.stringify(data)}
    )
}


export function update_study(urlprefix, token, data){
    return fetch(urlprefix + "/study",
        {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            },
            body: JSON.stringify(data)}
    )
}

export function update_analysis(urlprefix, token, data){
    return fetch(urlprefix + "/analysis",
        {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            },
            body: JSON.stringify(data)}
    )
}





export function save_analysis(urlprefix, token, data){
    return fetch(urlprefix + "/analysis",
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            },
            body: JSON.stringify(data)}
    )
}

export function get_study(urlprefix, token){
    return fetch(urlprefix + "/study",
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            }
        }
    )
}


export function updateDBQuery(urlprefix, data, token){
    return fetch(urlprefix + "/query",
        {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            },
            body: JSON.stringify(data)
        }
    )
}

export function delete_study(urlprefix, token, data){
    return fetch(urlprefix + "/study",
        {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            },
            body: JSON.stringify(data)
        }
    )
}
export function deleteAnalysis(urlprefix, token, data){
    return fetch(urlprefix + "/analysis",
        {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            },
            body: JSON.stringify(data)
        }
    )
}




export function get_all_studies(urlprefix, token){
    return fetch(urlprefix + "/studies_all",
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            }
        }
    )
}

export function get_study_info(urlprefix, token, data){
    return fetch(urlprefix + "/get_study",
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            },
            body: JSON.stringify(data)}
    )

}


export function get_study_analyses(urlprefix, token, data){
    return fetch(urlprefix + "/analyses_study",
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            },
            body: JSON.stringify(data)}
    )
}

export function saveQuery(urlprefix, token, data){
    return fetch(urlprefix + "/query",
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            },
            body: JSON.stringify(data)}

    )
}

export function updateQuery(urlprefix, token, data){
    return fetch(urlprefix + "/query",
        {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            },
            body: JSON.stringify(data)}

    )
}


export function delete_query_db(urlprefix, token, data){
    return fetch(urlprefix + "/query",
        {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            },
            body: JSON.stringify(data)
        }
    )
}


export function get_filter_set(urlprefix, token, data){
    return fetch(urlprefix + "/filter_set_request",
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            },
            body: JSON.stringify(data)}
    )
}

export function postFilterSet(urlprefix, token, data){
    return fetch(urlprefix + "/filter_set",
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            },
            body: JSON.stringify(data)}
    )
}

export function deleteFilterSet(urlprefix, token, data){
    return fetch(urlprefix + "/filter_set",
        {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            },
            body: JSON.stringify(data)}
    )
}

export function get_all_filter_sets(urlprefix, token){
    return fetch(urlprefix + "/filter_set",
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            }
        }
    )
}