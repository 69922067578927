import { AlignmentType, HeadingLevel, Paragraph,TextRun } from "docx";
import { drawBasicTable } from "../../write-sections/write_utils/drawTables_examples.js";
import { renderToStringAlt } from "../../../create-report/shared-components/renderToString.js";

const headers = [
    "Mean coverage",
    "Median coverage",
    "Duplication rate",
    "Uniformity",
    "C10",
    "C15",
];

const dataKeys = [
    "mean_coverage",
    "median_coverage",
    "duplication_rate",
    "uniformity",
    "c10",
    "c15",
];

function WriteExomeCoverageSection(sectionData) {
    let rows = sectionData && Array.isArray(sectionData) ? sectionData : [];
    
    return [
        new Paragraph({
            children: [
                new TextRun({
                    text: renderToStringAlt("Exome coverage metrics"),
                    bold: true,
                    size: 22 
                })
            ],
        }),
        new Paragraph({
            text: "",
        }),
        drawBasicTable(rows, headers, dataKeys, renderToStringAlt("No data available")),
      
        new Paragraph({
            children: [
                new TextRun({
                    text: renderToStringAlt("C10/C15: percentage of bases covered by at least 10 and 15 reads, respectively."),
                    font: "Times New Roman",
                    size: 16,
                    bold: false,
                    color: "757575",
                })
            ],
   
        }),
    ];
}

export default WriteExomeCoverageSection;