import React from "react";
import Typography from "@mui/material/Typography";
import {AlertInfo} from "./SelectionInfo";


const ExperimentInfo = ({ error_msg, tags, field }) => {
    const selectedTag = tags?.[field];

    return (
        <div>
            <Typography variant="subtitle2">
                Only experiments that have been processed and that are ready for analysis are visible.
            </Typography>

            {error_msg && (
                <AlertInfo
                    title={error_msg}
                    severity="error"
                />
            )}

            {selectedTag && (
                <div>
                    <Typography
                        variant="h4"
                        sx={{ fontSize: "15px", fontWeight: "normal" }}
                    >
                        You have selected:{" "}
                        <span style={{ fontWeight: "bold" }}>{selectedTag}</span>
                    </Typography>
                </div>
            )}
        </div>
    );
};

export default ExperimentInfo;
