export function mergeHits(hits) {
    const uniqueHitsMap = new Map();

    hits.forEach(hit => {
        const { chrom, pos } = hit._source;
        const key = `${chrom}:${pos}`;

        if (uniqueHitsMap.has(key)) {
            const existingHit = uniqueHitsMap.get(key);

            // Merge effs ( effs is an object, merge keys)
           /* existingHit.fields.effs = [{
                ...existingHit.fields.effs,
                ...hit.fields.effs
            }];*/

            existingHit.fields.effs = [
                ...new Set([
                    ...existingHit.fields.effs,
                    ...hit.fields.effs
                ])
                    ];


            // Merge samples_germline (it's an array, merge unique values)
            existingHit.fields.samples_germline = [
                ...new Set([
                    ...existingHit.fields.samples_germline,
                    ...hit.fields.samples_germline
                ])
            ];
        } else {
            // Add the new unique hit to the map
            uniqueHitsMap.set(key, { ...hit });
        }
    });

    // Return the merged hits as an array
    return Array.from(uniqueHitsMap.values());
}


