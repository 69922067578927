import React from 'react';
import { Grid } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';

import { SearchDM } from './SearchDM';


export const SearchSample = (props) => {

    const { title, searchExperiment, errorMessage } = props;
    const { field, searchText, sampleType } = props;
    const { handleSearch, filterParticipant, participantID } = props;
    

    return (
        <>
            <Grid item xs={4}>
                <InputLabel>{title} <span style={{ color: 'firebrick' }}> Required </span> </InputLabel>
                <SearchDM
                    error={errorMessage !== ""}
                    errorMessage={errorMessage}
                    field={field}
                    title={searchText}
                    passSelected={(exp) => {
                        let value=""; 
                        if (exp !== undefined) {
                            if (searchExperiment) value = exp.ExperimentID;
                            else value = exp.Participant_ID; 
                        }
                        handleSearch(field, value);
                    }}
                    sampleType={sampleType}
                    filterParticipant={{"filter": filterParticipant, "participantId": participantID}}
                />
            </Grid>
        </>
    );
}
