
import React from "react";
import FlagIcon from "@mui/icons-material/Flag";


// Utility function to check for matching treatments
function matchTreatment(treatment, criteria) {
    const { hgvs_p, gene_name, Therapies, Cancer_Type, Evidence_level } = criteria;
    return (
        treatment.gene === gene_name &&
        treatment.variant === hgvs_p &&
        treatment.cancer_type === Cancer_Type &&
        treatment.therapy === Therapies &&
        treatment.evidence_level === Evidence_level
    );
}

// Function to get the treatment annotation icon
export function getTreatmentAnnotationIcon(row, participant) {
    const { hgvs_p, gene_name, Therapies, Cancer_Type, Evidence_level } = row.values;

    if (!Object.isExtensible(participant)) {
        participant = { ...participant };
    }

    if (participant.tagged_treatments) {
        for (const treatment of participant.tagged_treatments) {
            if (matchTreatment(treatment, { hgvs_p, gene_name, Therapies, Cancer_Type, Evidence_level })) {
                return <FlagIcon style={{ color: "#3182bd" }} />;
            }
        }
    }

    return null;
}

// Function to find the treatment and return additional information
export function treatmentFinder(selected, participant) {
    const { hgvs_p, gene_name, Therapies, Cancer_Type, Evidence_level } = selected;
    let found = false;
    let interpretation = "";

    if (!Object.isExtensible(participant)) {
        participant = { ...participant };
    }

    if (participant.tagged_treatments) {
        for (const treatment of participant.tagged_treatments) {
            if (matchTreatment(treatment, { hgvs_p, gene_name, Therapies, Cancer_Type, Evidence_level })) {
                found = true;
                interpretation = treatment.interpretation;
                break;
            }
        }
    }

    return { found, interpretation };
}
