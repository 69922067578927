import {withStyles} from "@mui/styles";
import Badge from "@mui/material/Badge";


const FlaggedBadge = withStyles((theme) => ({
    root: {
        verticalAlign:"bottom"
    },
    badge: {
        right: 20,
        top: 0,
        border: "2px solid #6baed6",
        padding: '0 1px',
        backgroundColor: "#bdd7e7",
        fontSize: "12px"
    },
}))(Badge);

export default FlaggedBadge;