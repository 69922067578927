
import React, { useState, useEffect } from 'react';

import {useSelector} from "react-redux";
import {Grid, InputLabel, Paper, TextField, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import CircleIcon from '@mui/icons-material/Circle'; // Importing a circle icon for the dot

import Select from '@mui/material/Select';

import dayjs from 'dayjs';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormControl from "@mui/material/FormControl";
import {DateTimePicker} from "@mui/x-date-pickers";
import GPAP_Dialog from "../../../gpap-lib/components/GPAP_Dialog";
import CaButton from '../../../gpap-lib/components/shared-components/CaButton';

import { experiments_view_new } from '../../../services/api/get';
import { dir_insert } from '../../../services/api/post';

import { ExperimentsAssociated } from './components/ExperimentsAssociated';


import { dirAnalysisTypes } from './UtilCreateDir'
import auth from '../../../services/session/Auth'
import MappedText from '../reports/create-report/shared-components/mapText';
import { useHistory } from 'react-router-dom';

const config = window.config;
const URL_API_DM = config.apis_configuration.api_data_management;

const CreateDir = () => {

    const token = useSelector(state => state.authorization.token);
    const groups = useSelector(state => state.authorization.groups);
    const history = useHistory();
    const [analysisOptions, setAnalysisOptions] = useState([]);


    const [openDialog, setDialog] = useState({
        status: false,
        title: "",
        text: "",
        open: false,
        type: "success"
    });

    useEffect(() => {
        const fetchAnalysisTypes = async () => {
          const data = await dirAnalysisTypes(token); 
          setAnalysisOptions(data);
    
         
          if (data.length > 0) {
            setFormValues((prev) => ({ ...prev, analysis: data[0].id }));
          }
        };
    
        fetchAnalysisTypes();
      }, [token]); 

    const [saved_DIR, setSaveDIR] = useState({});
    const [validate, setValidate] = useState(false);

    const [searchExperimentControl, setSearchExperimentControl] = useState(false);

    const [formValues, setFormValues] = useState({
        analysis: "",
        assignee: '',
        description: "",
        clinical_referrer: '',
        clinical_referrer_contact: '',
        deadline: new Date().toISOString().slice(0, 19).replace('T', ' '),
        tumor_experiment_id: '',
        control_experiment_id: '',
        group_owner: (groups.length>0) ? groups[0] : "",
        hospital_name: '',
        priority: 'medium',
        status: 'to do',
        filter_set: 0,
        predefined_list: 0,
    });

    const [formErrorValues, setFormErrorValues] = useState({
        tumor_experiment_id: "",
        control_experiment_id: ""
    });

    const [filterSetList, setFilterSetList] = useState([]);
    const [predefinedGeneList, setPredefindedGeneList] = useState([]);

    const [participantID, setParticipantID] = useState({tumor_participant_id: "", control_participant_id: ""});

    const [value, setValue] = useState(dayjs());

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleCloseDialog = () => {
        setDialog({
            status: false,
            title: "",
            text: "",
            open: false,
            type: "success"
        });


        if(saved_DIR.dir_id!== undefined)
        {
            history.push(`/dir_overview/${saved_DIR.dir_id}`);
        }



    }

    // Function to update form state and handle validation for an experiment search
    const handleSearchExperiment = (field, experiment) => {
        if(experiment !== undefined){
            setFormValues({ ...formValues, [field]: experiment });
            setValidate(false);
            setFormErrorValues({ ...formErrorValues, [field]: ""});
            if (field === "tumor_experiment_id" && formValues.analysis === "somatic_tumor_normal"){
                setSearchExperimentControl(true);
            } 
        }
    }

     // Function to update participant ID state and reset related form values
    const handleSearchParticipant = (field, participant) => {
        if(participant !== undefined){
            setParticipantID({ ...participantID, [field]: participant });
            setFormValues({ ...formValues, ["control_experiment_id"]: "", ["tumor_experiment_id"]: "" });
            setValidate(false);
            setFormErrorValues({ ...formErrorValues, ["control_experiment_id"]: "", ["tumor_experiment_id"]: ""});
        }
    }

    // Handle form submission, create a new dir and handling responses
    const handleSubmit = () => {

        const payload = { ...formValues };

        dir_insert(payload)
        .then((response) => { 
            if (response.ok){ console.log(response); return response.json();}
            else {console.log(response); throw new Error(response.statusText);}
        })
        .then((data) => {
            console.log('Success:', data);
            setSaveDIR(data["created_dir"]);
            setDialog({
                status: true,
                title: "Request " +  data["created_dir"].dir_id + " created successfully",
                text: "You can find the created request in the Intepretation Request page",
                open: true,
                type: "success"
            });
        })
        .catch((error) => {
            console.error('ERROR:', error);
            setDialog({
                status: false,
                title: "Error in creating request",
                text: "Please, review entered data. Error code: " + error,
                open: true,
                type: "error"
            });
        });
    };

    const handleDateChange = (newValue) => {
        setFormValues({ ...formValues, deadline: newValue })
    };

    const renderDialog = () => {
        return <GPAP_Dialog
            open={openDialog.open}
            handleClose={handleCloseDialog}
            title={openDialog.title}
            text={openDialog.text}
            onExitText={(saved_DIR.dir_id!== undefined) ? "Go to Request List" : "Close"}
            onConfirmText={(saved_DIR.dir_id!== undefined) ? "Go to Request" : ""}
            onExitAction={onCancelClick}
            onConfirmAction={handleCloseDialog}
            type={openDialog.type}
        />
    }
    const errorMessages = {
        label: "No control experiment id for tumor experiment id: ",
    };

    // Load Filter Set List when view is load fot the first time
    useEffect(() => {
        const url = window.config.apis_configuration.api_nextgpap_server;
        fetch( url+ '/filter_set', {
            method: 'GET', headers: { 'Content-Type': 'application/json', "Authorization": token }
        })
        .then((response) => response.json())
        .then((data) => {
                setFilterSetList(data.data.filter(list=>list.filter_set_name !== "").map(fs => ({name: fs.filter_set_name, id: fs.id})));
        })
        .catch((error) => {
            setDialog({
                status: false, open: true, type: "error",
                title: "Could not get filter set list",
                text: "Broken connection to back-end API. Please try again later."             
            });
        });
    }, []);

    // After Having the filter set loaded then require the gene list
    useEffect(() => {
        const url = window.config.apis_configuration.api_nextgpap_server;
        fetch( url+ '/get_all_lists', {
            method: 'GET', headers: { 'Content-Type': 'application/json', "Authorization": token }
        })
        .then((response) => response.json())
        .then((data) => {
            setPredefindedGeneList(data.data.map(gl => ({name: gl.title, id: gl.id})));
        })
        .catch((error) => {
            setDialog({
                status: false, open: true, type: "error",
                title: "Could not get predefined gene lists",
                text: "Broken connection to back-end API. Please try again later."
            });
        });
    }, [filterSetList]);

    useEffect(() =>{
        if (searchExperimentControl) {
            // Define body post to Experiment View
            const body = {
                "page":1,"pageSize":1,
                "fields":["ExperimentID","control_experiment_id"],
                "filtered":[{"id":"ExperimentID", "value":[formValues.tumor_experiment_id]}],
            }
            experiments_view_new(URL_API_DM,auth.getToken(),body)
            .then((response) => {
                 if (response.ok) return response.json();
                 else throw new Error(response.statusText);
            })
            .then((dataResponse) => {
                if (dataResponse.items.length > 0) {
                    let control_experiment_id = dataResponse.items[0].control_experiment_id;
                    if (control_experiment_id !== "" && control_experiment_id !== null) {
                        setFormValues({ ...formValues, "control_experiment_id": control_experiment_id });
                        setFormErrorValues({ ...formErrorValues, "control_experiment_id": "" });
                    } else {
                        setFormErrorValues({ ...formErrorValues, "control_experiment_id": errorMessages.label + formValues.tumor_experiment_id });
                    }
                }
                setSearchExperimentControl(false);
            })
            .catch((error) => {
                setSearchExperimentControl(false);
            });
        }
    },[searchExperimentControl]);

    const onCancelClick = () => {
        history.push('/dir_list');
    };

    return (
        <div>
            {renderDialog()}
                <Box p={3}>
                <Grid container spacing={2} direction={"column"}>
                    <Grid item xs={12}>
                        <Typography variant={"h3"}><MappedText text={"Create Interpretation Request"}/> </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box pt={2}>
                                <Grid container spacing={2} direction={"row"}>
                                    <Grid item xs={12}>
                                        <FormControl style={{width: "300px"}}>
                                            <div style={{ marginBottom: '8px' }}>
                                                <InputLabel
                                                    id="select-label-analysis">
                                                    <MappedText text={"Analysis "}/>
                                                    <span style={{color: "firebrick"}}>
                                                    <MappedText text={"Required"}/>
                                                </span>
                                                </InputLabel>
                                            </div>
                                            <Select
                                                labelId="select-label-analysis"
                                                id="analysis-select"
                                                name="analysis"
                                                value={formValues.analysis}
                                                onChange={handleInputChange}
                                                >
                                                {analysisOptions.map((analysis) => (
                                                    <MenuItem key={analysis.id} value={analysis.id}>
                                                    {analysis.label}
                                                    </MenuItem>
                                                ))}
                                                </Select>

                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" gutterBottom>
                                            <MappedText text={"Assignee"}/>
                                        </Typography>
                                        <TextField
                                            variant="outlined"
                                            name="assignee"
                                            label=" "
                                            fullWidth
                                            value={formValues.assignee}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                            <Typography variant="body1" gutterBottom
                                                style={{ marginTop: "20px" }}
                                                color={"textSecondary"}>
                                                <MappedText text={"The users who will handle the interpretation of this request. Only these users can edit the request. If no users are selected, everyone in the organization can edit."}/></Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" gutterBottom>
                                            <MappedText text={"Description"}/>
                                        </Typography>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            name="description"
                                            data-testid="description"
                                            aria-labelledby="description-label"
                                            label=" "
                                            value={formValues.description}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <MappedText text={"Clinical Referrer"}/>
                                        </Typography>
                                        <TextField
                                            name="clinical_referrer"
                                            variant="outlined"
                                            fullWidth
                                            label=" "
                                            value={formValues.clinical_referrer}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <MappedText text={"Clinical Referrer Contact"}/>
                                        </Typography>
                                        <TextField
                                            name="clinical_referrer_contact"
                                            variant="outlined"
                                            fullWidth
                                            label=""
                                            value={formValues.clinical_referrer_contact}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <MappedText text={"Hospital name"}/>
                                        </Typography>
                                        <TextField
                                            name="hospital_name"
                                            fullWidth
                                            label=""
                                            variant="outlined"
                                            value={formValues.hospital_name}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <FormControl style={{width: "100%"}}>
                                            <Typography >
                                                <MappedText text={"Priority"}/>
                                            </Typography>
                                            <Select
                                                name="priority"
                                                value={formValues.priority}
                                                onChange={handleInputChange}
                                                fullWidth
                                                displayEmpty>
                                                <MenuItem value="" disabled>
                                                    <em><MappedText text={"Select Priority"}/></em>
                                                </MenuItem>
                                                <MenuItem value="low" data-testid="priority-low" >
                                                        <CircleIcon fontSize="small" style={{ float: "left", color: 'green', padding: "2px" }} />
                                                    <div style={{float:"left", display: "block"}}>
                                                    <MappedText text={"Low"}/></div>
                                                </MenuItem>
                                                <MenuItem value="medium" data-testid="priority-medium">
                                                        <CircleIcon fontSize="small" style={{ float: "left", color: 'orange', padding: "2px" }} />
                                                    <div style={{float:"left", display: "block"}}><MappedText text={"Medium"}/></div>
                                                </MenuItem>
                                                <MenuItem value="high" data-testid="priority-high">
                                                        <CircleIcon fontSize="small" style={{ float: "left",  color: 'red', padding: "2px" }} />
                                                    <div style={{float:"left", display: "block"}}><MappedText text={"High"}/></div>
                                                        </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl style={{width: "100%"}}>
                                                <Typography >
                                                    <MappedText text={"Deadline"}/>
                                                </Typography>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DateTimePicker
                                                        label=""
                                                        value={value}
                                                        defaultValue={new Date()}
                                                        onChange={handleDateChange}
                                                        minDate={dayjs()} // This will prevent selecting dates before today
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl style={{width: "100%"}}>
                                            <Typography >
                                                <MappedText text={"Group Owner"}/>
                                            </Typography>
                                            <Select
                                                name="group_owner"
                                                value={formValues.group_owner}
                                                onChange={handleInputChange}>
                                                {groups.map(g =>  <MenuItem value={g}>{g}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                
                                 
                                   <ExperimentsAssociated
                                        analysis_type={formValues.analysis}
                                        error_tumor_experiment_id={formErrorValues.tumor_experiment_id}
                                        error_control_experiment_id={formErrorValues.control_experiment_id}
                                        handleSearchExperiment={handleSearchExperiment}
                                        handleSearchParticipant={handleSearchParticipant}
                                        control_experiment_id={formValues.control_experiment_id} 
                                        tumor_experiment_id={formValues.tumor_experiment_id}
                                        control_participant_id={participantID.control_participant_id} 
                                        tumor_participant_id={participantID.tumor_participant_id}
                                    />

                                    <Grid item xs={12}>
                                        <div style={{marginBottom:"8px"}}>
                                            <Typography variant={"h6"}><MappedText text={"Filters"}/></Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl style={{ width: "100%" }}>
                                            <div style={{ marginBottom: "8px" }}>
                                                <InputLabel><MappedText text={"Filter Set"} /> <span style={{ color: "firebrick" }}><MappedText text={"Required"} /></span></InputLabel>
                                            </div>
                                            <Select
                                                fullwidth
                                                required
                                                name="filter_set"
                                                value={formValues.filter_set}
                                                onChange={handleInputChange}>
                                                {filterSetList.map(fs => <MenuItem value={fs.id}>{fs.name}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl style={{ width: "100%" }}>
                                            <div style={{marginBottom:"8px"}}>
                                            <InputLabel>
                                                <MappedText text={"Gene List "} />
                                                <span style={{ color: "firebrick" }}> 
                                                <MappedText text={"Required"} /></span>
                                            </InputLabel>
                                            </div>
                                                <Select
                                                required
                                                name="predefined_list"
                                                value={formValues.predefined_list}
                                                onChange={handleInputChange}>
                                                {predefinedGeneList.map(gl => <MenuItem value={gl.id}>{gl.name}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Paper
                                        elevation={3}
                                        sx={{
                                            position: "fixed",
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                            height: "50px",
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            zIndex: 1000
                                        }}
                                    >
                                        <CaButton onClick={onCancelClick}
                                            variant="outlined"
                                            text="Cancel"
                                            size="large"
                                            customStyles={{ marginRight: "20px" }} />
                                        <CaButton onClick={handleSubmit}
                                            variant="contained"
                                            text="Create"
                                            size="large"
                                            customStyles={{ marginRight: "20px" }} />
                                    </Paper>
                                </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>

    );
};

export default CreateDir;
