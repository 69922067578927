
import React, {useEffect, useRef, useState} from "react";
import Typography from "@mui/material/Typography";
import {clinical_evidence_columns} from "./clinical_evidence_columns.js";
import GeneralTable from "../../../create-report/shared-components/general-table/GeneralTable.js";
import Box from "@mui/material/Box";
import {TextField} from "@mui/material";

import SelectedRowInfoBox from "../../../create-report/shared-components/SelectedRowInfoBox.js";
import MappedText from "../../shared-components/mapText.js";
import {useSelector} from "react-redux";



function ClinicalEvidenceTable({data, setSelectedRows}){



    const handleSelectedRows = (rows) => {

        //setSelected(rows);
        setSelectedRows(rows);



    };

    return <GeneralTable columns={clinical_evidence_columns}
                         data={data}
                         handleSelectedRows={handleSelectedRows}
                         showCheckbox={true}/>


}


function ClinicalEvidenceSection({participant, handleSectionUpdate}){


    const [treatment_data, setTreatmentData] = useState([]);
    const [conclusionText, setConclusionText] = useState("");
    const [allClinicalEvidences, setAllClinicalEvidences] = useState([])

    const [selectedRows, storeSelectedRows] = useState([]);

    const [init, setInit] = useState(false);

    const commentsRef = useRef({});

    const { allDataApis  } = useSelector((state) => state.generaltable);
    const selectedDir = allDataApis?.dir?.find(d => d.dir_id === allDataApis?.dirs?.dir_id);

    const token = useSelector(state => state.authorization.token);

    useEffect( () =>{

        const url = window.config.apis_configuration.api_nextgpap_server_elastic;
        const experiment = selectedDir?.tumor_experiment_id;
        const payload= {"fields":[],"filtered":[{"id":"Experiment_id","value":[experiment]}],"page":1,"pageSize":1000,"sorted":[]}

        if(!init && participant){
            fetch(url + '/api/treatments', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": token
                },
                body: JSON.stringify(payload)
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then((data) => {

                    // filter out treatment_data, store these treatments instead;

                    setAllClinicalEvidences(data.items)
                    setInit(true);
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setInit(true);
                });
        }


    }, [participant])


    const configureTaggedTreatments = (all_treatments) => {


        let tagged_treatments = (participant && 'tagged_treatments' in participant) ? participant.tagged_treatments : [];

        if(participant && 'tagged_treatments' in participant){

            let tt_dir = tagged_treatments.filter(treatment =>
                all_treatments.some(tt =>
                    treatment.gene === tt.gene_name &&
                    treatment.variant === tt.hgvs_p &&
                    treatment.cancer_type === tt.Cancer_Type &&
                    treatment.therapy === tt.Therapies
                )
            );

            let copyTreatments = JSON.parse(JSON.stringify(tt_dir))

            copyTreatments.forEach(s => s["comment"] = s["interpretation"] ?? "");

            //all selected index

            let indexes = copyTreatments.map((s,i) => i );

            setTreatmentData(copyTreatments);
            storeSelectedRows(indexes);
            handleSectionUpdate({rows: copyTreatments, conclusion: conclusionText}, "Clinical_Evidence");
        }
        else{
            setTreatmentData(tagged_treatments);
            storeSelectedRows(tagged_treatments);
        }





    }


    useEffect(() => {


        // add comment field to tagged treatments
        if(allClinicalEvidences){
            configureTaggedTreatments(allClinicalEvidences);
        }




    }, [allClinicalEvidences]);

    const handleSelectedRows = (rows) => {

        // I d rather save the index...


        let selectedRowIndexes = rows.map(s =>parseInt(s.id));
        storeSelectedRows(selectedRowIndexes);

        // return with the right comment

        let tagged_selected = treatment_data
            .filter((row, index) => selectedRowIndexes.includes(index));


        handleSectionUpdate({rows: tagged_selected, conclusion: conclusionText}, "Clinical_Evidence");
    }

    const handleTextChange = (index, value) => {

        // get prevState of the Tagged Treatments and update comment;


      /*  setTreatmentData(prevState => {

            const updatedTreatments = [...prevState];
            updatedTreatments[index].comment= value;
            const selectedTreatments = updatedTreatments.filter((s,i) => {return selectedRows.includes(i);});
            handleSectionUpdate({rows: selectedTreatments, conclusion: conclusionText}, "Clinical_Evidence");
            return updatedTreatments;
        });*/

        // alternative approach without triggering a re-rendering
        commentsRef.current[index] = value;

        // You can optionally perform other operations using the commentsRef
        const selectedTreatments = selectedRows.map(i => ({
            ... treatment_data[i],
            comment: commentsRef.current[i] || ''
        }));

        handleSectionUpdate({rows: selectedTreatments, conclusion: conclusionText}, "Clinical_Evidence");

    };


    const handleConclusionChange = (event)  => {

        // handle Conclusion


        setConclusionText(event.target.value);
        handleSectionUpdate({rows: treatment_data, conclusion: event.target.value}, "Clinical_Evidence");

    }


    const renderTreatmentBoxes = (treatment_data) => {

        return treatment_data
            .filter((p, index)=>{ return selectedRows.includes(index)})
            .map((s,i) => {

            const selected= selectedRows.includes(i);
            const fields = { "field1": s.therapy, "field2": s.gene};


            return (
                <SelectedRowInfoBox
                    key={s.gene + "_"+ i}
                    fields = {fields}
                    comments={s.comment} // different that the variant where it is commentS.
                    selected={selected}
                    dataType={"evidence"}
                    handleOnTextChange={(event) => handleTextChange(i, event.target.value)}
                />
            );
        });

     /*   return treatment_data.map((s,i) => {

            const selected= selectedRows.includes(i);
            const fields = { "field1": s.therapy, "field2": s.gene};


            return (
                <SelectedRowInfoBox
                    key={s.gene + "_"+ i}
                    fields = {fields}
                    comments={s.comment} // different that the variant where it is commentS.
                    selected={selected}
                    dataType={"evidence"}
                    handleOnTextChange={(event) => handleTextChange(i, event.target.value)}
                />
            );
        });*/

    }

    return <div>
        <Typography variant={"body1"} color="textSecondary"><MappedText text="These are the evidences that were tagged in the" /> <b><MappedText text="Clinical Evidence and Patient Management"/></b><MappedText text=" tab of this Interpretation Request."/> </Typography>
            <Typography variant={"body1"} color="textSecondary"><MappedText text="Check all the evidences that you want to display in the final report."/> </Typography>
            <br/>
            <Typography variant={"body1"} color="textSecondary"><MappedText text="The"/> <b><MappedText text=" Clinical Significance"/></b> <MappedText text="field shows the comments that were added when tagging an evidence."/></Typography>
            <Typography variant={"body1"} color="textSecondary"><MappedText text="The original tag comment "/><b><MappedText text="will not"/></b><MappedText text=" be modified here. Your modifications will only be visible in the exported report."/></Typography>
            <Box pt={1}>
                <ClinicalEvidenceTable data={treatment_data}
                                       setSelectedRows={handleSelectedRows}/>
            </Box>
            <Box p={1}>
                <Typography variant={"body1"}><b><MappedText text="Conclusion"/></b></Typography>
                <TextField
                    id="outlined-multiline-flexible"
                    onChange = {handleConclusionChange}
                    multiline
                    maxRows={4}
                    style={{ width: '100%' }}
                />
            </Box>
            <Box p={1}>
                <Box>
                    <Typography variant={"h6"}><b><MappedText text="Clinical Significance"/></b></Typography>
                </Box>
                <Box pt={1}>
                <Typography variant={"body1"} color="textSecondary"><MappedText text="These are the interpretation comments that were written in each evidence tag."/></Typography>
                <Typography variant={"body1"} color="textSecondary"><MappedText text="The original tag comment "/><b><MappedText text="will not"/></b><MappedText text=" be modified here. Your modifications will only be visible in the exported report."/></Typography>
                </Box>
                {renderTreatmentBoxes(treatment_data)}

            </Box>
         </div>


}



export default ClinicalEvidenceSection;