

/**
 * This function takes in a data object and returns two objects: a summary and a sample aggregation.
 *
 * The summary contains information about each gene in the data, including the gene name, the number of variants,
 * the number of samples that express the gene, a list of those samples, and the number of genes expressed by each sample.
 *
 * The sample aggregation contains information about each sample, including the sample name and a list of genes expressed
 * in that sample, along with the number of variants for each gene.
 *
 * @param {Object} data - The data object containing information about genes and variants.
 * @return {Object} An object containing a summary and a sample aggregation.
 */

function map_sa_output(data){


    let n_exp = [];
    let mp_exp = new Map();
    let summary_storage = new Map();

    let summary = data.snv.aggregations.agg_effs.effs_gene.agg_effs.buckets.map( (b) => {
        let n_var = 0;
        let n_sam = [];
        if (!summary_storage.has(b.key)) {
            summary_storage.set(b.key, []);
        }
        b["recer"].chrom_agg.buckets.map( (x1) => {
            x1["pos_agg"].buckets.map( (x2) => {
                n_var += 1;
                x2["agg_germline"].samples_fil_germ.agg_germline.buckets.map( (x3) => {
                    if (!n_sam.includes(x3.key)) {
                        n_sam.push(x3.key);
                    }
                    if (!n_exp.includes(x3.key)) {
                        n_exp.push(x3.key);
                    }

                    if (mp_exp.has(x3.key)) {
                        if(mp_exp.get(x3.key).has(b.key)) {
                            mp_exp.set(x3.key, mp_exp.get(x3.key).set(b.key, mp_exp.get(x3.key).get(b.key) + 1));
                        } else {
                            mp_exp.set(x3.key, mp_exp.get(x3.key).set(b.key, 1));
                        }
                    } else {
                        mp_exp.set(x3.key, new Map().set(b.key, 1));
                    }


                })
            })
        });

        console.log(b);
        return( {
            'gene': b.key,
            'n_var': n_var,
            'n_exp': n_sam.length,
            "sample_list": n_sam,
            "genes_by_exp": sample_agg
        } )
    });
    // unpack geneMap

    console.log(summary);
    let sample_agg = [];

    mp_exp.forEach(function (exp, sample){

        let sample_id = sample;
        let gene_obj = [];

        console.log(exp);
        exp.forEach(function(value, key) {
            gene_obj.push({gene: key, value: value})

        });

        sample_agg.push({ experiment: sample_id, genes: gene_obj})

    });


    return {summary: summary, sample_agg: sample_agg}

}


export default map_sa_output;