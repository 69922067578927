import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { get_filter_set, get_study_analyses, get_study_info } from "../../../services/api/manage_study";
import * as SampleActions from "../create-study/analysis-page/reducers/sample_actions";
import * as StudyActions from "../../dashboard/reducers/study_actions";

import StudyOverview from "./StudyOverview";
import { parseSamples } from "./functions";

import {
    Typography,
    Box,
    CircularProgress,
    Container,
    Paper,
    Grid
} from "@mui/material";

import ErrorIcon from '@mui/icons-material/Error';

function StudyRedirect(props){

    const [study, setStudy] = useState(undefined);

    const [samplesLoaded, setSamplesLoaded] = useState(false);
    const [loading, setLoading] =  useState(false);
    const [request, setRequest] = useState(false);
    const [studyError, setStudyError] = useState(false);
    const [studySavingError, setSavingError] = useState(false);

    let samples_array = [];

    const loadFromHandler = () => {


        // get the data;
        let {handle} = props.match.params;
        if(handle!== undefined){

            let body = {study_id: parseInt(handle)};
            let url_prefix = config.apis_configuration.api_nextgpap_server;

            if(props.token!== undefined){
                get_study_info(url_prefix, props.token, body)
                    .then(function(resp){
                        console.log("get study");
                        if(resp.ok){
                            return resp.json();
                        }
                        throw new Error('Something went wrong.');
                    })
                    .then(function(json){
                        setRequest(true);
                        // study settings

                        let study = json.data;

                        setLoading(true);
                        get_study_analyses(url_prefix, props.token, body)
                            .then(r => r.json())
                            .then(function(json){

                                if(json.message === "Success"){

                                    if(json.analyses.length>0){
                                        json.analyses.forEach(function(s_analysis){

                                            // this is the case in which the study only has one analysis saved but with no queries saved correctly (empty analysis)
                                            if(json.analyses.length === 1 && s_analysis.queries.length === 0){
                                                setLoading(false);
                                                setSavingError(true);
                                            }
                                            else{
                                                s_analysis.queries.forEach(function(Q){
                                                    get_filter_set(url_prefix, props.token,{"filter_set_id": Q.filter_set_id})
                                                        .then(r=> r.json())
                                                        .then(function(d){
                                                            Q.filter_set = d.data;
                                                        })
                                                });
                                                let study_analyses =  json.analyses;

                                                //study = s;
                                                let analyses = study_analyses;
                                                if(analyses!== undefined){
                                                    samples_array = parseSamples(analyses);

                                                    samples_array.forEach(function(sample){
                                                        if(sample.sample_id!== "ALL_SAMPLES"){
                                                            props.sampleActions.setSamples(
                                                                {
                                                                    sample_id: sample.sample_id,
                                                                    pheno_id: sample.pheno_id,
                                                                    sample_type: sample.sample_type,
                                                                    affectedStatus: sample.affectedStatus
                                                            }
                                                                );
                                                        }

                                                    });
                                                }
                                                const studyLoad = { study: study, analyses:study_analyses, samples: samples_array};
                                                props.studyActions.setStudySettings({study_id_db: study.id, study_status: true});
                                                setStudy(studyLoad);
                                                setLoading(false);
                                            }
                                        });
                                    }
                                    else{
                                        setLoading(false);
                                        setSavingError(true);
                                    }
                                }
                                else{
                                    setLoading(false);
                                }


                            })
                    })
                    .catch(function(error) {
                            setLoading(false);// catch
                            setStudyError(true);
                        });
                    }

        }

    };


    useEffect(function(){

        if(study === undefined && !samplesLoaded){
            loadFromHandler();
        }

    },[props.match.params.handle]);


    /* Rendering */
   /* It performs conditional rendering based on the values of two variables: study and request.
        If study is undefined and request is falsy, then null is returned and nothing is rendered.
        If study is not undefined and request is truthy, then the StudyOverview component is rendered with props study,
        analyses, samples_array, samplesLoaded, and alreadyLoaded.
        If study is undefined and request is truthy, then the study_not_visibile_box component is rendered.
        This component displays a message indicating that the study is not visible due to its private status and includes instructions
        on how to gain access to the study.

    */

    if(loading){
        return <Container><CircularProgress/></Container>
    }
    else {

        let study_not_visible_box = <Box p={4}>

            <Grid container direction={"column"}
                  alignItems={"center"}>
                <Grid item lg={3}>
                    <Paper elevation={2}>
                        <Box p={2}>
                            <Typography variant={"h4"} align={"center"}> Study not found </Typography>
                            <Typography variant={"body1"} align={"center"}>The study that you are trying to load is
                                not visible or does not exist. Please, ask your collaborator to save the study with
                                either a public or group permission, or to verify the link. Don't hesitate to contact us at {window.config.help_email} if
                                you have any question. You can also consult our <a href={"/gpap_docs"}
                                                                                   target={"_blank"}>user guide.</a>
                            </Typography>

                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box>;
        let study_not_saved_correctly = <Box p={4}>

            <Grid container direction={"column"}
                  alignItems={"center"}>

                <Grid item lg={3}>
                    <Paper elevation={2}>
                        <Box p={2}>

                        </Box>
                        <Box p={2}>
                            <Typography variant={"h4"} align={"center"}>  <ErrorIcon style={{color: "#F44336"}} fontSize="large"/> </Typography>
                            <Typography variant={"h4"} align={"center"}> Study not saved correctly </Typography>
                            <Typography variant={"body1"} align={"center"}>The study that you are trying to load did not save correctly.
                                Don't hesitate to contact us at {window.config.help_email} if
                                you have any question. You can also consult our <a href={"/gpap_docs"}
                                                                                   target={"_blank"}>user guide.</a>
                            </Typography>

                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box>;

        if(study!== undefined && request){
            return <StudyOverview
                study={study.study}
                analyses={study.analyses}
                samples_array = { study.samples }
                samplesLoaded = { true }
                alreadyLoaded={false}
            />
        }
        else if(studySavingError){
            return study_not_saved_correctly;
        }
        else if(study === undefined && studyError){
            return study_not_visible_box;
        }
        else if(study === undefined && !request){
            return null;
        }
        else{
            return null;
        }
    }



}


function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {
            token: state.authorization.token,

        }
    }
}

// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    sampleActions: bindActionCreators(SampleActions, dispatch),
    studyActions: bindActionCreators(StudyActions, dispatch),
});





export default connect(mapStateToProps, mapDispatchToProps)(StudyRedirect);