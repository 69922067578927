import React from "react";
import {DATA_HEADER} from "./data_header_treatments";
import CustomTable from "../../../components/tables/general-table/components/tables/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { selectedVariant } from "../../dashboard/components/genomics-dashboard/components/results/components/variant-table/actions";
import iconCGI from "../../../../public/asset/img/icon_CGI.jpg";  
import iconCivic from "../../../../public/asset/img/icon_Civic.jpg";
import {
    setSelectedTableRows
} from "../../dashboard/components/genomics-dashboard/components/results/components/variant-table/actions";
    

function Treatments_Table(props){

    const dispatch = useDispatch();

    const {allDataApis } = useSelector((state) => state.generaltable);
    const selectedDir = allDataApis?.dir?.find(d => d.dir_id === allDataApis?.dirs?.dir_id);
    const tumor_experiment_id = selectedDir.tumor_experiment_id ? selectedDir.tumor_experiment_id : ""


    const handleSelectedTreatment= (selectedRows) => {

        let data = {
            data_row : [selectedRows],
            data_type: "Treatment_Somatic",

        };

        dispatch(setSelectedTableRows(data))

    }



        return <CustomTable
            url={props.url}
            method= "POST"
            dataSend = {{
                "fields": [],
                "filtered": [
                {
                    "id": "Experiment_id",
                    "value": [
                        tumor_experiment_id
                    ]
                }
                ],
                "page": 1,
                "pageSize": 1000,
                "sorted": []
            }}
            token={props.token}
            participant={props.participant}
            locationData="items"
            classRow= "Treatments"
            dataType = "Treatment_Somatic"
            setSelected = {(treatment) => handleSelectedTreatment(treatment)}
            customFeatures= {[
                { SwitchFS: false },  
                { toggle: false },
                { expanding: false },
                { showHeader: false },
                { columnsButton: true },

            ]}
        
            dataHeader={DATA_HEADER}
            chipFull =   {[
                {
                    referenceColumn: "Level of evidence",
                    cells: [
                        {
                            data: "A",
                            color: "#ebebeb",
                            textColor: "black",
                        },
                        {
                            data: "B",
                            color: "#ebebeb",
                            textColor: "black",
                        },
                        {
                            data: "C",
                            color: "#ebebeb",
                            textColor: "black",
                        },
                        {
                            data: "D",
                            color: "#ebebeb",
                            textColor: "black",
                        },
                
                    ],
                },
            ]}
            chipSmall =   {[
                {
                    referenceColumn: "Therapy status",
                    cells: [
                    {
                        data: "sensitive",
                        color: "#31A354",
                        textColor: "black",
                    },
                    {
                        data: "No benefit",
                        color: "grey",
                        textColor: "black",
                    },
                    {
                        data: "resistant",
                        color: "#DD4434",
                        textColor: "black",
                    },
                            
                ],
                },
                
                ]}

        />


    }

    export default Treatments_Table