
const textDic =
    {   "hg19": "GRCh37 (hg19)",
        "hg38": "GRCh38 (hg38)"
    }


export const getGenomicBuildValue = () => {
    const text = textDic[window.config.genome_ref] ?? textDic["hg38"];
    return text;
};