import React from "react";
import {CircularProgress} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";


const LoadingDataComponent = () => {

    return <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="50vh" // Adjust height based on your layout
        textAlign="center"
    >
        <CircularProgress />
        <Typography variant="h6" marginTop={2}>
            Loading CNV Data
        </Typography>
    </Box>


}

export default LoadingDataComponent;

