import React from "react";
import {TableCell, TableRow} from "@mui/material";




function GeneralTableHeaders({headerGroups}){



    return  headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()} style={{color: "white"}}>
                {headerGroup.headers.map(column => (
                    <TableCell {...column.getHeaderProps()} style={{color: "white"}} >

                        {/* Render the columns filter UI */}
                        <div>{column.canFilter ? column.render('Filter') : null}</div>

                    </TableCell>
                ))}
            </TableRow>

        ))


}


export default GeneralTableHeaders;