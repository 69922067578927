// Material UI Imports
import { styled } from '@mui/system'; // Replaces `withStyles`
import MuiDialogTitle from '@mui/material/DialogTitle'; // Updated to @mui/material
import Typography from '@mui/material/Typography'; // Updated to @mui/material
import IconButton from '@mui/material/IconButton'; // Updated to @mui/material
import CloseIcon from '@mui/icons-material/Close'; // Updated to @mui/icons-material

// React Imports
import React from 'react';
import {withStyles} from "@mui/styles";


export const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

export const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});