
export const completeColConfig = [
    {
        referenceTable: "ACMG Classific.",
        nameColumn: "AnnotSV reported pathogenic",
        tooltip: "Phenotype of the pathogenic genomic regions completely overlapped with the SV. Source is displayed in the tooltip: dbVarNR (dbVar), ClinVar (CLN), ClinGen (TS3).",
        dataSearch: "SV_type",
        position: "right",
        rowSelect: [
            {
                replaceWith: "P_gain_phen",
                dataSearch: "P_gain_phen",
                value: "DUP",
            },
            {
                replaceWith: "P_loss_phen",
                dataSearch: "P_loss_phen",
                value: "DEL",
            },
            {
                replaceWith: "P_ins_phen",
                dataSearch: "P_ins_phen",
                value: "INS",
            },
        ],
    },
    {
        referenceTable: "Internal Freq.",
        nameColumn: "AnnotSV population AFMax",
        tooltip: "Maximum allele frequency of the reported benign genomic regions completely overlapped with the SV. Source is displayed in the tooltip: gnomAD, ClinVar (CLN), ClinGen (TS40), DGV (dgv, nsv or esv), DDD, 1000 genomes (1000g), Ira M. Hall’s lab (IMH), Children’s Mercy Research Institute (CMRI). This filter applies values equal to or less than the specified threshold.",
        dataSearch: "SV_type",
        position: "left",
        rowSelect: [
            {
                replaceWith: "B_gain_AFmax",
                dataSearch: "B_gain_AFmax",
                value: "DUP",
            },
            {
                replaceWith: "B_loss_AFmax",
                dataSearch: "B_loss_AFmax",
                value: "DEL",
            },
            {
                replaceWith: "B_ins_AFmax",
                dataSearch: "B_ins_AFmax",
                value: "INS",
            },
        ],
    },
];