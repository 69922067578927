


import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom"
import * as StudyActions from "../../../../dashboard/reducers/study_actions";

import * as SampleActions from "../../analysis-page/reducers/sample_actions";
import {bindActionCreators} from "redux";
import * as Patient_Actions from "../../analysis-page/components/clinical-info/patient_actions";
import * as InheritanceActions from "../../analysis-page/reducers/inheritance_actions";
import * as QueryActions from "../../../../../actions";
import * as GeneActions
    from "../../../../dashboard/components/genomics-dashboard/components/side-panels/filter-panel/components/filter-sections/actions";
import {date_generator} from "../../../../func/helpers";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";



function CohortStudy(props){

    const [cohortID, setCohortID] = useState(undefined);
    const [redirect, setRedirect] = useState(true);



    const loadFromHandler = () => {


        // get the data;
        let {handle} = props.match.params;
        if(handle!== undefined){

            // create study cohort as in StudyCreator;
            let { studyActions }  = props;

            setCohortID(parseInt(handle));

            props.sampleActions.resetSamples();
            props.patientActions.reset_patients();
            props.queryActions.reset_selected_cohort();
            studyActions.resetStudySettings();


            // create the study;
            let localID = Math.floor(Math.random()*200);
            let date = date_generator();
            let sampleStudy = {
                "localId": localID,
                "studyName": "New GPAP Study",
                "description": "No description",
                "creationDate": date,
                "samples": [],
                "analyses": [],
                "tagged_variants": [],
                "info":[]
            };
            studyActions.create_study(sampleStudy);
            let settings = {analysisType: "cohort", id: localID};
            studyActions.setStudySettings(settings);

            // also reset geneList;
            //resetQuery()
            setRedirect(true);
            //setView("cohort");

            props.inheritanceActions.setInheritance("custom");
        }


    };


    useEffect(function(){

        if(cohortID === undefined){
            loadFromHandler();
        }

    });


    return (cohortID!== undefined && redirect)
        ? <Redirect to={{
            pathname: "/new_study",
            state:{
                handle: cohortID,
                key:"cohort"
            }}}/>
        : <Box p={2}>
            <Typography variant={"h6"}>Error </Typography>
        </Box>



}


function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {
            token: state.authorization.token,
            studySettings: state.studySettings
        }
    }
}

// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    sampleActions: bindActionCreators(SampleActions, dispatch),
    studyActions: bindActionCreators(StudyActions, dispatch),
    patientActions: bindActionCreators(Patient_Actions, dispatch),
    inheritanceActions: bindActionCreators(InheritanceActions, dispatch),
    queryActions: bindActionCreators(QueryActions, dispatch),
    geneActions: bindActionCreators(GeneActions,dispatch)
});





export default connect(mapStateToProps, mapDispatchToProps)(CohortStudy);
