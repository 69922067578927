import axios from "axios";
import {
  dataMapper
} from "../../customVariantTable/utils/dataMapper";




export const axiosApi = (options) => {
  const {
    url,
    method = "GET",
    dataSend,
    token,
    isCheck,
    split,
    dataHeader,
    nestedInfo,
    nestedInfoB,
    setMainData,
    locationData,
    setData,
    setResApi,
  } = options;

  let headers = {};
  if (token) {
    headers.Authorization = token;
  }

  const handleResponse = (res) => {

    setResApi(res.data);

    let data = ("items" in res.data) ? res.data.items : res.data;


    const dataToMap = locationData ? res.data[locationData] : data;

    setMainData(dataToMap);

    const response = dataMapper(dataToMap, isCheck, split, dataHeader, nestedInfoB, nestedInfo);

    setData(response);
  };

  const handleError = (error) => {

    console.error("Error", error);
    setData([]);
    setMainData([]);
  };

  if (method.toLowerCase() === "get") {
    axios.get(url, { headers }).then(handleResponse).catch(handleError);
  } else if (method.toLowerCase() === "post") {
    axios.post(url, dataSend, { headers }).then(handleResponse).catch(handleError);
  }
};

