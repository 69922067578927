import React, { useState, useEffect } from 'react';
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { TextField, Typography, Select, MenuItem, InputLabel } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import DataField from "./Datafield";
import GPAP_Dialog from '../../../gpap-lib/components/GPAP_Dialog';
import MappedText from '../../study-page/create-study/analysis-page/components/clinical-info/mapText';
import Chip from '@mui/material/Chip';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import CircleIcon from "@mui/icons-material/Circle";
import {analysisTypes, uniquePriorityDic, uniqueStatusDic} from "./configDirOverview";
import {convertDate} from "../reports/create-report/sections/utils";
import CaButton from '../../../gpap-lib/components/shared-components/CaButton';
import { setTable } from '../../../reducers/slices/tables/table.slice';

    export default function DIR_Overview() {
        const location = useLocation();
        const hideFooter = !location.pathname.includes("/new_clinical_report");
        const token = useSelector(state => state.authorization.token);
        const dispatch = useDispatch();
        const {allDataApis, allState  } = useSelector((state) => state.generaltable);

        const [selectedStatus, setSelectedStatus] = useState('');
        const [selectedPriority, setSelectedPriority] = useState('');

        const [sampleQualityObservations, setSampleQualityObservations] = useState('');
        const [sequencingQualityObservations, setSequencingQualityObservations] = useState('');
        const selectedDir = allDataApis?.dirs;

        const [openDialog, setDialog] = useState({
            status: false,
            title: "",
            text: "",
            open: false,
            type: "success"
        });

        const [formValues, setFormValues] = useState({
            filter_set: "",
            predefined_list: "",
        });

        const [filterSetList, setFilterSetList] = useState([]);
        const [predefinedGeneList, setPredefindedGeneList] = useState([]);

        useEffect(() => {
            if (selectedDir) {
                setSelectedStatus(selectedDir.status || '');
                setSelectedPriority(selectedDir.priority || '');
                setSampleQualityObservations(selectedDir.sample_quality_observations || '');
                setSequencingQualityObservations(selectedDir.sequencing_quality_observations || '');
                setFormValues({
                    filter_set: selectedDir.filter_set ? selectedDir.filter_set.toString() : "",
                    predefined_list: selectedDir.predefined_list ? selectedDir.predefined_list.toString() : ""
                });
            }
        }, [selectedDir]);

        const handleChange = (event) => {
            setSelectedStatus(event.target.value);
        };

        const handleChangePriority = (event) => {
            setSelectedPriority(event.target.value);
        }

        const uniqueStatus = uniqueStatusDic;
        const uniquePriority = uniquePriorityDic;

        // Query possible values for filterSetList and Predefined Gene List
        useEffect(() => {
            // Get Filter Set Options
            const url = window.config.apis_configuration.api_nextgpap_server;
            fetch(url+ '/filter_set', {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', "Authorization": token}
            })
            .then((response) => response.json())
            .then((data) => {
                setFilterSetList(data.data.filter(s => s.filter_set_name!=="").map(fs => ({name: fs.filter_set_name, id: fs.id.toString()})));
            })
            .catch((error) => {
                setDialog({
                    status: false, open: true, type: "error",
                    title: "Could not get filter set list",
                    text: "Broken connection to back-end API. Please try again later."
                });
            });
        }, []);

        // After Having the filter set loaded then require the gene list
        useEffect(() => {
            const url = window.config.apis_configuration.api_nextgpap_server;
            fetch( url + '/get_all_lists', {
                method: 'GET', headers: { 'Content-Type': 'application/json', "Authorization": token }
            })
            .then((response) => response.json())
            .then((data) => {
                setPredefindedGeneList(data.data.map(gl => ({name: gl.title, id: gl.id.toString()})));
            })
            .catch((error) => {
                setDialog({
                    status: false, open: true, type: "error",
                    title: "Could not get predefined gene lists",
                    text: "Broken connection to back-end API. Please try again later.",
                });
            });
        }, []);
        

        const handleInputChange = (event) => {
            const { name, value } = event.target;
            if (name === "sampleQualityObservations") {
                setSampleQualityObservations(value);
            } else if (name === "sequencingQualityObservations") {
                setSequencingQualityObservations(value);
            }
            else {
                setFormValues({ ...formValues, [name]: value });
            }
        };

        const updateDir = () => {
            const payload = { ...formValues };
            const dirId = allDataApis?.dirs?.dir_id;
            const url = window.config.apis_configuration.api_nextgpap_server;
            fetch( url+ '/dir/' + dirId, {
                method: 'PUT', headers: { 'Content-Type': 'application/json', "Authorization": token },
                body: JSON.stringify(payload),
            })
            .then((response) => response.json())
            .then((data) => {
                setDialog({ status: true, open: true, type: "success",
                    title: "Request " +  dirId +  " updated successfully",
                    text: "Request " + dirId + " has been properly updated."
                });
            })
            .catch((error) => {
                console.error('Error:', error);
                setDialog({ status: false, open: true, type: "error",
                    title: "Error updating request",
                    text: "Backend connection broken:",
                });
            });
        };
        const handleSave = async () => {
            let payload = {
                status: selectedStatus,
                priority: selectedPriority,
                sample_quality_observations: sampleQualityObservations,
                sequencing_quality_observations: sequencingQualityObservations,
                filter_set: formValues.filter_set ? parseInt(formValues.filter_set, 10) : null,
                predefined_list: formValues.predefined_list ? parseInt(formValues.predefined_list, 10) : null,
                deadline: selectedDir?.deadline
            };
        
            const analysisType = selectedDir?.analysis;
            if (analysisType === "germline") {
                payload = {
                    ...payload,
                    control_experiment_id: selectedDir?.control_experiment_id || "",
                    tumor_experiment_id: "",
                };
            } else if (analysisType === "somatic_tumor_only" || analysisType === "somatic_fusion_analysis") {
                payload = {
                    ...payload,
                    control_experiment_id: "",
                    tumor_experiment_id: selectedDir?.tumor_experiment_id || "",
                };
            } else if (analysisType === "somatic_tumor_normal") {
                payload = {
                    ...payload,
                    control_experiment_id: selectedDir?.control_experiment_id || "",
                    tumor_experiment_id: selectedDir?.tumor_experiment_id || "",
                };
            }
        
            const dirId = selectedDir?.dir_id;
            const url = window.config.apis_configuration.api_nextgpap_server;
            try {
                const response = await axios.put(`${url}/dir/${dirId}`, payload, {
                    headers: { 
                        'Content-Type': 'application/json', 
                        'Authorization': token 
                    }
                });
        
                if (response.status === 200 || response.status === 204) {
           
                    const updatedDirResponse = await axios.get(`${url}/dir/${dirId}`, {
                        headers: { 
                            'Content-Type': 'application/json', 
                            'Authorization': token 
                        }
                    });
                    const updatedDir = updatedDirResponse.data.item;
        
                    dispatch(setTable({
                        option: 'allDataApis',
                        value: {
                            ...allDataApis,
                            dirs: updatedDir
                        }
                    }));
        
                    setDialog({
                        status: true,
                        open: true,
                        type: "success",
                        title: "Request updated successfully",
                        text: "The request has been updated successfully."
                    });
                    
                    setSampleQualityObservations(updatedDir.sample_quality_observations);
                    setSequencingQualityObservations(updatedDir.sequencing_quality_observations);
        
                } else {
                    throw new Error('Failed to update request');
                }
        
            } catch (error) {
                console.error('Error:', error);
                setDialog({
                    status: false,
                    open: true,
                    type: "error",
                    title: "Error updating request",
                    text: "An error occurred while updating the request."
                });
            }
        };
        
        
        const analysisType = useSelector(state => state.generaltable.allDataApis?.dirs?.analysis);


        const analysisTypeLabel = analysisTypes.find(item => item.value === analysisType)?.label || analysisType || "NA";


        const handleCloseDialog = () => { setDialog({ status: false, title: "", text: "", open: false,type: "success"});}

        const renderDialog = () => {
            return <GPAP_Dialog
                open={openDialog.open}
                handleClose={handleCloseDialog}
                title={openDialog.title}
                text={openDialog.text}
                onExitText={false}
                onConfirmText={"Ok"}
                onExitAction={false}
                onConfirmAction={handleCloseDialog}
                type={openDialog.type}
            />
        }

        return (    
            <Box sx={{ flexGrow: 1, p: 2 }}>
            {renderDialog()}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                <Box>
                        <Typography variant={"h5"} style={{  fontWeight: "700", marginBottom: '20px' }}>
                            <MappedText text={"General Info"}/></Typography>
                        <Grid container spacing={2} sx={{ marginBottom: 4}}>
                        <Grid item xs={12} sm={4} md={3}>
                        <DataField title="Request ID" value={allDataApis?.dirs?.dir_id || "NA"} />
                        </Grid>
                        {selectedDir && (
                        <Grid item xs={12} sm={4} md={3}>
                                <DataField title="Description" value={selectedDir?.comments || "NA"} />
                        </Grid>
                        )}
                            <Grid item xs={12} sm={6} md={6}>
                                <Typography sx={{ color: '#626262', fontWeight: '400', fontSize: '14px' }}>
                                    <MappedText text={"Analysis Type"} />
                                </Typography>
                                <Chip label={ <MappedText text={analysisTypeLabel}/>}
                                      style={{ backgroundColor: "#3E69A9", color:"#FFFFFF"}}/>
                            </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                        <InputLabel>Status</InputLabel>
                        <Select fullWidth sx={{ fontSize: '16px' }} value={selectedStatus} onChange={handleChange}>
                            {uniqueStatus.map((status, index) => (
                                <MenuItem value={status.value} key={index}>
                                    <CircleIcon fontSize="small" style={{ float: "left", color: status.color, padding: "2px" }} />
                                    <div style={{float:"left", display: "block"}}>
                                        <MappedText text={status.label}/></div>
                                </MenuItem>
                            ))}
                        </Select>
                     

                        </Grid>
                        
                        <Grid item xs={12} sm={6} md={3}>
                        {/*<DataField title="Priority" value={allDataApis?.dirs?.priority || "NA"} />*/}
                            <InputLabel>Priority</InputLabel>
                            <Select fullWidth sx={{ fontSize: '16px' }} value={selectedPriority} onChange={handleChangePriority}>
                                {uniquePriority.map((status, index) => (
                                    <MenuItem value={status.value} key={index}>
                                        <CircleIcon fontSize="small" style={{ float: "left", color: status.color, padding: "2px" }} />
                                        <div style={{float:"left", display: "block"}}>
                                            <MappedText text={status.label}/></div>
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid> 
                        <Grid item  xs={12} sm={6} md={3}>
                                <DataField title="Deadline" value={convertDate(allDataApis?.dirs?.deadline) || "NA"} />
                              
                            </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Assignee(s)" value={allDataApis?.dirs?.assignee || "NA"} />
                        </Grid>
                        </Grid>

                        <Typography variant={"h5"} style={{  fontWeight: "700", marginBottom: '20px' }}>
                            <MappedText text={"Administrative Info"}/></Typography>
                        <Grid container spacing={2} sx={{ marginBottom: 4}}>
                        <Grid item xs={12} sm={6} md={3}   >
                            <DataField title="Creator" value={allDataApis?.dirs?.creator || "NA"}  />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Creation date" value={convertDate(allDataApis?.dirs?.creation_date) || "NA"} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Hospital name" value={allDataApis?.dirs?.hospital_name || "NA"} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Clinical referrer" value={allDataApis?.dirs?.clinical_referrer || "NA"}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <DataField title="Clinical referrer contact info" value={allDataApis?.dirs?.clinical_referrer_contact || "NA"} />
                        </Grid>
                        </Grid>

                        <Typography variant="h5" sx={{ marginBottom: 2, fontWeight: 'bold' }}><MappedText text={"Filters"}/></Typography>
                        <Grid container spacing={2} sx={{ marginBottom: 4}}>
                            <Grid item xs={12} sm={6} md={4}   >
                                <InputLabel><MappedText text={"Filter Set"}/></InputLabel>
                                <Select
                                    fullWidth name="filter_set"
                                    value={formValues.filter_set}
                                    onChange={handleInputChange}>
                                    {filterSetList.map(fs =>  <MenuItem value={fs.id}>{fs.name}</MenuItem>)}
                                </Select>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <InputLabel><MappedText text={"Gene List"}/></InputLabel>
                                <Select
                                    fullWidth name="predefined_list"
                                    value={formValues.predefined_list}
                                    onChange={handleInputChange}>
                                    {predefinedGeneList.map(gl =>  <MenuItem value={gl.id}>{gl.name}</MenuItem>)}
                                </Select>
                            </Grid>
                        </Grid>
                        {/* <Grid container spacing={2} sx={{ marginBottom: 4}}>
                            <Grid item xs={12} sm={6} md={2}>
                                <CaButton 
                                onClick={updateDir} 
                                variant="contained"
                                size="large"
                                text="Update Filters"
                                />      
                            </Grid>
                        </Grid> */}


                        <Typography variant={"h5"} style={{  fontWeight: "700", marginBottom: '20px' }}>
                        <MappedText text={"Patient Info"}/>
                            </Typography>

                        <Grid container spacing={2} sx={{ marginBottom: 4}}>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Patient ID" value={allDataApis?.participants?.[0].report_id || "NA"} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <DataField title="Sex"
                                       value={allDataApis?.participants?.[0].sex || "NA"}  />
                        </Grid>
                        </Grid>

                        <Typography variant={"h5"} style={{  fontWeight: "700", marginBottom: '20px' }}>
                        <MappedText text={"Clinical Info"}/>
                            </Typography>
                        <Grid container spacing={2} sx={{ marginBottom: 4}}>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Clinical referral" value={allDataApis?.participants?.[0]?.referral || "NA"}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                        <DataField 
                        title="Observed Symptoms" 
                        value={
                            allDataApis?.participants?.[0]?.features
                            ?.filter(feature => feature.observed === true) 
                            .map(feature => feature.name) 
                            .join(", ") || "NA"
                        }
                    /> 
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Diagnostic date" value={convertDate(allDataApis?.participants?.[0]?.diagnosis?.[0]?.diagnosis_date) || "NA"}  />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>  
                            <DataField title="Clinical diagnosis" value={allDataApis?.participants?.[0]?.diagnosis?.[0]?.ordo.name || "NA"} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Case number pathology" value={allDataApis?.participants?.[0]?.case_number_pathology || "NA"} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Treatment history and response" value={`${allDataApis?.participants?.[0]?.treatmenthistory}, ${allDataApis?.participants?.[0]?.treatment_response}`} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Cancer type" value={allDataApis?.participants?.[0]?.cancer_type || "NA"} />
                        </Grid>
                        </Grid>
                        {/*Tumor Sample */}
                        {analysisType !== "germline" && (
                          <>
                            <Typography variant={"h5"} style={{ fontWeight: "700", marginBottom: '20px' }}>
                                <MappedText text={"Tumor Sample and Experiment Info"} />
                            </Typography>
                            <Typography 
                                variant={"h6"} 
                                style={{ fontWeight: "700", marginBottom: '20px' }}
                            >
                                <MappedText text={"Sample Info"} />
                            </Typography>

                            <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <DataField title="Sample ID" value={allDataApis?.samples?.sampleID || "NA"} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <DataField title="Sample fixation" value={allDataApis?.samples?.sample_type || "NA"} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <DataField title="Sample site of origin" value={allDataApis?.samples?.sample_tissue_of_origin || "NA"} />
                                </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <DataField title="Sample site of origin status" value={allDataApis?.samples?.sample_tissue_of_origin_status || "NA"} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <DataField title="Fixative" value={allDataApis?.samples?.fixative || "NA"} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <DataField title="Purity" value={allDataApis?.samples?.percentage_of_tumoral_cells || "NA"} />
                                </Grid>
                            </Grid>

      
                            <Typography  variant={"h6"} style={{  fontWeight: "700", marginBottom: '20px' }}>
                            <MappedText text={"Experiment Info"}/>
                               </Typography>

                        <Grid container spacing={2} sx={{ marginBottom: 4}}>

                        {selectedDir && (
                    <Grid item xs={12} sm={6} md={3}>
                        <DataField title="Experiment ID" value={selectedDir?.tumor_experiment_id || "NA"} />
                    </Grid>
                )}
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Library strategy" value={allDataApis?.experiments?.[0].library_strategy || "NA"}  />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Kit" value={allDataApis?.experiments?.[0].kit || "NA"}  />
                        </Grid>
                        </Grid> 
                        </>
                        )}
                        {/* Control Sample     */}
                      {(analysisType === "germline" || analysisType === "somatic_tumor_normal") && (
                         <>
                        <Typography variant={"h5"} style={{  fontWeight: "700", marginBottom: '20px' }}>
                        <MappedText text={"Sample and Experiment Info"}/>
                           </Typography>
                           <Typography variant={"h6"} style={{  fontWeight: "700", marginBottom: '20px' }}>
                        <MappedText text={"Sample Info"}/>
                            </Typography>

                            <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <DataField title="Sample ID" value={allDataApis?.samples?.sampleID || "NA"} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <DataField title="Sample fixation" value={allDataApis?.samples?.sample_type || "NA"} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <DataField title="Sample site of origin" value={allDataApis?.samples?.sample_tissue_of_origin || "NA"} />
                                </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <DataField title="Sample site of origin status" value={allDataApis?.samples?.sample_tissue_of_origin_status || "NA"} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <DataField title="Fixative" value={allDataApis?.samples?.fixative || "NA"} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <DataField title="Purity" value={allDataApis?.samples?.percentage_of_tumoral_cells || "NA"} />
                                </Grid>
                            </Grid>


                                        
                            <Typography variant={"h6"} style={{  fontWeight: "700", marginBottom: '20px' }}>
                            <MappedText text={"Experiment Info"}/>
                                </Typography>

                        <Grid container spacing={2} sx={{ marginBottom: 4}}>
                        {selectedDir && (
                    <Grid item xs={12} sm={6} md={3}>
                        <DataField title="Experiment ID" value={selectedDir?.control_experiment_id || "NA"} />
                    </Grid>
                )}
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Library strategy" value={allDataApis?.experiments?.[0].library_strategy || "NA"}  />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Kit" value={allDataApis?.experiments?.[0].kit || "NA"}  />
                        </Grid>
                        </Grid> 
                        </>
                        )}
                      
                       <Typography variant={"h5"} style={{  fontWeight: "700", marginBottom: '20px' }}>
                        <MappedText text={"Relevant Observations"}/>
                            </Typography>

                        <Grid container spacing={2} sx={{ marginBottom: 4}}>
                        <Grid item xs={12} sm={6} md={6}>
                        <TextField 
                            fullWidth 
                            id="standard-multiline-static"
                            label=<MappedText text={"Sample quality observations"}/>
                            multiline
                            rows={4}
                            name="sampleQualityObservations"
                            value={sampleQualityObservations}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField 
                            fullWidth 
                            id="standard-multiline-static"
                            label=<MappedText text={"Sequencing quality observations"}/>
                            multiline
                            rows={4}
                            name="sequencingQualityObservations"
                            value={sequencingQualityObservations}
                            onChange={handleInputChange} 
                        />
                        </Grid>
                        </Grid>
                        {hideFooter && (
                        <Paper
                            elevation={3}
                            sx={{
                                position: "fixed",
                                bottom: 0,
                                left: 0,
                                right: 0,
                                height: "50px",
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                zIndex: 1000
                            }}
                        >
                            <CaButton
                                onClick={handleSave} 
                                variant="contained"
                                text="Save"
                                size="large"
                                customStyles={{ width: "90px", marginRight:"20px"}}
                            />
                        </Paper>
                    )}

                       
                </Box>
            </Grid>
            </Grid>
            
        </Box>
        
    );
    }