import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

const CustomTab = styled(Tab)(({ theme }) => ({
    maxWidth: "400px",
    textTransform: "none",  // Prevent uppercase text
}));

const CustomQueryTabs = styled(Tabs)(({ theme }) => ({
    root: {
        borderBottom: '1px solid #e8e8e8',
        minHeight: "25px",
        textTransform: "none",  // Prevent uppercase text
    },
    '& .MuiTabs-indicator': {
        top: "0px",
        backgroundColor: '#31a354',
        height: "4px",
    },
}));

export { CustomQueryTabs, CustomTab };