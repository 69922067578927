import React from "react";
import { Document, Packer, Paragraph, Header, AlignmentType, TextRun, Footer, PageNumber, NumberFormat, SectionType  } from "docx";
import {createBoldUnderlinedParagraph, createSpacer, createCaption, createKeyValueParagraph } from "../write-sections/write_utils/documentHelpers.js";
import WriteClinicalEvidenceSection from "../write-sections/clinical-evidence/WriteClinicalEvidenceSection.js";
import WriteReferences from "../write-sections/references/WriteReferences.js";
import AnnexesSomatic from "../annexes-sections/somatic/AnnexesSomatic.js";
import WriteGeneticFindingsSection from "../write-sections/genetic-findings/WriteGeneticFindingsSection.js";
import WriteInterpretationData from "../write-sections/interpretation/WriteInterpretationSection.js";
import WriteExomeCoverageSection from "../annexes-sections/somatic/TableExomeCoverage.js";
import WriteGenomeCoverageSection from "../annexes-sections/germline/TableGenomeCoverage.js";
import AnnexesGermline from "../annexes-sections/germline/AnnexesGermline.js";
import WriteAssociationsSection from "../annexes-sections/germline/TableAssociations.js";
import WriteVariantMetricsSection from "../annexes-sections/germline/TableVariantMetrics.js";
import WriteGenesInterestSection from "../annexes-sections/germline/TableGenesInterest.js";
import WriteDisclaimer from "../write-sections/disclaimer/WriteDisclaimer.js";
import WriteTechnicalLimitations from "../../reports/templates/write-sections/WriteTechnicalLimitations";
import WriteGeneralInfo from "../write-sections/general-info/WriteGeneralInfo.js";
import { renderToStringAlt } from "../../create-report/shared-components/renderToString.js";
import WritePharmacogenomicsSection from "../annexes-sections/germline/TablePharmacogenomicsResults.js";
import writePharmaInterpretation from "../annexes-sections/germline/WritePharmaInterpretation";
import { fontDoc, NEGATIVE_TARGETED_GENES } from "../config/template_config.js";

export const createDoc = async (data, analysisType, experimentType, allDataApis, translationFunc,  dirSelected, studyPerformed) => {

  const header = new Header({
    children: [
      new Paragraph({
        children: [
          new TextRun({
            text: renderToStringAlt("MOLECULAR GENETIC REPORT"),
            color: "#808080",
            size: 11 * 2,
          }),
        ],
        alignment: AlignmentType.LEFT,
      }),
      new Paragraph({
        children: [
          new TextRun({
            text: data.reportName,
            color: "#808080",
            size: 12 * 2,
          }),
        ],
        alignment: AlignmentType.LEFT,
      }),
    ],
  });

  const footer = new Footer({
    children: [
      new Paragraph({
        children: [
          new TextRun({
            children: [renderToStringAlt("Page "), PageNumber.CURRENT],
          }),
          new TextRun({
            children: [renderToStringAlt(" of "), PageNumber.TOTAL_PAGES],
          }),
        ],
        alignment: AlignmentType.LEFT,
      }),
    ],
  });



  if (analysisType !== 'germline') {
    const doc = new Document({
      styles: {
        default: {
          document: {
            run: {
              font: fontDoc,
            },
          },
        },
      },
      sections: [
        {
          properties: {
            page: {
              margin: {
                top: 1117,
                right: 787,
                bottom: 1117,
                left: 787,
              }
            },
            pageNumbers: {
              start: 1,
              formatType: NumberFormat.DECIMAL,
            },
          },
          headers: {
            default: header,
          },

          children: [
            createSpacer(50, 50),
            new Paragraph({
              children: [new TextRun({
                  text: renderToStringAlt("MOLECULAR GENETIC REPORT"),
                  bold: true,
                  color: "#3e699e",
                  size: 20 * 2,
                })],
              alignment: AlignmentType.CENTER,
            }),
            ...WriteGeneralInfo(data, allDataApis,  dirSelected, studyPerformed),
            createSpacer(50, 50),
            ...WriteGeneticFindingsSection(data["geneticFindingsData"], analysisType),
            ...WriteInterpretationData(data["interpretationData"], analysisType),
          ],
          footers: {
            default: footer,
          },
        },
        {
          properties: {
            type: SectionType.CONTINUOUS,
            page: {
              margin: {
                top: 1117,
                right: 787,
                bottom: 1117,
                left: 787,
              }
            },
          },
          headers: {
            default: header,
          },
          footers: {
            default: footer,
          },
          children: [
            createSpacer(50, 50),
            ...WriteClinicalEvidenceSection(data["clinicalEvidenceData"]),
            createSpacer(50, 50),
            ...WriteDisclaimer("Disclaimer", data["disclaimer"], analysisType, translationFunc),
            createSpacer(50, 50),
            ...WriteTechnicalLimitations("Technical Limitations", data["technicalLimitations"], experimentType, translationFunc),
            createSpacer(50, 50),
            ...WriteReferences("References", data["references"]),
          ]
        },
        {
          properties: {
            page: {
              margin: {
                top: 1117,
                right: 787,
                bottom: 1117,
                left: 787,
              }
            },
          },
          headers: {
            default: header,
          },
          footers: {
            default: footer,
          },
          children: AnnexesSomatic(allDataApis, data["annexesFields"]),
        },

        {
          properties: {
            page: {
              margin: {
                top: 1117,
                right: 787,
                bottom: 1117,
                left: 787,
              }
            },
          },
          headers: {
            default: header,
          },
          footers: {
            default: footer,
          },
          children: [
            createBoldUnderlinedParagraph("ANNEX V: QUALITY CONTROL METRICS"),  
            ...WriteExomeCoverageSection(data["exomeCoverageData"]),
            createSpacer(50, 50),
            createKeyValueParagraph(
              "List of diagnostically targeted genes", 
              data["annexesFields"].list_of_diag_genes || renderToStringAlt(NEGATIVE_TARGETED_GENES.text)
            ),
  
            createSpacer(50, 50),
            ...WriteGenesInterestSection(data["coverageGenesData"]), 
          ],
         
        },
      ],


    });

    return await Packer.toBlob(doc);
  } else if (analysisType === 'germline') {
    const doc = new Document({
      styles: {
        default: {
          document: {
            run: {
              font: fontDoc,
            },
          },
        },
      },
      sections: [
        {
          properties: {
            page: {
              margin: {
                top: 1117,
                right: 787,
                bottom: 1117,
                left: 787,
              }
            },
          },
          headers: {
            default: header,
          },
          footers: {
            default: footer,
          },
          children: [

            createSpacer(50, 50),
            new Paragraph({
              children: [new TextRun({
                text: renderToStringAlt("MOLECULAR GENETIC REPORT"),
                bold: true,
                color: "#3e699e",
                size: 20 * 2,
              })],
              alignment: AlignmentType.CENTER,
            }),
            ...WriteGeneralInfo(data, allDataApis, dirSelected),
            createSpacer(50, 50),
            ...WriteGeneticFindingsSection(data["geneticFindingsData"], analysisType),
            ...WriteInterpretationData(data["interpretationData"], analysisType),
          
              ],
        },
        {
          properties: {
            page: {
              margin: {
                top: 1117,
                right: 787,
                bottom: 1117,
                left: 787,
              }
            },
          },
          headers: {
            default: header,
          },
          children: [
           /* ...WriteClinicalEvidenceSection(data["clinicalEvidenceData"]),
            createSpacer(50, 50),*/
            ...WriteDisclaimer("Disclaimer", data["disclaimer"], analysisType, translationFunc),
            createSpacer(50, 50),
            ...WriteTechnicalLimitations("Technical Limitations", data["technicalLimitations"], experimentType, translationFunc),
            createSpacer(50, 50),
            ...WriteReferences("References", data["references"]),
          ],
        },
        {
          properties: {
            page: {
              margin: {
                top: 1117,
                right: 787,
                bottom: 1117,
                left: 787,
              }
            },
          },
          headers: {
            default: header,
          },
          children: AnnexesGermline(allDataApis, data["annexesFields"]),
        },
        {
          properties: {
            page: {
              margin: {
                top: 1117,
                right: 787,
                bottom: 1117,
                left: 787,
              }
            },
            pageNumbers: {
              start: 1,
              formatType: NumberFormat.CARDINAL_TEXT,
            },

          },
          headers: {
            default: header,
          },
          children: [
            createBoldUnderlinedParagraph("ANNEX III: QUALITY CONTROL METRICS"),
            ...WriteGenomeCoverageSection(data["genomeCoverageData"]),
            createSpacer(50, 50),
            createKeyValueParagraph(
              "List of diagnostically targeted genes", 
              data["annexesFields"].list_of_diag_genes || renderToStringAlt(NEGATIVE_TARGETED_GENES.text)
            ),
            createSpacer(50, 50),
            ...WriteGenesInterestSection(data["coverageGenesData"]),
            createSpacer(50, 50),
            createBoldUnderlinedParagraph("ANNEX IV: VARIANT METRICS AND GENOTYPE-PHENOTYPE ASSOCIATIONS"),
            ...WriteVariantMetricsSection(data["variantMetricsData"]),
            createCaption("GT: genotype; GQ: genotype quality; DP: depth of coverage (total reads); REF count: reads supporting reference allele; ALT count: reads supporting alternative allele; AAF: alternative allele frequency"),
            createSpacer(50, 50),
            ...WriteAssociationsSection(data["genotypePhenotypeData"]),
            createSpacer(50, 50),
            createBoldUnderlinedParagraph("ANNEX V: PHARMACOGENOMICS"),
            createSpacer(50, 50),
            ...WritePharmacogenomicsSection(data["pharmacogenomicsData"]),
            ...writePharmaInterpretation(data["pharmacogenomicsData"], analysisType),
          ],
        },
      ],
    });

    return await Packer.toBlob(doc);
  }


};