import { AlignmentType, HeadingLevel, Paragraph, TextRun} from "docx";
import { drawBasicTable } from "../../write-sections/write_utils/drawTables_examples.js";
import { renderToStringAlt } from "../../../create-report/shared-components/renderToString.js";

const headers = [
    "Mean coverage",
    "Median coverage",
    "C10",
    "C15",
    "C20"
];

const dataKeys = [
    "mean_coverage",
    "median_coverage",
    "c10",
    "c15",
    "c20"
];

function WriteGenomeCoverageSection(sectionData) {
    let rows = sectionData && Array.isArray(sectionData) ? sectionData : [];
    
    return [

        new Paragraph({
            children: [
                new TextRun({
                    text: renderToStringAlt("Genome coverage metrics"),
                    bold: true,
                    size: 22 
                })
            ],
        }),

        new Paragraph({
            text: "",
        }),
        drawBasicTable(rows, headers, dataKeys, renderToStringAlt("No data available")),
      
        new Paragraph({
            text: "",
        }),
    ];
}

export default WriteGenomeCoverageSection;
