

import {inheritances} from "../../../../../../../../config-files/config_inheritances";
import {createConfiguration} from "./GenotypeForm";


export function create_gt_config(samples, inheritance_key) {

    //

    const inheritance_modes = inheritances;
    let configs = [];
    inheritance_modes.forEach(function(IM){
        configs = configs.concat(  IM.config)
    });


    let selected = configs.find(IM => IM.id === inheritance_key);
    //let configuration = selected.config;

    let genotypes = selected.genotypes;

    return samples.filter(s => s!== undefined).map(function (sample_case) {
        let family_case = genotypes.find(gt => gt.case === sample_case.case);
        let setting = [];
        if (family_case.case === "index") {
            setting = family_case.setting[0];

            let gt_sample_configuration = createConfiguration(setting, inheritance_key, selected);

            console.log("Inheritance-Key", inheritance_key);

            return {
                id: sample_case.id,
                report_id: sample_case.report_id,
                configuration: gt_sample_configuration,
                bool: true
            }
        } else {
            // set setting;
            setting = family_case.setting.find(member => member.affected === sample_case.affected);
            let gt_sample_configuration = createConfiguration(setting, inheritance_key, selected);
            return {
                id: sample_case.id,
                report_id: sample_case.report_id,
                configuration: gt_sample_configuration,
                bool: sample_case.affected
            }
        }


    });


}