


import React, { Component } from "react";



// Redux Imports
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../../../actions";

// MUI Imports
import Box from '@mui/material/Box'; // MUI v5 Box
import Typography from '@mui/material/Typography'; // MUI v5 Typography
import Link from '@mui/material/Link'; // MUI v5 Link
import IconButton from '@mui/material/IconButton'; // MUI v5 IconButton
import { CircularProgress } from '@mui/material'; // MUI v5 CircularProgress

// MUI Icons Imports
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined'; // MUI v5 icon

// Custom Imports
import { getGenesfromOMIM, getOMIM_API } from "../../../../../../../../../../../../services/api/get";
import { mapGeneOperatorToProps } from "../../functions/mapStateToProps";
import GPAP_Tooltip from "../../../../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import GeneralAutoComplete from "../GeneralAutoComplete";
import { handleResponse } from "../async-selection/handlers";
import { AlertInfo } from "../../../../../../../../../../../study-page/create-study/analysis-page/components/samples-section/search-by-id/SelectionInfo";
import MappedText from "../../../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";



class OMIM_Search extends Component{

    constructor(props){
        super(props);
        this.state = {
            data:undefined,
            geneList: [],
            refCode: undefined,
            refCodeList: [],
            results: [],
            default: [],
            reset: false
        };

        this.passData = this.passData.bind(this);
        this.add_genes_to_store = this.add_genes_to_store.bind(this);
        this.clearList = this.clearList.bind(this);
        this.getResults = this.getResults.bind(this);
        this.renderAutoComplete = this.renderAutoComplete.bind(this);
    }


    componentDidMount() {


        if(this.props.applied_lists!== undefined){
            let myOMIMList = this.props.applied_lists.find(s => s.type === "OMIM");

            if(this.state.refCodeList.length === 0
                && this.state.geneList.length === 0
                && myOMIMList !== undefined){
                // load list
                this.setState({
                    refCodeList: myOMIMList.omim_codes,
                    geneList: myOMIMList.genes
                })
                this.add_genes_to_store(myOMIMList.omim_codes, myOMIMList.genes)
            }
        }

    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        // load applied lists if necessary;


    }


    add_genes_to_store(omim_codes, genes)
    {
        let { actions, operator } = this.props;
        if(operator=== "intersection"){
            actions.intersect_genelist(genes, "disease");
        }
        else{
            actions.add_genes_to_specific_genelist(genes,"disease");
        }

        // add - update OMIM gene list
        this.props.actions.reset_list_types("OMIM");
        let list ={};
        list["type"] = "OMIM";
        list["label"] = "OMIM-Associated";
        list["id"] = "OMIM";
        list["n_genes"] = genes.length;
        list["genes"] = genes;
        list["omim_codes"] = omim_codes;

        this.props.actions.add_applied_list(list);
    }

    clearList(){

        let { actions } = this.props;
        let genes = this.state.geneList;

        actions.remove_genes_from_list(genes, "disease");

       this.setState(
            {
                geneList: [],
                refCode: undefined,
                refCodeList: [],
                reset: true
            }
            )
    }




    passData(obj, reason){

        //let { actions } = this.props;
        let self = this;

        if(reason === "clear"){
            self.clearList()
        }
        else{
            obj.forEach(function(omimItem){

                getOMIM_API(omimItem.refCode)
                    .then(function(response){
                        if (!response.ok) {
                            self.setState({requestFailed:true});
                            throw Error("Something went wrong");
                        }
                        else{

                            return response.json()
                        }

                    })
                    .then(function(json)
                    {

                        //let map_path= undefined;
                        //let geneList= [];

                        let cp_refCodeList = self.state.refCodeList;
                        let cp_geneList = self.state.geneList;

                        //let gene_term = [];

                        //If there are genes
                        if (json.genes && json.genes.length > 0){

                        for(var i=0;i<json.genes.length;i++) {
                           if (cp_geneList.indexOf(json.genes[i].name) === -1)
                           cp_geneList.push(json.genes[i].name)
                        }


                        if (cp_refCodeList.findIndex(omimTerm => omimTerm.refCode === omimItem.refCode) === -1)
                            cp_refCodeList.push({refCode: omimItem.refCode, value: omimItem.value})

                            self.add_genes_to_store(cp_refCodeList, cp_geneList);
                            self.setState({geneList: cp_geneList, refCodeList: cp_refCodeList})
                        }
                        else{
                            // this is actually no genes found;
                            if (cp_refCodeList.findIndex(omimTerm => omimTerm.refCode === omimItem.refCode) === -1)
                                cp_refCodeList.push({refCode: omimItem.refCode, value: omimItem.value})

                            return  self.setState({refCodeList: cp_refCodeList});}
                    })
                    .catch(function(error){
                        self.setState({requestFailed:true})});


            });


            // pass selected up for later...

            //this.props.storeSelected(obj);
        }




    };

    getResults(val){

        const self = this;
        
        if (val && val != "") {
        getGenesfromOMIM(val)
            .then(r => r.json())
            .then(function(json){
                let resp = handleResponse("OMIM", json);
                self.setState({results: resp})
            })
        }

    }


    renderAutoComplete = () => {

        if(this.state.reset){

            setTimeout( () => this.setState({reset:false}), 500);
            return <CircularProgress/>
        }
        else{
            return   <GeneralAutoComplete
                results = {this.state.results}
                onInputChange = {this.getResults}
                title= {<MappedText text={"search for OMIM terms"} />}
                passSelected = {this.passData}
                default_tags = {this.state.default}
            />
        }
    }



    render(){

        return (
            <React.Fragment>
                <Box p={2}>
                    {
                        (this.state.geneList.length!==0)
                            ? <GPAP_Tooltip title= {<MappedText text={"Delete OMIM associated genes"}/>}>
                                <IconButton onClick={this.clearList}>
                                    <DeleteSweepOutlinedIcon/>
                                </IconButton>
                            </GPAP_Tooltip>
                            : null
                    }
                <GPAP_Tooltip title={<MappedText text={"Enter multiple OMIM ids or names of disorders"}/>}>
                        <div>
                            <Typography variant={"body1"}><MappedText text={"Search for OMIM terms"}/></Typography>
                        </div>
                    </GPAP_Tooltip>
                    {this.renderAutoComplete()}
                    {(this.state.requestFailed)
                        ? <AlertInfo severity={"error"}
                                     message={<MappedText text={"Request Error"}/>}
                                     title={<MappedText text={"Error"}/>}/>
                        : null
                    }
                    {this.state.refCodeList.length > 0 &&
                    <Typography > Genes associated to
                        {this.state.refCodeList.map((omim_id, index) => (
                            <Link target={"_blank"} rel="noreferrer" href={"https://www.omim.org/entry/" + omim_id.refCode }> {omim_id.refCode} </Link>
                        ))}
                        : {(this.state.geneList.length!== 0 ) ? this.state.geneList.toString() : "None"}
                    </Typography>
                    }

                </Box>
            </React.Fragment>

        )
    }

}





const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});


export default connect(mapGeneOperatorToProps,mapDispatchToProps)(OMIM_Search);
