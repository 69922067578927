import React from 'react';
import { Grid, Typography, Button, Box, Divider, IconButton} from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import {NavLink} from "react-router-dom";
import MappedText from '../../instand/reports/create-report/shared-components/mapText';

function App() {



    const url_img = `${window.config?.static_files}/img/nextgpap/cafolder/home`;
    
    const CNAG_LOGO = `${url_img}/logo_CNAG.svg`;
    const HomeBG_Clean = `${url_img}/HomeBG-Clean.png`;
    const AnalysisSVG = `${url_img}/analysis.svg`;
    const ReportingSVG = `${url_img}/reporting.svg`;
    const FeedbackSVG = `${url_img}/feedback.svg`;
    const LogoClinics = `${url_img}/Clinics-HalfWhite.svg`;



  const solutionsRef = React.useRef(null);
  const handleScrollToSolutions = () => {
    if (solutionsRef.current) {
      const yOffset = -10; 
      const y = solutionsRef.current.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };
  
  return (
    <div>
    {/* Hero */}    
      <Box sx={{
          textAlign: 'center',
          backgroundImage: `url(${HomeBG_Clean})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 0,
          padding: '80px 0', 
        }}
        >
        <img
          src={LogoClinics} 
          alt="GPAP Logo"
          style={{ height: '77px', marginTop:'80px' }}
        />
        <Typography
          variant={"h4"}
          sx={{
            mt: 5,
            color: '#fff',
            fontWeight: '300',
            fontSize: '34px',
            px: 10
          }}
        >
        <MappedText text={"Shaping Genomic Futures. GPAP + is your one-stop-shop platform for cutting-edge genomics services. The CNAG is committed to providing top-tier genomic analysis, interpretation and reporting, to meet your needs."}/>
        </Typography>
        <IconButton
            sx={{
              backgroundColor: '#fff',
              color: '#3E69A9',
              height: '64px',
              width: '64px',
              marginTop: '76px',
              '&:hover': {
                backgroundColor: '#f0f0f0',
              }
            }}
            onClick={handleScrollToSolutions}
          >
            <KeyboardArrowDown sx={{ fontSize: '32px' }} />
          </IconButton>
      </Box>

      {/* Solutions Section */}
      <Box
        ref={solutionsRef}
        sx={{
            padding: '0 100px', 
        }}
        >
      <Typography
        variant={"h3"}
        sx={{
          marginTop:'55px',
          marginBottom:'55px',
          textAlign: 'center',
          fontWeight: '400',
          fontSize: '48px',
          color: '#3A3A3A',
        }}
      >
        <MappedText text={"GPAP+ in a nutshell"}/>
        </Typography>
        <Grid container spacing={4} alignItems="flex-start">
               
                <Grid item xs={12} md={6}>
                    <Box
                    sx={{
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        height: '100%',
                      
                    }}
                    >
                    <img
                        src={AnalysisSVG}
                        alt="Genome Analysis"
                        style={{ height: '164px' }}  
                    />
                    <Typography
                        variant={"h4"}
                        sx={{ mt: 5, color: '#3E69A9', fontSize: '34px' }}
                    >
                        <MappedText text={"Genome Analysis"}/>
                    </Typography>
                    <Typography
                        variant={"h5"}
                        sx={{
                        mt: 5,
                        mb: 5,
                        fontSize: '24px',
                        fontWeight: '300',
                        color: '#000000',
                        }}
                    >
                        <MappedText text={"State-of-the art standardised bioinformatics analysis workflows for the analysis of NGS data within a user-friendly interface."}/>
                    </Typography>
                    <Box sx={{ mt: 'auto' }}> 
                

                    </Box>
                    </Box>
                </Grid>

               
                <Grid item xs={12} md={6}>
                    <Box
                    sx={{
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        height: '100%',
                    }}
                    >
                    <img
                        src={ReportingSVG}
                        alt="Interpretation and Reporting"
                        style={{ height: '164px' }}  
                    />
                    <Typography
                        variant={"h4"}
                        sx={{ mt: 5, color: '#3E69A9', fontSize: '34px' }}
                    >
                        <MappedText text={"Interpretation and Reporting"}/>
                    </Typography>
                    <Typography
                        variant={"h5"}
                        sx={{
                        mt: 5,
                        mb: 5,
                        fontSize: '24px',
                        fontWeight: '300',
                        color: '#000000',
                        }}
                    >
                       <MappedText text={"User friendly interpretation of integrated genomic, phenotypic and clinical information to facilitate molecular diagnosis and reporting."}/>
                    </Typography>
                    <Box sx={{ mt: 'auto' }}> 
                       
                    </Box>
                    </Box>
                    
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box
                    sx={{
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        height: '100%',
                      
                    }}
                    >
                    <img
                        src={ReportingSVG}
                        alt="Quality"
                        style={{ height: '164px' }}  
                    />
                    <Typography
                        variant={"h4"}
                        sx={{ mt: 5, color: '#3E69A9', fontSize: '34px' }}
                    >
                        <MappedText text={"Quality"}/>
                    </Typography>
                    <Typography
                        variant={"h5"}
                        sx={{
                        mt: 5,
                        mb: 5,
                        fontSize: '24px',
                        fontWeight: '300',
                        color: '#000000',
                        }}
                    >
                       <MappedText text={"Pipeline and process validated with reference materials. GPAP+ includes QC checkpoints and informative reports, metrics and figures."}/>
                    </Typography>

                    <Box sx={{ mt: 'auto' }}> 
              

                    </Box>
                    </Box>
                </Grid>

               
                <Grid item xs={12} md={6}>
                    <Box
                    sx={{
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        height: '100%',
                    }}
                    >
                    <img
                        src={AnalysisSVG}
                        alt="Flexibility and Scalability"
                        style={{ height: '164px' }}  
                    />
                    <Typography
                        variant={"h4"}
                        sx={{ mt: 5, color: '#3E69A9', fontSize: '34px' }}
                    >
                        <MappedText text={"Flexibility and Scalability"}/>
                    </Typography>
                    <Typography
                        variant={"h5"}
                        sx={{
                        mt: 5,
                        mb: 5,
                        fontSize: '24px',
                        fontWeight: '300',
                        color: '#000000',
                        }}
                    >
                       <MappedText text={"GPAP+ is built upon open standards and scalable technologies. It can be deployed On Cloud, As a Service, and On Premises, allowing customisation and integration within a broader workflow."}/>
                    </Typography>
                  
                    </Box>
                    
                </Grid>
                
                </Grid>
                
                </Box>
       {/* Feedback*/}
       <Box
        sx={{
            backgroundColor: '#f5f5f5',
            mt: 12,
            padding: 10,
        }}
        >
        <Grid container spacing={4} alignItems="center">
          
            <Grid item xs={12} md={8} lg={8}>
            <Typography
                variant="h4"
                sx={{
                mb: 3,
                color: '#000000',
                fontWeight: '400',
                fontSize: '34px',
                }}
            >
                <MappedText text={"Know us a bit more!"} />
            </Typography>
            <Typography
                variant="h5"
                sx={{
                mb: 2,
                fontWeight: '400',
                fontSize: '20px',
                lineHeight: 1.6, 
                }}
            >
                <MappedText text={"GPAP+ is a development from the RD-Connect GPAP and the Cancer GPAP, both developed by the CNAG. The platform facilitates advanced genome analysis for the diagnosis of rare diseases, cancer, and other conditions. With over 15 years of experience in clinical research projects, the CNAG has processed and uploaded to GPAP clinical research instances data from 35,000+ individuals submitted by 35+ hospitals and research centers across Europe."} />
            </Typography>
            <Button
                href="mailto:info@cnag.eu"
                variant="contained"
                sx={{
                padding: '8px 22px',
                color: '#fff',
                backgroundColor: '#66A7E0',
                fontSize: '13px',
                textTransform: 'none',
                '&:hover': {
                    backgroundColor: '#4F8BCE',
                },
                }}
            >
                <MappedText text={"Contact us"} />
            </Button>
            </Grid>


            <Grid item xs={12} md={4} lg={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img
                src={FeedbackSVG}
                alt="Feedback Icon"
                style={{
                maxWidth: '100%',
                maxHeight: '150px',
                height: 'auto',
                objectFit: 'contain',
                }}
            />
            </Grid>
        </Grid>
        </Box>


        <Box sx={{ padding: '0 80px', mt: { xs: 4, md: 5 }, mb: { xs: 3, md: 4 } }}>
        <Grid container spacing={4} alignItems="flex-start">

            <Grid item xs={12} md={4} sx={{ textAlign: 'left' }}>
            <Typography
                variant="h4"
                sx={{
                mb: 3,
                color: '#3A3A3A',
                fontWeight: '400',
                fontSize: '34px',
                }}
            >
                <MappedText text={"Developed by"} />
            </Typography>
            <img
                src={CNAG_LOGO}
                alt="CNAG Logo"
                style={{
                maxWidth: '250px',
                marginBottom: '20px',
                }}
            />

            </Grid>


            <Grid item xs={12} md={8} sx={{ textAlign: 'left' }}>
            <Typography
                variant="h4"
                sx={{
                mb: 3,
                color: '#3A3A3A',
                fontWeight: '400',
                fontSize: '34px',
                }}
            >
                <MappedText text={"With support from"} />
            </Typography>
            <Typography
                variant="body1"
                sx={{
                fontSize: '16px',
                color: '#3A3A3A',
                lineHeight: 1.6, 
                }}
            >
                <MappedText text={"The RD-Connect Genome-Phenome Analysis Platform has received funding from EU projects RD-Connect, Solve-RD and EJP-RD (grant numbers FP7 305444, H2020 779257, H2020 825575), Instituto de Salud Carlos III (Grant numbers PT13/0001/0044, PT17/0009/0019; Instituto Nacional de Bioinformática, INB), ELIXIR-EXCELERATE (Grant number EU H2020 #676559) and several ELIXIR Implementation Studies. The Cancer GPAP has received funding from contracts signed through the EU INSTAND-NGS4P project (grant number 874719)."} />
            </Typography>
            </Grid>
        </Grid>
        </Box>

      {/* Footer */}
      <Box sx={{ textAlign: 'left', mt: 3, color: '#3A3A3A', padding: '40px 80px',mb:5  }}>
        <Typography variant="body2"><MappedText text={"© CNAG 2024. All rights reserved."}/></Typography>
      </Box>
      </div>
  );
}


export default App;