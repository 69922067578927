import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import GPAP_Manager from "./predefined-gene-lists/PredefinedGeneLists_Manager";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import FilterSets_Manager from "./filter-sets/FilterSets_Manager";
import QueryGenerator from "../dashboard/components/genomics-dashboard/components/query-generator/QueryGenerator";
import MappedText from '../study-page/create-study/analysis-page/components/clinical-info/mapText';
import PreviewExternalLinks from "./preview-external-links/PreviewExternalLinks";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function SettingsHome() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Container>
            <Grid container direction={"row"} >
                <Grid item lg={12}>
                    <Box p={2}>
                        <Typography variant={"h4"}><MappedText text="Settings"/></Typography>
                    </Box>
                </Grid>
                <Grid item lg={10} >
                    <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 600 }}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            sx={{ borderRight: 1, borderColor: 'divider', background: "#f0f0f0", minWidth: 200 }}>
                            <Tab label=<MappedText text="Filter Sets"/> {...a11yProps(0)} />
                            <Tab label=<MappedText text="Predefined Gene Lists"/> {...a11yProps(1)} />
                           {/* <Tab label=<MappedText text="Analysis Settings"/> {...a11yProps(2)} />
                            <Tab label=<MappedText text="Analysis Parameters"/> {...a11yProps(3)} />*/}
{/*
                            <Tab label=<MappedText text="Standard Pipelines"/> {...a11yProps(4)} />
*/}
                            <Tab label=<MappedText text="Preview External Resources"/> {...a11yProps(2)} />
                        </Tabs>
                        <TabPanel value={value} index={0} style={{width: "100%"}}>
                            <QueryGenerator/>
                            <FilterSets_Manager somatic={window.config.instand}/>
                        </TabPanel>
                        <TabPanel value={value} index={1} style={{width: "100%"}}>
                            <div >
                                <GPAP_Manager/>
                            </div>
                        </TabPanel>
                        {/*<TabPanel value={value} index={2}>
                            Analysis Settings
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            Analysis Parameters
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            Standard Pipelines
                        </TabPanel>*/}
                        <TabPanel value={value} index={2}>
                            <div >
                                <PreviewExternalLinks/>
                            </div>
                        </TabPanel>

                    </Box>
                </Grid>
            </Grid>
        </Container>

    )
}