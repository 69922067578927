import React, { useState, useEffect } from "react";
import { Typography, Box} from "@mui/material";
import { evidences_config } from "./evidences_config";
import MappedText from "../../../../../../instand/reports/create-report/shared-components/mapText";
import { connect } from "react-redux";
import { Container } from "@mui/system";
import ScoreCategoryChips from "./ScoreCategoryChips";
import OncoEvidenceCard from "./OncoEvidenceCard";



function OncoEvidencesPanel({ selected_table_rows }) {

    const [evidenceInfo, setEvidenceInfo] = useState([]);
    const [ocScore, setOcScore] = useState(null);

    const [expandedIndexes, setExpandedIndexes] = useState([]);


    const handleExpandClick = (index) => {
        setExpandedIndexes(prevExpanded =>
            prevExpanded.includes(index)
                ? prevExpanded.filter(i => i !== index)
                : [...prevExpanded, index]
        );
    };

    const getSelectedTableRow = () => {
        if (selected_table_rows && selected_table_rows.length > 0) {
            const item = selected_table_rows[1].data_row[0];
            return item;

        }
        return null;

    };


    const selectedRow = getSelectedTableRow();


    useEffect(() => {
        if (selectedRow) {
            if (selectedRow.Evidence) {
                const evidencesArray = selectedRow.Evidence.split(',').map(evidence => evidence.trim());

                const foundEvidences = evidencesArray.map(evidenceKey => {
                    const evidenceConfig = evidences_config.find(config => config.key === evidenceKey);
                    return evidenceConfig ? { ...evidenceConfig.values[0], key: evidenceKey } : { key: evidenceKey };
                });

                setEvidenceInfo(foundEvidences);
            } else {
                setEvidenceInfo([]);
            }

            setOcScore(selectedRow.OCScore);
        }
    }, [selectedRow]);


    return (
        <Container>
            <Typography variant="h6">
                <MappedText text={"OncoEvidences"} />
            </Typography>
            {evidenceInfo.length > 0 ? (
                <Box>
                    <ScoreCategoryChips ocScore={ocScore} />
                    {evidenceInfo.map((info, index) => (
                        <OncoEvidenceCard
                            key={index}
                            info={info}
                            index={index}
                            expanded={expandedIndexes.includes(index)}
                            onExpandClick={handleExpandClick}
                        />
                    ))}
                </Box>
            ) : (
                <Typography variant="body1"><MappedText text={"There are 0 onco evidences"} /></Typography>
            )}
        </Container>
    );
}

function mapStateToProps (state) {
    if(state.selected_table_rows!==undefined)
    {
        return {
            selected_table_rows: state.selected_table_rows
        }
    }
    else{
        return {selected_table_rows: []}
    }
}

export default connect(mapStateToProps)(OncoEvidencesPanel);