import React, { useState } from 'react';
import { styled } from '@mui/material/styles'; // Replace withStyles with styled

// MUI 5 imports
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from "@mui/material/Typography";

// MUI Icons
import LabelIcon from '@mui/icons-material/Label';

// Custom Components
import GPAP_Tooltip from "../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import { GPAP_Button_LightOrange } from "../../../../../../../../../gpap-lib/components/Button_Collection";
import MappedText from '../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText';

// Redux
import { bindActionCreators } from "redux";
import * as StudyActions from "../../../../../../../reducers/study_actions";
import { connect } from "react-redux";

// Import menu items function
import getMenuItems from "./menu-items";
import {withStyles} from "@mui/styles";

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        color:" #3182bd",
        '&:hover': {
           /* backgroundColor: "#deebf7",*/
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: "#3182bd",
            },
        },
    },
}))(MenuItem);

function Label_Menu(props) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);

    };

    const handleClose = () => {
            setAnchorEl(null);
    };

    const select_label_options = (label_type) => {

        // say to labbeled the selected Variants;
        props.annotate_variants("LABEL", label_type );
        handleClose();
    };


   



    let text = "Label variant in this study only. Label will be private and different than the tag."
    if(props.disable){
        text = "The variant is tagged and cannot be labelled"
    }

    const showMenuOptions = () => {


        if(props.disable){
            return <MenuItem>
                <Typography variant={"subtitle2"}><MappedText text={"The variant is tagged and can't be labelled"}/> </Typography>
            </MenuItem>
        }
        else{


          const menuItems = getMenuItems(select_label_options);

          return [
                <MenuItem key="instruction">
                    <Typography variant="subtitle2">
                        <MappedText text="Once selected, click again to unapply" />
                    </Typography>
                </MenuItem>,
                ...menuItems.map(item => (
                    <StyledMenuItem key={item.key} onClick={() => {
                        item.onClick();
                        handleClose(); 
                    }}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText button primary={item.label} />
                    </StyledMenuItem>
                ))
            ];

        }


    }


    return (
        <React.Fragment>
            <GPAP_Tooltip title={<MappedText text={text}/>}>
                <GPAP_Button_LightOrange
                    style={{
                        height: '28px',
                        boxShadow: 'none', 
                        textTransform: 'none',
                        color: 'white',
                    }}
                    size={"small"}
                    color="warning"
                    onClick={handleClick}
                    aria-haspopup="true"
                    variant="contained" startIcon={<LabelIcon />}>
                    <MappedText text={"Label"}/>
                </GPAP_Button_LightOrange>
            </GPAP_Tooltip>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                {showMenuOptions()}
            </StyledMenu>
        </React.Fragment>
    );
}


function mapStateToProps (state) {

    if(state.authorization!==undefined)
    {
        return {
            studySettings: state.studySettings,
            studyBucket: state.studyBucket
        }

    }

}

// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    studyActions: bindActionCreators(StudyActions, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(Label_Menu);