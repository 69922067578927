
import React from "react";
import {GPAP_Button_Light} from "../../gpap-lib/components/Button_Collection";
import Box from "@mui/material/Box";



function PlaygroundVersion(){


    if (window.config.config_keycloak.realm === "playground"){

        return   <Grid item>
            <Box p={1}>
            <GPAP_Button_Light href={"#/playground_cases"} target={"_blank"}> Playground Solutions</GPAP_Button_Light>
        </Box>
        </Grid>

    }
    else{
        return null;
    }



}


export default PlaygroundVersion