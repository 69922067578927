import React from 'react';
import { Grid } from '@mui/material';
import { SearchSample } from './SearchSample';
import { ControlExperiment } from './ControlExperiment';
import { ExperimentParticipantMetadata } from '../ExperimentParticipantMetadata';

export const AnalysisTypeShowExperiments = ({
  analysis_type,
  searchByParticipant,
  error_tumor_experiment_id,
  error_control_experiment_id,
  handleSearchExperiment,
  handleSearchParticipant,
  control_experiment_id,
  tumor_experiment_id,
  control_participant_id,
  tumor_participant_id
}) => {

  const renderSearchSample = (title, errorMessage, field, searchExperiment, sampleType, participantID) => (
    <SearchSample
      title={title}
      searchExperiment={searchExperiment}
      errorMessage={errorMessage}
      field={field}
      searchText={searchExperiment ? "Search Experiment ID" : "Search Participant ID"}
      sampleType={sampleType}
      handleSearch={searchExperiment ? handleSearchExperiment : handleSearchParticipant} //functions to handle search
      filterParticipant={searchByParticipant}
      participantID={participantID}
    />
  );

  const renderExperimentParticipantMetadata = (experimentId, sampleType) => (
    <Grid item xs={12}>
      <ExperimentParticipantMetadata experimentId={experimentId} sampleType={sampleType} />
    </Grid>
  );

  if (analysis_type === "germline") {
    return (
      <>
        {searchByParticipant && renderSearchSample("Control Patient ID", error_control_experiment_id, "control_participant_id", false, "germline", control_participant_id)}
        {renderSearchSample("Control Experiment ID", error_control_experiment_id, "control_experiment_id", true, "germline", control_participant_id)}
        {renderExperimentParticipantMetadata(control_experiment_id, "Control")}
      </>
    );
  } else if (analysis_type === "somatic_tumor_normal") {
    return (
      <>
        {searchByParticipant && renderSearchSample("Patient ID", error_tumor_experiment_id, "tumor_participant_id", false, "somatic", tumor_participant_id)}
        {renderSearchSample("Tumor Experiment ID", error_tumor_experiment_id, "tumor_experiment_id", true, "somatic", tumor_participant_id)}
        <ControlExperiment
          title="Experiment ID"
          errorMessage={error_control_experiment_id}
          value={control_experiment_id}
          id="control_experiment_id"
        />
        {renderExperimentParticipantMetadata(control_experiment_id, "Control")}
      </>
    );
  } else if (analysis_type === "somatic_fusion_analysis" || analysis_type === "somatic_tumor_only") {
    return (
      <>
        {searchByParticipant && (
          <Grid item xs={12}>
            {renderSearchSample("Tumor Patient ID", error_tumor_experiment_id, "tumor_participant_id", false, "somatic", tumor_participant_id)}
          </Grid>
        )}
        {renderSearchSample("Tumor Experiment ID", error_tumor_experiment_id, "tumor_experiment_id", true, "somatic", tumor_participant_id)}
        {renderExperimentParticipantMetadata(tumor_experiment_id, "Tumor")}
      </>
    );
  } else {
    return null;
  }
};