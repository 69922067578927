import React from 'react';
import { Card, CardContent, Typography, Chip, CardActions, Collapse } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';

const OncoEvidenceCard = ({ info, index, expanded, onExpandClick }) => {
    const borderColor = info.color;

    return (
        <Card style={{ margin: '10px 0', maxWidth: "200px", padding: 0 }} elevation={1}>
            <CardContent
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '6px 16px',
                    
                }}
            >
                <Typography variant="body2" style={{ fontWeight: 700, borderBottom: `3px solid ${borderColor}`, }}>
                    {info.category || info.key || "N/A"}
                </Typography>
                <Chip
                    size="small"
                    variant="filled"
                    label={info.points}
                    style={{ backgroundColor: "#00000014", color: "#000", borderRadius: "100px", marginLeft: '8px' }}
                />
                <CardActions style={{ marginLeft: 'auto', padding: 0 }}>
                    <IconButton
                        onClick={() => onExpandClick(index)}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </CardActions>
            </CardContent>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent style={{ padding: "0 16px 6px 16px", display: "flex", flexDirection: "column" }}>
                    <Typography variant="caption" style={{ color: "gray", fontStyle: "italic" }}>
                        {info.evidence_family}
                    </Typography>
                    <Typography variant="caption">{info.comment}</Typography>
                </CardContent>
            </Collapse>
        </Card>
    );
};

export default OncoEvidenceCard;