

import React, {useEffect, useMemo, useRef} from 'react'
import styled from 'styled-components'
import {useTable, useRowSelect, usePagination, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy  } from "react-table";
import {matchSorter} from "match-sorter";
import Checkbox from '@mui/material/Checkbox';

import Box from "@mui/material/Box";
import MaUTable from "@mui/material/Table";
import {TableBody, TableCell, TableHead, TableRow, Pagination, TablePagination, Typography} from "@mui/material";
import MappedText from '../mapText.js';

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

const Styles = styled.div`
  /*padding: 1rem;*/
  border-radius: 10px;

  table {
    border-spacing: 0;
    /*
    border: 1px solid black;
    */

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      //border-bottom: 1px solid #3E69A9;
      border-right:  1px solid var(--primary-contrast, #FFF);
      gap: 30px;

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`





// Define a default UI for filtering
function DefaultColumnFilter({
                                 column: { filterValue, preFilteredRows, setFilter },
                             }) {
    const count = preFilteredRows.length

    if (!filterValue) {
    return null;
  }

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined); 
      }}
      placeholder={`Search ${count} records...`}
      style={{
        width: "143px",
        height: "40px",
        borderRadius: "4px",
        border: "solid 1px",
        borderColor: "rgba(0, 0, 0, 0.23)",
        padding: "0 0 0 20px",
      }}
    />
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
                                column: { filterValue, setFilter, preFilteredRows, id },
                            }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

function Table({ columns, data, showCheckbox, handleSelectedRows, dataType }) {
    // Use the state and functions returned from useTable to build your UI

    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const modifiedColumns = useMemo(() => {
        if (showCheckbox) {
            return [
                {
                    id: 'selection',
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            <Checkbox {...getToggleAllRowsSelectedProps()} />
                        </div>
                    ),
                    Cell: ({ row }) => (
                        <div>
                            <Checkbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ];
        }
        return columns;
    }, [columns, showCheckbox]);


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page
        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        state,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        visibleColumns,
        selectedFlatRows,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize, selectedRowIds },
    } = useTable(
        {
            columns: modifiedColumns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            initialState: {
                selectedRowIds: data.reduce((acc, row, index) => {
                    acc[index] = true; // Select all rows by default
                    return acc;
                }, {}),
                pageSize: 10 }
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!,
        useSortBy,
        usePagination,
        useRowSelect,
      /*  hooks => {
            hooks.visibleColumns.push(columns => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({ getToggleAllPageRowsSelectedProps }) => (
                        <div>
                            <Checkbox {...getToggleAllPageRowsSelectedProps()} />
                        </div>
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }) => (
                        <div>
                            <Checkbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ])
        }*/
    )

    React.useEffect(() => {

        if(handleSelectedRows){
            handleSelectedRows(selectedFlatRows);
        }

    }, [selectedFlatRows.length]);

    // Render the UI for your table
    return (<div>
            <div   style={{    overflow: "auto"}}>
                <MaUTable
                    style ={{ background:"#fafafa", padding: 0 }}
                    {...getTableProps()}>
                    <TableHead style={{background: "#3E69A9", color: "white"}}>
                    {headerGroups.map(headerGroup => (
                        <TableRow {...headerGroup.getHeaderGroupProps()} style={{color: "white"}}>
                            {headerGroup.headers.map(column => (
                                <TableCell  {...column.getHeaderProps(column.getSortByToggleProps(), {
                                })} style={{color: "white", width: "20px"}} >
                                    {column.render('Header')}
                                    {/* <MappedText text={column.render('Header')}/> */}
                                    <span>
                                    {column.isSorted
                                        ? column.isSortedDesc
                                            ? ' 🔽'
                                            : ' 🔼'
                                        : ''}
                                  </span>
                                </TableCell>
                            ))}
                        </TableRow>

                    ))}
                    </TableHead>
                    {
                        (page.length === 0)
                        ? <div style={{padding: "24px"}}>
                            <Typography variant={"body1"} style={{fontWeight: "bold", color: "gray"}}><MappedText text={"No "}/>{dataType} <MappedText text={" calculated"}/></Typography>
                            <Typography variant={"secondaryText"} style={{color: "gray"}} ><MappedText text={"Please fill these in manually after downloading the report"}/></Typography>
                            </div>
                        : <TableBody {...getTableBodyProps()}>
                                {headerGroups.map(headerGroup => (
                                    <TableRow {...headerGroup.getHeaderGroupProps()} style={{color: "white"}}>
                                        {headerGroup.headers.map(column => (
                                            <TableCell {...column.getHeaderProps()} style={{color: "white"}} >

                                                {/* Render the columns filter UI */}
                                                <div>{column.canFilter ? column.render('Filter') : null}</div>

                                            </TableCell>
                                        ))}
                                    </TableRow>

                                ))}
                                {page.map((row, i) => {
                                    prepareRow(row);
                                    return (
                                        <TableRow {...row.getRowProps()}>

                                            {row.cells.map(cell => {
                                                return <TableCell {...cell.getCellProps()}>
                                                    {cell.render('Cell')}
                                                </TableCell>
                                            })}
                                        </TableRow>
                                    )
                                })}
                            </TableBody>

                    }
                </MaUTable>
            </div>
            <Box>
                {data.length > 10 && (
                    <div style={{ display: "flex", alignItems: "center", height: "50px", justifyContent: "center"}}>
                    <TablePagination
                        component="div"
                        count={data.length}
                        rowsPerPage={pageSize}
                        page={pageIndex}
                        onPageChange={(event, newPage) => gotoPage(newPage)}
                        onRowsPerPageChange={(event) => setPageSize(Number(event.target.value))}
                        style={{ '& .MuiTablePagination-actions': { display: 'none' } }}
                    />

                    <Pagination
                        style={{ marginLeft: '40px' }}
                        count={pageCount}
                        page={pageIndex + 1}
                        onChange={(event, value) => gotoPage(value - 1)}
                        showFirstButton
                        showLastButton
                    />
                    </div>
                )}
                </Box>
        </div>
    )
}

function GeneralTable(props) {
    const columns = useMemo(
      () => props.columns.map(column => ({
          ...column,
          Header:
            typeof column.Header === 'string' ? (
              <MappedText text={column.Header} />
            ) : (
              column.Header
            ),
        })),
      [props.columns]
    );
  
    const data = props.data;
    const showCheckbox = props.showCheckbox;

    return (
        <Styles>
            <Table columns={columns}
                   data={data}
                   dataType = {props.dataType}
                   showCheckbox={showCheckbox}
                   handleSelectedRows={props.handleSelectedRows} />
        </Styles>
    )
}

export default GeneralTable
